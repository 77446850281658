import { Center, Stack, Text } from '@mantine/core';
import type { Dispatch, SetStateAction } from 'react';
import useTranslation from 'next-translate/useTranslation';

import UserRow from './UserRow';

type DrawerUserListProps = {
  options: string[];
  optionsSelected: string[];
  setOptionsSelected: Dispatch<SetStateAction<string[]>>;
  isEmail?: boolean;
};

function DrawerUserList({
  options,
  optionsSelected,
  setOptionsSelected,
  isEmail,
}: DrawerUserListProps) {
  const { t } = useTranslation('common');

  if (options.length === 0) {
    return (
      <Center>
        <Text size={14}>{t('invites.modal.noUsersFound')}</Text>
      </Center>
    );
  }

  return (
    <Stack spacing={2}>
      {options.map((userJSON: string) => {
        const user = isEmail ? userJSON : JSON.parse(userJSON);
        const isChecked = optionsSelected.includes(userJSON);

        const handleOnChange = () => {
          if (optionsSelected.includes(userJSON)) {
            const removedUsers = optionsSelected.filter((invite) => invite !== userJSON);
            setOptionsSelected(removedUsers);
            return;
          }
          setOptionsSelected([...optionsSelected, userJSON]);
        };

        return (
          <UserRow
            key={user.id}
            isChecked={isChecked}
            onChange={handleOnChange}
            username={isEmail ? user : user.username ?? user.name}
            isDrawer
          />
        );
      })}
    </Stack>
  );
}

export default DrawerUserList;
