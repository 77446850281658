export default {
  layout: {
    main: 'myContests.layout.main',

    header: {
      main: 'myContests.header.main',
      title: 'myContests.header.title',
      chevron: 'myContests.header.chevron',
      breadcrumps: 'myContests.header.breadcrumps',
      subTitle: 'myContests.header.subTitle',
      poster: 'myContests.header.poster',
      tabs: {
        main: 'myContests.header.tabs.main',
        list: 'myContests.header.tabs.list',
        active: 'myContests.header.tabs.active',
      },
      action: 'myContests.header.action',
    },

    content: {
      main: 'myContests.content.main',
      pills: 'myContests.content.pills',
      grid: {
        main: 'myContests.content.grid.main',
      },
    },
    skeleton: 'myContests.skeleton',
  },
};
