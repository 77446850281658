import { useSyncExternalStore } from 'react';

import XPointSingleton from '~/utils/xpoint';

function getLocationState() {
  return XPointSingleton.getLocationTokenData()?.state;
}

function useLocationState() {
  return useSyncExternalStore(XPointSingleton.subscribeToLocationToken, getLocationState);
}

export { useLocationState };
