/**
 * There is a known issue with `isLoading` when `enabled` is set to `false`
 *
 * The `isLoading` is always `true` even when the query is not loading.
 *
 * The solution is based on https://github.com/TanStack/query/issues/3584#issuecomment-1256986636
 */
export function isLoadingFixedForDisabledQuery(isLoading: boolean, fetchStatus: string) {
  return isLoading && fetchStatus !== 'idle';
}
