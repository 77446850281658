import type { TablerIconsProps } from '@tabler/icons-react';
import { IconBrandMastercard, IconBrandVisa, IconCreditCard } from '@tabler/icons-react';

import { CardType } from '~/services/wallet/interfaces/wallets.interface';

const cartTypeToIconComponent = {
  [CardType.MC]: IconBrandMastercard,
  [CardType.VI]: IconBrandVisa,
};

export default function CardTypeIcon({ type, ...props }: TablerIconsProps & { type?: CardType }) {
  const Icon = cartTypeToIconComponent[type] ?? IconCreditCard;
  return <Icon {...props} />;
}
