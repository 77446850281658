import { MantineProvider } from '@mantine/core';
import Head from 'next/head';
import { createContext, useCallback, useMemo, useState } from 'react';

import { mainTheme } from '~/config/theme';
import { getPrimaryThemeColor } from '~/config/themeUtils';

const ThemeContext = createContext({
  theme: mainTheme,
  changeTheme: () => {},
});

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  const themes = useMemo(() => [mainTheme], []);

  const [activeTheme, setActiveTheme] = useState(mainTheme);

  const handleThemeChange = useCallback(() => {
    const activeThemeIndex = themes.findIndex((theme) => theme === activeTheme);
    if (activeThemeIndex === themes.length - 1) {
      setActiveTheme(themes[0]);
    } else {
      setActiveTheme(themes[activeThemeIndex + 1]);
    }
  }, [activeTheme, themes]);

  const value = useMemo(
    () => ({ theme: activeTheme, changeTheme: handleThemeChange }),
    [activeTheme, handleThemeChange]
  );

  return (
    <ThemeContext.Provider value={value}>
      <Head>
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color={getPrimaryThemeColor(activeTheme)}
        />
        <meta name="msapplication-TileColor" content={getPrimaryThemeColor(activeTheme)} />
        <meta name="theme-color" content={activeTheme.colors.dark[8]} />
      </Head>
      <MantineProvider withNormalizeCSS theme={activeTheme}>
        {children}
      </MantineProvider>
    </ThemeContext.Provider>
  );
}
