import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { PaymentMethod, TransactionType } from '~/common/enums/payments.enum';
import { getTransactionLimits } from '~/services/wallet/wallet.service.api';

import { PAYMENTS_QUERY_STALE_TIME } from './consts';

const FALLBACK_MINIMUM = 10; // This is also the very minimum set by Peter Slater

type UseMinimumTransactionAmountArgs = { transactionType: TransactionType };

function useGetMinimumTransactionAmount({ transactionType }: UseMinimumTransactionAmountArgs) {
  const { data: transactionLimits } = useQuery({
    queryKey: ['payments/transactionLimits'],
    queryFn: getTransactionLimits,
    staleTime: PAYMENTS_QUERY_STALE_TIME,
  });

  return useCallback(
    (paymentMethod: PaymentMethod) =>
      Math.max(
        transactionLimits?.find(
          (limit) => limit.paymentMethod === paymentMethod && limit.type === transactionType
        )?.amount ?? 0,
        FALLBACK_MINIMUM
      ),
    [transactionLimits, transactionType]
  );
}

export { useGetMinimumTransactionAmount };
