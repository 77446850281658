import { Text } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { CurrencyConvertor } from '~/components/utils/formatters';

import useLeaderboardWidgetStyles from './styles';
import type { LeaderboardWidgetData } from './types';

function MetaGeneric({
  userEntries,
  entryPrice,
  isSettled,
  winning,
}: Omit<LeaderboardWidgetData, 'totalEntries' | 'winningEntries' | 'isSurvivor'>) {
  const { t } = useTranslation('contest');
  const { classes } = useLeaderboardWidgetStyles({});

  return (
    <div className={classes.meta}>
      <div className={classes.metaItem}>
        <Text size={10} span>
          {t('game.common.leadeboardWidget.myEntries')}&nbsp;
        </Text>
        <Text size="xs" span fw={700}>
          {userEntries.length}
        </Text>
      </div>
      {entryPrice && (
        <div className={classes.metaItem}>
          <Text size={10} span>
            {t('game.common.leadeboardWidget.entry')}&nbsp;
          </Text>
          <Text size="xs" span fw={700}>
            {CurrencyConvertor(entryPrice)}
          </Text>
        </div>
      )}
      <div className={classes.metaItem}>
        <Text size={10} span>
          {t(`game.common.leadeboardWidget.${isSettled ? 'won' : 'winning'}`)}&nbsp;
        </Text>
        <Text size="xs" span fw={700} color={winning === 0 ? 'gray.5' : 'green.5'}>
          {CurrencyConvertor(winning)}
        </Text>
      </div>
    </div>
  );
}

export default MetaGeneric;
