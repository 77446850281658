import { type AxiosInstance } from 'axios';
// import Cookies from 'js-cookie';

import { logError } from '~/common/utils/log';
import XPointSingleton from '~/utils/xpoint';

export const enum Method {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export const setLocationInterceptor = (
  instance: AxiosInstance,
  requiredTokenEndpoints: [Method, string][] = []
) => {
  instance.interceptors.request.use(
    async (config) => {
      if (process.env.STORYBOOK) {
        return config;
      }

      // Try to read the persisted token
      let token = XPointSingleton.getPersistedLocationToken();

      // Get fresh token for required endpoints (will still serve the persisted one if it's fresh)
      if (Array.isArray(requiredTokenEndpoints) && requiredTokenEndpoints.length > 0) {
        const requiredTokenEndpoint = requiredTokenEndpoints.find(
          ([method, url]) => method === config.method && url === config.url
        );

        if (requiredTokenEndpoint) {
          const { errors, token: newToken } = await XPointSingleton.getValidLocationToken(500);
          if (errors) {
            logError('location token error', errors);
            return Promise.reject(errors);
          }
          token = newToken;
        }
      }

      if (token) {
        config.headers.set('location-token', token);
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};
