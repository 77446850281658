import type { Sx } from '@mantine/core';
import { Text } from '@mantine/core';

import { useCountdown } from '../../hooks/useCountdown';

type ContestCountdownPillProps = {
  targetDate: Date;
  variant?: 'light' | 'dark';
  sx?: Sx;
};

function ContestCountdownPill({ targetDate, variant = 'dark', sx }: ContestCountdownPillProps) {
  const { timeLeft, isApproachingDeadline, hasDeadlinePassed } = useCountdown(new Date(targetDate));

  if (hasDeadlinePassed) {
    return null;
  }

  return (
    <Text
      sx={{
        width: 'fit-content',
        borderRadius: 50,
        padding: '2px 8px',
        background: variant === 'dark' ? '#000' : 'rgba(255, 255, 255, 0.1)',
        color: isApproachingDeadline ? '#DB3B3B' : '#D1D1D1',
        fontWeight: 600,
        fontSize: '11px',
        // tabular-nums ensure there is no layout shift when the time changes, but it makes it kinda ugly. Uncomment on your own risk :)
        // fontVariantNumeric: 'tabular-nums',
        ...sx,
      }}
    >
      {timeLeft}
    </Text>
  );
}

export default ContestCountdownPill;
