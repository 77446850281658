import styled from '@emotion/styled';
import type { MantineTheme } from '@mantine/core';

/** @deprecated used in legacy withdrawal form */
const RadioButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.fn.largerThan('md')} {
    flex-wrap: nowrap;
  }
`;

const getBackgroundColor = (theme: MantineTheme, isActive?: boolean) => {
  if (isActive) {
    return theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.dark[6];
  }
  return theme.colorScheme === 'dark' ? theme.black : theme.white;
};

const getForegroundColor = (theme: MantineTheme, isActive?: boolean) => {
  if (isActive) {
    return theme.colorScheme === 'dark' ? theme.colors.gray[3] : theme.white;
  }
  return theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black;
};

/** @deprecated used in legacy withdrawal form */
const RadioButton = styled.button<{ $active?: boolean }>`
  background-color: ${({ $active, theme }) => getBackgroundColor(theme, $active)};
  border: 1px solid
    ${({ theme }) => (theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4])};
  border-radius: 8px;
  color: ${({ $active, theme }) => getForegroundColor(theme, $active)};
  padding: 12px;
  flex: 0 1 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  gap: 8px;
  transition: border-color 100ms ease;

  &:focus,
  &:focus-within {
    ${({ theme }) => theme.focusRingStyles.inputStyles(theme)};
  }

  ${({ theme }) => theme.fn.largerThan('md')} {
    border-radius: 0;
    flex: 1 1 0;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

export { RadioButton, RadioButtonGroup };
