import { useForm } from '@mantine/form';
import { useDidUpdate } from '@mantine/hooks';
import useTranslation from 'next-translate/useTranslation';
import { useContext, useRef } from 'react';

import { Countries } from '~/common/constants/countries.enum';
import { PaymentMethod, PaymentType, TransactionType } from '~/common/enums/payments.enum';
import { UserContext } from '~/components/providers/UserProvider';
import { getFullName } from '~/components/utils/formatters';
import { formatDollars } from '~/domains/common/utils/formatters';
import { useGetMinimumTransactionAmount } from '~/domains/payments/hooks/useGetMinimumTransactionAmount';
import { useProcessingFees } from '~/domains/payments/hooks/useProcessingFees';

import { getAmount, isNewCardOption } from '../../utils/utils';
import { useDepositWithNewCard } from '../hooks/useDepositWithNewCard';
import { useDepositWithPaypal } from '../hooks/useDepositWithPaypal';
import { useDepositWithSavedCard } from '../hooks/useDepositWithSavedCard';
import { useDepositWithVIPP } from '../hooks/useDepositWithVIPP';
import { useDepositWithVenmo } from '../hooks/useDepositWithVenmo';
import { useDepositWithSkrill } from '../hooks/useDepositWithSkrill';

import { type CardDetailsFormSectionHandleRef } from './CardDetailsFormSection';
import {
  CUSTOM_AMOUNT_MAX_LIMIT,
  DepositOption,
  defaultCountryOption,
  defaultDepositOption,
} from './consts';
import type { DepositFormValues } from './types';

export default function useDepositForm({
  contestIdForPostDeposit,
  setCompletedDepositAmount,
  fallbackUrl,
}: {
  contestIdForPostDeposit?: string;
  setCompletedDepositAmount: (amount: number) => void;
  fallbackUrl?: string;
}) {
  const { t } = useTranslation('payments');
  const { user, userDetails } = useContext(UserContext);

  const getMinimumTransactionAmount = useGetMinimumTransactionAmount({
    transactionType: TransactionType.DEPOSIT,
  });

  const cardDetailsRef = useRef<CardDetailsFormSectionHandleRef>();

  const form = useForm<DepositFormValues>({
    initialValues: {
      paymentType: undefined,
      depositOption: defaultDepositOption,
      cardOption: undefined, // Will be set once cards are fetched
      customAmount: undefined,
      cardDetails: {
        cardholderName: getFullName(userDetails?.firstName, userDetails?.lastName),
        cardNumber: undefined, // Paysafe field
        cvv: undefined, // Paysafe field
        expDate: undefined, // Paysafe field
      },
      confirmCVV: '',
      billingAddress: {
        address: userDetails?.address ?? '',
        address2: '',
        city: userDetails?.city ?? '',
        country: userDetails?.country ?? defaultCountryOption,
        state: userDetails?.state ?? '',
        zip: userDetails?.zip ?? '',
      },
    },
    validate: {
      customAmount: (value, values) => {
        if (values.depositOption !== DepositOption.CUSTOM) return null;
        if (typeof value === 'undefined') {
          return t('deposits.form.amount.errors.mustNotBeEmpty');
        }
        if (value > CUSTOM_AMOUNT_MAX_LIMIT) {
          return t('deposits.form.amount.errors.mustBeLessThan', {
            amount: formatDollars(CUSTOM_AMOUNT_MAX_LIMIT),
          });
        }
        const paymentMethod =
          values.paymentType === PaymentType.PAYPAL ? PaymentMethod.PAYPAL : PaymentMethod.CARD;
        const minimumDepositAmount = getMinimumTransactionAmount(paymentMethod);
        if (value < minimumDepositAmount) {
          return t('deposits.form.amount.errors.mustBeMoreThan', {
            amount: formatDollars(minimumDepositAmount),
          });
        }
        return null;
      },
      confirmCVV: (value, values) => {
        if (values.paymentType !== PaymentType.CARD) return null;
        if (isNewCardOption(values.cardOption)) return null;
        if (/^[0-9]{3,4}$/.test(value)) return null;
        return t('deposits.form.confirmCVV.error');
      },
      cardDetails: {
        cardholderName: (value, values) => {
          if (values.paymentType !== PaymentType.CARD) return null;
          if (!isNewCardOption(values.cardOption)) return null;
          if (value.trim().length > 0) return null;
          return t('deposits.form.cardholderName.error');
        },
        cardNumber: (_, values) => {
          if (values.paymentType !== PaymentType.CARD) return null;
          if (!isNewCardOption(values.cardOption)) return null;
          if (cardDetailsRef.current?.validateCardNumber?.() ?? true) return null;
          return t('deposits.form.cardNumber.error');
        },
        expDate: (_, values) => {
          if (values.paymentType !== PaymentType.CARD) return null;
          if (!isNewCardOption(values.cardOption)) return null;
          if (cardDetailsRef.current?.validateExpiryDate?.() ?? true) return null;
          return t('deposits.form.expDate.error');
        },
        cvv: (_, values) => {
          if (values.paymentType !== PaymentType.CARD) return null;
          if (!isNewCardOption(values.cardOption)) return null;
          if (cardDetailsRef.current?.validateCVV?.() ?? true) return null;
          return t('deposits.form.cvv.error');
        },
      },
      billingAddress: {
        address: (value, values) => {
          if (values.paymentType !== PaymentType.CARD) return null;
          if (!isNewCardOption(values.cardOption)) return null;
          if (value.trim().length > 0) return null;
          return t('forms:billingAddress.address.error');
        },
        city: (value, values) => {
          if (values.paymentType !== PaymentType.CARD) return null;
          if (!isNewCardOption(values.cardOption)) return null;
          if (value.trim().length > 0) return null;
          return t('forms:billingAddress.city.error');
        },
        state: (value, values) => {
          if (values.paymentType !== PaymentType.CARD) return null;
          if (!isNewCardOption(values.cardOption)) return null;
          if (value.trim().length > 0) return null;
          return t('forms:billingAddress.state.error');
        },
        zip: (value, values) => {
          if (values.paymentType !== PaymentType.CARD) return null;
          if (!isNewCardOption(values.cardOption)) return null;

          const isUSZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
          const isCanadaPostalCode = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/.test(value);

          if (values.billingAddress.country === Countries.US && !isUSZipCode) {
            return t('forms:billingAddress.zip.error.US');
          }

          if (values.billingAddress.country === Countries.CA && !isCanadaPostalCode) {
            return t('forms:billingAddress.zip.error.CA');
          }

          return null;
        },
        country: (value, values) => {
          if (values.paymentType !== PaymentType.CARD) return null;
          if (!isNewCardOption(values.cardOption)) return null;
          if (value.trim().length > 0) return null;
          return t('forms:billingAddress.country.error');
        },
      },
    },
  });

  // setValues and onSubmit are memoized, form is not
  const { setValues, onSubmit } = form;

  // When accessing deposit page directly, we don't have user details loaded yet
  useDidUpdate(() => {
    setValues({
      cardDetails: {
        cardholderName: getFullName(userDetails.firstName, userDetails.lastName),
        cardNumber: undefined,
        cvv: undefined,
        expDate: undefined,
      },
      billingAddress: {
        address: userDetails.address,
        address2: '',
        city: userDetails.city,
        country: userDetails.country,
        state: userDetails.state,
        zip: userDetails.zip,
      },
    });
  }, [userDetails]);

  const depositAmount = getAmount(form.values.depositOption, form.values.customAmount || 0);
  const paymentMethod =
    form.values.paymentType === PaymentType.PAYPAL ? PaymentMethod.PAYPAL : PaymentMethod.CARD;
  const { calculateFee } = useProcessingFees({
    transactionType: TransactionType.DEPOSIT,
    paymentMethod,
  });
  const depositAmountWithFees = depositAmount + calculateFee(depositAmount);

  const { mutate: depositWithPaypal, isLoading: isDepositingWithPaypal } = useDepositWithPaypal();
  const { mutate: depositWithVIPP, isLoading: isDepositingWithOnlineBanking } =
    useDepositWithVIPP();
  const {
    mutate: depositWithVenmo,
    isLoading: isCreatingVenmoToken,
    isSuccess: isDepositingWithVenmoToken,
  } = useDepositWithVenmo({
    setCompletedDepositAmount,
  });
  const { mutate: depositWithNewCard, isLoading: isDepositingWithNewCard } = useDepositWithNewCard({
    setCompletedDepositAmount,
  });
  const { mutate: depositWithSavedCard, isLoading: isDepositingWithSavedCard } =
    useDepositWithSavedCard({
      setCompletedDepositAmount,
    });
  const { mutate: depositWithSkrill, isLoading: isDepositingWithSkrill } = useDepositWithSkrill();

  const isDepositingWithVenmo =
    isCreatingVenmoToken || (!isCreatingVenmoToken && isDepositingWithVenmoToken);
  const isDepositing =
    isDepositingWithPaypal ||
    isDepositingWithNewCard ||
    isDepositingWithSavedCard ||
    isDepositingWithOnlineBanking ||
    isDepositingWithVenmo ||
    isDepositingWithSkrill;

  const handleSubmit = onSubmit((values) => {
    if (isDepositing) {
      return;
    }

    if (values.paymentType === PaymentType.PAYPAL) {
      depositWithPaypal({
        depositAmount,
        depositAmountWithFees,
        email: user.email,
        contestIdForPostDeposit,
        fallbackUrl,
      });
      return;
    }

    if (values.paymentType === PaymentType.VIPPREFERRED) {
      depositWithVIPP({
        depositAmountWithFees,
        setCompletedDepositAmount,
      });
      return;
    }

    if (values.paymentType === PaymentType.VENMO) {
      depositWithVenmo({
        depositAmount,
        depositAmountWithFees,
        contestIdForPostDeposit,
        fallbackUrl,
      });
      return;
    }

    if (values.paymentType === PaymentType.SKRILL) {
      depositWithSkrill({
        depositAmount,
        depositAmountWithFees,
        contestIdForPostDeposit,
        fallbackUrl,
      });
      return;
    }

    if (isNewCardOption(values.cardOption)) {
      depositWithNewCard({
        paysafeInstance: cardDetailsRef.current.paysafeInstance,
        billingDetails: {
          // TODO: Can't we get rid of address2? We are never using it on its own, it's only visual...
          address:
            `${form.values.billingAddress.address} ${form.values.billingAddress.address2}`.trim(),
          city: form.values.billingAddress.city.trim(),
          country: form.values.billingAddress.country,
          state: form.values.billingAddress.state,
          zip: form.values.billingAddress.zip,
        },
        cardholderName: form.values.cardDetails.cardholderName,
        depositAmount,
        depositAmountWithFees,
        email: user.email,
      });
      return;
    }

    depositWithSavedCard({
      cvv: form.values.confirmCVV,
      depositAmount,
      depositAmountWithFees,
      paymentHandleId: form.values.cardOption,
    });
  });

  return {
    form,
    handleSubmit,
    isDepositing,
    cardDetailsRef,
    depositAmountWithFees,
    paymentMethod,
  };
}
