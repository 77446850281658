import { useTheme } from '@emotion/react';
import { Box, Tooltip, keyframes } from '@mantine/core';

import { Avatar } from '~/domains/common/components/Avatar/Avatar';

import type { LeaderboardWidgetEntryProps } from './types';

const rotate = keyframes({
  '100%': { transform: 'rotate(360deg)' },
});

type PickEmUserBarEntryProps = Omit<LeaderboardWidgetEntryProps, 'rank'> & {
  pointsTooltip?: string;
  rankPct: number; // Rank needs to be percentage based
  compact?: boolean; // Styling variant
};

export function LeaderboardWidgetEntry({
  avatarSrc,
  rankPct,
  isSelected,
  pointsTooltip,
  compact,
}: PickEmUserBarEntryProps) {
  const theme = useTheme();
  const showSelectedAnimation = isSelected && !compact;

  const avatar = (
    <Avatar
      src={avatarSrc}
      size={showSelectedAnimation ? 20 : 16}
      radius="xl"
      styles={{
        placeholder: {
          backgroundColor: isSelected ? theme.colors.cyan[5] : theme.colors.gray[5],
          border: `none`,
        },
        placeholderIcon: {
          color: 'white',
          height: '100%',
          width: '100%',
        },
      }}
    />
  );

  return (
    <Box
      pos="absolute"
      top="50%"
      left={`${rankPct}%`}
      p={isSelected ? theme.spacing.xl / 4 : 0}
      sx={{
        transform: 'translate(-50%, -50%)',
        backgroundColor: showSelectedAnimation ? theme.white : 'transparent',
        borderRadius: '50%',
        '&:hover': {
          zIndex: 1,
        },
      }}
    >
      {showSelectedAnimation ? (
        <Box
          pos="absolute"
          sx={{
            inset: 0,
            borderRadius: '100%',
            border: `2px dashed ${theme.colors.cyan[5]}`,
            animation: `${rotate} 4s linear infinite`,
          }}
        />
      ) : null}
      {pointsTooltip ? (
        <Tooltip label={pointsTooltip} color="dark" position="bottom" withArrow>
          <div>{avatar}</div>
        </Tooltip>
      ) : (
        avatar
      )}
    </Box>
  );
}
