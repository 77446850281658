import packageJson from '../../../../package.json';

const logStyles = {
  'font-weight': 'regular',
  background: '#4bebe2',
  color: 'black',
  padding: '3px 7px',
  'margin-bottom': '2px',
};

const parseStyles = (styled: Record<string, string>) =>
  Object.entries(styled)
    .map(([key, value]) => `${key}: ${value}`)
    .join('; ');

const logVersionToConsole = () => {
  // eslint-disable-next-line no-console
  console.log(
    `%cSplash web client%c\n%cVersion: %c${packageJson.version}%c\n%cEnvironment: %c${process.env.NEXT_PUBLIC_ENV}`,
    parseStyles(logStyles),
    '',
    parseStyles({ ...logStyles, 'padding-right': '0px' }),
    parseStyles({ ...logStyles, 'font-weight': 'bold', 'padding-left': '0px' }),
    '',
    parseStyles({ ...logStyles, 'padding-right': '0px' }),
    parseStyles({ ...logStyles, 'font-weight': 'bold', 'padding-left': '0px' })
  );
};

export default logVersionToConsole;
