import queryString from 'query-string';

import { nextApiAuthServiceInstance } from './nextApi.instance';

export const getQRcodeBase64 = async (
  branchLink: string,
  size = 150,
  margin = 0
): Promise<string> => {
  const query = queryString.stringify({ link: branchLink, size, margin });

  const qrResponse = await nextApiAuthServiceInstance.get(`/qrcode/base64?${query}`);

  return qrResponse.data;
};
