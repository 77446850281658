import { Box, Flex } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { useIsTablet } from '~/domains/common/hooks/mediaQueries';

import MetaGeneric from './MetaGeneric';
import MetaSurvivor from './MetaSurvivor';
import { LeaderboardWidgetEntry } from './LeaderboardWidgetEntry';
import type { LeaderboardWidgetProps } from './types';
import LeaderboardWidgetProgressBar from './LeaderboardWidgetProgressBar';

export function LeaderboardWidget({
  isSurvivor = false,
  isOnlyProgress = false,
  isVisibleOnTablet = false,
  isCompact = false,
  isWidthAuto = false,
  leaderboard,
  progressBarHeight,
}: LeaderboardWidgetProps) {
  const { t } = useTranslation('contest');
  const isTablet = useIsTablet();

  if ((!isVisibleOnTablet && isTablet) || !leaderboard) return null;

  const { entryPrice, isSettled, userEntries, totalEntries, winning, winningEntries } = leaderboard;

  const styles = isCompact
    ? {
        gap: 12,
        pt: 8,
        pb: 12,
      }
    : {
        gap: 20,
        pt: 12,
        pb: 18,
      };

  return (
    <Flex direction="column" {...styles} miw={isWidthAuto ? '100%' : '350px'}>
      {!isOnlyProgress && isSurvivor && (
        <MetaSurvivor
          userEntries={userEntries}
          entryPrice={entryPrice}
          isSettled={isSettled}
          totalEntries={totalEntries}
          winning={winning}
          winningEntries={winningEntries}
        />
      )}
      {!isOnlyProgress && !isSurvivor && (
        <MetaGeneric
          userEntries={userEntries}
          entryPrice={entryPrice}
          winning={winning}
          isSettled={isSettled}
        />
      )}

      <Box pos="relative">
        <LeaderboardWidgetProgressBar
          progressBarHeight={progressBarHeight}
          isSurvivor={isSurvivor}
          isSettled={isSettled}
          value={(winningEntries / totalEntries) * 100}
        />
        {!isSurvivor &&
          userEntries?.map((entry) => (
            <LeaderboardWidgetEntry
              key={entry.id}
              {...entry}
              rankPct={100 - (entry.rank / (totalEntries - 1)) * 100}
              compact={isCompact}
              pointsTooltip={
                typeof entry.points === 'number'
                  ? t('game.common.leadeboardWidget.tooltip__hasPlural', {
                      index: entry.entryNumber ?? entry.id,
                      count: parseFloat(entry.points.toFixed(2)),
                    })
                  : undefined
              }
            />
          ))}
      </Box>
    </Flex>
  );
}
