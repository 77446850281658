import { Box, Button, Group, Stack, Text, createStyles } from '@mantine/core';
import type { Dispatch, SetStateAction } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { FEATURE } from '~/common/enums/feature.enum';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

import type { EntrantUser } from '../../../services/interfaces/entrant.interface';

import UserRow from './UserRow';

type UserListProps = {
  options: EntrantUser[];
  optionsSelected: string[];
  setOptionsSelected: Dispatch<SetStateAction<string[]>>;
  selectedItem: string;
  contestsSelected: string[];
  setContestsSelected: Dispatch<SetStateAction<string[]>>;
};

const useStyles = createStyles((theme) => ({
  list: {
    position: 'relative',
  },
  listHeader: {
    position: 'sticky',
    top: 0,
    background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    zIndex: 2,
    paddingBottom: `${theme.spacing.sm}px`,
  },
}));

function UserList({
  options,
  optionsSelected,
  setOptionsSelected,
  selectedItem,
  contestsSelected,
  setContestsSelected,
}: UserListProps) {
  const { t } = useTranslation('common');
  const { classes } = useStyles();
  const isAddAllEnabled = useIsFeatureEnabled(FEATURE.SHOW_ADD_ALL_USERS_ON_INVITES);

  const handleAddAll = () => {
    setContestsSelected([...contestsSelected, selectedItem]);
  };

  return (
    <Stack w="100%" spacing={2} h="calc(100% - 10px)">
      {options?.length > 0 && (
        <Box className={classes.list}>
          <Group position="apart" className={classes.listHeader}>
            <Text size={12} transform="uppercase">
              {t('invites.modal.users__hasPlural', { count: options?.length })}
            </Text>
            {isAddAllEnabled &&
              (contestsSelected.includes(selectedItem) ? (
                <Button disabled onClick={handleAddAll}>
                  {t('invites.modal.added')}
                </Button>
              ) : (
                <Button onClick={handleAddAll}>
                  <Text color="black">{t('invites.modal.addAll')}</Text>
                </Button>
              ))}
          </Group>
          <Stack spacing={2}>
            {options.map((user: EntrantUser) => {
              const userJSON = JSON.stringify(user);
              const isChecked = optionsSelected.includes(userJSON);

              const handleOnChange = () => {
                if (optionsSelected.includes(userJSON)) {
                  const removedUsers = optionsSelected.filter((invite) => invite !== userJSON);
                  setOptionsSelected(removedUsers);
                  return;
                }
                setOptionsSelected([...optionsSelected, userJSON]);
              };

              return (
                <UserRow
                  key={user.id}
                  isChecked={isChecked}
                  onChange={handleOnChange}
                  username={user.username}
                />
              );
            })}
          </Stack>
        </Box>
      )}
    </Stack>
  );
}

export default UserList;
