import { QueryClient } from '@tanstack/react-query';

// @review and fine tune when and how often we should fetch data
// for now during dev let's explicitly make requests
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: false, // will probably want to change this
    },
  },
});

// defaultOptions: {
//   queries: {
//     refetchOnWindowFocus: false,
//     refetchOnmount: false,
//     refetchOnReconnect: false,
//     retry: false,
//     staleTime: 5*60*1000,
//   },
// },

export default queryClient;
