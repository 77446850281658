import type { PropsWithChildren } from 'react';
import React from 'react';
import { createStyles, Center } from '@mantine/core';
import { renderToStaticMarkup } from 'react-dom/server';
import { keyframes } from '@emotion/react';

import { SplashyWave, PrimaryWhale } from '~/components/shared/Logos/SplashLogo';

const wave = keyframes`
  0% { background-position-x: 0% }
  100% { background-position-x: -500px }
`;

const jumpWhale = keyframes`
  0% { transform: translate(0, 60px) rotate(-45deg) }
  30% { transform: translate(10px, 0px) rotate(-15deg) }
  60% { transform: translate(20px, -4px) rotate(0deg) }
  100% { transform: translate(32px, 60px) rotate(50deg) }
`;

const useStyles = createStyles((_, { encodedSvg }: { encodedSvg: string }) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  innerWrapper: {
    overflow: 'hidden',
    marginBottom: 12,
  },
  primaryWhale: {
    position: 'relative',
    top: 5,
    transform: 'translate(0, 60px) rotate(-45deg)',
    animation: `${jumpWhale} 5s cubic-bezier(0.34, 0.49, 0.64, 0.85) infinite`,
    animationDelay: '-2.9s',
  },
  wave: {
    backgroundImage: `url('data:image/svg+xml;base64,${encodedSvg}')`,
    backgroundRepeat: 'repeat-x',
    backgroundSize: '50px auto',
    backgroundPosition: 'bottom',
    width: 100,
    height: 20,
    animation: `${wave} 8s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite`,
    zIndex: 1,
    borderRadius: 8,
  },
}));

export function SplashLoader({ children }: PropsWithChildren) {
  const svgCode = renderToStaticMarkup(<SplashyWave />);
  const base64Svg = btoa(svgCode);
  const { classes } = useStyles({ encodedSvg: base64Svg });

  return (
    <Center className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <PrimaryWhale size={28} className={classes.primaryWhale} />
        <div className={classes.wave} />
      </div>
      {children}
    </Center>
  );
}
