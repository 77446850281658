import axios from 'axios';

import { handleRefreshInterceptor } from '~/services/interceptors/handle-refresh.interceptor';
import { setAnonymousIdInterceptor } from '~/services/interceptors/set-anonymous-id.interceptor';
import { setAuthTokenInterceptor } from '~/services/interceptors/set-auth-token.interceptor';
import { setUserTimezoneInterceptor } from '~/services/interceptors/set-user-timezone.interceptor';

export const nextApiServiceInstance = axios.create({
  baseURL: `/api`,
});

export const nextApiAuthServiceInstance = axios.create({
  baseURL: `/api`,
});

setAuthTokenInterceptor(nextApiAuthServiceInstance);
handleRefreshInterceptor(nextApiAuthServiceInstance);
setAnonymousIdInterceptor(nextApiAuthServiceInstance);
setUserTimezoneInterceptor(nextApiAuthServiceInstance);
