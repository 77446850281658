import RequestHeadersPlatform from './RequestHeadersPlatform';

const RequestHeadersBase = {
  Accept: 'application/json',
  'Content-Type': 'application/json',

  // This is due to API restrictions for user agents blocking robots
  ...(typeof window === 'undefined' && { 'User-Agent': 'Splash Sports/Next' }),
  ...RequestHeadersPlatform,
};

export default RequestHeadersBase;
