import useTranslation from 'next-translate/useTranslation';
import { Box, MantineProvider, Skeleton } from '@mantine/core';

import dt from '~/domains/contest/domains/manage/domains/common/testing.const';
import { Routes } from '~/domains/common/constants/routes';

import { ButtonLink } from '../Button/Button';
import { useCurrentUserCommissionerChannel } from '../../hooks/useCurrentUserCommissionerChannel';

export function SubHeaderCommissionerCTA() {
  const { t } = useTranslation('common');

  const {
    showCommissionerCTA,
    isLoading: isLoadingCommissioner,
    data: channelData,
  } = useCurrentUserCommissionerChannel();

  if (!showCommissionerCTA) {
    return null;
  }

  const isCurrentUserCommissioner = !isLoadingCommissioner && channelData?.data?.slug;

  return (
    <Box pos="relative">
      {isLoadingCommissioner ? (
        <MantineProvider theme={{ colorScheme: 'dark' }}>
          <Skeleton h="100%" w="100%" pos="absolute" top={0} left={0} />
        </MantineProvider>
      ) : null}
      <ButtonLink
        data-test-id={dt.action.cta}
        href={isCurrentUserCommissioner ? Routes.createAContest() : Routes.commissionerOnboarding()}
        variant="outline"
        sx={{
          transition: '.2 opacity',
          opacity: isLoadingCommissioner ? 0 : 1,
        }}
      >
        {t('headerNavigation.createAContest')}
      </ButtonLink>
    </Box>
  );
}
