import { useContext } from 'react';
import { showNotification } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';

import { UserContext } from '~/components/providers/UserProvider';
import { reportEvent, updateAnalyticsUser } from '~/domains/analytics';

import { getWalletBalance } from '../../../services/wallet/wallet.service.api';

import { PAYMENTS_QUERY_STALE_TIME } from './consts';

type UseWalletBalanceProps = {
  enabled?: boolean;
};

let oldBallance = 0;

export function useWalletBalance({ enabled = true }: UseWalletBalanceProps = {}) {
  const { user } = useContext(UserContext);
  const isEnabled = !!user && enabled;

  const query = useQuery({
    queryKey: ['wallets/balance', user?.id],
    queryFn: async () => {
      const response = await getWalletBalance();
      return response.data;
    },
    enabled: isEnabled,
    staleTime: PAYMENTS_QUERY_STALE_TIME,
    onSuccess: ({ availableBalance }) => {
      if (availableBalance !== oldBallance) {
        updateAnalyticsUser({
          id: user.id,
          walletBallance: availableBalance,
        });

        oldBallance = availableBalance;

        reportEvent('Transactions > Balance change', {
          balance: availableBalance,
          currency: 'USD',
        });
      }
    },
    onError: () => {
      showNotification({
        title: 'Balance Currently Unavailable',
        message: 'Your account balance is temporarily unavailable.',
        color: 'red',
        autoClose: 5000,
      });
    },
  });

  return { ...query, enabled: isEnabled };
}
