import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import type { DropdownOffset, DropdownPosition } from './types';

const calculatePosition = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: any,
  position: DropdownPosition,
  offset: DropdownOffset = { x: 0, y: 0 }
) => {
  const top = (trigger as HTMLElement).offsetTop;
  const left = (trigger as HTMLElement).offsetLeft;
  const height = (trigger as HTMLElement).offsetHeight;
  const width = (trigger as HTMLElement).offsetWidth;
  const parent = (trigger as HTMLElement).offsetParent as HTMLElement;

  switch (position) {
    case 'bottom-center':
      return { top: top + height + (offset?.y ?? 0), left: left + (offset?.x ?? 0) + width / 2 };
    case 'bottom-start':
      return { top: top + height + (offset?.y ?? 0), left: left + (offset?.x ?? 0) };
    case 'bottom-end':
      return {
        top: top + height + (offset?.y ?? 0),
        right: parent ? parent.offsetWidth - left - width + (offset?.x ?? 0) : 0,
      };
    case 'top-center':
      return {
        bottom: parent ? parent.offsetHeight - top + (offset?.y ?? 0) : 0,
        left: left + (offset?.x ?? 0) + width / 2,
      };
    case 'top-start':
      return {
        bottom: parent ? parent.offsetHeight - top + (offset?.y ?? 0) : 0,
        left: left + (offset?.x ?? 0),
      };
    case 'top-end':
      return {
        bottom: parent ? parent.offsetHeight - top + (offset?.y ?? 0) : 0,
        right: parent ? parent.offsetWidth - left - width + (offset?.x ?? 0) : 0,
      };
    default:
      return {};
  }
};

const useDropdown = <T, D>({
  dropdownPosition = 'bottom-center',
  dropdownOffset,
  controlledIsOpen,
  controlledOnChange,
}: {
  dropdownPosition?: DropdownPosition;
  dropdownOffset?: DropdownOffset;
  controlledIsOpen?: boolean;
  controlledOnChange?: (isOpen: boolean) => void;
}) => {
  const triggerRef = useRef<T>(null);
  const dropdownRef = useRef<D>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [position, setPosition] = useState<{
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  }>({});

  const close = useCallback(() => {
    if (controlledOnChange) {
      controlledOnChange(false);
    } else {
      setIsOpen(false);
    }
  }, [controlledOnChange]);

  const open = useCallback(() => {
    if (controlledOnChange) {
      controlledOnChange(true);
    } else {
      setIsOpen(true);
    }
  }, [controlledOnChange]);

  const toggle = useCallback(() => {
    if (controlledIsOpen !== undefined) {
      controlledOnChange?.(!controlledIsOpen);
    } else {
      setIsOpen((o) => !o);
    }
  }, [controlledIsOpen, controlledOnChange]);

  useLayoutEffect(() => {
    setPosition(calculatePosition(triggerRef.current, dropdownPosition, dropdownOffset));
  }, [dropdownPosition, dropdownOffset]);

  useLayoutEffect(() => {
    const fn = (event: MouseEvent) => {
      if (!dropdownRef.current || !triggerRef.current) {
        return;
      }

      if (
        // @ts-expect-error no idea
        !dropdownRef.current.contains(event.target as Node) &&
        // @ts-expect-error no idea
        !triggerRef.current.contains(event.target as Node)
      ) {
        close();
      }
    };

    window.addEventListener('click', fn, true);

    return () => {
      window.removeEventListener('click', fn);
    };
  }, [close, isOpen]);

  return {
    triggerRef,
    dropdownRef,
    isOpen: controlledIsOpen ?? isOpen,
    open,
    close,
    toggle,
    position,
  };
};

export default useDropdown;
