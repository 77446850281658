import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  contestCardSection: {
    background: theme.colors.gray[2],
    padding: theme.spacing.md,
    width: `calc(100% + ${2 * theme.spacing.lg}px)`,
    margin: `${theme.spacing.md}px -${theme.spacing.lg}px`,
    display: 'flex',
    justifyContent: 'center',

    [theme.fn.smallerThan('md')]: {
      width: `calc(100% + ${2 * theme.spacing.md}px)`,
      padding: theme.spacing.xs,
      margin: `${theme.spacing.md}px -${theme.spacing.md}px`,
    },
  },
  contestCardContainer: {
    maxWidth: 440,
    flexGrow: 1,
  },
  numberInputContainer: {
    [theme.fn.smallerThan('md')]: {
      width: '100%',
      flexGrow: 1,
    },
  },
  numberInput: {
    width: 54,
    textAlign: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing.md,
      textAlign: 'center',
    },
  },
  ctaWrapper: {
    width: 'auto',
  },
}));

export default useStyles;
