import { Checkbox, Group, Stack, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { useTheme } from '@emotion/react';

import { Avatar } from '~/domains/common/components/Avatar/Avatar';

import { useStyles } from '../InviteModalMiddle.styles';

type UserRowProps = {
  isChecked: boolean;
  onChange: VoidFunction;
  username: string;
  isDrawer?: boolean;
};

function UserRow({ isChecked, onChange, username, isDrawer }: UserRowProps) {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  if (!username) {
    return null;
  }

  return (
    <Checkbox
      checked={isChecked}
      onChange={onChange}
      classNames={{
        body: cx(classes.userRowBody, {
          [classes.userRowBodyChecked]: isChecked,
          [classes.drawer]: isDrawer,
        }),
        labelWrapper: classes.userRowLabelWrapper,
      }}
      labelPosition="left"
      label={
        <Group>
          <Avatar className={classes.avatar}>
            <Text transform="uppercase" color="gray.9" fw={600}>
              {username?.slice(0, 2)}
            </Text>
          </Avatar>
          <Stack spacing={0}>
            <Text
              fw={700}
              color={isChecked ? 'gray.7' : `gray.${[theme.colorScheme === 'dark' ? 3 : 5]}`}
            >
              {username}
            </Text>
            {/* TODO: Handle "From RunYourPool" here */}
            {/* <Text color="dark.2" size={10}>
                          {user?.username}
                        </Text> */}
          </Stack>
        </Group>
      }
      icon={isDrawer ? IconTrash : undefined}
    />
  );
}

export default UserRow;
