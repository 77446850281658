export default {
  header: {
    title: 'Entries',
    tabs: {
      active: 'Active',
      finalized: 'Final',
    },
  },
  filters: {
    sortBy: {
      title: 'Sort by',
      options: {
        nextSlateStarting: 'Next slate starting soonest',
        recentlyEntered: 'Recently entered',
        highestEntryFee: 'Highest entry fee',
        lowestEntryFee: 'Lowest entry fee',
        highestTotalPrizes: 'Highest total prizes',
      },
    },
    gameType: {
      title: 'Game types',
      options: {
        all: 'All',
        teamSurvivor: 'Survivor',
        teamPickEm: "Pick'Em",
        playerTiers: 'Tiers',
        playerOneAndDone: 'One & Done',
      },
    },
    status: {
      title: 'Status',
      options: {
        all: 'All',
        missingPicks: 'Missing picks',
        scheduled: 'Not yet started',
        inProgress: 'Started',
      },
    },
  },
  states: {
    empty: {
      active: {
        title: 'No active entries',
        text: 'If a contest has ended or all of your entries in a contest have been eliminated, it is in the Final tab.<br></br><br></br>Click the home tab at the bottom to find some new contests to enter!',
      },
      finalized: {
        title: 'No final entries',
        text: 'You do not have any final entries.<br></br><br></br>Click the home tab at the bottom to find some new contests to enter!',
      },
    },
    error: {
      title: 'Something went wrong',
      subtitle1: 'Our servers could not handle your request.',
      subtitle2: "Don't worry, our development team was already notified. Try refreshing the page.",
      action: 'Reload the page',
    },
  },
  itemsCount: 'Showing {{count}} out of {{total}} contests',
};
