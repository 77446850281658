import { createContext, useContext, useReducer } from 'react';

type Action =
  | {
      type: 'openSignUpModal';
      payload: {
        contestId?: string;
      };
    }
  | {
      type: 'closeSignUpModal';
    };

type Dispatch = (action: Action) => void;

type State = {
  isSignUpModalOpen: boolean;
  contestId?: string;
};

const SignUpModalStateContext = createContext<State | undefined>(undefined);
const SignUpModalDispatchContext = createContext<Dispatch | undefined>(undefined);

function SignUpModalReducer(state: State, action: Action) {
  switch (action.type) {
    case 'openSignUpModal': {
      return {
        isSignUpModalOpen: true,
        contestId: action.payload.contestId,
      };
    }
    case 'closeSignUpModal': {
      return {
        isSignUpModalOpen: false,
      };
    }

    default: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore for DX purposes
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function SignUpModalContextProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(SignUpModalReducer, {
    isSignUpModalOpen: false,
  });

  return (
    <SignUpModalStateContext.Provider value={state}>
      <SignUpModalDispatchContext.Provider value={dispatch}>
        {children}
      </SignUpModalDispatchContext.Provider>
    </SignUpModalStateContext.Provider>
  );
}

// eslint-disable-next-line import/no-unused-modules
export const useSignUpModalStateContext = () => {
  const context = useContext(SignUpModalStateContext);

  if (context === undefined) {
    console.error('useSignUpModalContextState must be used within a SignUpModalContextProvider');
  }
  return context;
};

export const useSignUpModalDispatchContext = () => {
  const context = useContext(SignUpModalDispatchContext);

  if (context === undefined) {
    console.error('useSignUpModalContextDispatch must be used within a SignUpModalContextProvider');
  }
  return context;
};

export const useSignUpModalContext = () => {
  const stateContext = useSignUpModalStateContext();
  const dispatch = useSignUpModalDispatchContext();

  return {
    ...stateContext,
    dispatch,
  };
};
