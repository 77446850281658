import { Flex, Stack, Text, TextInput, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import type { UseFormReturnType } from '@mantine/form';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import { usePaysafe } from '~/domains/payments/hooks/usePaysafe';
import type { PaysafeInstance } from '~/domains/payments/types/paysafe';
import { PaysafeFieldId } from '~/domains/payments/types/paysafe';
import { getTextColor } from '~/domains/payments/utils/utils';
import dt from '~/testing';

import type { DepositFormValues } from './types';
import { PaysafeInput, type PaysafeInputHandleRef } from './PaysafeInput';

type CardDetailsFormSectionHandleRef = {
  validateCardNumber?: () => boolean;
  validateExpiryDate?: () => boolean;
  validateCVV?: () => boolean;
  paysafeInstance?: PaysafeInstance;
};

type CardDetailsFormSectionProps = {
  form: UseFormReturnType<DepositFormValues>;
};

const CardDetailsFormSection = forwardRef<
  CardDetailsFormSectionHandleRef,
  CardDetailsFormSectionProps
>(({ form }, ref) => {
  const { t } = useTranslation('payments');
  const theme = useMantineTheme();

  const { data: instance, isLoading: isPaysafeLoading } = usePaysafe();

  const cardNumberRef = useRef<PaysafeInputHandleRef>();
  const expiryDateRef = useRef<PaysafeInputHandleRef>();
  const cvvRef = useRef<PaysafeInputHandleRef>();
  useImperativeHandle(ref, () => ({
    validateCardNumber: cardNumberRef.current?.validate,
    validateExpiryDate: expiryDateRef.current?.validate,
    validateCVV: cvvRef.current?.validate,
    paysafeInstance: instance,
  }));

  return (
    <Stack
      spacing={8}
      data-test-id={dt.payments.deposits.depositForm.cardDetailsFormSelection.container}
    >
      <Text fw={700} size={18} color={getTextColor(theme)}>
        {t('deposits.form.cardDetails')}
      </Text>

      <TextInput
        {...form.getInputProps('cardDetails.cardholderName')}
        placeholder={t('deposits.form.cardholderName.placeholder')}
        label={t('deposits.form.cardholderName.label')}
        labelProps={{ size: 'sm' }}
        data-test-id={dt.payments.deposits.depositForm.cardDetailsFormSelection.cardholderNameInput}
        withAsterisk
        size="lg"
        w="100%"
      />

      <PaysafeInput
        id={PaysafeFieldId.CARD_NUMBER}
        paysafeInstance={instance}
        form={form}
        ref={cardNumberRef}
        label={t('deposits.form.cardNumber.label')}
        loading={isPaysafeLoading}
        dataTestId={dt.payments.deposits.depositForm.cardDetailsFormSelection.cardNumberInput}
      />

      <Flex w="100%" gap="md" align="flex-start" direction={{ base: 'column', sm: 'row' }}>
        <PaysafeInput
          id={PaysafeFieldId.EXPIRY_DATE}
          paysafeInstance={instance}
          form={form}
          ref={expiryDateRef}
          label={t('deposits.form.expDate.label')}
          loading={isPaysafeLoading}
          dataTestId={dt.payments.deposits.depositForm.cardDetailsFormSelection.expiryDateInput}
        />
        <PaysafeInput
          id={PaysafeFieldId.CVV}
          paysafeInstance={instance}
          form={form}
          ref={cvvRef}
          label={t('deposits.form.cvv.label')}
          loading={isPaysafeLoading}
          dataTestId={dt.payments.deposits.depositForm.cardDetailsFormSelection.cvvInput}
        />
      </Flex>
    </Stack>
  );
});

CardDetailsFormSection.displayName = 'CardDetailsFormSection';

export { CardDetailsFormSection, type CardDetailsFormSectionHandleRef };
