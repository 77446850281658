import { ActionIcon, Button, Group, Stack, Tabs, Text } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@emotion/react';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { IconX } from '@tabler/icons-react';

import { FEATURE } from '~/common/enums/feature.enum';
import { ApplicationType } from '~/domains/oauth/const';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

import { useCopyContestLink } from '../../../hooks/useCopyContestLink';
import { getHasUserLinkedRYP } from '../../../../oauth/utils/getAccessTokenData';

import LinkEOCTA from './shared/LinkEOCTA';

type InviteHeaderProps = {
  contestId: string;
  contestName: string;
  selectedMobileTab: string;
  setSelectedMobileTab: Dispatch<SetStateAction<string>>;
  emailOnly: boolean;
  onClose?: VoidFunction;
  isRYPOnly: boolean;
};

function InviteHeader({
  contestId,
  contestName,
  selectedMobileTab,
  setSelectedMobileTab,
  emailOnly,
  onClose,
  isRYPOnly,
}: InviteHeaderProps) {
  const { t } = useTranslation('common');
  const { handleCopyLink } = useCopyContestLink(contestId, contestName);
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });
  const enableOauth = useIsFeatureEnabled(FEATURE.SHOW_OAUTH_OPTIONS);
  const [showRYP, setShowRYP] = useState(true);
  const hasUserLinkedRYP = getHasUserLinkedRYP();
  const showRYPCTA = enableOauth && showRYP && !hasUserLinkedRYP;

  const handleTabChange = (tab) => {
    setSelectedMobileTab(tab);
  };

  return (
    <Stack>
      <Group position="apart">
        <Text size={22} fw={700} align="center">
          {t('invites.modal.title')}
        </Text>
        <Group>
          {!isRYPOnly && !emailOnly && (
            <Button onClick={handleCopyLink} color="gray.6">
              <Text fw={400}>{t('invites.modal.copyLink')}</Text>
            </Button>
          )}
          {onClose && (
            <ActionIcon onClick={onClose}>
              <IconX />
            </ActionIcon>
          )}
        </Group>
      </Group>
      {isTablet && (
        <Stack spacing={0}>
          <Tabs defaultValue="people" value={selectedMobileTab} onTabChange={handleTabChange}>
            <Tabs.List grow>
              {!emailOnly && (
                <>
                  {!isRYPOnly && <Tabs.Tab value="people">{t('invites.modal.people')}</Tabs.Tab>}
                  <Tabs.Tab value="contests">{t('invites.modal.contests')}</Tabs.Tab>
                </>
              )}
              {!isRYPOnly && <Tabs.Tab value="email">{t('invites.modal.addViaEmail')}</Tabs.Tab>}
            </Tabs.List>
          </Tabs>
          {showRYPCTA && <LinkEOCTA eo={ApplicationType.RYP} onClose={() => setShowRYP(false)} />}
        </Stack>
      )}
    </Stack>
  );
}

export default InviteHeader;
