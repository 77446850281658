import { ScrollArea, Stack, Text, Skeleton, Box } from '@mantine/core';
import type { Dispatch, MutableRefObject, ReactNode, SetStateAction } from 'react';
import { IconSearch } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';

import repeat from '~/common/utils/repeat';
import { safeJSONParse } from '~/common/utils/safeJSONParse.util';

import type { EntrantUser } from '../../services/interfaces/entrant.interface';

import { MOBILE_SECTION_HEIGHT } from './consts';
import UserList from './shared/UserList';

type PeoplePanelProps = {
  splashInvites: string[];
  setSplashInvites: Dispatch<SetStateAction<string[]>>;
  rypInvites: string[];
  setRYPInvites: Dispatch<SetStateAction<string[]>>;
  rypPools: string[];
  setRYPPools: Dispatch<SetStateAction<string[]>>;
  contests: string[];
  setContests: Dispatch<SetStateAction<string[]>>;
  users?: EntrantUser[];
  isSearching?: boolean;
  selectedItem: string;
  children: ReactNode;
  loaderRef: MutableRefObject<undefined>;
};

function PeoplePanel({
  splashInvites,
  setSplashInvites,
  rypInvites,
  setRYPInvites,
  rypPools,
  setRYPPools,
  contests,
  setContests,
  users,
  isSearching,
  children,
  selectedItem,
  loaderRef,
}: PeoplePanelProps) {
  const isRYPContest = selectedItem ? safeJSONParse(selectedItem)?.id.startsWith('ryp_') : false;
  const { t } = useTranslation('common');
  return (
    <Stack mt="lg" h={MOBILE_SECTION_HEIGHT}>
      {users?.length > 0 ? (
        <ScrollArea h="100%">
          <UserList
            selectedItem={selectedItem}
            options={users}
            setOptionsSelected={isRYPContest ? setRYPInvites : setSplashInvites}
            optionsSelected={isRYPContest ? rypInvites : splashInvites}
            contestsSelected={isRYPContest ? rypPools : contests}
            setContestsSelected={isRYPContest ? setRYPPools : setContests}
          />
          <Box ref={loaderRef} sx={{ height: isSearching ? '20px' : '1px' }} />
        </ScrollArea>
      ) : (
        <Stack h="100%" align="center" justify="center">
          {isSearching ? (
            <Stack h="100%" w="100%">
              {repeat(10, (i) => (
                <Skeleton key={i} height={30} width="100%" />
              ))}
            </Stack>
          ) : (
            <>
              <IconSearch />
              <Text w="70%" size={14} align="center">
                {t('invites.modal.usersEmptyState')}
              </Text>
            </>
          )}
        </Stack>
      )}
      {children}
    </Stack>
  );
}

export default PeoplePanel;
