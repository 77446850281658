import { useTheme } from '@emotion/react';
import { ActionIcon, Box, Group, Stack, Text } from '@mantine/core';
import { IconCirclePlus, IconTrash } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';
import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef, useState } from 'react';

import CardTypeIcon from '~/domains/payments/icons/CardTypeIcon';
import type { CardType } from '~/services/wallet/interfaces/wallets.interface';
import dt from '~/testing';

import { getIconColor } from '../../utils/utils';

import DeleteCardModal from './DeleteCardModal';
import { NEW_CARD_OPTION } from './consts';

type ItemProps = ComponentPropsWithoutRef<'div'> & {
  value: string;
  lastDigits: string;
  expMonth: string;
  expYear: string;
  cardType: CardType;
  processingFee?: number;
  selected: boolean;
};

export const CardSelect = forwardRef<HTMLDivElement, ItemProps>(
  (
    { lastDigits, expMonth, expYear, cardType, processingFee, selected, ...others }: ItemProps,
    ref
  ) => {
    const { t } = useTranslation('payments');
    const theme = useTheme();
    const [isDeleteCardModalOpen, setIsDeleteCardModalOpen] = useState(false);

    if (others.value === NEW_CARD_OPTION) {
      return (
        <div data-test-id={dt.payments.deposits.depositForm.cardSelect.option}>
          <Group
            ref={ref}
            {...others}
            noWrap
            position="apart"
            bg="white"
            p="sm"
            sx={{ borderRadius: theme.radius.md, border: `1px solid ${theme.colors.gray[3]}` }}
          >
            <Group>
              <CardTypeIcon type={cardType} color={getIconColor(theme)} />
              <Text color="gray.7" size={14} fw={600}>
                {t('addNewCard')}
              </Text>
            </Group>
            <IconCirclePlus size={20} color={theme.colors.gray[4]} />
          </Group>
        </div>
      );
    }
    return (
      <>
        <div data-test-id={dt.payments.deposits.depositForm.cardSelect.option}>
          <Group
            h={48}
            ref={ref}
            {...others}
            onMouseDown={(e) => {
              // Works like a preventDefault if there is something clickable within the
              // SelectItem component (in this case, is the trash can)
              // @ts-expect-error: classList exists on targets
              if (e?.target?.classList?.value.includes('mantine-ActionIcon-root')) {
                setIsDeleteCardModalOpen(true);
                return;
              }
              others.onMouseDown(e);
            }}
            noWrap
            position="apart"
            p={0}
            bg="white"
            sx={{
              borderRadius: theme.radius.md,
              border: `1px solid ${theme.colors.gray[3]}`,
              overflow: 'hidden',
            }}
          >
            <Group p="sm" noWrap>
              <CardTypeIcon type={cardType} color={getIconColor(theme)} />
              <Stack spacing={0}>
                <Text color="gray.7" size={processingFee ? 12 : 14} fw={600}>
                  {t('cardSelect.label', { lastDigits })}
                </Text>
                {processingFee && (
                  <Text color={selected ? 'gray.6' : 'gray.4'} size={10}>
                    {t('cardSelect.processingFee', { processingFee })}
                  </Text>
                )}
              </Stack>
              <Text
                color="gray.7"
                size={processingFee ? 12 : 14}
                fw={600}
                mb={processingFee && 'md'}
              >
                {t('cardSelect.expiration', { expMonth, expYear })}
              </Text>
            </Group>
            <Box h="100%" data-test-id={dt.payments.deposits.depositForm.cardSelect.deleteCardIcon}>
              <ActionIcon w={48} h="100%" sx={{ ':hover': { background: 'transparent' } }}>
                <IconTrash
                  color={selected ? theme.colors.gray[6] : theme.colors.gray[4]}
                  size={20}
                  style={{ pointerEvents: 'none' }}
                />
              </ActionIcon>
            </Box>
          </Group>
        </div>
        <DeleteCardModal
          opened={isDeleteCardModalOpen}
          onClose={() => {
            setIsDeleteCardModalOpen(false);
          }}
          id={others.value}
          lastDigits={lastDigits}
        />
      </>
    );
  }
);
CardSelect.displayName = 'CardSelect';
