import { PermissionStatusState } from '~/utils/geoPermissions';

import { hideBannerRoutes } from './consts';

export const calculateAge = (dateOfBirth) => {
  const birthDate = new Date(dateOfBirth);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const months = today.getMonth() - birthDate.getMonth();
  if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export const isHideBannerRoute = (route) => hideBannerRoutes.includes(route);
export const isUserTimeout = (route, userDetails) =>
  userDetails && userDetails.timeout && route !== '/timeout';

// Banners
export const isUserExcluded = (userDetails) => userDetails && userDetails.status === 'excluded';
export const isUserLocked = (userDetails) =>
  userDetails && (userDetails.status === 'blocked' || userDetails.status === 'closed');
export const isUserDuplicate = (userDetails) => userDetails && userDetails.status === 'duplicate';
export const isLocationDenied = (locationStatus) => locationStatus?.toLowerCase() === 'denied';
export const isGeoLocationPrompted = (geoPermissionStatus) =>
  geoPermissionStatus === PermissionStatusState.PROMPT;
export const isGeoLocationDenied = (geoPermissionStatus) =>
  [
    PermissionStatusState.DENIED,
    PermissionStatusState.UNAVAILABLE,
    PermissionStatusState.TIMEOUT,
    PermissionStatusState.UNKNOWN,
  ].includes(geoPermissionStatus);
export const isUserVerificationNeeded = (user, KYCStatus) =>
  user && !!KYCStatus && KYCStatus !== 'accept';
