import axios from 'axios';

import RequestHeadersBase from '~/common/constants/RequestHeadersBase';
import RequestHeadersPlatform from '~/common/constants/RequestHeadersPlatform';

import { handleRefreshInterceptor } from '../interceptors/handle-refresh.interceptor';
import { setAuthTokenInterceptor } from '../interceptors/set-auth-token.interceptor';

export const commissionerService = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_SPLASH}commissioner-service/api`,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthTokenInterceptor(commissionerService);
handleRefreshInterceptor(commissionerService);

export const commissionerImageUploadService = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_SPLASH}commissioner-service/api/assets`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    ...RequestHeadersPlatform,
  },
});

setAuthTokenInterceptor(commissionerImageUploadService);
handleRefreshInterceptor(commissionerImageUploadService);
