import { logError } from '~/common/utils/log';

import { isBrowserFn } from './browser';

function readSessionStorage<T>(STORAGE_KEY: string): T {
  if (!isBrowserFn()) {
    return null;
  }

  try {
    const storageData = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
    return storageData;
  } catch (e) {
    return null;
  }
}

function writeSessionStorage<T>(STORAGE_KEY: string, data: T): void {
  if (!isBrowserFn()) {
    return;
  }

  try {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  } catch (e) {
    logError('Error writing to session storage', e);
  }
}

function updateSessionStorage<T>(STORAGE_KEY: string, data: Partial<T>): T {
  if (!isBrowserFn()) {
    return;
  }

  try {
    const storageData = readSessionStorage<T>(STORAGE_KEY) || ({} as T);
    const updatedData = {
      ...storageData,
      ...data,
    };

    writeSessionStorage(STORAGE_KEY, updatedData);
    return updatedData;
  } catch (e) {
    logError('Error updating session storage', e);
  }
}

function deleteSessionStorage(STORAGE_KEY: string): void {
  if (!isBrowserFn()) {
    return;
  }

  try {
    sessionStorage.removeItem(STORAGE_KEY);
  } catch (e) {
    logError('Error deleting session storage', e);
  }
}

function resetSessionStorage(): void {
  if (!isBrowserFn()) {
    return;
  }

  try {
    sessionStorage.clear();
  } catch (e) {
    logError('Error resetting session storage', e);
  }
}

export {
  readSessionStorage,
  writeSessionStorage,
  updateSessionStorage,
  deleteSessionStorage,
  resetSessionStorage,
};
