import type { ContestType, Sport } from '@betterpool/api-types/contests-service';

import { Routes } from '~/domains/common/constants/routes';
import { URLQueryKeys } from '~/domains/common/types/URLQueryKeys';

import { useIsGamecenterEnabled } from './useIsGamecenterEnabled';

const useGamecenterPath = ({
  contestId,
  contestType,
  sport,
  contestStatus,
  userEntriesCount,
  currentSlateStatus,
  promoSource,
  promoIndex,
}: Parameters<typeof Routes.gamecenter>[0] & { sport: Sport }) => {
  const { isReady, isEnabled } = useIsGamecenterEnabled(contestType, sport);

  return isReady && isEnabled
    ? Routes.gamecenter({
        contestId,
        contestStatus,
        userEntriesCount,
        currentSlateStatus,
        contestType,
      })
    : Routes.contestDetail(contestId, {
        [URLQueryKeys.UTM_SOURCE]: promoSource,
        [URLQueryKeys.UTM_CAMPAIGN]: `${promoIndex}`,
      });
};

export default useGamecenterPath;
