import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from 'react-responsive';

export const useIsNotebook = () => {
  const theme = useMantineTheme();
  return useMediaQuery({ maxWidth: theme.breakpoints.lg });
};

export const useIsTablet = () => {
  const theme = useMantineTheme();
  return useMediaQuery({ maxWidth: theme.breakpoints.md });
};

export const useIsMobile = () => {
  const theme = useMantineTheme();
  return useMediaQuery({ maxWidth: theme.breakpoints.sm });
};
