import type {
  GetNotificationsCountResponse,
  GetNotificationsResponse,
  NotificationType,
  ReadNotificationsResponse,
} from '~/domains/notifications/notifications.types';

import { notificationsService } from './notifications-service.service.instance';

type InfiniteDataPayload = {
  page?: number;
  pageSize?: number;
  filter?: {
    types?: NotificationType[];
    contestId?: string;
  };
};

export const getNotifications = async (payload: InfiniteDataPayload) => {
  const response = await notificationsService.get<GetNotificationsResponse>(`/notifications`, {
    params: payload,
  });
  return response.data;
};

export const getNotificationsCount = async () => {
  const response = await notificationsService.get<GetNotificationsCountResponse>(
    '/notifications/unread-count'
  );
  return response.data;
};

export const readNotifications = async (payload: { notificationIds: string[] }) => {
  const response = await notificationsService.patch<ReadNotificationsResponse>(
    `/notifications/read`,
    payload
  );
  return response.data;
};

export const readAllNotifications = async () => {
  const response =
    await notificationsService.patch<ReadNotificationsResponse>(`/notifications/read/all`);

  return response.data;
};
