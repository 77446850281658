import { createStyles } from '@mantine/core';

import { titleStyles } from '~/domains/design-system/Title';

const useStyles = createStyles((theme) => ({
  sitesButton: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    padding: '0 16px',
    marginRight: -16,
    marginLeft: -16,
    gap: 8,

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',

      '&:hover': {
        opacity: 0.8,
      },
    },
  },
  dropdownLabel: {
    color: theme.colors.gray2[4],
    ...titleStyles['subhead-medium'],
    marginBottom: theme.spacing.md,
  },
  dropdown: {
    borderRadius: `0 0 ${theme.radius.sm}px ${theme.radius.sm}px`,
    padding: 0,
    boxShadow: theme.shadows.lg,
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',

      '&:hover': {
        opacity: 0.5,
      },
    },
  },
  content: {
    padding: `${theme.spacing.md}px ${theme.spacing.lg}px`,

    [theme.fn.largerThan('md')]: {
      width: 350,
    },
  },
}));

export default useStyles;
