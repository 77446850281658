import { createQueryString } from '~/domains/common/utils/queryString';

import { rypInstance } from './ryp-instance';
import type {
  GetRYPPoolMembersPayload,
  GetRYPPoolMembersResponse,
  GetRYPPoolsPayload,
  GetRYPPoolsResponse,
} from './types';

const getPools = async (payload: GetRYPPoolsPayload) => {
  const response = await rypInstance.get<GetRYPPoolsResponse>(
    `/splash/pools${createQueryString(payload)}`
  );
  return response.data;
};

const getPoolMembers = async ({ poolId, ...rest }: GetRYPPoolMembersPayload) => {
  const response = await rypInstance.get<GetRYPPoolMembersResponse>(
    `/splash/pools/${poolId}/members${createQueryString(rest)}`
  );
  return response.data;
};

export { getPools, getPoolMembers };
