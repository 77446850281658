const numberFormatters = {
  decimal: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }),
  whole: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }),
};

export function formatDollars(value: number, forceShowDecimals = false) {
  const showDecimals = forceShowDecimals || value % 1 !== 0;
  const formatter = showDecimals ? numberFormatters.decimal : numberFormatters.whole;
  return formatter.format(value);
}

export function formatDollarsSplitParts(value: number) {
  const formatted = numberFormatters.decimal.format(value);

  const [wholeFormatted, decimalFormattedRaw] = formatted.replace(/[^0-9.]/g, '').split('.');
  const formattedDecimal = decimalFormattedRaw?.padEnd(2, '0') ?? '00';

  return {
    formattedWhole: numberFormatters.whole.format(Number(wholeFormatted)),
    formattedDecimal,
  };
}
