import { showNotification } from '@mantine/notifications';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useContext } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import { reportEvent } from '~/domains/analytics';
import { createQueryString } from '~/domains/common/utils/queryString';

export const useCopyReferAFriendLink = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation('common');
  const handleCopyLink = useCallback(async () => {
    const rafURL = `${window.location.origin}${createQueryString({
      utm_source: 'SplashWeb',
      utm_medium: 'GlobalNav',
      utm_content: 'btn_refer-a-friend',
      ...(user?.id
        ? { referral: user?.id, utm_campaign: user?.id }
        : { referral: 'not logged in', utm_campaign: 'not logged in' }),
    })}`;

    try {
      await window.navigator.clipboard.writeText(rafURL);
      showNotification({
        title: t('copyReferAFriendLink.success.title'),
        message: t('copyReferAFriendLink.success.message'),
        color: 'green',
        autoClose: 4000,
      });

      reportEvent('Share > Refer a Friend', {
        user: user?.id,
      });
    } catch {
      showNotification({
        title: t('copyReferAFriendLink.error.title'),
        message: t('copyReferAFriendLink.error.message'),
        color: 'red',
        autoClose: 2500,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return { handleCopyLink };
};
