// eslint-disable-next-line no-restricted-syntax
export enum RewardTypes {
  contest_handle_reward = 'contest_handle_reward',
  new_user_reward = 'new_user_reward',
  consecutive_games_reward = 'consecutive_games_reward',
}

export interface Reward {
  id: RewardTypes;
  amount: number;
  reason: RewardTypes;
}

export interface GetRecentRewardsResponse {
  page: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
  id: string;
  data: Reward[];
}

export interface RewardsRule {
  amountForConsecutiveGames: number;
  amountPerHandle: number;
  amountPerNewUser: number;
  createdAt: Date;
  id: string;
  requiredConsecutiveGames: number;
  requiredHandle: number;
  tier: number;
}

export interface RewardsRequirement {
  contestCount: number;
  contestHandle: number;
  createdAt: Date;
  entryFee: number;
  id: string;
  maxTotalHandle: number;
  minTotalHandle: number;
  tier: number;
}
export interface GetCommissionerRewardsRulesResponse {
  id: string;
  data: RewardsRule[];
}
export interface GetCommissionerRewardsRequirementResponse {
  id: string;
  data: RewardsRequirement[];
}
