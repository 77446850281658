import { createStyles } from '@mantine/core';

import type { APIAuthUserData } from '~/services/users/types';

import AppShellHeaderSignedIn from './AppShellHeaderSignedIn/AppShellHeaderSignedIn';
import AppShellHeaderSignedOut from './AppShellHeaderSignedOut/AppShellHeaderSignedOut';
import { HeaderMenusContextProvider } from './HeaderMenusContext';
import { HEADER_HEIGHT } from './constants';

const useStyles = createStyles((theme) => ({
  container: {
    background: theme.black,
    zIndex: theme.other.zIndex.header,
    height: HEADER_HEIGHT,
    position: 'sticky',
    top: 0,
  },
  linkIcon: {
    height: HEADER_HEIGHT,
    width: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',
      '&:hover': {
        opacity: 0.5,
      },
    },
  },
  content: {
    maxWidth: theme.other.layout.maxWidth,
    paddingLeft: theme.other.layout.paddingLg,
    paddingRight: theme.other.layout.paddingLg,
    margin: '0 auto',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    gap: 40,

    [theme.fn.smallerThan('lg')]: {
      gap: 20,
      paddingLeft: theme.other.layout.paddingMd,
      paddingRight: theme.other.layout.paddingMd,
    },
    [theme.fn.smallerThan('md')]: {
      paddingLeft: theme.other.layout.paddingSm,
      paddingRight: theme.other.layout.paddingSm,
    },
  },
}));

function AppShellHeader({
  user,
  channelHandle,
  channelSlug,
  avatarUrl,
  balance,
  isUserVerified,
}: {
  user?: APIAuthUserData;
  channelSlug?: string;
  channelHandle?: string;
  avatarUrl?: string;
  balance?: number;
  isUserVerified?: boolean;
} = {}) {
  const { classes } = useStyles();

  return (
    <HeaderMenusContextProvider>
      <div className={classes.container}>
        <div className={classes.content}>
          {user ? (
            <AppShellHeaderSignedIn
              user={user}
              channelHandle={channelHandle}
              channelSlug={channelSlug}
              avatarUrl={avatarUrl}
              balance={balance}
              isUserVerified={isUserVerified}
            />
          ) : (
            <AppShellHeaderSignedOut />
          )}
        </div>
      </div>
    </HeaderMenusContextProvider>
  );
}

export default AppShellHeader;
