'use client';

import type * as braze from '@braze/web-sdk';
import { hideNotification, showNotification } from '@mantine/notifications';
import Router from 'next/router';

import { logError } from '~/common/utils/log';

import type { BrazeInstance } from './types';
import { isMessageWithUrlRedirect } from './types';

function handleSlideUpMessage(brazeInstance: BrazeInstance, inAppMessage: braze.SlideUpMessage) {
  const handleOnClick = () => {
    // Current support only for URL redirect actions
    if (!isMessageWithUrlRedirect(inAppMessage)) return;

    try {
      // Support both full URLs and relative paths. Uses current origin as default base URL.
      const targetURL = new URL(inAppMessage.uri, window.location.origin);

      brazeInstance.logInAppMessageClick(inAppMessage);
      hideNotification(inAppMessage.triggerId);

      // Support for opening in new tab (window)
      if (inAppMessage.openTarget === 'BLANK') {
        window.open(targetURL.href, '_blank');
      } else {
        Router.push(targetURL.href);
      }
    } catch (error) {
      logError(error);
    }
  };

  // First line is treated as a notification title, the rest of it is body
  const [messageTitle, ...messageBody] = inAppMessage.message.split('\n');

  showNotification({
    id: inAppMessage.triggerId,
    title: messageTitle,
    message: messageBody.join(''),
    color: 'cyan',
    autoClose: inAppMessage.dismissType === 'AUTO_DISMISS' ? inAppMessage.duration : false,
    onClick: handleOnClick,
    style: {
      cursor: isMessageWithUrlRedirect(inAppMessage) ? 'pointer' : 'default',
    },
  });
}

export default handleSlideUpMessage;
