import { useMantineTheme } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

import { logError } from '~/common/utils/log';

import { PaysafeFieldId } from '../types/paysafe';

const apiKey = process.env.NEXT_PUBLIC_PAYSAFE_API_KEY;
const environment = process.env.NEXT_PUBLIC_PAYSAFE_ENVIRONMENT;
const accountNumber = parseInt(process.env.NEXT_PUBLIC_PAYSAFE_DEFAULT_DEPOSIT_ACCOUNT, 10);

function usePaysafe() {
  const { t } = useTranslation('payments');
  const theme = useMantineTheme();

  // Paysafe needs to be setup on each new render, because it hooks up with the newly added DIV elements
  const [renderId] = useState(Date.now());

  return useQuery({
    queryKey: ['paysafe/instance', renderId],
    queryFn: async () => {
      if (!window.paysafe) {
        throw new Error('Paysafe not loaded yet');
      }

      const instance = await window.paysafe.fields.setup(apiKey, {
        accounts: {
          default: accountNumber,
        },
        currencyCode: 'USD',
        environment,
        fields: {
          cardNumber: {
            selector: `#${PaysafeFieldId.CARD_NUMBER}`,
            placeholder: t('deposits.form.cardNumber.placeholder'),
          },
          expiryDate: {
            selector: `#${PaysafeFieldId.EXPIRY_DATE}`,
            placeholder: t('deposits.form.expDate.placeholder'),
            separator: '/',
          },
          cvv: {
            selector: `#${PaysafeFieldId.CVV}`,
            placeholder: t('deposits.form.cvv.placeholder'),
          },
        },
        style: {
          input: {
            // Does work only when Inter is installed on the local computer, because we are loading Inter with next/font
            'font-family': `Inter, ${theme.fontFamily}`,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
            'font-size': `${theme.fontSizes.lg}px`,
          },
          'input::placeholder': {
            color: theme.fn.placeholderStyles().color,
          },
        },
      });

      return instance;
    },
    onError: (err) => logError(err),
    cacheTime: 0,
    staleTime: Infinity,
    retryDelay: 1000,
    retry: 5,
  });
}

export { usePaysafe };
