import { Countries } from '~/common/constants/countries.enum';
import { FEATURE } from '~/common/enums/feature.enum';
import { useTreatments } from '~/domains/common/hooks/useTreatments';

export function useCountryOptions() {
  const { treatments } = useTreatments([
    FEATURE.ENABLE_CANADA_IN_KYC_AND_BILLING_ADDRESS,
    FEATURE.ENABLE_SKIP_WELCOME_SCREEN,
  ]);

  const isCanadaEnabled =
    treatments[FEATURE.ENABLE_CANADA_IN_KYC_AND_BILLING_ADDRESS].treatment === 'on';

  const countryOptions = [{ value: Countries.US, label: 'United States of America (USA)' }];

  if (isCanadaEnabled) {
    countryOptions.push({ value: Countries.CA, label: 'Canada' });
  }

  return countryOptions;
}
