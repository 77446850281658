/* eslint-disable import/no-unused-modules */
// TODO: add a storybook story and/or delete unused components
import type { BadgeProps as BaseBadgeProps } from '@mantine/core';
import { Badge, Tooltip, useMantineTheme } from '@mantine/core';
import {
  IconBallFootball,
  IconBallAmericanFootball,
  IconBallBaseball,
  IconIceSkating,
  IconBallBasketball,
  IconGolf,
  IconLetterX,
  IconAward,
} from '@tabler/icons-react';
import styled from '@emotion/styled';

interface BadgeIconsProps {
  tags: string[];
  wrapStyle?: string;
  iconSize?: number;
}

// Tags and array of tags and displays Badge Icons
export function BadgeIcons({ tags, wrapStyle = 'Lobby', iconSize }: BadgeIconsProps) {
  const listItems = tags?.map((tag) => {
    switch (tag.toLowerCase()) {
      case 'golf':
        return <BadgeGolf key={tag} iconSize={iconSize} />;
      case 'soccer':
        return <BadgeSoccer key={tag} iconSize={iconSize} />;
      case 'football':
        return <BadgeFootball key={tag} iconSize={iconSize} />;
      case 'baseball':
        return <BadgeBaseball key={tag} iconSize={iconSize} />;
      case 'basketball':
        return <BadgeBasketball key={tag} iconSize={iconSize} />;
      case 'hockey':
        return <BadgeHockey key={tag} iconSize={iconSize} />;
      case 'beginner':
        return <BadgeBeginner key={tag} iconSize={iconSize} />;
      case 'guaranteed':
        return <BadgeGuaranteed key={tag} iconSize={iconSize} />;
      // case 'ismultientry':
      // case 'multientry':
      //   return <BadgeMultiEntry key={tag} iconSize={iconSize} />;
      // case 'multiplier':
      //   return <BadgeMultiplier key={tag} iconSize={iconSize} />;
      default:
        return null;
    }
  });

  if (wrapStyle === 'Lobby') {
    return <IconWrapperLobby>{listItems}</IconWrapperLobby>;
  }
  return <IconWrapperEntries>{listItems}</IconWrapperEntries>;
}

interface BadgeProps extends BaseBadgeProps {
  iconSize?: number;
}

export function BadgeSoccer({ iconSize = 24 }: BadgeProps) {
  return (
    <Tooltip label="Soccer">
      <IconBallFootball style={{ position: 'relative' }} size={iconSize} />
    </Tooltip>
  );
}

export function BadgeGolf({ iconSize = 24 }: BadgeProps) {
  return (
    <Tooltip label="Golf">
      <IconGolf style={{ position: 'relative' }} size={iconSize} />
    </Tooltip>
  );
}

export function BadgeFootball({ iconSize = 24 }: BadgeProps) {
  return (
    <Tooltip label="Football">
      <IconBallAmericanFootball
        style={{ position: 'relative', transform: 'rotate(0.01deg)' }}
        size={iconSize}
      />
    </Tooltip>
  );
}

export function BadgeBaseball({ iconSize = 24 }: BadgeProps) {
  return (
    <Tooltip label="Baseball">
      <IconBallBaseball style={{ position: 'relative' }} size={iconSize} />
    </Tooltip>
  );
}

export function BadgeBasketball({ iconSize = 24 }: BadgeProps) {
  return (
    <Tooltip label="Basketball">
      <IconBallBasketball style={{ position: 'relative' }} size={iconSize} />
    </Tooltip>
  );
}

export function BadgeHockey({ iconSize = 24 }: BadgeProps) {
  return (
    <Tooltip label="Hockey">
      <IconIceSkating style={{ position: 'relative' }} size={iconSize} />
    </Tooltip>
  );
}

export function BadgeMultiEntry({ iconSize = 24, fontSize }: BadgeProps & { fontSize?: number }) {
  return (
    <Tooltip label="MultiEntry">
      <StyledBadge
        style={{
          width: iconSize,
          height: iconSize,
          fontSize: fontSize ?? iconSize * (15 / 24),
        }}
        variant="gradient"
        gradient={{ from: 'cyan.9', to: 'cyan.9' }}
      >
        M
      </StyledBadge>
    </Tooltip>
  );
}

export function BadgeGuaranteed({ iconSize = 24 }: BadgeProps) {
  const theme = useMantineTheme();
  return (
    <Tooltip label="Guaranteed">
      <StyledBadge
        variant="gradient"
        bg={theme.colors.green[4]}
        style={{
          width: iconSize,
          height: iconSize,
          fontSize: iconSize * (15 / 24),
        }}
      >
        G
      </StyledBadge>
    </Tooltip>
  );
}

export function BadgeResizable({ iconSize = 24 }: BadgeProps) {
  return (
    <Tooltip label="Resizable">
      <StyledBadge
        variant="gradient"
        gradient={{ from: 'pink.5', to: 'pink.5' }}
        style={{
          width: iconSize,
          height: iconSize,
          fontSize: iconSize * (15 / 24),
        }}
      >
        R
      </StyledBadge>
    </Tooltip>
  );
}

export function BadgePick6({ iconSize = 24 }: BadgeProps) {
  return (
    <Tooltip label="Pick6">
      <StyledBadge
        variant="gradient"
        gradient={{ from: 'teal.9', to: 'teal.9' }}
        style={{
          width: iconSize,
          height: iconSize,
          fontSize: iconSize * (15 / 24),
        }}
      >
        6
      </StyledBadge>
    </Tooltip>
  );
}

export function BadgeBeginner({ iconSize = 24, fontSize }: BadgeProps & { fontSize?: number }) {
  return (
    <Tooltip label="Beginner Only">
      <StyledBadge
        variant="filled"
        color="gray.6"
        style={{
          height: iconSize,
          width: iconSize,
          fontSize: fontSize ?? iconSize * (15 / 24),
        }}
      >
        B
      </StyledBadge>
    </Tooltip>
  );
}

export function BadgeMultiplier({ iconSize = 24 }: BadgeProps) {
  return (
    <Tooltip label="Multiplier">
      <StyledBadge
        variant="gradient"
        gradient={{ from: 'dark.3', to: 'dark.3' }}
        style={{
          width: iconSize,
          height: iconSize,
        }}
      >
        <IconLetterX style={{ position: 'relative', top: 2 }} size={iconSize - 4} />
      </StyledBadge>
    </Tooltip>
  );
}

export function BadgeTopX({ iconSize = 24 }: BadgeProps) {
  return (
    <Tooltip label="Top X Win">
      <StyledBadge
        variant="gradient"
        gradient={{ from: 'dark.3', to: 'dark.3' }}
        style={{
          width: iconSize,
          height: iconSize,
        }}
      >
        <IconAward style={{ position: 'relative', top: 2 }} size={iconSize - 4} />
      </StyledBadge>
    </Tooltip>
  );
}

export default function LobbyBadgesGroup() {
  return (
    <div>
      <BadgeMultiplier />
      <BadgeTopX />
      <BadgeSoccer />
      <BadgeMultiEntry />
      <BadgeGuaranteed />
      <BadgeBeginner />
      <BadgePick6 />
      <BadgeFootball />
    </div>
  );
}

const StyledBadge = styled(Badge)<BadgeProps>`
  padding: 0;
  margin: 0;
  overflow: visible;
  font-size: 13px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// (My Entries Style)
const IconWrapperEntries = styled.div`
  max-width: 150px;
  display: flex;
  gap: 4px;
`;

// (Lobby)
export const IconWrapperLobby = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  flex-direction: row;
`;
