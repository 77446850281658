import { ContestStatus } from '~/domains/contest/domains/services/enums/contests-status.enum';
import { ResizingModeType } from '~/domains/commissioner/interfaces/contest.interface';

import { RewardTypes } from '../interfaces/rewards.interface';

export default {
  common: {
    notFound: 'Channel not found',
    unknownChannelError: 'Unknown channel error',
    comingSoonTitle: '{{feature}} coming soon',
    comingSoonDescription: 'This feature is coming soon!',
    comingSoon: '(Coming soon)',
    here: 'Here',
    previous: 'Previous',
    next: 'Next',
    continue: 'Continue',
    submit: 'Submit',
    verifyToSubmit: 'Verify to create',
    logInToSubmit: 'Log in to create',
    custom: 'Custom',
    review: 'Review',
    goBack: 'Go Back',
    goToChannel: 'Go to Commissioner Channel',
    goToHub: 'Go to Commissioner Channel',
    goToChannelShort: 'My Commissioner Channel',
    goToHubShort: 'Commissioner Channel',
    viewProfile: 'View Channel',
    goToLobby: 'Go to Contest Lobby',
    contactSupport: 'Contact Support',
    followers: 'Followers',
    seeAll: 'See all',
    allFollowersFor: 'All Followers for {{name}}',
    selectAll: 'Select All',
    deselectAll: 'Deselect All',
    yes: 'Yes',
    no: 'No',
    header: {
      buttons: {
        editProfile: 'Edit Channel',
        follow: 'Follow',
        copyLink: 'Copy link',
        copyChannelLink: 'Copy link',
      },
    },
    notifications: {
      copySuccess: 'Commissioner Channel Link Copied',
      copyError: 'Could not copy URL: {{url}}',
      followSuccess: {
        title: 'Followed!',
        message: 'You are now following {{channelName}}',
      },
      followError: {
        title: 'Error following {{channelName}}',
      },
    },
    cancel: 'Cancel',
    exit: 'Exit',
    contests: {
      header: 'Contests',
      noUpcomingContests: {
        title: 'No {{type}} contests',
        description: 'Stay tuned for more contests from {{commissionerName}}',
      },
      statuses: {
        [ContestStatus.SCHEDULED]: 'Upcoming',
        [ContestStatus.IN_PROGRESS]: 'Live',
        [ContestStatus.FINALIZED]: 'Recent',
        [ContestStatus.FINISHED]: 'Recent',
        [ContestStatus.CANCELLED]: 'Cancelled',
        commissioner: {
          [ContestStatus.SCHEDULED]: 'Upcoming',
          [ContestStatus.IN_PROGRESS]: 'Live',
          [ContestStatus.FINALIZED]: 'Final',
          [ContestStatus.FINISHED]: 'Final',
          [ContestStatus.CANCELLED]: 'Cancelled',
        },
      },
      allStatuses: 'All Statuses',
    },
    timeAgo: {
      year__hasPlural: {
        one: '{{count}} year ago',
        other: '{{count}} years ago',
      },
      month__hasPlural: {
        one: '{{count}} month ago',
        other: '{{count}} months ago',
      },
      day__hasPlural: {
        one: '{{count}} day ago',
        other: '{{count}} days ago',
      },
      hour__hasPlural: {
        one: '{{count}} hour ago',
        other: '{{count}} hours ago',
      },
      minute__hasPlural: {
        one: '{{count}} minute ago',
        other: '{{count}} minutes ago',
      },
      second__hasPlural: {
        one: '{{count}} second ago',
        other: '{{count}} seconds ago',
      },
      justNow: 'just now',
    },
  },
  onboarding: {
    profileUpdated: 'Channel Updated!',
    profileCreated: 'Channel Created!',
    modal: {
      copy: "You're almost a commissioner! Complete our simple 2-Step Commissioner onboarding flow to create your commissioner channel.",
      cta: 'Become a Commissioner',
    },
    name: {
      label: 'Commissioner Channel Name',
      description:
        'This will be the name that users see on your Commissioner Channel. This cannot be changed once your channel is created.',
      placeholder: 'Commissioner Channel name (required)',
    },
    description: {
      label: 'Commissioner Channel Description',
      description: 'Add an overview that best describes you as a commissioner.',
      placeholder: 'Add a description (required)',
    },
    visibility: {
      label: 'Commissioner Channel Visibility',
      description:
        'By default, your channel is Private, viewable only to you and your invited members',
      placeholder: 'Set as Public Commissioner Channel',
    },
    avatar: {
      label: 'Commissioner Avatar',
      description:
        'Upload an avatar. This will appear on your commissioner channel and as your picture on the contests that you create.',
    },
    backdrop: {
      label: 'Commissioner Banner',
      description: 'Upload a banner for your channel.',
    },
    colorPicker: {
      label: 'Channel Theme',
      description: 'Choose a theme color for your channel',
    },
    leagues: {
      label: 'Select the leagues you intend to create contests for.',
      description:
        'We may not offer all of these leagues just yet, but it will help us prioritize which leagues to add!',
    },
    contestTypes: {
      label: 'Which contest types are you most interested in?',
      description: 'Select which types of contests you intend to feature.',
    },
    entryFee: {
      label: 'What do you plan on setting as the entry fee for most of your contests?',
      description: "Large or small, let's tailor your experience.",
    },
    contestSize: {
      label: 'How large do you expect your average contests to be?',
      description: "This'll give us an idea as to how to build the contest creation flow!",
    },
    navAwayModal: {
      title: 'Are you sure you want to exit?',
      content: 'All changes will be lost.',
    },
    imageUploadRules: {
      maxFilesize: 'Max Filesize: {{fileSize}}MB',
      minRes: 'Min res: {{minWidth}}x{{minHeight}}',
      maxRes: 'Max res: {{maxWidth}}x{{maxHeight}}',
      suggestedRes: 'Suggested Resolution: {{width}}x{{height}}',
      fileTypes: 'Formats: {{formats}}',
    },
  },
  console: {
    about: {
      members: 'Members',
      contests: 'Contests',
      entries: 'Entries',
      more: '...more',
    },
    quickActions: {
      title: 'Quick Actions',
      viewAll: 'View all',
      links: {
        invitesManager: 'Invites manager',
        createContest: 'Create contest',
        composeEmail: 'Compose email',
        createMemberGroup: 'Create member group',
      },
    },
    overviewStats: {
      title: 'Overview',
      today: 'Today',
      totalEntries: 'Total Entries',
      totalEntrants: 'Total Entrants',
      totalEntryFees: 'Total Entry Fees',
      newMembersPercentage: 'New Members %',
      returningMembersPercentage: 'Returning Members %',
    },
    contestManagement: {
      search: 'Search',
      status: {
        label: 'Status',
      },
      page: 'Page',
      pageNumber: 'Page {{number}}',
      contests: 'Contests',
      entries: 'Entries',
      fillPercentage: 'Fill %',
      entryFee: 'Entry Fee',
      noContestsFound: 'No contests found',
    },
    contestInsightsOverview: {
      memberEntry: 'Member Entry %',
      totalEntries: 'Total Entries',
      firstTimeEntrants: 'First-Time Entrants',
      totalEntryFees: 'Total Entry Fees',
      needsPicks: 'Needs Picks',
      unfilledEntries: 'Unfilled Entries',
      pendingInvites: 'Pending Invites',
      title: {
        starts: 'Starts:',
        entries: 'Entries',
        rulesAndScoring: 'Rules & Scoring',
      },
      actions: {
        manageInvites: 'Manage Invites',
        goToGamecenter: 'Go to Gamecenter',
      },
    },
  },
  hub: {
    insights: {
      title: 'Insights',
    },
    about: {
      title: 'About',
    },
    members: {
      title: 'Members',
    },
    rewards: {
      title: 'Rewards',
      recentRewards: 'Recent Rewards',
      recentRewardTypes: {
        [RewardTypes.contest_handle_reward]: 'Reached $1k in contest handle',
        [RewardTypes.new_user_reward]: '3 new users entered your contests',
        [RewardTypes.consecutive_games_reward]: '3 consecutive games',
      },
      noRewardsYet: 'No rewards yet',
      handle: 'Handle',
      myRewards: 'My Rewards',
      start: 'Start',
      tierX: 'Tier {{number}}',
      XInHandleToGo: '{{dollars}} in handle to go',
      XContestsToGo__hasPlural: {
        one: '{{count}} contest to go',
        other: '{{count}} contests to go',
      },
      tierFourRewardsComingSoon: 'Tier 4 rewards coming soon!',
      tierXRewards: 'Tier {{number}} Reward Requirements',
      unlockedRewards: "You've unlocked the Tier {{number}} rewards",
      rewardRequirements: {
        minimumContestHandle: '{{amount}} minimum contest handle',
      },
      unlockRewards: 'Tier {{number}} unlocks the below rewards:',
      youWillReceive: 'You will receive: ',
      reward_1: '{{reward}} per contest per {{handle}} in handle',
      reward_2: '{{reward}} new user bonus',
      reward_3: '{{reward}} for {{gamesNeeded}} consecutive games',
      xLeft: '{{number}} left',
      createContest: 'Create Contest',
      inviteUser: 'Invite User',
    },
    posts: {
      title: 'Posts',
      createPostCTA: 'Create a new post',
      emptyState: 'No posts yet...',
      pinned: 'Pinned',
      postLikeError: 'There was an issue liking this post',
      postUnlikeError: 'There was an issue unliking this post',
      report: 'Report Post',
      comments: {
        emptyState: 'No comments yet...',
        report: 'Report Comment',
        commentError: 'Error Posting Comment',
        addComment: 'Add comment',
      },
      modal: {
        title: 'Create Post',
        postCreateError: 'Post Create Error',
        postCreateSuccess: 'Your post was sent!',
        public: 'Public',
        private: 'Private',
        showLikes: 'Allow likes',
        showComments: 'Allow comments',
        commentsComingSoon: 'Comments coming soon!',
        pinToTheTop: 'Pin to the top',
        titlePlaceholder: 'Add a title',
        bodyPlaceholder: 'Write your post...',
        cta: 'Post',
        contestLinkLabel: 'Contest',
        contestLinkPlaceholder: 'Choose a contest',
        contestLinkTextLabel: 'Contest Text',
        contestLinkTextPlaceholder: 'Enter text for contest link',
        linkYourContest: 'Link your contest',
      },
    },
  },
  createContest: {
    quickCreate: {
      title: "Let's Get Started!",
      subtitle:
        'Jump right in with one of our pre-configured contests or create your own custom contest',
      quickCreate: {
        label: 'Quick Create',
        description: 'Quickly get your contest started with one of our presets',
        isLoading: 'Gathering data for your contest',
      },
      customCreate: {
        label: 'Custom Contest',
        description: 'Configure all details of your contest one step at a time',
      },
      contestPresets: {
        label: 'Contest Presets',
        description:
          'Once your contest details are prefilled, you can review or customize further and then create your contest!',
      },
    },
    today: 'Today',
    tomorrow: 'Tomorrow',
    step1: {
      title: 'Create Your Contest',
      public: 'Discoverable',
      private: 'Invite Only',
      [ResizingModeType.GUARANTEED]: 'Guaranteed',
      [ResizingModeType.RESIZABLE]: 'Resizable',
      [ResizingModeType.STANDARD]: 'Non-Guaranteed',
      splashCreated: 'Created by Splash',
      selfCreated: 'Created by {{name}}',
      autoApprove: 'Auto-Approve',
      manualApprove: 'Approve Manually',
      beginnersOnly: {
        option1: 'Beginner Only',
        option2: 'Open to All',
        label: 'Beginner Only Contest',
        description: 'Is this a beginner only contest?',
        confirmation: 'Beginner Only: {{value}}',
      },
      disclaimer:
        'Creating discoverable contests is available only for commissioners who qualify for our <0></0> program.',
      commissionerRewards: 'commissioner rewards',
      disclaimer_admin:
        "For more information on what happens if your contest doesn't fill, please see ",
      contestResizingInfo: 'contest resizing info',
      adminOnly: {
        label: 'Admin Only',
        description: 'Settings only available to Splash Admininstrators (AKA Spladmins).',
      },
      unlisted: {
        label: 'Discoverability',
        description:
          'Invite only contests will not appear in the public contest lobby. Unlock the ability to host discoverable contests when you qualify as a high earning commissioner. Think you qualify? <supportLink/>.',
        contactSupport: 'Contact support',
      },
      entryApproval: {
        label: 'Entry Approval Required?',
        description:
          'If approval is required, you will have the chance to approve or deny all entries into the contest before those entries are submitted.',
      },
      createdByAdmin: {
        label: 'Created By',
        description:
          'Choose whether to list the contest under yourself or under Splash. Note: Only Splash-created contests can be guaranteed.',
      },
      resizingMode: {
        label: 'Resizing Mode',
        description:
          'Resizable = run by anyone, will be resized down if it does not fill.<br/> Guaranteed = Only run by Splash, will run regardless of if they fill or not.<br/> Non-Guaranteed = Only run by Splash, will be cancelled/refunded if it does not fill. ',
      },
      makeListedAt: {
        label: 'Show In Lobby At (Optional)',
        labelOnly: 'Show In Lobby At: {{value}}',
        description:
          'Date and time that this contest should be made discoverable in the lobby (Eastern Time).<br/><br/> Note: There may be a 2m-3m delay in the contests showing in the lobby. Please plan accordingly.<br/> Note 2: Be sure to set both date and time. If both are not set, neither will be applied. Thank you!',
      },
    },
    step2: {
      title: 'Create Your Contest',
      leagues: {
        label: 'Sports Leagues',
        altLabel: 'League',
        description: 'Choose the sports league for your contest.',
      },
      format: {
        label: 'Contest Game Type',
        description: 'Choose the type of contest you want to host.',
        nflDescription:
          'Choose the type of contest you want to host. Learn more about <pickEm/>, <survivor/> and <tiers/>.',
        pickEm: "Pick'Em",
        survivor: 'Survivor',
        tiers: 'Tiers',
      },
      rosterSize: {
        label: 'Roster Size',
        description: {
          ncaaf: 'Available roster sizes based on your selections',
          ncaab: 'Available roster sizes based on your selections',
          pga: 'Your selection indicates the number of golfers that each entrant will select for their lineup.',
          nfl: 'Available roster sizes based on your selections',
          nba: 'Available roster sizes based on your selections',
          default: 'Available roster sizes based on your selections',
        },
      },
      perTier: {
        label: {
          ncaaf: 'Select amount of players selected per slate',
          ncaab: 'Select amount of players selected per slate',
          pga: 'Select amount of golfers selected per tournament',
          nfl: 'Select amount of players selected per week',
          nba: 'Select amount of players selected per slate',
          default: 'Select amount of players selected per slate',
          errorLabel: 'Players per Slate',
        },
        description: {
          ncaaf:
            'This will detemine how may players your entrants will need to select for each slate',
          ncaab:
            'This will detemine how may players your entrants will need to select for each slate',
          pga: 'This will detemine how may golfers your entrants will need to select each tournament.',
          nfl: 'This will detemine how may players your entrants will need to select for each week',
          nba: 'This will detemine how may players your entrants will need to select for each slate',
          default:
            'This will detemine how may players your entrants will need to select for each slate',
        },
      },
      primaryScoring: {
        label: 'Primary Scoring',
        description: 'Select how you wish to score your contest',
      },
      assignFlex: {
        label: 'Assign Flex Position',
        description: 'Use Flex position in your lineup',
      },
      selections: {
        label: 'Number of Selections',
        description: 'Number of selections per slate',
      },
      doublePicks: {
        label: 'Double Picks',
        description: 'Set to allow Double Picks',
      },
      doublePicksStart: {
        label: 'Double Picks Start',
        description: 'Select the week to start Double Picks',
      },
      contestType: {
        player_tier: 'Player Tier',
        player_category: 'Player Category',
        team_survivor: 'Survivor',
        team_pick_em: "Pick'em",
      },
    },
    step3: {
      title: 'Customize Your Gameplay',
      contestCalendar: {
        label: {
          nfl: 'Set your schedule',
          nba: 'Set your schedule',
          ncaaf: 'Set your schedule',
          ncaab: 'Set your schedule',
          pga: 'Select Tournaments',
          default: 'Set your schedule',
        },
        description: {
          nfl: 'The weeks you select will determine which weeks will require picks. Note: Contests will not be paid out on a weekly basis. Prizes are distributed after all selected weeks have been completed.',
          ncaaf:
            'The slates you select will determine which weeks will require picks. Note: Contests will not be paid out on a weekly basis. Prizes are distributed after all selected slates have been completed.',
          ncaab:
            'The slates you select will determine which weeks will require picks. Note: Contests will not be paid out on a weekly basis. Prizes are distributed after all selected slates have been completed.',
          pga: 'The tournaments you select will determine which tournaments will require picks. Note: Contests will not be paid out on a weekly basis. Prizes are distributed after all selected tournaments have been completed.',
          nba: 'The days you select will determine which days will require picks. Note: Contests will not be paid out on a weekly basis. Prizes are distributed after selected day has been completed.',
          default:
            'The slates you select will determine which slates will require picks. Note: Contests will not be paid out on a weekly basis. Prizes are distributed after all selected slates have been completed.',
        },
        altLabel: 'Schedule',
        allSlates: {
          nfl: 'All Weeks',
          ncaaf: 'All Games',
          pga: 'All Tournaments',
          nba: 'All Days',
          default: 'All Slates',
          ncaab: 'All Days',
        },
        allOptions: {
          nfl: 'All Games',
          ncaaf: 'All Games (again?)',
          pga: 'All Slates',
          nba: 'All Games',
          ncaab: 'All Days',
          default: 'All Slates',
        },
        minSlateGroupsToSelectError: {
          title: 'Invalid configuration',
          message: 'You must select at least {{number}} slates.',
        },
      },
      pickDeadline: {
        label: 'Pick Deadline',
        description:
          'Set your pick deadline to be at the start of each slate or at the start of each individual game',
      },
      slateGroupGroups: {
        label: 'Set your schedule',
        description:
          'The slates you select will determine which will require picks. Note: Contests will not be paid out on a weekly basis. Prizes are distributed after all selected slates have been completed.',
        slateGroupsAlt: {
          label: 'Select starting tournament',
          description:
            'All entrants must join before the first tournament in the contest starts. {{purseText}}',
          individualLabel: 'Select tournaments',
          individualDescription:
            'The earliest tournament you select will be the starting tournament. All entrants must join before the first tournament in the contest starts. {{purseText}}',
          purseText: '*Previous tournament purse',
        },
      },
      weightedScoring: {
        errorField: 'Scoring Type',
        label: 'Set your scoring',
        description: 'How many points do you want each correct pick to be worth?',
        onePoint: 'All games worth 1 point',
        weightedScoring: 'Weighted scoring',
        slate: 'Slate',
        ptsPerWin: 'Points per win',
        ptsPerWinMobile: 'Pts/Win',
        ptsPerWinTooltip: 'Weighted points per win must be in ascending order.',
        bestPick: 'Best pick',
        bestPickTooltip:
          'If Best Pick is enabled, entrants will get double points if their selected "Best Pick" wins.',
      },
      dollarWinningsMultiplier: {
        label: 'Dollar winnings multipliers',
        description:
          'By default, all tournament purses are worth 1x. Enable multipliers if you wish to customize this.',
        slate: 'Slate',
        purse: 'Purse',
        multiplier: 'Multiplier',
      },
      pickReuseLimits: {
        label: 'Player use limit',
        description:
          'This will determine how many times an entrant can select a player for the full length of this contest.',
        options: {
          '0': 'Once',
          '1': 'Twice',
          unlimited: 'Unlimited',
        },
      },
      customGames: {
        label: 'Exclude Games',
        description: 'Would you like to enable game exclusion?',
        slate: 'Slate',
        xGames: '({{value}} Games)',
        xGamesExcluded: '({{value}} games excluded)',
        exclude: 'Exclude',
      },
      contestSlate: {
        label: {
          nfl: 'Select Games',
          ncaaf: 'Select Games',
          ncaab: 'Select Games',
          pga: 'Select Options',
          nba: 'Select Games',
          default: 'Select Options',
        },
        description: {
          nfl: 'Select which games you wish to add to your contest',
          nba: 'Select which games you wish to add to your contest',
          ncaaf: 'Select which games you wish to add to your contest',
          ncaab: 'Select which games you wish to add to your contest',
          pga: 'Select which options you wish to add to your contest',
          default: 'Select which options you wish to add to your contest',
        },
      },
      tertiaryNFLOptions: {
        numberOfSelections: {
          label: 'Set amount of weekly picks',
          labelNCAAF: 'Set amount of picks per slate',
          labelGeneric: 'Amount of expected picks',
          description:
            'This will determine how many teams your entries will need to select each week.',
          descriptionNCAAF:
            'This will determine how many teams your entries will need to select each slate.',
          allGames: 'All Games',
          tooltip: {
            survivor: {
              atLeastEightWeeks:
                'NFL Survivor contests that require 1 or 2 selections per week must be at least 8 weeks.',
              atLeastFourWeeks: 'NFL Survivor contests must be at least 4 weeks.',
            },
            pickEm: {
              lessThanNineWeeks:
                "NFL Pick'em contests with less than 9 weeks must select from all games each week",
              lessThanEighteenWeeks:
                "NFL Pick'em contests with less than 18 weeks must have at least 8 selections per week.",
            },
          },
        },
        doublePicks: {
          label: 'Add double picks after a certain week?',
          description:
            'If you select yes, you will then choose which week you would like double picks to start.',
        },
        doublePickWeekStart: {
          label: 'When will double picks start?',
          description: 'Select the week that entries will need to begin making two picks per week.',
        },
        invalidConfig: {
          title: 'Invalid Survivor Selection',
          message:
            'Your survivor contest configuration is invalid. As configured, your contest will run out of teams to select before the contest is completed. Please adjust your Selected Weeks or Number of Selections to continue.',
        },
      },
    },
    step4: {
      title: 'Set Your Contest Details',
      entryFee: {
        label: 'Select The Entry Fee',
        description:
          'This is the cost per entry. The higher the entry fee, the bigger the prize pot.',
      },
      freerollPrizePool: {
        label: 'Prize Total',
        description: 'Set the prize total to payout for your freeroll. Must be greater than $0',
      },
      contestSize: {
        label: 'Select Maximum Amount of Anticipated Entries',
        description:
          "This helps us set your contest's prize pot. We recommend estimating on the higher end because you will not be able to exceed this amount after creating your contest. Private contests will resize if you don't reach the max entries based on <0></0>.",

        headToHead: 'H2H',
        upToX: 'Up to {{number}}',
      },
      payoutFormat: {
        label: 'Payout Format',
        description:
          'Select the number of winners. If your private contest doesn’t fill and you have more payouts than entries, your contest will run as a free contest and entry fees will be returned, as described in <0></0>.',
      },
      entryLimit: {
        label: 'Entry Limit',
        description: 'Select how many entries each user can make.',
        singleEntry: 'Single Entry',
        entryMax: '{{number}} Entry Max',
      },
      rewardsOptIn: {
        label: 'Earn Rewards?',
        description:
          'Rewards are optional. If you opt in to receive a handle bonus, it will come from the total handle. If you opt out, the money will go back into the prize pot.',
      },
      segmentedPayouts: {
        label: 'Customize payout schedule',
        description:
          'You will be able to pay out tournament or segment winners. Click <0></0> for more information.',
      },
      contestResizingInfo: 'these guidelines',
      customAmounts: {
        errors: {
          customAmountExceeded: {
            intercomMessage: {
              subject: 'Custom Contest Request',
              message:
                "Please tell us about the contest you'd like to start. How big would you like it to be? What's the entry fee? Is this your first contest or have you run one before? If so, how long?",
            },
          },
          customEntryFeeAmountExceeded: {
            title: 'Max Entry Fee Ammount Exceeded',
            message: 'Click here to chat with our VIP team for private contests larger than 100.',
            submitError: `The contest you're trying to create has exceeded the entry fee. Please chat with our VIP team to get your contest created.`,
          },
          customMaxEntriesAmountExceeded: {
            title: 'Max Entries Amount Exceeded',
            message: 'Click here to chat with our VIP team for private contests larger than 100.',
            submitError: `The contest you're trying to create has exceeded the contest size limit. Please chat with our VIP team to get your contest created.`,
          },
        },
        vipModal: {
          title: 'Create a Custom Contest',
          copy1:
            "The contest you're trying to create has exceeded the entry fee or contest size limit.",
          copy2: 'But no worries, we got you covered!',
          copy3:
            "Our VIP team exists for scenarios just like this one, so you'll have your contest created in no time!",
          cta: 'Chat with our VIP team',
        },
      },
    },
    step5: {
      title: 'Make Your Contest Stand Out!',
      contestTitle: {
        label: 'Contest Title',
        description: 'Add a title that best describes your contest.',
        placeholder: 'Contest name',
      },
      contestDescription: {
        label: 'Contest Description',
        description: 'Tell people about your contest. A good description goes a long way.',
        placeholder: 'Add a contest description (Optional)',
      },
      contestThumbnail: {
        label: 'Contest Thumbnail',
        description:
          "Upload a thumbnail that best showcases your contest. A good thumbnail stands out and draws viewers' attention.",
      },
    },
    step6: {
      title: 'Confirm Your Settings',
      change: 'Change',
      contestFormat: 'Contest Format',
      publicPrivate: 'Create Your Contest',
      publicPrivateAdmin: 'Lobby Settings',
      leagueSelected: 'League: {{value}}',
      formatSelected: 'Format: {{value}}',
      rosterSelected: 'Roster Size: {{value}}',
      perTier: 'Players per Tier: {{value}}',
      contestSchedule: 'Contest Schedule',
      slatesSelected: 'Slates: ',
      allNFLWeeks: 'All NFL Weeks',
      optionsSelected: 'Options: ',
      entrantsAndPrizes: 'Entrants & Prizes',
      entryFeeSelected: 'Entry Fee: {{value}}',
      contestSizeSelected: 'Contest Size: {{value}}',
      maxEntriesSelected: 'Max Entries per User: {{value}}',
      payoutFormat: 'Payout Format:',
      contestInfo: 'Contest Info',
      contestTitleSelected: 'Contest Title: {{value}}',
      contestDescriptionSelected: 'Contest Description: {{value}}',
      createdByX: 'Created by: {{value}}',
      resizingMode: 'Resizing Mode: {{value}}',
      numberOfSelections: 'Number of Selections: {{value}}',
      excludedGames: 'Games Excluded: {{value}}',
      pickDeadlineType: 'Pick Deadline Type: {{value}}',
      weightedScoring: 'Scoring Value: {{value}}',
      bestPicksEnabled: 'Best Picks Enabled: {{value}}',
      doublePicks: 'Double Picks: {{value}}',
      doublePicksWeekStart: 'Double Pick Start Week: {{value}}',
      freerollPrizePool: 'Freeroll Prize Pool: {{value}}',
    },
    complete: {
      title: 'Contest Created',
      contestPreview: 'Contest Preview',
      contestPreviewDescription: 'Preview and enter your contest',
      shareContestLink: 'Share Link',
      viewContestPage: 'View Contest',
      otherOptions: "What's Next?",
      otherOptionsDescription: 'Share the link with your friends, create another contest, etc.',
    },
    contestCreateError: 'Contest Create Error',
    freerollAdminValuesNotification: {
      title: 'Freeroll values applied!',
      message:
        "Freerolls can only be GUARANTEED and must be CREATED BY ADMINS. Don't worry, we set those for you!",
    },
    optionUnavailableForFreeRolls: 'This option cannot be selected for Freeroll contests',
    cta: 'Create a Contest',
    cta2: 'Create a new contest',
    intercomMessage: `How would you like to configure your contest?
- Total Number of Contest Entries (Minimum of 10):
- Users can submit multiple Entries (Yes / No):
- Entry Fee for the contest (Minimum of $20):
Contest Rules:
Each Golfer gains or loses fantasy points based on their performance (score) for each hole played during all rounds the tournament. Missed cuts and withdrawals also result in added fantasy points. Similar to real golf, the player with the FEWEST fantasy points after Round 4 wins.`,
    intercomError: {
      title: 'Contest Creation Error',
      message:
        'Contest creation is currently unavailable. Please contact support to create a contest.',
    },
    submitErrors: {
      missingInformation: 'Missing Information',
      pleaseSelectXOnStepY: 'Please make a valid selection for {{name}} on Step {{number}}.',
      pleaseSelectXOnStepYText:
        'Please make a valid selection for {{name}} on Step {{number}}. Note, the use of the words "pool", "gamble" or "gambling" is not allowed.',
      xIsRequired: '{{name}} is required.',
      notApplicable: 'N/A',
      verificationNeeded: {
        title: 'User Verification Needed',
        message:
          "Before creating a contest, you must verify your identity. In the meantime, we'll save these contest settings for you. Click here to verify.",
      },
    },
  },
  channelNotFound: {
    title: 'No commissioner found',
    message:
      'We did not find any commissioner channel at this address. Please check your URL and try again. If you believe this is an error, please contact Splash Support.',
  },
  unauthorized: {
    title: 'You are not authorized to make changes to this commissioner channel',
    message:
      'You may return to the contest lobby or if you believe this is an error please contact support',
    intercomMessage: 'Hey Splash, I cannot access my commissioner channel (ID: {{channelSlug}}).',
    intercomError: {
      title: 'Support Chat Unavailable',
      message: 'Please email support directly at support@splashsports.com',
    },
  },
  sports: {
    pga: 'PGA',
    nfl: 'NFL',
    nba: 'NBA',
    mlb: 'MLB',
    nhl: 'NHL',
    mls: 'MLS',
    epl: 'EPL',
    ncaaf: 'NCAAF',
    wnba: 'WNBA',
    world_cup: 'World Cup',
    soccer: 'Soccer',
    ncaa: 'NCAA',
    usfl: 'USFL',
  },
  payoutFormats: {
    winnerTakeAll: 'Winner Take All',
    paysTopX: 'Pays Top {{number}}',
  },
  payoutCalculator: {
    prizePosition: 'Prize Position',
    percentage: 'Percentage',
    payout: 'Payout',
  },
  autoPickReminders: 'Auto pick reminders',
};
