import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import orderBy from 'lodash.orderby';

import { safeJSONParse } from '~/common/utils/safeJSONParse.util';
import { useInfiniteScroll } from '~/hooks/useInfiniteScroll';
import { getContestEntriesForInvites } from '~/services/contests/contests.service.api';

type UseFetchUsersByContestProps = {
  selectedItem: string;
};

const useFetchUsersByContest = ({ selectedItem }: UseFetchUsersByContestProps) => {
  const PAGE_SIZE = 50;
  const selectedItemInfo = safeJSONParse(selectedItem);

  const {
    data: entriesData,
    isInitialLoading: isEntriesInitialLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['invites/contestEntrants', selectedItem],
    enabled: !!selectedItemInfo && !selectedItemInfo.id.startsWith('ryp_'),
    queryFn: ({ pageParam = 0 }) =>
      getContestEntriesForInvites(selectedItemInfo.id, PAGE_SIZE, pageParam),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.offset + PAGE_SIZE;
      if (nextPage >= lastPage.total) {
        return undefined;
      }
      return nextPage;
    },
  });

  const { loaderRef } = useInfiniteScroll({
    fetchMore: fetchNextPage,
    hasMore: hasNextPage,
  });

  const contestUsers = useMemo(() => {
    const allUsers = entriesData?.pages?.map((page) => page.data.map((data) => data.user)).flat();

    const uniqueUsers = [...new Map(allUsers?.map((user) => [user.id, user])).values()];
    const users = orderBy(uniqueUsers, 'username');
    if (users?.length) {
      return users;
    }
    return [];
  }, [entriesData]);

  return {
    contestUsers,
    loaderRef,
    hasNextPage,
    isLoading: isEntriesInitialLoading || isFetching,
  };
};
export default useFetchUsersByContest;
