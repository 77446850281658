import { useTheme } from '@emotion/react';
import { Group, Input, Skeleton, Text, Tooltip } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import type { PaymentType } from '~/common/enums/payments.enum';
import { PAYMENT_TYPE_TO_METHOD } from '~/common/enums/payments.enum';
import { snakeCaseToTitleCase, upperCaseToTitleCase } from '~/components/utils/formatters';
import { ErrorAlert } from '~/domains/common/components/Alerts/Alerts';
import { RadioButton, RadioButtonGroup } from '~/domains/payments/components/RadioButton';
import PaymentTypeIcon from '~/domains/payments/icons/PaymentTypeIcon';
import dt from '~/testing';

import type { useAvailablePaymentMethods } from '../../hooks/useAvailablePaymentMethods';

type DepositMethodsProps = {
  availablePaymentMethods: ReturnType<typeof useAvailablePaymentMethods>['availablePaymentMethods'];
  paymentType: PaymentType;
  filteredPaymentTypes: PaymentType[];
  isLoading: boolean;
  onChange: (type: PaymentType) => void;
};

export function DepositMethods({
  availablePaymentMethods,
  filteredPaymentTypes,
  isLoading,
  paymentType,
  onChange,
}: DepositMethodsProps) {
  const { t } = useTranslation('payments');
  const theme = useTheme();

  const getIsPaymentTypeDisabled = (type: PaymentType) =>
    availablePaymentMethods?.some(
      (item) => item.paymentMethod === PAYMENT_TYPE_TO_METHOD[type] && item.status === 'disabled'
    );
  const isCurrentPaymentTypeDisabled = getIsPaymentTypeDisabled(paymentType);

  const handleOnChange = (type) => {
    onChange(type);
  };

  return (
    <Input.Wrapper label={t('deposits.form.paymentType.label')}>
      <RadioButtonGroup>
        {isLoading && <Skeleton width="100%" height={75} />}
        {!isLoading &&
          filteredPaymentTypes.length > 0 &&
          filteredPaymentTypes.map((type) =>
            getIsPaymentTypeDisabled(type) ? (
              // eslint-disable-next-line react/jsx-key
              <Tooltip
                label={t('deposits.tooltip.disabledPaymentMethod', {
                  paymentMethod: upperCaseToTitleCase(snakeCaseToTitleCase(type)),
                })}
                events={{ hover: true, focus: true, touch: true }}
                zIndex={theme.other.zIndex.tooltip.default}
              >
                <RadioButton
                  key={type}
                  $active={type === paymentType}
                  type="button"
                  data-test-id={dt.payments.deposits.depositForm.paymentTypeButton}
                >
                  <Group spacing={8} noWrap>
                    <PaymentTypeIcon type={type} color={theme.colors.gray[6]} />
                    <Text color="gray.6" size={14}>
                      {t(`paymentTypes.${type}`)}
                    </Text>
                  </Group>
                </RadioButton>
              </Tooltip>
            ) : (
              <RadioButton
                key={type}
                $active={type === paymentType}
                onClick={() => handleOnChange(type)}
                type="button"
                data-test-id={dt.payments.deposits.depositForm.paymentTypeButton}
              >
                <Group spacing={8} noWrap>
                  <PaymentTypeIcon type={type} />
                  <Text size={14}>{t(`paymentTypes.${type}`)}</Text>
                </Group>
              </RadioButton>
            )
          )}
      </RadioButtonGroup>
      {isCurrentPaymentTypeDisabled && (
        <ErrorAlert px="lg" mt="xs">
          {t('deposits.errors.unavailablePaymentType', {
            type: t(`paymentTypes.${paymentType}`),
          })}
        </ErrorAlert>
      )}
    </Input.Wrapper>
  );
}
