import { WebSdkLite } from '@xpointtech/xpoint-js';
import type {
  CheckRequestError,
  CheckRequestData,
  CheckRequestStatus,
  JurisdictionArea,
} from '@xpointtech/xpoint-js';
import { type RequestType } from '@xpointtech/xpoint-js/dist/src/lib/types/RequestType';

import log from '~/common/utils/log';
import { getAnonymousId } from '~/domains/analytics';

import { jwtDecodeSafe } from './jwt';
import { isTestingEnvironment } from './isTestingEnvironment';

type LocationTokenData = {
  userId: string;
  clientId: string;
  deviceId: string;
  ip: string;
  sdkVersion: string;
  requestId: string;
  status: CheckRequestStatus;
  errors: CheckRequestError[];
  currentCheckType: RequestType;
  nextCheckInterval: number;
  nextCheckType: RequestType;
  timestamp: string;
  country: string;
  state: string;
  latitude: number;
  longitude: number;
  dataType: 'GPS'; // Possibly more const values
  jurisdictionArea: {
    id: string;
    name: string;
    detectedAutomatically: boolean;
  };
};

type LocationToken = {
  iss: string;
  data: LocationTokenData;
  exp: number;
  iat: number;
};

const LOCATION_TOKEN_KEY = 'locationToken';

// This needs to be outside the class, for reason unknown to me (doesn't work inside)
const locationTokenDataChangeListeners = new Set<VoidFunction>();

class XPointSingleton {
  private static instance: WebSdkLite;

  private static clientKey = process.env.NEXT_PUBLIC_XPOINT_CLIENT_KEY;

  private static userId: string | undefined;

  private static jurisdictionArea: JurisdictionArea;

  private static locationToken: string | null;

  private static liteCheckPromise: Promise<CheckRequestData> | undefined;

  private static liteCheckTimer: ReturnType<typeof setTimeout>;

  private static enablePolling = false;

  // ================== PRIVATE methods ==================
  private static getInstance() {
    if (!this.clientKey) throw new Error('XPoint client key is not set');
    this.locationToken = localStorage.getItem(LOCATION_TOKEN_KEY);

    if (!this.instance) {
      const options = {
        watchPositionOptions: {
          enableHighAccuracy: false,
          maximumAge: 300 * 1000,
          timeout: 60 * 1000,
        },
        checkStatusPositionOptions: {
          enableHighAccuracy: false,
          maximumAge: 300 * 1000,
          timeout: isTestingEnvironment() ? 100 : 30 * 1000, // Change timeout to reduce waiting for GPS lock
        },
      };

      this.instance = WebSdkLite.getInstance(options);
    }
    return this.instance;
  }

  private static persistToken(token: string) {
    this.locationToken = token;
    localStorage.setItem(LOCATION_TOKEN_KEY, token);
    locationTokenDataChangeListeners.forEach((listener) => listener());
  }

  // This method fetches fresh location and starts polling
  // for new location based on the nextCheckInterval
  private static async checkLocation(): Promise<CheckRequestData> {
    log('Fetching new location token: ', new Date().toISOString());

    // Always reset timer when checking location
    if (this.liteCheckTimer) {
      clearTimeout(this.liteCheckTimer);
    }

    const checkLocationCallback = async () => {
      let liteCheckResult: CheckRequestData;
      try {
        if (!this.liteCheckPromise) {
          this.liteCheckPromise = this.getInstance().liteCheck({
            userId: this.userId || getAnonymousId(),
            client: this.clientKey,
            jurisdictionArea: this.jurisdictionArea,
          });
        }

        liteCheckResult = await this.liteCheckPromise.finally(() => {
          this.liteCheckPromise = undefined;
        });
      } catch (error) {
        liteCheckResult = { errors: [error] };
      }

      // Start the timer to fetch new location after nextCheckInterval expires
      const checkInterval = liteCheckResult?.nextCheckInterval
        ? liteCheckResult.nextCheckInterval * 1000 // xPoint returns interval in seconds
        : 60 * 1000; // Default polling 1 minute

      // ENABLE POLLING BASED ON FEATURE FLAG
      this.liteCheckTimer = this.enablePolling
        ? setTimeout(checkLocationCallback, checkInterval)
        : null;

      this.persistToken(liteCheckResult.jwt);
      return liteCheckResult;
    };

    const location = await checkLocationCallback();
    return location;
  }

  // ================== PUBLIC methods ==================
  public static async init(userId?: string, enablePolling = false) {
    this.userId = userId;
    this.enablePolling = enablePolling;
    const instance = this.getInstance();

    // TODO Add jurisdiction user selection later
    // const jurisdictionArea = await instance.getJurisdictionArea(this.clientKey);
    // this.jurisdictionArea = jurisdictionArea.candidateAreas[0] || jurisdictionArea.preferableArea;

    return instance;
  }

  public static setUserId(userId: string) {
    this.userId = userId;
  }

  public static subscribeToLocationToken(listener: VoidFunction) {
    locationTokenDataChangeListeners.add(listener);
    return () => {
      locationTokenDataChangeListeners.delete(listener);
    };
  }

  public static getPersistedLocationToken(): string | null {
    return this.locationToken;
  }

  public static getLocationTokenData(): LocationTokenData | undefined {
    if (!this.locationToken) return null;

    const decoded = jwtDecodeSafe<LocationToken>(this.locationToken);
    return decoded?.data ?? null;
  }

  // Call this method when you need to invalidate the token and
  public static invalidateToken() {
    this.locationToken = null;
    this.userId = null;

    if (this.liteCheckTimer) {
      clearTimeout(this.liteCheckTimer);
    }

    localStorage.removeItem(LOCATION_TOKEN_KEY);
  }

  public static async getValidLocationToken(validAtLeastForMs = 0, forceRefetch = false) {
    const savedLocationToken = this.locationToken;

    if (!forceRefetch && savedLocationToken) {
      const decoded = jwtDecodeSafe<LocationToken>(savedLocationToken);
      if (decoded?.exp > (Date.now() + validAtLeastForMs) / 1000) {
        return { token: savedLocationToken };
      }
    }

    const liteCheckResult = await this.checkLocation();

    return liteCheckResult.errors &&
      Array.isArray(liteCheckResult.errors) &&
      liteCheckResult.errors.length
      ? { errors: liteCheckResult.errors }
      : { token: liteCheckResult.jwt };
  }
}

export { LOCATION_TOKEN_KEY };
export type { LocationToken };

export default XPointSingleton;
