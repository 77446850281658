const enum KYCVerificationStatus {
  ACCEPT = 'accept',
  // TODO: add other statuses
}

interface IGetKYCVerificationResponse {
  id: string;
  data: {
    status: KYCVerificationStatus;
    referenceId: string;
    userId: string;
  };
}

export { type IGetKYCVerificationResponse, KYCVerificationStatus };
