import { Stack } from '@mantine/core';
import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';

import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';

import { Title } from '~/domains/common/components/Title/Title';
import { Text } from '~/domains/common/components/Text/Text';
import { CurrencyConvertor } from '~/components/utils/formatters';

import { REDIRECT_TIMEOUT } from '../useEnterContestModal';

import animation from './animation.lottie';

function EnterContestSuccessScreen({
  balanceInDollars,
  entryCount,
}: {
  balanceInDollars: number;
  entryCount: number;
}) {
  const { t } = useTranslation('contest');
  const [remaining, setRemaining] = useState(REDIRECT_TIMEOUT);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setRemaining((prev) => {
        const nextValue = prev - 1000;
        if (nextValue <= 0) {
          clearInterval(timerRef.current);
        }
        return nextValue;
      });
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  return (
    <Stack align="center" justify="center" spacing="xs" h="100%" mih={300}>
      <Title variant="web-headline-medium">{t('common.enterContestModal.success.title')}</Title>
      <Text variant="web-body-large">
        {t('common.enterContestModal.success.text__hasPlural', {
          count: entryCount,
        })}
      </Text>
      <Text variant="web-body-medium" c="gray.6">
        {t('common.enterContestModal.success.balance', {
          balance: CurrencyConvertor(balanceInDollars),
        })}
      </Text>
      <DotLottiePlayer
        src={animation}
        autoplay
        style={{ width: '130px', height: '130px', marginTop: '-30px', marginBottom: '-30px' }}
      />
      <Text>Redirecting to contest in {remaining / 1000}</Text>
    </Stack>
  );
}

export default EnterContestSuccessScreen;
