import { useContext, useEffect, useMemo } from 'react';
import { Burger, Group, ScrollArea, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { UserContext } from '~/components/providers/UserProvider';
import { LogoTypes, SplashLogo } from '~/components/shared/Logos/SplashLogo';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { Routes } from '~/domains/common/constants/routes';
import { PrimaryButtonLink } from '~/domains/common/components/Button/Button';
import { Header } from '~/domains/common/components/Header/Header';

import { HeaderLoggedInDesktop } from './HeaderLoggedInDesktop';
import { HeaderLoggedInMobile } from './HeaderLoggedInMobile';
import { HeaderDrawer } from './HeaderDrawer';

type AppShellHeaderProps = {
  isSticky?: boolean;
};

export function AppShellHeader({ isSticky }: AppShellHeaderProps) {
  const isMobile = useIsMobile();
  const theme = useMantineTheme();
  const { user } = useContext(UserContext);
  const router = useRouter();
  const { t } = useTranslation('common');
  const [isOpen, { toggle, close }] = useDisclosure(false);

  const signInRedirectTo = useMemo(() => {
    if (
      router.pathname === Routes.signIn() ||
      router.pathname === Routes.signUp() ||
      router.pathname === Routes.main()
    ) {
      return undefined;
    }

    return `${window.location.pathname}${window.location.search}`;
  }, []);

  useEffect(() => {
    if (close) {
      router.events.on('routeChangeComplete', close);
      router.events.on('routeChangeError', close);
      return () => {
        router.events.off('routeChangeComplete', close);
        router.events.off('routeChangeError', close);
      };
    }
  }, [router, close]);

  const isStickyOrFixed = isOpen ? 'fixed' : 'sticky';

  return (
    <Header pos={isSticky ? isStickyOrFixed : 'relative'}>
      <SplashLogo
        size={isMobile ? 24 : 30}
        type={isMobile ? LogoTypes.PRIMARY_NO_TEXT : LogoTypes.PRIMARY_WITH_TEXT}
      />
      {isMobile && user ? <Burger opened={isOpen} onClick={toggle} color="white" /> : null}
      {!user ? (
        <Group>
          <PrimaryButtonLink
            href={Routes.signIn({
              redirectTo: signInRedirectTo,
            })}
          >
            {t('headerNavigation.login')}
          </PrimaryButtonLink>
          <PrimaryButtonLink
            c={theme.colors.splash[5]}
            variant="outline"
            href={Routes.createAContest()}
          >
            {t('headerNavigation.createAContest')}
          </PrimaryButtonLink>
        </Group>
      ) : null}
      {!isMobile && user ? <HeaderLoggedInDesktop /> : null}
      {isMobile && user ? (
        <HeaderDrawer isOpen={isOpen} onClose={close}>
          <ScrollArea sx={{ height: 'calc(100vh - 60px)' }}>
            <HeaderLoggedInMobile />
          </ScrollArea>
        </HeaderDrawer>
      ) : null}
    </Header>
  );
}
