function IconExternalLink({ size = 25, color = '#909296' }: { size?: number; color?: string }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 7.34473H6C5.46957 7.34473 4.96086 7.55544 4.58579 7.93051C4.21071 8.30559 4 8.81429 4 9.34473V18.3447C4 18.8752 4.21071 19.3839 4.58579 19.7589C4.96086 20.134 5.46957 20.3447 6 20.3447H15C15.5304 20.3447 16.0391 20.134 16.4142 19.7589C16.7893 19.3839 17 18.8752 17 18.3447V13.3447M10 14.3447L20 4.34473M20 4.34473H15M20 4.34473V9.34473"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconExternalLink.displayName = 'IconExternalLink';

export default IconExternalLink;
