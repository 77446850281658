import { ActionIcon, Box, Group, Text, createStyles } from '@mantine/core';
import { IconPlugConnected, IconX } from '@tabler/icons-react';
import { useTheme } from '@emotion/react';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

import { UnstyledNextLink } from '~/components/shared/UnstyledNextLink/UnstyledNextLink';
import { getPrimaryThemeColor } from '~/config/themeUtils';
import type { ApplicationType } from '~/domains/oauth/const';

const useStyles = createStyles((theme) => ({
  wrapper: {
    borderRadius: 0,
    background: 'black',
    padding: 16,
  },
  eoText: {
    display: 'inline',
    fontWeight: 700,
  },
  link: {
    display: 'inline',
    color: getPrimaryThemeColor(theme),
    textDecoration: 'underline',
  },
}));

type LinkEOCTAProps = {
  eo: ApplicationType;
  onClose: VoidFunction;
};

function LinkEOCTA({ eo, onClose }: LinkEOCTAProps) {
  const { t } = useTranslation('common');
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.wrapper}>
      <Group noWrap position="apart" align="center" spacing={0}>
        <UnstyledNextLink
          href="/account?open=externalAccounts"
          id={`link_${eo}_account`}
          target="_blank"
        >
          <Group noWrap spacing="xs">
            <IconPlugConnected size={30} color={getPrimaryThemeColor(theme)} />
            <Text color="white" size={14}>
              <Trans
                i18nKey="common:invites.modal.linkEOCTA.copy"
                components={{
                  eoSite: <Text className={classes.eoText}>{t(`invites.modal.${eo}`)}</Text>,
                  link: (
                    <Text className={classes.link}>{t('invites.modal.linkEOCTA.linkNow')}</Text>
                  ),
                }}
              />
            </Text>
          </Group>
        </UnstyledNextLink>
        <ActionIcon radius="xl" onClick={onClose}>
          <IconX size={16} />
        </ActionIcon>
      </Group>
    </Box>
  );
}

export default LinkEOCTA;
