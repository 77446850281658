import React, { useMemo, useRef, useState } from 'react';
import type { NumberInputHandlers } from '@mantine/core';
import {
  ActionIcon,
  Group,
  LoadingOverlay,
  NumberInput,
  Skeleton,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { IconCircleMinus, IconCirclePlus } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';

import { Text } from '~/domains/common/components/Text/Text';
import { PrimaryButton } from '~/domains/common/components/Button/Button';
import { CurrencyConvertor } from '~/components/utils/formatters';

import ContestCard from '../../ContestCard/ContestCard';
import dt from '../../../testing.const';

import useStyles from './EnterContestScreen.styles';

function EnterContestScreen({
  balanceInDollars,
  contest,
  onDepositClick,
  onCreateEntries,
  onClose,
  errorMessage,
  isCreateEntriesReady,
}: {
  balanceInDollars;
  contest;
  onDepositClick: () => void;
  onCreateEntries: (entriesCount: number) => void;
  onClose: () => void;
  errorMessage?: string;
  isCreateEntriesReady: boolean;
}) {
  const { t } = useTranslation('contest');
  const theme = useMantineTheme();
  const handlers = useRef<NumberInputHandlers>();

  const [value, setValue] = useState(1);
  const maxAllowedEntries = useMemo(
    () =>
      contest?.entries
        ? Math.min(
            contest.entries.max_per_user - contest.entries.user,
            contest.entries.max - contest.entries.filled
          )
        : 0,
    [contest]
  );

  const isOverBalance = useMemo(() => {
    if (typeof balanceInDollars !== 'number') {
      return false;
    }

    return ((contest?.entry_fee ?? 0) / 100) * value > balanceInDollars;
  }, [balanceInDollars, contest?.entry_fee, value]);

  const { classes } = useStyles();

  return (
    <div>
      <Stack align="center" ta="center" data-test-id={dt.enterContestModal.modal}>
        {(() => {
          if (errorMessage) {
            return (
              <Text variant="web-body-large" c="red">
                {errorMessage}
              </Text>
            );
          }

          if (contest.entries.max_per_user > 1) {
            return (
              <Text variant="web-body-large">
                {t('common.enterContestModal.enterContest.text')}
              </Text>
            );
          }

          return null;
        })()}

        {typeof balanceInDollars === 'number' && (
          <Text variant="web-body-medium" c="gray.6">
            {t('common.enterContestModal.enterContest.balance', {
              balance: CurrencyConvertor(balanceInDollars),
            })}
          </Text>
        )}
      </Stack>

      {contest && (
        <div className={classes.contestCardSection}>
          <div className={classes.contestCardContainer}>
            <ContestCard
              contest={contest}
              isContestThumbnailShown
              isStatic
              isCardMenuVisible={false}
            />
          </div>
        </div>
      )}

      {!errorMessage ? (
        <div className={classes.footer}>
          {contest.entries.max_per_user > 1 ? (
            <Stack spacing={4}>
              <Group spacing={4}>
                <ActionIcon
                  size={36}
                  variant="default"
                  onClick={() => {
                    handlers.current.decrement();
                  }}
                  data-test-id={dt.enterContestModal.action.decrement}
                  disabled={maxAllowedEntries === 1}
                >
                  <IconCircleMinus size={20} color={theme.colors.gray[6]} />
                </ActionIcon>
                <NumberInput
                  hideControls
                  value={value}
                  onChange={setValue}
                  data-test-id={dt.enterContestModal.action.numberOfEntries}
                  handlersRef={handlers}
                  min={1}
                  max={maxAllowedEntries}
                  classNames={{ input: classes.numberInput }}
                  disabled={maxAllowedEntries === 1}
                />
                <ActionIcon
                  size={36}
                  variant="default"
                  onClick={() => {
                    handlers.current.increment();
                  }}
                  data-test-id={dt.enterContestModal.action.increment}
                  disabled={maxAllowedEntries === 1}
                >
                  <IconCirclePlus size={20} color={theme.colors.gray[6]} />
                </ActionIcon>
              </Group>
              <Text variant="web-body-small" c={theme.colors.gray[6]}>
                {t('common.enterContestModal.enterContest.maxEntries__hasPlural', {
                  count: maxAllowedEntries,
                })}
              </Text>
            </Stack>
          ) : (
            <div />
          )}
          <Skeleton visible={!isCreateEntriesReady} className={classes.ctaWrapper}>
            <LoadingOverlay visible={!isCreateEntriesReady} />
            {isOverBalance ? (
              <PrimaryButton
                onClick={onDepositClick}
                data-test-id={dt.enterContestModal.action.depositToEnter}
              >
                {t('common.enterContestModal.enterContest.depositToEnter')}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={() => {
                  onCreateEntries(value);
                }}
                data-test-id={dt.enterContestModal.action.createEntries}
                disabled={!value || !isCreateEntriesReady}
              >
                {t('common.enterContestModal.enterContest.addEntries__hasPlural', {
                  count: value ?? '0',
                  entryFee: CurrencyConvertor(((contest?.entry_fee ?? 0) / 100) * (value ?? 0)),
                })}
              </PrimaryButton>
            )}
          </Skeleton>
        </div>
      ) : (
        <Group position="center">
          <PrimaryButton onClick={onClose} data-test-id={dt.enterContestModal.action.cancel}>
            {t('common.enterContestModal.enterContest.close')}
          </PrimaryButton>
        </Group>
      )}
    </div>
  );
}

export default EnterContestScreen;
