// TODO: Remove this once we can auth
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { logError } from '~/common/utils/log';

import { getKycVerify } from '../../services/kyc/kyc.service.api';

import { UserContext } from './UserProvider';

export const KYCContext = createContext({
  KYCStatus: null,
  setKYCStatus: null,
  KYCDeviceToken: null,
});

interface KYCProviderProps {
  children: React.ReactNode;
}

function KYCProvider({ children }: KYCProviderProps) {
  const [KYCStatus, setKYCStatus] = useState(null);
  const [KYCDeviceToken, setKYCDeviceToken] = useState(null);
  const { user } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    if (document.readyState !== 'loading' && (window as any).devicer && !KYCDeviceToken) {
      const deviceFPOptions = {
        publicKey: process.env.NEXT_PUBLIC_SOCURE_SDK_KEY,
        userConsent: true,
        context: 'homepage',
      };
      (window as any).devicer?.run(deviceFPOptions, (response) => {
        setKYCDeviceToken(response.sessionId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [KYCDeviceToken, router, (window as any).devicer]);

  useEffect(() => {
    const getKYCStatus = async () => {
      const { data } = await getKycVerify();
      setKYCStatus(data.status);
    };
    if (user) {
      getKYCStatus().catch(logError).finally();
    }
  }, [KYCStatus, user]);

  const value = useMemo(
    () => ({
      KYCStatus,
      setKYCStatus,
      KYCDeviceToken,
    }),
    [KYCDeviceToken, KYCStatus]
  );

  return <KYCContext.Provider value={value}>{children}</KYCContext.Provider>;
}

export default KYCProvider;
