import { Input, Skeleton, createStyles } from '@mantine/core';
import { type UseFormReturnType } from '@mantine/form/lib/types';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { FS_VISIBILITY } from '~/domains/analytics';
import {
  PAYSAFE_FIELD_ID_TO_NAME,
  PaysafeFieldId,
  type PaysafeInstance,
} from '~/domains/payments/types/paysafe';

import type { DepositFormValues } from './types';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingLeft: `${theme.spacing.md}px`,
    paddingRight: `${theme.spacing.md}px`,
    borderRadius: `${theme.radius.sm}px`,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4]
    }`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    height: '50px',
    width: '100%',
  },
  error: {
    borderColor: theme.fn.variant({ variant: 'filled', color: 'red' }).background,
  },
  focus: theme.focusRingStyles.inputStyles(theme),
}));

const PAYSAFE_FIELD_ID_TO_FORM_FIELD_NAME = {
  [PaysafeFieldId.CARD_NUMBER]: 'cardDetails.cardNumber',
  [PaysafeFieldId.EXPIRY_DATE]: 'cardDetails.expDate',
  [PaysafeFieldId.CVV]: 'cardDetails.cvv',
};

type PaysafeInputProps = {
  id: PaysafeFieldId;
  paysafeInstance: PaysafeInstance;
  form: UseFormReturnType<DepositFormValues>;
  label?: string;
  loading?: boolean;
  dataTestId?: string;
};

type PaysafeInputHandleRef = {
  validate: () => boolean;
};

const PaysafeInput = forwardRef<PaysafeInputHandleRef, PaysafeInputProps>(
  ({ id, paysafeInstance, dataTestId, form, label, loading = false }, ref) => {
    const formFieldName = PAYSAFE_FIELD_ID_TO_FORM_FIELD_NAME[id];
    const paysafeFieldName = PAYSAFE_FIELD_ID_TO_NAME[id];

    const { classes, cx } = useStyles();
    const [isFocused, setIsFocused] = useState(false);

    const { clearFieldError, validateField } = form;

    const validate = useCallback(
      () => paysafeInstance.fields[paysafeFieldName].isValid(),
      [paysafeInstance, paysafeFieldName]
    );

    useImperativeHandle(ref, () => ({ validate }));

    useEffect(() => {
      const handleFocus = () => {
        setIsFocused(true);
        clearFieldError(formFieldName);
      };
      const handleBlur = () => {
        setIsFocused(false);
        validateField(formFieldName);
      };
      if (paysafeInstance) {
        paysafeInstance.fields(paysafeFieldName).on('Focus', handleFocus);
        paysafeInstance.fields(paysafeFieldName).on('Blur', handleBlur);
      }
    }, [
      paysafeInstance,
      validateField,
      formFieldName,
      validate,
      paysafeFieldName,
      clearFieldError,
    ]);

    const error = form.errors[formFieldName];

    return (
      <Input.Wrapper label={label} w="100%" withAsterisk>
        <Skeleton visible={loading}>
          <div
            className={cx(
              FS_VISIBILITY.FS_EXCLUDE,
              classes.wrapper,
              !!error && classes.error,
              isFocused && classes.focus
            )}
            data-test-id={dataTestId}
            id={id}
          />
          <Input.Error size="lg" mt={4}>
            {error}
          </Input.Error>
        </Skeleton>
      </Input.Wrapper>
    );
  }
);

PaysafeInput.displayName = 'PaysafeInput';

export { PaysafeInput, type PaysafeInputHandleRef };
