import { FEATURE } from '~/common/enums/feature.enum';
import { useTreatments } from '~/domains/common/hooks/useTreatments';

export const useMyContestsPageEnabled = () => {
  const { isReady, treatments } = useTreatments([FEATURE.ENABLE_NEW_MY_CONTESTS_PAGE]);

  const isMyContestsPageEnabled =
    treatments[FEATURE.ENABLE_NEW_MY_CONTESTS_PAGE]?.treatment === 'on';

  return {
    isReady,
    isMyContestsPageEnabled,
  };
};
