import type { APIPicksheet, Picksheet } from '../interfaces/picksheet.interface';

const parseContestPicksheet = (picksheet: APIPicksheet): Picksheet => ({
  ...picksheet,
  games: picksheet.games.map((game) => ({
    ...game,
    startDate: new Date(game.startDate),
  })),
});

export default parseContestPicksheet;
