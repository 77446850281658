function IconChevronRight({ color = '#1a1a1a', size = 16 }: { color?: string; size?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
        d="m6 4 4 4-4 4"
      />
    </svg>
  );
}

IconChevronRight.displayName = 'IconChevronRight';

export { IconChevronRight };
