import type { TablerIconsProps } from '@tabler/icons-react';
import {
  IconBrandPaypal,
  IconBuildingBank,
  IconCashBanknote,
  IconCreditCard,
} from '@tabler/icons-react';

import { PaymentType } from '~/common/enums/payments.enum';

function IconVenmo() {
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.333333"
        y="0.333333"
        width="23.3333"
        height="15.3333"
        rx="2.16667"
        fill="#3D95CE"
      />
      <path
        d="M15.7667 5.64C15.7667 7.68267 14.062 10.3367 12.678 12.2H9.518L8.25 4.44667L11.0173 4.17867L11.6873 9.69533C12.314 8.65133 13.0867 7.012 13.0867 5.894C13.0867 5.282 12.984 4.86533 12.824 4.522L15.344 4C15.6353 4.492 15.7667 4.99933 15.7667 5.64Z"
        fill="white"
      />
      <rect
        x="0.333333"
        y="0.333333"
        width="23.3333"
        height="15.3333"
        rx="2.16667"
        stroke="#D1D1D1"
        strokeWidth="0.666667"
      />
    </svg>
  );
}

function IconSkrill() {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 -9 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="57" height="39" rx="3.5" fill="white" stroke="#F3F3F3" />
      <path d="M48.6494 12.7211V27.2361H52.6426V12.0115L48.6494 12.7211" fill="#862165" />
      <path d="M42.8205 12.7211L46.8111 12.0115V27.2361H42.8205V12.7211" fill="#862165" />
      <path d="M37.136 27.2361V16.1005H41.1286V27.2361H37.136" fill="#862165" />
      <path
        d="M37.1636 13.0775C37.1636 11.9975 38.0453 11.1213 39.1311 11.1213C40.2187 11.1213 41.0998 11.9975 41.0998 13.0775C41.0998 14.1583 40.2187 15.0325 39.1311 15.0325C38.0453 15.0325 37.1636 14.1583 37.1636 13.0775"
        fill="#862165"
      />
      <path
        d="M34.884 15.9372C31.1952 16.0554 29.3211 17.7009 29.3211 20.9717V27.2361H33.3469V22.1267C33.3469 20.1692 33.6052 19.33 35.9435 19.2499V15.9951C35.5503 15.9118 34.884 15.9372 34.884 15.9372V15.9372"
        fill="#862165"
      />
      <path
        d="M24.0524 16.0878C23.9641 16.3102 23.326 18.105 21.7786 19.9647V12.0115L17.6582 12.8215V27.2361H21.7786V22.7798C22.9717 24.5588 23.5613 27.2361 23.5613 27.2361H28.4957C28.0078 25.2195 25.8728 21.5022 25.8728 21.5022C27.7897 19.0866 28.6306 16.5141 28.7649 16.0878H24.0524"
        fill="#862165"
      />
      <path
        d="M10.7487 17.6755C10.2442 17.6431 9.0753 17.5675 9.0753 16.5224C9.0753 15.2612 10.757 15.2612 11.3881 15.2612C12.4981 15.2612 13.9342 15.5865 14.9611 15.8921C14.9611 15.8921 15.5328 16.0929 16.0194 16.2975L16.0648 16.3102V12.8558L16.004 12.8374C14.7917 12.4162 13.3843 12.0115 10.7736 12.0115C6.27274 12.0115 4.67804 14.6157 4.67804 16.8489C4.67804 18.1349 5.23305 21.1642 10.3861 21.513C10.8241 21.5397 11.984 21.6064 11.984 22.6864C11.984 23.5785 11.0345 24.1033 9.43785 24.1033C7.68841 24.1033 5.99012 23.6611 4.95938 23.2398V26.7965C6.50293 27.1999 8.24342 27.3994 10.2832 27.3994C14.6823 27.3994 16.6543 24.9349 16.6543 22.4914C16.6543 19.722 14.449 17.922 10.7487 17.6755V17.6755"
        fill="#862165"
      />
    </svg>
  );
}

const paymentTypeToIconComponent = {
  [PaymentType.CARD]: IconCreditCard,
  [PaymentType.PAYPAL]: IconBrandPaypal,
  [PaymentType.VIPPREFERRED]: IconBuildingBank,
  [PaymentType.PAPER_CHECK]: IconCashBanknote,
  [PaymentType.VENMO]: IconVenmo,
  [PaymentType.SKRILL]: IconSkrill,
};

export default function PaymentTypeIcon({
  type,
  ...props
}: TablerIconsProps & { type: PaymentType }) {
  const Icon = paymentTypeToIconComponent[type];
  return <Icon {...props} />;
}
