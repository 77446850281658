import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { v4 as uuidv4 } from 'uuid';

import { reportEvent } from '~/domains/analytics';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';
import { depositToWallet } from '~/services/wallet/wallet.service.api';
import { PaymentType } from '~/services/wallet/interfaces/wallets.interface';

import { isCustomDeposit } from './consts';

export function useDepositWithSavedCard({
  setCompletedDepositAmount,
}: {
  setCompletedDepositAmount?: (amount: number) => void;
}) {
  const { t } = useTranslation('payments');
  const { refetch: refetchWalletBalance } = useWalletBalance();

  return useMutation({
    mutationFn: async ({
      depositAmountWithFees,
      merchantRefNum = uuidv4(),
      cvv,
      paymentHandleId,
    }: {
      depositAmount: number;
      depositAmountWithFees: number;
      merchantRefNum?: string;
      cvv: string;
      paymentHandleId: string;
    }) =>
      depositToWallet({
        amount: depositAmountWithFees,
        merchantRefNum,
        cvv,
        paymentHandleId,
        currency: 'USD',
        paymentType: PaymentType.CARD,
      }),
    onSuccess: (response, payload) => {
      if (response.data.status === 'COMPLETED') {
        reportEvent('Transactions > Deposit', {
          deposit_id: response.id,
          deposit_method: 'Card (saved)',
          deposit_amount: payload.depositAmount,
          is_custom_deposit: isCustomDeposit(payload.depositAmount),
          currency: 'USD',
        });
        refetchWalletBalance();
        setCompletedDepositAmount?.(payload.depositAmount);
      } else {
        showNotification({
          // eslint-disable @typescript-eslint/ban-ts-comment
          // @ts-expect-error Missing types for error response in Swagger
          title: response.error,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error Missing types for error response in Swagger
          message: response?.data?.message || response.message,
          color: 'red',
          autoClose: 5000,
        });
      }
    },
    onError: (error: AxiosError<{ message: string }>) => {
      const errorMessage = {
        /** @ts-expect-error @todo EDGE-5999 - error is not typed */
        title: t(`errors.${error.code}.title`),
        /** @ts-expect-error @todo EDGE-5999 - error is not typed */
        message: t(`errors.${error.code}.message`),
      };

      if (error.code === 'ERR_BAD_REQUEST') {
        errorMessage.title = t('deposits.errors.savedCard');
        errorMessage.message = error.response?.data?.message ?? '';
      }

      showNotification({
        title: errorMessage.title,
        message: errorMessage.message,
        color: 'red',
        autoClose: 5000,
      });
    },
  });
}
