import type { AutocompleteItem } from '@mantine/core';
import { Autocomplete, Loader } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';

import useAddressLookup from '~/domains/user/hooks/useAddressLookup';
import dt from '~/testing';
import { Countries } from '~/common/constants/countries.enum';

import type { BillingAddressFormValues } from './types';

type AddressAutocompleteProps<T extends BillingAddressFormValues> = {
  form: UseFormReturnType<T>;
};

export default function AddressAutocomplete<T extends BillingAddressFormValues>({
  form,
}: AddressAutocompleteProps<T>) {
  const { t } = useTranslation('forms');
  const { setValues, values } = form;

  const { dataForAutocomplete, isLoading, getPlaceDetail } = useAddressLookup({
    query: form.values.billingAddress.address,
    country: form.values.billingAddress.country,
  });

  const onItemSubmit = useCallback(
    async (item: AutocompleteItem) => {
      const { city, state, address, zip } = await getPlaceDetail(item.data.place_id);

      if (!item) return;
      setValues((currentState) => ({
        ...currentState,
        billingAddress: {
          address,
          address2: '',
          city,
          state,
          zip,
          country: values.billingAddress.country || Countries.US,
        },
      }));
    },
    [getPlaceDetail, setValues, values.billingAddress.country]
  );

  return (
    <Autocomplete
      {...form.getInputProps('billingAddress.address')}
      onItemSubmit={onItemSubmit}
      placeholder={t('billingAddress.address.placeholder')}
      label={t('billingAddress.address.label')}
      labelProps={{ size: 'sm' }}
      data-test-id={dt.payments.deposits.depositForm.billingAddressAutocomplete}
      withAsterisk
      maxLength={30}
      rightSection={isLoading ? <Loader size={20} /> : null}
      data={dataForAutocomplete}
      limit={Infinity} // Limited in `useAddressLookup`
      autoComplete="chrome-off"
      w="100%"
      size="lg"
    />
  );
}
