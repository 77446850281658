import { useMantineTheme } from '@mantine/core';
import type { ComponentPropsWithoutRef } from 'react';

import { Avatar as OldAvatar } from '~/domains/common/components/Avatar/Avatar';
import { textStyles } from '~/domains/design-system/Text';

const colors = ['#B2F2BB', '#F5B094', '#EDA3FB', '#B4A6EF', '#ED9D9D', '#FFEC99'];

const getAvatarColor = (handle?: string) => {
  if (!handle) return colors[0];
  return colors[handle.length % colors.length];
};
const getAvatarLetter = (handle?: string) => {
  if (!handle) return '';
  const firstChar = handle.replace(/[^a-zA-Z]/g, '')[0] || '';
  return firstChar.toUpperCase();
};

const FontSizesMap = {
  xs: 12,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 44,
};

const getPlaceholderFontSize = (size: string | number) => {
  if (typeof size === 'number') return size / 2;
  return FontSizesMap[size];
};

type AvatarProps = {
  /** New property of channels, if not available use legacy `username` instead. */
  handle?: string;
  noColor?: boolean;
} & ComponentPropsWithoutRef<typeof OldAvatar>;

export function Avatar({ handle, noColor, ...props }: AvatarProps) {
  const theme = useMantineTheme();
  const avatarColor = noColor ? theme.colors.gray[2] : getAvatarColor(handle);
  const letter = getAvatarLetter(handle);
  const placeholderFontSize = getPlaceholderFontSize(props.size);

  return (
    <OldAvatar
      {...props}
      color={avatarColor}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      styles={(theme) => ({
        placeholder: {
          ...textStyles['headline-xxl'],
          color: theme.colors.gray[9],
          background: avatarColor,
          fontSize: placeholderFontSize,
          lineHeight: 1,

          '& svg': {
            color: theme.colors.gray[9],
          },
        },
      })}
    >
      {letter}
    </OldAvatar>
  );
}

// mainly for Storybook
Avatar.colors = colors;
