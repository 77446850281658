import { useTheme } from '@emotion/react';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Group,
  ScrollArea,
  Stack,
  Text,
  UnstyledButton,
  createStyles,
} from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

import { getPrimaryThemeColor } from '~/config/themeUtils';

import DrawerUserList from './shared/DrawerUserList';

const useStyles = createStyles((theme) => ({
  panelWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  tabletFloatingButton: {
    position: 'absolute',
    bottom: '80px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.colors.dark[6],
    color: theme.white,
    padding: theme.spacing.sm,
    borderRadius: theme.radius.md,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

type TabletDrawerProps = {
  totalInvites: number;
  emailInvites: string[];
  setEmailInvites?: Dispatch<SetStateAction<never[]>>;
  splashInvites: string[];
  setSplashInvites: Dispatch<SetStateAction<never[]>>;
  rypInvites: string[];
  setRYPInvites: Dispatch<SetStateAction<never[]>>;
  rypPools: string[];
  setRYPPools: Dispatch<SetStateAction<string[]>>;
  contests: string[];
  setContests: Dispatch<SetStateAction<string[]>>;
  handleSendInvites: () => void;
  isLoading: boolean;
};

function TabletDrawer({
  splashInvites,
  setSplashInvites,
  rypInvites,
  setRYPInvites,
  emailInvites,
  setEmailInvites,
  totalInvites,
  handleSendInvites,
  isLoading,
  rypPools,
  setRYPPools,
  contests,
  setContests,
}: TabletDrawerProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('common');
  const theme = useTheme();
  const [tabletDrawerOpen, setTabletDrawerOpen] = useState(false);
  return (
    <>
      <UnstyledButton
        onClick={() => setTabletDrawerOpen(true)}
        className={classes.tabletFloatingButton}
      >
        {t('invites.modal.selected__hasPlural', {
          count: totalInvites,
        })}
      </UnstyledButton>
      <Drawer
        size="95%"
        opened={tabletDrawerOpen}
        onClose={() => setTabletDrawerOpen(false)}
        zIndex={301}
        position="bottom"
        title={t('invites.modal.selected')}
        styles={{
          drawer: {
            borderRadius: theme.radius.md,
          },
          body: {
            padding: theme.spacing.md,
          },
          title: { fontWeight: 700, fontSize: '22px' },
          header: { padding: theme.spacing.md },
          closeButton: { color: theme.black },
        }}
      >
        <Box h="calc(100vh - 250px)">
          <ScrollArea h="90%">
            <Stack spacing="xl">
              {contests.length > 0 && (
                <Stack>
                  <Group position="apart">
                    <Text size={12} color={theme.colors.gray[6]} transform="uppercase">
                      {t('invites.modal.splashContests__hasPlural', {
                        count: contests.length,
                      })}
                    </Text>
                    <UnstyledButton
                      onClick={() => {
                        setContests([]);
                        setTabletDrawerOpen(false);
                      }}
                    >
                      <Text size={12} color={getPrimaryThemeColor(theme)} transform="uppercase">
                        {t('invites.modal.unselectAll')}
                      </Text>
                    </UnstyledButton>
                  </Group>
                  <DrawerUserList
                    options={contests}
                    optionsSelected={contests}
                    setOptionsSelected={setContests}
                  />
                </Stack>
              )}
              {splashInvites.length > 0 && (
                <Stack>
                  <Group position="apart">
                    <Text size={12} color={theme.colors.gray[6]} transform="uppercase">
                      {t('invites.modal.splashInvites__hasPlural', {
                        count: splashInvites.length,
                      })}
                    </Text>
                    <UnstyledButton
                      onClick={() => {
                        setSplashInvites([]);
                        setTabletDrawerOpen(false);
                      }}
                    >
                      <Text size={12} color={getPrimaryThemeColor(theme)} transform="uppercase">
                        {t('invites.modal.unselectAll')}
                      </Text>
                    </UnstyledButton>
                  </Group>
                  <DrawerUserList
                    options={splashInvites}
                    optionsSelected={splashInvites}
                    setOptionsSelected={setSplashInvites}
                  />
                </Stack>
              )}
              {rypPools.length > 0 && (
                <Stack>
                  <Group position="apart">
                    <Text size={12} color={theme.colors.gray[6]} transform="uppercase">
                      {t('invites.modal.rypPools__hasPlural', {
                        count: rypPools.length,
                      })}
                    </Text>
                    <UnstyledButton
                      onClick={() => {
                        setRYPPools([]);
                        setTabletDrawerOpen(false);
                      }}
                    >
                      <Text size={12} color={getPrimaryThemeColor(theme)} transform="uppercase">
                        {t('invites.modal.unselectAll')}
                      </Text>
                    </UnstyledButton>
                  </Group>
                  <DrawerUserList
                    options={rypPools}
                    optionsSelected={rypPools}
                    setOptionsSelected={setRYPPools}
                  />
                </Stack>
              )}
              {rypInvites.length > 0 && (
                <Stack>
                  <Group position="apart">
                    <Text size={12} color={theme.colors.gray[6]} transform="uppercase">
                      {t('invites.modal.rypInvites__hasPlural', {
                        count: rypInvites.length,
                      })}
                    </Text>
                    <UnstyledButton
                      onClick={() => {
                        setRYPInvites([]);
                        setTabletDrawerOpen(false);
                      }}
                    >
                      <Text size={12} color={getPrimaryThemeColor(theme)} transform="uppercase">
                        {t('invites.modal.unselectAll')}
                      </Text>
                    </UnstyledButton>
                  </Group>
                  <DrawerUserList
                    options={rypInvites}
                    optionsSelected={rypInvites}
                    setOptionsSelected={setRYPInvites}
                  />
                </Stack>
              )}
              {emailInvites.length > 0 && (
                <Stack>
                  <Group position="apart">
                    <Text size={12} color={theme.colors.gray[6]} transform="uppercase">
                      {t('invites.modal.inviteViaEmail.title')}
                    </Text>
                    <UnstyledButton
                      onClick={() => {
                        setEmailInvites([]);
                        setTabletDrawerOpen(false);
                      }}
                    >
                      <Text size={12} color={getPrimaryThemeColor(theme)} transform="uppercase">
                        {t('invites.modal.removeAll')}
                      </Text>
                    </UnstyledButton>
                  </Group>
                  <DrawerUserList
                    options={emailInvites}
                    optionsSelected={emailInvites}
                    setOptionsSelected={setEmailInvites}
                    isEmail
                  />
                </Stack>
              )}
            </Stack>
            <Divider />
          </ScrollArea>
          <Group mt="lg" position="right" align="center">
            <Button
              fullWidth
              size="lg"
              radius="md"
              onClick={() => handleSendInvites()}
              loading={isLoading}
            >
              <Text color="black">{t('invites.modal.sendInvites')}</Text>
            </Button>
          </Group>
        </Box>
      </Drawer>
    </>
  );
}

export default TabletDrawer;
