import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  userRowBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing.lg,
    borderRadius: theme.radius.md,
  },
  userRowBodyChecked: {
    backgroundColor: '#DBFBFF', // TODO: Add to theme colors
  },
  drawer: {
    backgroundColor: 'white',
    // Override for Tablet Drawer icons
    '.mantine-Checkbox-inner .mantine-Checkbox-input': {
      background: 0,
      border: 0,
    },
    '& .mantine-Checkbox-inner > .mantine-Checkbox-icon': {
      color: theme.colors.gray[7],
      transform: 'scale(2.5)',
    },
  },
  userRowLabelWrapper: {
    width: '100%',
    padding: theme.spacing.xs,
  },
  avatar: {
    '& .mantine-Avatar-placeholder': {
      backgroundColor: theme.colors.gray[4],
      borderRadius: '50%',
    },
  },
}));
