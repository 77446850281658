import type { SelectItem } from '@mantine/core';
import { Flex, Select, Stack, Text, TextInput, useMantineTheme } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import useTranslation from 'next-translate/useTranslation';

import { useFullStoryRef, FS_VISIBILITY } from '~/domains/analytics';
import dt from '~/testing';

import { type BillingAddressFormValues } from './types';
import SameAddressCheckbox from './SameAddressCheckbox';
import AddressAutocomplete from './AddressAutocomplete';

type BillingAddressFormSectionProps<T extends BillingAddressFormValues> = {
  form: UseFormReturnType<T>;
  stateOptions: SelectItem[];
  countryOptions: SelectItem[];
  withSameAddressCheckbox?: boolean;
};

export default function BillingAddressFormSection<T extends BillingAddressFormValues>({
  form,
  countryOptions,
  withSameAddressCheckbox,
}: BillingAddressFormSectionProps<T>) {
  const { t } = useTranslation('forms');
  const theme = useMantineTheme();
  const FS_HIDE = useFullStoryRef(FS_VISIBILITY.FS_HIDE);

  return (
    <Stack
      spacing={8}
      ref={FS_HIDE}
      data-test-id={dt.payments.deposits.depositForm.billingAddressFormSection}
    >
      <Text
        fw={700}
        size={18}
        color={theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.black}
      >
        {t('billingAddress.title')}
      </Text>

      {withSameAddressCheckbox && <SameAddressCheckbox form={form} />}

      <Select
        {...form.getInputProps('billingAddress.country')}
        placeholder={t('billingAddress.country.placeholder')}
        label={t('billingAddress.country.label')}
        data-test-id={dt.payments.deposits.depositForm.billingCountrySelect}
        labelProps={{ size: 'sm' }}
        data={countryOptions}
        withAsterisk
        size="lg"
        w="100%"
      />

      <AddressAutocomplete form={form} />

      <TextInput
        {...form.getInputProps('billingAddress.address2')}
        placeholder={t('billingAddress.address2.placeholder')}
        label={t('billingAddress.address2.label')}
        labelProps={{ size: 'sm' }}
        size="lg"
        w="100%"
      />

      <Flex w="100%" gap="md" align="flex-start" direction={{ base: 'column', sm: 'row' }}>
        <TextInput
          {...form.getInputProps('billingAddress.city')}
          placeholder={t('billingAddress.city.placeholder')}
          label={t('billingAddress.city.label')}
          data-test-id={dt.payments.deposits.depositForm.billingCityInput}
          labelProps={{ size: 'sm' }}
          withAsterisk
          size="lg"
          w="100%"
        />

        <TextInput
          {...form.getInputProps('billingAddress.state')}
          placeholder={
            form.values.billingAddress.country === 'US'
              ? t('billingAddress.state.placeholder.US')
              : t('billingAddress.state.placeholder.CA')
          }
          label={
            form.values.billingAddress.country === 'US'
              ? t('billingAddress.state.label.US')
              : t('billingAddress.state.label.CA')
          }
          labelProps={{ size: 'sm' }}
          data-test-id={dt.payments.deposits.depositForm.billingStateSelect}
          withAsterisk
          size="lg"
          w="100%"
        />
      </Flex>

      <TextInput
        {...form.getInputProps('billingAddress.zip')}
        placeholder={t('billingAddress.zip.placeholder')}
        label={t('billingAddress.zip.label')}
        data-test-id={dt.payments.deposits.depositForm.billingZipInput}
        labelProps={{ size: 'sm' }}
        withAsterisk
        size="lg"
        w="100%"
      />
    </Stack>
  );
}
