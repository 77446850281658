import { Loader, Stack } from '@mantine/core';
import '@dotlottie/react-player/dist/index.css';

function EnterContestSubmittingScreen() {
  return (
    <Stack align="center" justify="center" spacing="xs" h="100%" mih={300}>
      <Loader />
    </Stack>
  );
}

export default EnterContestSubmittingScreen;
