/* eslint-disable react/jsx-no-target-blank */
import { Divider } from '@mantine/core';

import { UnstyledNextLink } from '~/components/shared/UnstyledNextLink/UnstyledNextLink';
import IconExternalLink from '~/domains/design-system/icons/IconExternalLink';

import useStyles from './styles';
import { LogoRunYourPool } from './logos/LogoRunYourPool';
import { LogoOfficeFootballPool } from './logos/LogoOfficeFootballPool';
import { LogoSplashText } from './logos/LogoSplashText';

type SitesMenuContentProps = {
  onClick?: () => void;
};

function SitesMenuContent({ onClick }: SitesMenuContentProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.content}>
      <div className={classes.dropdownLabel}>Our sites</div>
      <div className={classes.links}>
        <UnstyledNextLink href="/" className={classes.link} onClick={onClick}>
          <LogoSplashText />
        </UnstyledNextLink>
        <Divider color="gray.2" />
        <a
          href="https://runyourpool.com"
          target="_blank"
          className={classes.link}
          onClick={onClick}
        >
          <LogoRunYourPool />
          <IconExternalLink />
        </a>
        <Divider color="gray.2" />
        <a
          href="https://officefootballpool.com"
          className={classes.link}
          target="_blank"
          onClick={onClick}
        >
          <LogoOfficeFootballPool />
          <IconExternalLink />
        </a>
      </div>
    </div>
  );
}

export default SitesMenuContent;
