import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { v4 as uuidv4 } from 'uuid';

import { FEATURE } from '~/common/enums/feature.enum';
import { PaymentType } from '~/common/enums/payments.enum';
import { reportEvent } from '~/domains/analytics';
import { MessageKeys } from '~/domains/payments/deposits/components/consts';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { createPayPalHandle } from '~/services/wallet/wallet.service.api';

import { isCustomDeposit } from './consts';
import { getFallbackUrl } from './getFallbackUrl';

function useDepositWithPaypal() {
  const { t } = useTranslation('payments');
  const usePayPalInternalSimulator = useIsFeatureEnabled(FEATURE.USE_PAYPAL_INTERNAL_SIMULATOR);

  return useMutation({
    mutationFn: async ({
      depositAmountWithFees,
      email,
      merchantRefNum = uuidv4(),
      contestIdForPostDeposit,
      fallbackUrl,
    }: {
      depositAmount: number;
      depositAmountWithFees: number;
      email: string;
      merchantRefNum?: string;
      contestIdForPostDeposit?: string;
      fallbackUrl: string;
    }) =>
      createPayPalHandle({
        amount: depositAmountWithFees,
        currency: 'USD',
        merchantRefNum,
        email,
        simulator: usePayPalInternalSimulator ? 'INTERNAL' : 'EXTERNAL',
        transactionType: 'PAYMENT',
        returnLinks: [
          {
            rel: 'default',
            href: getFallbackUrl(fallbackUrl),
            method: 'GET',
          },
          {
            rel: 'on_completed',
            href: `${window.location.origin}/account/wallet/deposit/success?type=${
              PaymentType.PAYPAL
            }${contestIdForPostDeposit ? `&contestId=${contestIdForPostDeposit}` : ''}`,
            method: 'POST',
          },
          {
            rel: 'on_failed',

            href: `${window.location.origin}/account/wallet/deposit?message=${MessageKeys.REJECTED}`,
            method: 'POST',
          },
        ],
      }),
    onSuccess: (response, payload) => {
      if (response.data.links) {
        reportEvent('Transactions > Deposit', {
          deposit_id: response.id,
          deposit_method: 'Paypal',
          deposit_amount: payload.depositAmount,
          is_custom_deposit: isCustomDeposit(payload.depositAmount),
          currency: 'USD',
        });

        localStorage.setItem(
          'depositData',
          JSON.stringify({
            paymentHandleId: response.data.id,
            paymentHandleToken: response.data.paymentHandleToken,
            amount: payload.depositAmount,
            merchantRefNum: payload.merchantRefNum,
          })
        );
        const redirectLink = response.data.links[0].href;
        window.location.href = redirectLink;
      } else {
        showNotification({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error Missing types for error response in Swagger
          title: response.error,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error Missing types for error response in Swagger
          message: response?.data?.message || response.message,
          color: 'red',
          autoClose: 5000,
        });
      }
    },
    onError: (error: AxiosError<{ message: string }>) => {
      const errorMessage = {
        /** @ts-expect-error @todo EDGE-5999 - error is not typed */
        title: t(`errors.${error.code}.title`),
        /** @ts-expect-error @todo EDGE-5999 - error is not typed */
        message: t(`errors.${error.code}.message`),
      };

      if (error.code === 'ERR_BAD_REQUEST') {
        errorMessage.title = t('deposits.errors.paypal');
        errorMessage.message = error.response?.data?.message ?? '';
      }

      showNotification({
        title: errorMessage.title,
        message: errorMessage.message,
        color: 'red',
        autoClose: 5000,
      });
    },
  });
}

export { useDepositWithPaypal };
