export function objectToBase64(obj: Record<string, unknown>): string {
  const jsonString = JSON.stringify(obj);

  return typeof window === 'undefined'
    ? // TODO: Explore why there were issues with `Buffer.from`
      // eslint-disable-next-line no-buffer-constructor
      new Buffer(jsonString).toString('base64')
    : btoa(jsonString);
}

export function base64ToObject(param: string): Record<string, unknown> {
  const jsonString =
    typeof window === 'undefined' ? Buffer.from(param, 'base64').toString() : atob(param);

  return JSON.parse(jsonString);
}
