import { useQuery } from '@tanstack/react-query';
import { useDebouncedValue } from '@mantine/hooks';
import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { getAutocomplete } from '~/domains/services/nextApi.service';

const mapAddressComponents = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  const getAddressComponent = (type: string, short = false) => {
    const component = addressComponents.find((item) => item.types.includes(type));
    return short ? component?.short_name || '' : component?.long_name || '';
  };

  return {
    address: `${getAddressComponent('street_number')} ${getAddressComponent('route', true)}`.trim(),
    city: getAddressComponent('locality'),
    state: getAddressComponent('administrative_area_level_1', true),
    zip: getAddressComponent('postal_code'),
  };
};

const useAddressLookup = ({ query, country }: { query: string; country: string }) => {
  const [debouncedValue] = useDebouncedValue(query?.trim(), 300);
  const enabled = !!debouncedValue;
  const sessionToken = useMemo(() => uuidv4(), []);

  const getPlaceDetail = useCallback(
    async (placeId: string) => {
      try {
        const data = await getAutocomplete({
          placeId,
          sessionToken,
        });

        if (data && 'result' in data) {
          return mapAddressComponents(data?.result?.address_components);
        }

        return mapAddressComponents([]);
      } catch (error) {
        console.error(error);
        return mapAddressComponents([]);
      }
    },
    [sessionToken]
  );

  const { data, isLoading, error } = useQuery({
    queryKey: ['googlePlacesAddressLookup', debouncedValue, country],
    queryFn: () =>
      getAutocomplete({
        input: debouncedValue,
        country,
        sessionToken,
      }),
    enabled,
  });

  const dataForAutocomplete = useMemo(() => {
    if (data && 'predictions' in data) {
      return (
        data?.predictions?.map((prediction) => ({
          value: prediction.description,
          data: prediction,
        })) || []
      );
    }
    return [];
  }, [data]);

  return {
    dataForAutocomplete,
    getPlaceDetail,
    isLoading: enabled && isLoading,
    error,
  };
};

export default useAddressLookup;
