import { Header as HeaderPrimitive, Group } from '@mantine/core';
import type { ComponentProps } from 'react';
import React from 'react';

export const defaultHeaderHeight = 60;

type HeaderProps = {
  children: React.ReactNode;
  height?: ComponentProps<typeof HeaderPrimitive>['height'];
  pos?: ComponentProps<typeof HeaderPrimitive>['pos'];
  className?: string;
};

export function Header({ children, height = defaultHeaderHeight, pos, className }: HeaderProps) {
  return (
    <HeaderPrimitive
      height={height}
      px="md"
      sx={(theme) => ({
        background: theme.black,
        zIndex: theme.other.zIndex.header,
        borderBottom: 'none',
      })}
      pos={pos}
      className={className}
    >
      <Group spacing={0} h="100%" align="center" position="apart" noWrap>
        {children}
      </Group>
    </HeaderPrimitive>
  );
}
