export function CurrencyConvertor(value, forceShowDecimal = false) {
  const isValidNumber = !Number.isNaN(parseFloat(value));
  if (isValidNumber) {
    const decimal = value % 1 === 0 ? 0 : 2;
    const decimalPlaces = forceShowDecimal ? 2 : decimal;

    return `${new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
    }).format(value)}`;
  }
  return '';
}

export function AddRankSuffix(num: number): string {
  let suffix: string;
  if (num % 100 >= 11 && num % 100 <= 13) {
    suffix = 'th';
  } else {
    switch (num % 10) {
      case 1:
        suffix = 'st';
        break;
      case 2:
        suffix = 'nd';
        break;
      case 3:
        suffix = 'rd';
        break;
      default:
        suffix = 'th';
    }
  }
  return num + suffix;
}

// Shortens currency name into K(Thousands), M(Millions), B(Billions) etc
// eslint-disable-next-line import/no-unused-modules
export function IntToString(currency) {
  const value = currency.toString().replace(/[^0-9.]/g, '');
  if (value < 1000) {
    return value;
  }
  const si = [
    { val: 1e3, str: 'K' },
    { val: 1e6, str: 'M' },
    { val: 1e9, str: 'B' },
    { val: 1e12, str: 'T' },
    { val: 1e15, str: 'P' },
    { val: 1e18, str: 'E' },
  ];
  let index;
  for (index = si.length - 1; index > 0; index -= 1) {
    if (value >= si[index].val) {
      break;
    }
  }
  return (
    (value / si[index].val).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].str
  );
}

export function ReturnPercentage(val, total) {
  return (val / total) * 100;
}

// create a function to convert a date string to american
export function FormatDate(dateString: string): string {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

// create a function to convert a date string to short date
// eslint-disable-next-line import/no-unused-modules
export function FormatShortDate(dateString: string): string {
  const date = new Date(dateString);
  const month = date.toLocaleString('en-us', { month: 'short' });
  return `${month} ${date.getDate()}`;
}

export const SplitFullNameToFirstLast = (fullName: string) => {
  if (!fullName) {
    return { firstName: '', lastName: '' };
  }

  const spaceIndex = fullName.indexOf(' ');
  if (spaceIndex === -1) {
    // in case there are any pop stars
    return { firstName: fullName, lastName: '' };
  }
  const firstName = fullName.substring(0, spaceIndex);
  const lastName = fullName.substring(spaceIndex + 1, fullName.length);

  return { firstName, lastName };
};

export const camelCaseToTitleCase = (camelCaseString: string) => {
  const result = camelCaseString.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const upperCaseToTitleCase = (uppercaseString: string) => {
  const finalResult =
    uppercaseString.charAt(0).toUpperCase() + uppercaseString.slice(1).toLowerCase();
  return finalResult;
};

export const snakeCaseToTitleCase = (snakeCaseString: string) => {
  const words = snakeCaseString.split('_');
  const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return titleCaseWords.join(' ');
};

export const getInitialsFromFullName = (fullName: string) => {
  const initials = fullName
    .split(' ')
    .map((n) => n[0])
    .join('');

  return initials;
};

export const getFullName = (firstName: string | undefined, lastName: string | undefined) =>
  [firstName, lastName].filter(Boolean).join(' ').trim();

export const getTimeAgoTranslation = (rawDate: string) => {
  const date = new Date(rawDate);
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  const TIME = {
    secondsPerYear: 31536000,
    secondsPerMonth: 2592000,
    secondsPerDay: 86400,
    secondsPerHour: 3600,
    secondsPerMinute: 60,
  };
  let interval = Math.floor(seconds / TIME.secondsPerYear);
  if (interval >= 1) {
    return { key: 'common.timeAgo.year__hasPlural' as const, interval };
  }

  interval = Math.floor(seconds / TIME.secondsPerMonth);
  if (interval >= 1) {
    return { key: 'common.timeAgo.month__hasPlural' as const, interval };
  }

  interval = Math.floor(seconds / TIME.secondsPerDay);
  if (interval >= 1) {
    return { key: 'common.timeAgo.day__hasPlural' as const, interval };
  }

  interval = Math.floor(seconds / TIME.secondsPerHour);
  if (interval >= 1) {
    return { key: 'common.timeAgo.hour__hasPlural' as const, interval };
  }

  interval = Math.floor(seconds / TIME.secondsPerMinute);
  if (interval >= 1) {
    return { key: 'common.timeAgo.minute__hasPlural' as const, interval };
  }

  if (seconds < 10) return { key: 'common.timeAgo.justNow' as const };

  interval = Math.floor(seconds);
  return { key: 'common.timeAgo.second__hasPlural' as const, interval };
};

// eslint-disable-next-line import/no-unused-modules
export const extractFirstNumberFromString = (string) => {
  const pattern = /\d+/;
  const match = string.match(pattern);
  if (match) {
    return parseInt(match[0], 10); // Convert the matched string to an integer
  }
  return null; // Return null if no number is found
};
