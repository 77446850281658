import { createStyles } from '@mantine/core';

type UseLeaderboardWidgetStylesArgs = { isSurvivor?: boolean; progressBarHeight?: number };

const SURVIVOR_TICK_HEIGHT = 16;

const useLeaderboardWidgetStyles = createStyles(
  (theme, { isSurvivor, progressBarHeight }: UseLeaderboardWidgetStylesArgs) => ({
    meta: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',
    },
    metaItem: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'inherit',
    },

    progressBar: {
      position: 'relative',
      height: progressBarHeight,
      background: theme.colors.gray[2],
      borderRadius: theme.radius.md,
    },
    progressBarValue: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      borderRadius: theme.radius.md,
      borderTopLeftRadius: isSurvivor ? '0' : 'inherit',
      borderBottomLeftRadius: isSurvivor ? '0' : 'inherit',
      background: isSurvivor ? theme.colors.gray[9] : theme.colors.green[5],
      transition: 'width 100ms linear',

      '&:before': isSurvivor
        ? {
            content: '""',
            position: 'absolute',
            left: 0,
            top: -(SURVIVOR_TICK_HEIGHT - progressBarHeight) / 2,
            background: theme.colors.gray[9],
            height: SURVIVOR_TICK_HEIGHT,
            width: 1,
          }
        : null,
    },
  })
);

export default useLeaderboardWidgetStyles;
