import { useQuery } from '@tanstack/react-query';

import {
  getAvailablePaymentMethodsDeposit,
  getAvailablePaymentMethodsWithdrawal,
} from '~/services/wallet/wallet.service.api';
import { TransactionType } from '~/common/enums/payments.enum';

import { PAYMENTS_QUERY_STALE_TIME } from './consts';

type UseAvailablePaymentMethodsProps = {
  transactionType: TransactionType;
};

export function useAvailablePaymentMethods({ transactionType }: UseAvailablePaymentMethodsProps) {
  const { data, isFetching } = useQuery({
    queryKey: [`payments/availablePaymentMethods/${transactionType}`],
    queryFn:
      transactionType === TransactionType.DEPOSIT
        ? getAvailablePaymentMethodsDeposit
        : getAvailablePaymentMethodsWithdrawal,
    staleTime: PAYMENTS_QUERY_STALE_TIME,
  });

  return {
    availablePaymentMethods: data,
    isLoadingAvailablePaymentMethods: isFetching,
  };
}
