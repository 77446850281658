import { useQuery } from '@tanstack/react-query';

import { getCurrentLocation } from '../contest.service.api';

const useCurrentLocation = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}) =>
  useQuery({
    queryKey: ['locations', 'current'],
    queryFn: getCurrentLocation,
    enabled,
  });

export default useCurrentLocation;
