import type { AvatarProps as AvatarPrimitiveProps } from '@mantine/core';
import { Avatar as AvatarPrimitive, Box, createStyles } from '@mantine/core';
import Image from 'next/image';
import { useState } from 'react';

const useStyles = createStyles({
  avatar: {
    borderRadius: '100%',
    flexShrink: 0,
    aspectRatio: '1 / 1',
    objectFit: 'cover',
  },
  nextImage: {
    width: '100%',
    height: '100%',
  },
});

// https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/Avatar/Avatar.styles.ts#L22-L28
const avatarSizes = {
  xs: 16,
  sm: 26,
  md: 38,
  lg: 56,
  xl: 84,
};

type AvatarProps = AvatarPrimitiveProps & {
  /**
   * @description Image optimization by appending a nonce to the URL, effectively skipping the cache.
   */
  skipNextImageCache?: boolean;

  /**
   * @deprecated Avoids using Next.js Image component. This option is provided for specific cases and should be used cautiously.
   */
  skipNextImageOptimization?: boolean;
};

/**
 * @deprecated use `Avatar` from `~/domains/design-system/Avatar` instead.
 * TODO: migrate this component to design-system.
 */
export function Avatar({
  src,
  alt = '',
  size = 'md',
  skipNextImageCache,
  skipNextImageOptimization,
  className,
  ...props
}: AvatarProps) {
  const { cx, classes } = useStyles();
  const [imageError, setImageError] = useState(false);

  const getCacheBustingUrl = (url: string): string => {
    const oneMinute = 1000 * 60;
    const timestamp = Math.round(Date.now() / oneMinute);
    return `${url}?cache=${timestamp}`;
  };

  const sizeInPixels = typeof size === 'number' ? size : avatarSizes[size];

  if (src && !imageError && !skipNextImageOptimization) {
    return (
      <Box h={sizeInPixels} w={sizeInPixels} sx={{ flexShrink: 0 }}>
        <Image
          src={skipNextImageCache ? getCacheBustingUrl(src) : src}
          height={sizeInPixels * 2}
          width={sizeInPixels * 2}
          className={cx(classes.avatar, classes.nextImage, className)}
          onError={() => setImageError(true)}
          data-test-id={props['data-test-id']}
          alt={alt}
          quality={100}
        />
      </Box>
    );
  }

  return (
    <AvatarPrimitive
      src={src}
      alt={alt}
      data-test-id={props['data-test-id']}
      className={cx(classes.avatar, className)}
      size={size}
      {...props}
    />
  );
}
