import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';

export const useCountdown = (targetDate: Date) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [isApproachingDeadline, setIsApproachingDeadline] = useState(false);
  const [hasDeadlinePassed, setHasDeadlinePassed] = useState(false);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();

      if (!targetDate) {
        return null;
      }

      if (now >= targetDate && !hasDeadlinePassed) {
        setTimeLeft('00:00:00');
        setHasDeadlinePassed(true);
      } else {
        const duration = intervalToDuration({ start: now, end: targetDate });
        const totalHours = (duration.days || 0) * 24 + (duration.hours || 0);
        const totalMonths = (duration.years || 0) * 12 + (duration.months || 0);

        if (totalHours < 2 && !isApproachingDeadline) {
          setIsApproachingDeadline(true);
        }

        const formattedTime =
          duration.days > 0 || totalMonths > 0
            ? `${totalMonths ? `${totalMonths}m ` : ''}${duration.days}d ${duration.hours}h`
            : [
                String(duration.hours).padStart(2, '0'),
                String(duration.minutes || 0).padStart(2, '0'),
                String(duration.seconds || 0).padStart(2, '0'),
              ].join(':');

        setTimeLeft(formattedTime);
      }
    };

    updateCountdown();

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [hasDeadlinePassed, isApproachingDeadline, targetDate]);

  return { timeLeft, isApproachingDeadline, hasDeadlinePassed };
};
