import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as FullStory from '@fullstory/browser';
import { isAxiosError } from 'axios';

import { isFullStoryInitialised } from './fullStory/initFullStory';

const initBugSnag = () => {
  if (process.env.NEXT_PUBLIC_BUGSNAG_ENABLED !== 'true') {
    return;
  }

  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NEXT_PUBLIC_ENV,
    appVersion: process.env.VERSION,
    // appType: config.BUILD_TARGET,
    trackInlineScripts: false,
    onError: (event) => {
      const firstError = event.errors[0];
      if (
        isAxiosError(firstError) &&
        ['Request aborted', 'Network Error', 'Timeout error'].includes(firstError?.message)
      ) {
        return false;
      }

      if (isFullStoryInitialised()) {
        event.addMetadata('fullstory', {
          urlAtTime: FullStory.getCurrentSessionURL(true),
        });
      }
    },
  });
};

const isBugSnagInitialised = () => Bugsnag.isStarted();

const stopBugSnag = () => {
  if (isBugSnagInitialised()) {
    Bugsnag.pauseSession();
  }
};

export { initBugSnag, isBugSnagInitialised, stopBugSnag };
