import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import type { PaymentMethod, TransactionType } from '~/common/enums/payments.enum';
import { FeeType } from '~/common/enums/payments.enum';
import { getProcessingFees } from '~/services/wallet/wallet.service.api';

import { PAYMENTS_QUERY_STALE_TIME } from './consts';

type UseProcessingFeeArgs = {
  transactionType: TransactionType;
  paymentMethod: PaymentMethod;
};

export function useProcessingFees({ transactionType, paymentMethod }: UseProcessingFeeArgs) {
  const { data: processingFees, isLoading } = useQuery({
    queryKey: ['payments/processingFees'],
    queryFn: getProcessingFees,
    staleTime: PAYMENTS_QUERY_STALE_TIME,
  });

  const processingFee = useMemo(
    () =>
      processingFees?.find(
        (fee) => fee.paymentMethod === paymentMethod && fee.transactionType === transactionType
      ),
    [paymentMethod, processingFees, transactionType]
  );

  const calculateFee = useCallback(
    (amount: number) => {
      if (!processingFee) {
        return 0;
      }
      if (processingFee.type === FeeType.AMOUNT) {
        return processingFee.amount;
      }
      if (processingFee.type === FeeType.PERCENTAGE) {
        return (processingFee.amount / 100) * amount;
      }
    },
    [processingFee]
  );

  return { processingFee, processingFees, calculateFee, isLoading };
}
