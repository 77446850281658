import { Text } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { CurrencyConvertor } from '~/components/utils/formatters';

import useLeaderboardWidgetStyles from './styles';
import type { LeaderboardWidgetData } from './types';

function MetaSurvivor({
  userEntries,
  entryPrice,
  isSettled,
  totalEntries,
  winning,
  winningEntries,
}: LeaderboardWidgetData) {
  const { t } = useTranslation('contest');
  const { classes } = useLeaderboardWidgetStyles({ isSurvivor: true });

  const aliveEntries = userEntries.filter((entry) => entry.isAlive);

  return (
    <div className={classes.meta}>
      <div className={classes.metaItem}>
        <Text size={10} span>
          {t('game.common.leadeboardWidget.mySurvivors')}&nbsp;
        </Text>
        <Text size="xs" span fw={700}>
          {aliveEntries.length}/{userEntries.length}
        </Text>
      </div>
      {entryPrice && (
        <div className={classes.metaItem}>
          <Text size={10} span>
            {t('game.common.leadeboardWidget.entry')}&nbsp;
          </Text>
          <Text size="xs" span fw={700}>
            {CurrencyConvertor(entryPrice)}
          </Text>
        </div>
      )}
      <div className={classes.metaItem}>
        {isSettled ? (
          <>
            <Text size={10} span>
              {t('game.common.leadeboardWidget.won')}&nbsp;
            </Text>
            <Text size="xs" span fw={700} color={winning === 0 ? 'gray.5' : 'green.5'}>
              {CurrencyConvertor(winning)}
            </Text>
          </>
        ) : (
          <>
            <Text size={10} span>
              {t('game.common.leadeboardWidget.suvivors')}&nbsp;
            </Text>
            <Text size="xs" span fw={700}>
              {winningEntries}/{totalEntries}
            </Text>
          </>
        )}
      </div>
    </div>
  );
}

export default MetaSurvivor;
