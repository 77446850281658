import { logError } from '~/common/utils/log';

export const getCdnUrl = (assetPath: string) => {
  try {
    return new URL(assetPath, process.env.NEXT_PUBLIC_ASSETS_URL).toString();
  } catch (err) {
    logError(err);
    return `${process.env.NEXT_PUBLIC_ASSETS_URL}${
      assetPath.startsWith('/') ? '' : '/'
    }${assetPath}`;
  }
};
