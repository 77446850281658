import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { UserContext } from '~/components/providers/UserProvider';

import { getKycVerify } from '../../../services/kyc/kyc.service.api';

import { PAYMENTS_QUERY_STALE_TIME } from './consts';

export function useKYCStatus() {
  const { user } = useContext(UserContext);
  return useQuery({
    queryKey: ['kyc/verification', user?.id],
    queryFn: async () => {
      const response = await getKycVerify();
      return response.data.status;
    },
    enabled: !!user,
    staleTime: PAYMENTS_QUERY_STALE_TIME,
  });
}
