export default {
  myAccount: {
    limits: {
      title: 'User Limits',
      depositLimit: 'Deposit Limit',
      depositLimitCopy:
        'Set deposit limits to restrict the amount you can deposit into your account for each timeframe.',
      entryFeeLimit: 'Entry Fee Limit',
      entryFeeLimitCopy: 'Set limits on the amount you can use on entry fees for each timeframe.',
      contestEntryLimit: 'Contest Entry Limit',
      contestEntryLimitCopy:
        'Set limits on the amount of contest you can enter for each timeframe.',
      loading: 'Fetching Limits...',
      label: '{{count}} Limits Set',
      cta: 'View and Set Limits',
      emptyLabel: 'No Limits Set',
      emptyCta: 'Set Limits',
      limitsAlreadySet: 'Limits Already Set',
      conformLimits: 'Confirm Limits',
      xContests: '{{amount}} contests',
      missingField: {
        title: 'Missing Field',
        amount: 'Please select your amount',
      },
      successSetLimits: {
        title: 'Limits Set Successfully!',
        message: 'Congratulations, you set your responsible gaming limits.',
      },
      errorSetLimits: {
        title: 'Limits Not Set Successfully',
        defaultMessage:
          'Unfortunately, we could not set your responsible gaming limits. Please contact customer support.',
      },
      weeklyMonthly: 'Weekly/Monthly',
      weekly: 'Weekly',
      monthly: 'Monthly',
      limit: 'Limit',
      setYourLimit: 'Set your limit',
      amount: 'Amount',
    },
    timeouts: {
      label: 'No Timeout Set',
      cta: 'Set Timeout',
    },
    responsibleGaming: {
      label: 'Responsible Gaming Info',
      cta: 'Learn More',
      accordionLabel: 'Responsible Gaming',
      accordionDescription: 'Set your limits and learn more about responsible gaming',
    },
    communications: {
      accordionLabel: 'Communication Settings',
      accordionDescription: 'Manage your communication settings',
      panelLabel: 'For the email {{email}}',
      panelDescription:
        'If you wish to stop receiving emails for any of the topics below, simply uncheck the relevant box.',
      splashNewsletterLabel: 'Marketing Communications',
      splashNewsletterDescription:
        'If this box is checked, you will receive communications from our marketing team.',
      preferences: {
        updated: {
          title: 'Preference Updated',
        },
        enabledNotification: 'You will now receive {{type}} notifications for {{name}}.',
        disabledNotification: 'You will no longer receive {{type}} notifications for {{name}}.',
      },
    },
    loginAndSettings: {
      accordionLabel: 'Login and Account Settings',
      accordionDescription: 'Manage your account settings',
      resetPassword: 'Reset Password',
      deactivateAccount: 'Deactivate Account',
      accountDeactivation: {
        title: 'Account Deactivation',
        copy1:
          'Deactivating your Splash account will temporarily close your account. You will no longer be able to log in and you will not receive communications from us.',
        copy2: 'You may reach out to customer support at any time to reactivate your account.',
        cancel: "I've changed my mind!",
      },
    },
  },
};
