import { showNotification } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { CheckRequestStatus } from '@xpointtech/xpoint-js';
import { createContext, useContext, useEffect, useMemo, useState, type ReactNode } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { updateAnalyticsUser } from '~/domains/analytics';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { useLocationStatus } from '~/hooks/useLocationStatus';
import { PermissionStatusState, getGeoPermissions } from '~/utils/geoPermissions';
import XPointSingleton from '~/utils/xpoint';
// TODO: fix cycle dependency
// eslint-disable-next-line import/no-cycle
import { useLocationCountry } from '~/domains/common/hooks/useLocationCountry';
import useCurrentLocation from '~/domains/contest/domains/services/hooks/useCurrentLocation';

import { UserContext } from './UserProvider';

type LocationCountryName = 'united states' | 'canada';

const AllowedLocations: LocationCountryName[] = ['united states', 'canada'];
const DEFAULT_LOCATION_COUNTRY_NAME: LocationCountryName = 'united states';

export const LocationContext = createContext<{
  locationStatus?: CheckRequestStatus;
  locationCountryName?: string;
  locationCountryNameSafe?: LocationCountryName;
  geoPermissionStatus?: PermissionState;
  isLoading?: boolean;
  setLocationCountryNameOverride: (country: LocationCountryName) => void;
}>({
  locationStatus: undefined,
  locationCountryName: undefined,
  geoPermissionStatus: undefined,
  setLocationCountryNameOverride: () => {},
});

function LocationProvider({ children }: { children?: ReactNode }) {
  const { user } = useContext(UserContext);
  const [geoPermissionStatus, setGeoPermissionStatus] = useState<PermissionState>();
  const isLocationCheckOnLoginEnabled = useIsFeatureEnabled(FEATURE.ENABLE_LOCATION_CHECK_ON_LOGIN);
  const [locationCountryNameOverride, setLocationCountryNameOverride] =
    useState<LocationCountryName>();

  // Step 1: Log in into the Web Platform
  const { isFetched: isXPointReady } = useQuery({
    queryKey: ['location/xPoint/init'],
    queryFn: () => XPointSingleton.init(user.id),
    staleTime: Infinity,
    cacheTime: 0,
    enabled: !!user?.id,
  });

  // Step 2: Ensure any token unless disabled in SplitIO
  // The business logic is "fetch location token after login"
  // If user just logged in, there is no locationToken in localStorage so this will fetch a new one
  // If user just refreshed a browser, the previous token is saved in localStorage
  // so the fetch won't trigger
  // Added 6/20: If the users geoPermissionStatus updates, we will refetch location token
  useQuery({
    queryKey: ['location/xPoint/liteCheck', user?.id, geoPermissionStatus],
    queryFn: async () => {
      // `-Infinity` means we prefer an expired token to a new one
      const locationToken = await XPointSingleton.getValidLocationToken(
        -Infinity,
        geoPermissionStatus === PermissionStatusState.GRANTED &&
          locationStatus === CheckRequestStatus.DENIED
      );
      const errors = locationToken?.errors;

      if (errors) {
        for (const error of errors) {
          showNotification({
            title: 'Location Error',
            message: error.description,
            color: 'orange.3',
            autoClose: 20000,
          });
        }
      }
      return null;
    },
    staleTime: Infinity,
    cacheTime: 0,
    enabled: isXPointReady && isLocationCheckOnLoginEnabled,
  });

  // Step 3: Use saved location token
  const locationStatus = useLocationStatus();
  const authLocationCountry = useLocationCountry();

  // Step 4: Use navigator permissions obtain geolocation permission
  useQuery({
    queryKey: ['location/geoLocationPermissionState'],
    queryFn: async () => getGeoPermissions(setGeoPermissionStatus),
    enabled: !!navigator?.permissions && !!user?.id,
  });

  const { data: anonUserCurrentLocation, isLoading: anonUserCurrentLocationIsLoading } =
    useCurrentLocation({
      enabled: !user,
    });

  const locationCountryName: LocationCountryName = useMemo(
    () =>
      user ? authLocationCountry?.toLowerCase() : anonUserCurrentLocation?.country.toLowerCase(),
    [anonUserCurrentLocation?.country, authLocationCountry, user]
  );

  // This is used for displaying filtered content based on user's location
  // If the location is not in the allowed list, we default to 'united states'
  // and show e.g. homepage content for as if the user is in the US
  const locationCountryNameSafe: LocationCountryName = useMemo(() => {
    if (locationCountryNameOverride) {
      return locationCountryNameOverride;
    }

    if (!AllowedLocations.includes(locationCountryName)) {
      return DEFAULT_LOCATION_COUNTRY_NAME;
    }

    return locationCountryName;
  }, [locationCountryName, locationCountryNameOverride]);

  const value = useMemo(
    () => ({
      locationStatus,
      locationCountryName: locationCountryNameOverride ?? locationCountryName,
      locationCountryNameSafe,
      isLoading: user ? !locationStatus : anonUserCurrentLocationIsLoading,
      setLocationCountryNameOverride,
      geoPermissionStatus,
    }),
    [
      locationStatus,
      locationCountryNameOverride,
      locationCountryName,
      locationCountryNameSafe,
      user,
      anonUserCurrentLocationIsLoading,
      geoPermissionStatus,
    ]
  );

  // Step 5: Report the location status to Segment
  useEffect(() => {
    const locationDetails = XPointSingleton.getLocationTokenData();

    if (user) {
      updateAnalyticsUser({
        id: user.id,
        geo_status: locationStatus || 'NO GEO STATUS SET',
        geo_detected_state: locationDetails?.state,
        geo_detected_country: locationDetails?.country,
      });
    }
  }, [user, locationStatus]);

  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
}

export default LocationProvider;
