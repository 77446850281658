import { useMantineTheme, Box } from '@mantine/core';
import React from 'react';
import Image from 'next/image';

import { getContestBanner } from '~/domains/contest/domains/detail/components/ContestHeader/utils/getContestBanner';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import type { MyUserContest } from '~/domains/contest/domains/myContests/types/userContest';

import ContestCountdownPill from '../ContestCountdownPill/ContestCountdownPill';

type ContestCardBannerProps = {
  contest: Contest | MyUserContest;
  isBannerEnabled?: boolean;
  isCountdownPillEnabled?: boolean;
};

function ContestCardBanner({
  contest,
  isBannerEnabled = false,
  isCountdownPillEnabled = false,
}: ContestCardBannerProps) {
  const theme = useMantineTheme();
  if (!isBannerEnabled) return null;

  return (
    <Box h={140} w="100%" pos="relative">
      <Box pos="absolute" w="calc(100% + 40px)" h={140} style={{ margin: '-20px -20px 0 -20px' }}>
        <Image
          fill
          style={{ objectFit: 'cover' }}
          alt={contest.name}
          src={getContestBanner({
            contestBannerlUrl: contest?.banner_url,
            sport: contest.league.sport,
          })}
          sizes={`${theme.breakpoints.xl * 2}px`}
          quality={100}
        />
        {isCountdownPillEnabled ? (
          <ContestCountdownPill
            targetDate={new Date(contest.start_date)}
            sx={{
              position: 'absolute',
              zIndex: 1,
              left: 13,
              bottom: 8,
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
}

export default ContestCardBanner;
