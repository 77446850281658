import type { ContestType, Sport } from '@betterpool/api-types/contests-service';

import { Routes } from '~/domains/common/constants/routes';

import { useIsGamecenterEnabled } from './useIsGamecenterEnabled';

const useGamecenterPicksPath = ({
  id,
  contestType,
  sport,
  entryId,
  slateId,
  ref,
}: {
  id: string;
  contestType: ContestType;
  sport: Sport;
  entryId?: string;
  slateId?: string;
  ref?: string;
}) => {
  const { isEnabled } = useIsGamecenterEnabled(contestType, sport);

  return isEnabled
    ? Routes.gamecenterPicks(id, { entryId, slateId })
    : Routes.gameshellPicksheet({
        contestId: id,
        sport,
        contestType,
        referrer: ref,
        entryId,
        slateId,
      });
};

export default useGamecenterPicksPath;
