import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import Modal from '~/domains/common/components/Modal/Modal/Modal';

import tc from '../../testing.const';

import EnterContestScreen from './EnterContestScreen/EnterContestScreen';
import EnterContestLoadingScreen from './EnterContestLoadingScreen/EnterContestLoadingScreen';
import EnterContestSuccessScreen from './EnterContestSuccessScreen/EnterContestSuccessScreen';
import EnterContestSubmittingScreen from './EnterContestSubmittingScreen/EnterContestSubmittingScreen';
import useEnterContestModal from './useEnterContestModal';
import useStyles from './EnterContestModal.styles';

type AddEntriesModalProps = {
  contestId: string;
  onClose: () => void;
  onSuccess?: (entryIDs: string[]) => void;
};

function EnterContestModal({ contestId, onClose, onSuccess }: AddEntriesModalProps) {
  const { classes } = useStyles();

  const {
    availableBalance,
    contest,
    createEntriesErrorMessage,
    createdEntryCount,
    handleCreateEntries,
    handleOpenDepositModal,
    isCreateEntriesReady,
    nodeRef,
    state,
  } = useEnterContestModal({
    contestId,
    onClose,
    onSuccess,
  });

  return (
    <Modal
      onClose={onClose}
      classNames={{ modal: { modal: classes.modal } }}
      lockScroll
      withCloseButton={!['success', 'submitting'].includes(state)}
      closeOnClickOutside={!['success', 'submitting'].includes(state)}
      dataTestId={tc.enterContestModal.modal}
    >
      <SwitchTransition>
        <CSSTransition
          key={state}
          nodeRef={nodeRef}
          // @ts-expect-error typescript definition defies their documentation
          addEndListener={(node, done) => {
            if (typeof node === 'function') {
              return;
            }
            node?.addEventListener('transitionend', done, false);
          }}
          classNames={{
            enter: classes.contentEnter,
            enterActive: classes.contentEnterActive,
            exit: classes.contentExit,
            exitActive: classes.contentExitActive,
          }}
          timeout={300}
        >
          <div ref={nodeRef} className={classes.content}>
            {(() => {
              switch (state) {
                case 'loading': {
                  return <EnterContestLoadingScreen />;
                }
                case 'submitting': {
                  return <EnterContestSubmittingScreen />;
                }
                case 'success': {
                  return (
                    <EnterContestSuccessScreen
                      balanceInDollars={availableBalance}
                      entryCount={createdEntryCount}
                    />
                  );
                }
                default: {
                  return (
                    <EnterContestScreen
                      balanceInDollars={availableBalance}
                      contest={contest.data}
                      errorMessage={createEntriesErrorMessage}
                      onClose={onClose}
                      onCreateEntries={handleCreateEntries}
                      onDepositClick={handleOpenDepositModal}
                      isCreateEntriesReady={isCreateEntriesReady}
                    />
                  );
                }
              }
            })()}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </Modal>
  );
}

export default EnterContestModal;
