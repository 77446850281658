'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { reportPageView } from './events';

const useReportLocationChange = () => {
  const router = useRouter();

  // Report first page render
  useEffect(() => {
    reportPageView(window.document.URL, window.document.title);
  }, []);

  // Reporti future route changes
  useEffect(() => {
    const reportRouteChange = (url) => {
      reportPageView(url, window.document.title);
    };

    router.events.on('routeChangeStart', reportRouteChange);

    return () => {
      router.events.off('routeChangeStart', reportRouteChange);
    };
  }, [router]);
};

export default useReportLocationChange;
