import { Text } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { CurrencyConvertor } from '~/components/utils/formatters';
import { FS_VISIBILITY, useFullStoryRef } from '~/domains/analytics';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';
import dt from '~/testing';

export function HeaderWalletBalance() {
  const { t } = useTranslation('common');
  const { data, isError } = useWalletBalance();
  const FS_HIDE = useFullStoryRef(FS_VISIBILITY.FS_HIDE);

  return isError ? (
    <Text color="red.7">{t('balanceUnavailable')}</Text>
  ) : (
    <Text
      color="green.5"
      ref={FS_HIDE}
      data-test-id={dt.components.shared.header.avatar.hoverCard.balance}
      fw={600}
    >
      {CurrencyConvertor(data?.availableBalance)}
    </Text>
  );
}
