import useTranslation from 'next-translate/useTranslation';
import { Box, Button, Text } from '@mantine/core';

import { FEATURE } from '~/common/enums/feature.enum';
import { useCopyReferAFriendLink } from '~/domains/common/hooks/useCopyReferAFriendLink';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

export function SubHeaderReferAFriendCTA() {
  const { t } = useTranslation('common');
  const showReferAFriendCTA = useIsFeatureEnabled(FEATURE.ENABLE_REFER_A_FRIEND);
  const { handleCopyLink } = useCopyReferAFriendLink();

  if (!showReferAFriendCTA) {
    return null;
  }

  return (
    <Box pos="relative">
      <Button onClick={handleCopyLink}>
        <Text color="black">{t('headerNavigation.referAFriend')}</Text>
      </Button>
    </Box>
  );
}
