import { Progress, createStyles } from '@mantine/core';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { keyframes } from '@emotion/react';
import { useIsMutating } from '@tanstack/react-query';

const bgAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
`;

const useStyles = createStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 9999,
    opacity: 0,
    transition: 'opacity 0.5s ease',

    '&.isLoading': {
      opacity: 1,
    },
  },
  bar: {
    background: theme.fn.gradient({
      from: theme.colors.splash[5],
      to: theme.colors.splash[2],
    }),
    backgroundSize: '200% 200%',
    transition: 'width 500ms ease-in-out',
    animation: `${bgAnimation} 2s ease infinite`,
  },
  root: {
    background: 'transparent',
  },
}));

export function RouteChangeOrMutationProgressBar() {
  const router = useRouter();
  const { classes, cx } = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(0);

  const isMutating = useIsMutating();

  useEffect(() => {
    let finishAnimationTimer;
    let middleAnimationTimer;

    const handleStart = (url) => {
      if (url === router.asPath) return;
      setIsLoading(true);
      setWidth((v) => (v > 30 ? v : 30));
      middleAnimationTimer = setTimeout(() => {
        setWidth((v) => (v > 70 ? v : 70));
      }, 500);
    };
    const handleComplete = () => {
      setWidth(100);
      setIsLoading(false);
      clearTimeout(middleAnimationTimer);
      clearTimeout(finishAnimationTimer);
      finishAnimationTimer = setTimeout(() => {
        setWidth(0);
      }, 500);
    };

    const handleError = () => {
      setWidth(0);
      setIsLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleError);

    if (isMutating) {
      handleStart('');
    } else {
      handleComplete();
    }

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleError);
      clearTimeout(middleAnimationTimer);
      clearTimeout(finishAnimationTimer);
    };
  }, [isMutating, router.asPath, router.events]);

  return (
    <div className={cx(classes.container, { isLoading })}>
      <Progress
        value={width}
        w="100%"
        radius={0}
        size="sm"
        classNames={{ bar: classes.bar, root: classes.root }}
      />
    </div>
  );
}
