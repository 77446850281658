import { Box, Divider, Group, Menu, Stack, UnstyledButton, useMantineTheme } from '@mantine/core';
import { IconInfoTriangle, IconPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useContext, useEffect, useRef } from 'react';

import { Routes } from '~/domains/common/constants/routes';
import { Avatar } from '~/domains/common/components/Avatar/Avatar';
import dt from '~/testing';
import { UserContext } from '~/components/providers/UserProvider';

import { PrimaryButtonLink } from '../Button/Button';
import { useCurrentUserCommissionerChannel } from '../../hooks/useCurrentUserCommissionerChannel';

import { HeaderWalletBalance } from './HeaderWalletBalance';
import { HeaderNavigation } from './HeaderNavigation';
import { HeaderUserInfo } from './HeaderUserInfo';

export function HeaderLoggedInDesktop() {
  const [isOpen, { close, toggle }] = useDisclosure(false);
  const { data } = useCurrentUserCommissionerChannel();
  const theme = useMantineTheme();
  const { userSsnStatus } = useContext(UserContext);

  const menuDropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const menuDropdown = menuDropdownRef.current;
      if (!menuDropdown) return;

      const rect = menuDropdown.getBoundingClientRect();
      if (rect.top <= 0) {
        close();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [close]);

  return (
    <Group>
      <Menu
        trigger="click"
        position="bottom"
        radius={0}
        offset={9}
        withinPortal
        closeOnItemClick
        styles={{ dropdown: { borderColor: theme.colors.gray[2] } }}
        opened={isOpen}
        onChange={toggle}
      >
        <Menu.Target>
          <UnstyledButton py={2} px={4} mx={-4} sx={{ borderRadius: 4 }}>
            <Group position="center" align="center">
              <Box pos="relative">
                <Avatar
                  radius="xl"
                  src={data?.data?.avatarUrl}
                  data-test-id={dt.components.shared.header.avatar.main}
                />
                {userSsnStatus === 'required' ? (
                  <Box pos="absolute" top={-10} right={-10}>
                    <IconInfoTriangle size={20} color={theme.colors.gray[9]} fill="#FFD43B" />
                  </Box>
                ) : null}
              </Box>
              <Divider orientation="vertical" h={28} color="gray.2" sx={{ alignSelf: 'center' }} />
              <HeaderWalletBalance />
            </Group>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown bg="white">
          <Stack spacing={0} ref={menuDropdownRef}>
            <HeaderUserInfo />
            <HeaderNavigation />
          </Stack>
        </Menu.Dropdown>
      </Menu>
      <PrimaryButtonLink
        href={Routes.deposit()}
        data-test-id={dt.components.shared.header.avatar.hoverCard.username}
        p={6}
      >
        <IconPlus stroke={2} />
      </PrimaryButtonLink>
    </Group>
  );
}
