import type { FullContest } from '@betterpool/api-types';
import Head from 'next/head';
import queryString from 'query-string';

import { getCdnUrl } from '~/domains/assets/utils/getCdnUrl';
import { getContestPreviewImageData } from '~/domains/contest/utils/getContestPreviewImageData';

import { objectToBase64 } from '../../utils/base64';

type CommonMetaTagsProps = {
  title?: string;
  description?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
};

type DefaultProps = {
  type: '';
};

type ContestMetaTagsProps = {
  type: 'contest';
  fullContest: FullContest;
  isDynamicContestLinkPreviewEnabled?: boolean;
  isDynamicContestLinkPreviewBase64Enabled?: boolean;
  isDynamicContestLinkPreviewDeadlineEnabled?: boolean;
};

type InviteMetaTagsProps = {
  type: 'invite';
};

type ReferralMetaTagProps = {
  type: 'referral';
  referralCode: string;
};

type ChannelMetaTagsProps = {
  type: 'channel';
  handle: string;
};

// Union of all specific meta tag props
type MetaTagsProps = CommonMetaTagsProps &
  (
    | DefaultProps
    | ContestMetaTagsProps
    | InviteMetaTagsProps
    | ReferralMetaTagProps
    | ChannelMetaTagsProps
  );

const mainTitle = 'Splash Sports';

const getTitle = (title?: string) => (title ? `${title} | ${mainTitle}` : mainTitle);

export function MetaTags({
  title,
  description = 'Splash Sports is the leading skill-based sports gaming platform built for friends to use their knowledge to compete for real money. Learn more and get started.',
  image = getCdnUrl('/images/logos/social-preview.png'),
  imageWidth = '1200',
  imageHeight = '628',
  ...props
}: MetaTagsProps) {
  const pageTitle = (() => {
    switch (props.type) {
      case 'contest': {
        return getTitle(props.fullContest?.name);
      }
      default: {
        return getTitle(title);
      }
    }
  })();

  const pageDescription = (() => {
    switch (props.type) {
      case 'contest': {
        return props.fullContest.short_rules || description;
      }
      default: {
        return description;
      }
    }
  })();

  const pageImage = (() => {
    switch (props.type) {
      case 'contest': {
        const {
          fullContest,
          isDynamicContestLinkPreviewEnabled,
          isDynamicContestLinkPreviewBase64Enabled,
          isDynamicContestLinkPreviewDeadlineEnabled,
        } = props;

        const isDeadlineEnabled = isDynamicContestLinkPreviewDeadlineEnabled;
        const partialContest = isDynamicContestLinkPreviewBase64Enabled
          ? objectToBase64(getContestPreviewImageData(fullContest))
          : undefined;

        if (isDynamicContestLinkPreviewEnabled && fullContest) {
          const query = queryString.stringify({
            contestUuid: fullContest.id,
            isDeadlineEnabled,
            partialContest,
          });
          return `/og?${query}`;
        }
        return fullContest.thumbnail_url || image;
      }
      case 'invite': {
        return getCdnUrl('/images/logos/social-preview-invite.png');
      }

      case 'referral': {
        return `/og/type/referral?code=${props.referralCode}`;
      }

      case 'channel': {
        return `/og/type/channel?handle=${props.handle}`;
      }

      default:
        return image;
    }
  })();

  return (
    <Head>
      <title key="title">{pageTitle}</title>
      <meta property="og:title" content={pageTitle} key="og:title" />
      <meta name="twitter:title" content={pageTitle} key="twitter:title" />
      <meta name="description" content={pageDescription} key="description" />
      <meta property="og:description" content={pageDescription} key="og:description" />
      <meta name="twitter:description" content={pageDescription} key="twitter:description" />
      <meta property="og:image" content={pageImage} key="og:image" />
      <meta property="og:image:width" content={imageWidth} key="og:image:width" />
      <meta property="og:image:height" content={imageHeight} key="og:image:height" />
      <meta name="twitter:image" content={pageImage} key="twitter:image" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
    </Head>
  );
}
