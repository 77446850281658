import { useContext } from 'react';

import DepositModal from '~/domains/payments/deposits/components/DepositModal';
import EnterContestModal from '~/domains/contest/domains/common/components/EnterContestModal/EnterContestModal';
import InviteModal from '~/domains/contest/domains/invite/components/InviteModal';

import { RootModalsContext } from './RootModalsContext';

const Modals = {
  EnterContestModal,
  DepositModal,
  InviteModal,
};

function RootModals() {
  const { modals, closeModal } = useContext(RootModalsContext);

  if (!modals.length) {
    return null;
  }

  const { type, ...modalProps } = modals[modals.length - 1];
  const ModalComponent = Modals[type];
  if (!ModalComponent) {
    return null;
  }

  // @ts-expect-error No idea how to solve this
  return <ModalComponent {...modalProps} onClose={closeModal} />;
}

export default RootModals;
