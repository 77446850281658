import { FEATURE } from '~/common/enums/feature.enum';
import { useTreatments } from '~/domains/common/hooks/useTreatments';

export const useIsAnonymousPicksEnabled = () => {
  const { isReady, treatments } = useTreatments([FEATURE.ENABLE_ANONYMOUS_PICKS]);
  const isAnonymousPicksEnabled =
    isReady && treatments[FEATURE.ENABLE_ANONYMOUS_PICKS]?.treatment === 'on';

  return isAnonymousPicksEnabled;
};
