import { useInfiniteQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';
import type { ContestType } from '@betterpool/api-types/contests-service';

import { UserContext } from '~/components/providers/UserProvider';

import type { ContestStatusFilter } from '../domains/services/enums/contest-status-filter.enum';
import { getContestSummaries } from '../domains/services/contest.service.api';
import { ContestQueryKeys } from '../constants/query';

const STALE_TIME = 30_000;
const PAGE_SIZE = 20;

type UseContestsUserArgs = {
  status: ContestStatusFilter;
  contestType?: ContestType;
  sort?: Parameters<typeof getContestSummaries>[1]['sort'];
  limit?: number;
  enabled?: boolean;
};

const useContestsUser = ({
  status,
  contestType,
  sort,
  limit = PAGE_SIZE,
  enabled,
}: UseContestsUserArgs) => {
  const { user } = useContext(UserContext);
  const userId = user?.id;

  const data = useInfiniteQuery({
    queryKey: ContestQueryKeys.USER_CONTESTS({ userId, status, contestType, limit, sort }),
    staleTime: STALE_TIME,
    queryFn: ({ pageParam = 0 }) =>
      getContestSummaries(userId, {
        filters: {
          status,
          contestType,
        },
        sort,
        limit,
        offset: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.offset + limit;
      if (nextPage > lastPage.total) {
        return undefined;
      }
      return nextPage;
    },
    enabled,
  });

  const itemsTotal = data.data?.pages?.[0]?.total;

  const items = useMemo(() => data.data?.pages?.flatMap((page) => page.data) ?? [], [data]);

  return {
    ...data,
    itemsTotal,
    items,
  };
};

export default useContestsUser;
