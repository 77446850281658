import axios from 'axios';

import RequestHeadersBase from '~/common/constants/RequestHeadersBase';

import { handleRefreshInterceptor } from '../interceptors/handle-refresh.interceptor';
import { setAuthTokenInterceptor } from '../interceptors/set-auth-token.interceptor';

export const rypInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL_RYP,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthTokenInterceptor(rypInstance);
handleRefreshInterceptor(rypInstance);
