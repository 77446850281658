import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useMantineTheme } from '@mantine/core';

import { CurrencyConvertor } from '~/components/utils/formatters';

import type { ContestCardActionBanner } from '../types';

import ContestCardFooterMeta from './ContestCardFooterMeta';

type UserContestMetaGenericProps = {
  isSettled: boolean;
  winning: number;
  entryPrice: number;
  actionBanner: ContestCardActionBanner;
  numberOfUserEntries: number;
  userSegmentedPayouts?: number;
};

function UserContestMetaGeneric({
  isSettled,
  winning,
  entryPrice,
  actionBanner,
  numberOfUserEntries,
  userSegmentedPayouts,
}: UserContestMetaGenericProps) {
  const theme = useMantineTheme();
  const { t } = useTranslation('contest');
  const winningColor = winning > 0 ? theme.colors.green[5] : 'inherit';
  const userSegmentedPayoutsColor = userSegmentedPayouts > 0 ? theme.colors.green[5] : 'inherit';
  const settledWinningColor =
    userSegmentedPayouts + winning > 0 ? theme.colors.green[5] : 'inherit';
  const isUserSegmentedPayoutsNumber = typeof userSegmentedPayouts === 'number';
  const isSegmented = !isSettled && isUserSegmentedPayoutsNumber;

  const meta = [
    {
      value: `${numberOfUserEntries}`,
      label: t('common.contestCard.footer.myEntries'),
      span: 3,
    },
    {
      value: CurrencyConvertor(entryPrice / 100),
      label: t('common.contestCard.footer.entry'),
      span: 2,
    },
    isSegmented && {
      value: CurrencyConvertor(userSegmentedPayouts / 100),
      label: t('common.contestCard.footer.segmented'),
      valueColor: userSegmentedPayoutsColor,
      span: 4,
      textAlign: 'right',
    },
    {
      value:
        isSettled && isUserSegmentedPayoutsNumber
          ? CurrencyConvertor((winning + userSegmentedPayouts) / 100)
          : CurrencyConvertor(winning / 100),
      label: t(`common.contestCard.footer.${isSettled ? 'won' : 'winning'}`),
      valueColor: isSettled && isUserSegmentedPayoutsNumber ? settledWinningColor : winningColor,
      span: isSegmented ? 3 : 7,
      textAlign: 'right',
    },
  ];

  return <ContestCardFooterMeta actionBanner={actionBanner} meta={meta} />;
}

export default UserContestMetaGeneric;
