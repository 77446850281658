import type { APITeamPicks, TeamPicks } from '../interfaces/team-picks.interface';

const parseTeamPicks = (response: APITeamPicks): TeamPicks =>
  Object.entries(response).reduce(
    (acc, [entryId, entry]) => ({
      ...acc,
      [entryId]: {
        picks: entry.picks ?? entry,
        record: entry.record ?? {},
        slates: entry.slates ?? {},
        eliminatedSlateId: entry.eliminatedSlateId,
        livesRemaining: entry.livesRemaining,
      },
    }),
    {}
  );

export default parseTeamPicks;
