import { useContext, useMemo } from 'react';
import type { EntriesDetails } from '@betterpool/api-types/contests-service';
import { ContestStatus } from '@betterpool/api-types/contests-service';
import { useIsMutating } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';

import { FEATURE } from '~/common/enums/feature.enum';
import { UserContext } from '~/components/providers/UserProvider';
import { useLocationStatus } from '~/hooks/useLocationStatus';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import type { APIUserResponse } from '~/services/users/types';

const enum EntryValidationErrorType {
  OVER = 'OVER',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
  DEADLINE_PASSED = 'DEADLINE_PASSED',
  MAX_USER_ENTRIES = 'MAX_USER_ENTRIES',
  MAX_TOTAL_ENTRIES = 'MAX_TOTAL_ENTRIES',
  LOCATION_LOADING = 'LOCATION_LOADING',
  LOCATION_INVALID = 'LOCATION_INVALID',
  USER_INVALID = 'USER_INVALID',
}

const getErrorByContestStatus = (status: ContestStatus, isAfterDeadline: boolean) => {
  switch (status) {
    case ContestStatus.CANCELLED:
      return EntryValidationErrorType.CANCELED;
    case ContestStatus.IN_PROGRESS:
      if (!isAfterDeadline) return null;
      return EntryValidationErrorType.IN_PROGRESS;
    case ContestStatus.FINISHED:
    case ContestStatus.FINALIZED:
      return EntryValidationErrorType.OVER;
    default: {
      return null;
    }
  }
};

const getEntriesLimitError = (entries?: EntriesDetails) => {
  if (entries?.filled >= entries?.max) {
    return EntryValidationErrorType.MAX_TOTAL_ENTRIES;
  }

  if (entries?.user >= entries?.max_per_user) {
    return EntryValidationErrorType.MAX_USER_ENTRIES;
  }

  return null;
};

const getDeadlineError = (isAfterDeadline: boolean) => {
  if (isAfterDeadline) {
    return EntryValidationErrorType.DEADLINE_PASSED;
  }

  return null;
};

const getLocationError = (isLoading: boolean, isAllowedInLocation?: boolean) => {
  if (isLoading) {
    return EntryValidationErrorType.LOCATION_LOADING;
  }

  // We specifically wanna check for false, since `undefined` means that we don't know yet
  if (isAllowedInLocation === false) {
    return EntryValidationErrorType.LOCATION_INVALID;
  }

  return null;
};

const getUserError = (user?: APIUserResponse['data']) => {
  if (!user) {
    return null;
  }

  if (user.status !== 'active' || user.verificationStatus !== 'accept') {
    return EntryValidationErrorType.USER_INVALID;
  }

  return null;
};

const useEnterContestValidationMessage = (
  error: EntryValidationErrorType | null
): string | null => {
  const { t } = useTranslation('contest');

  if (!error) {
    return null;
  }

  switch (error) {
    case EntryValidationErrorType.OVER:
      return t('common.enterContestError.over');
    case EntryValidationErrorType.CANCELED:
      return t('common.enterContestError.cancel');
    case EntryValidationErrorType.IN_PROGRESS:
      return t('common.enterContestError.inprogress');
    case EntryValidationErrorType.DEADLINE_PASSED:
      return t('common.enterContestError.deadline');
    case EntryValidationErrorType.MAX_USER_ENTRIES:
      return t('common.enterContestError.maxEntries');
    case EntryValidationErrorType.MAX_TOTAL_ENTRIES:
      return t('common.enterContestError.full');
    case EntryValidationErrorType.LOCATION_LOADING:
      return t('common.enterContestError.locationLoading');
    case EntryValidationErrorType.LOCATION_INVALID:
      return t('common.enterContestError.locationInvalid');
    case EntryValidationErrorType.USER_INVALID:
      return t('common.enterContestError.userInvalid');
    default:
      return t('common.enterContestError.default');
  }
};

type UseContestEntryValidationProps = {
  isAfterDeadline: boolean;
  entries?: EntriesDetails;
  isAllowedInLocation?: boolean;
  status: ContestStatus;
};

const useEnterContestValidation = ({
  isAfterDeadline,
  entries,
  isAllowedInLocation,
  status,
}: UseContestEntryValidationProps) => {
  const { user, userDetails } = useContext(UserContext);

  const locationStatus = useLocationStatus();
  const isLocationAvailable = !!locationStatus;

  const error: EntryValidationErrorType | null = useMemo(() => {
    if (!user) {
      return (
        getErrorByContestStatus(status, isAfterDeadline) ||
        getDeadlineError(isAfterDeadline) ||
        getEntriesLimitError(entries)
      );
    }

    return (
      getLocationError(!isLocationAvailable, isAllowedInLocation) ||
      getErrorByContestStatus(status, isAfterDeadline) ||
      getDeadlineError(isAfterDeadline) ||
      getEntriesLimitError(entries) ||
      getUserError(userDetails)
    );
  }, [
    entries,
    isAfterDeadline,
    isAllowedInLocation,
    isLocationAvailable,
    status,
    user,
    userDetails,
  ]);

  const errorMessage = useEnterContestValidationMessage(error);

  const isEntryEnabled = useIsFeatureEnabled(
    FEATURE.ENABLE_CONTEST_DETAILS_JOIN_AND_DEPOSIT_BUTTONS
  );

  const isCancelEntryMutating = Boolean(useIsMutating(['cancelEntry']));
  const isCreateOrJoinMutating = Boolean(useIsMutating(['createOrJoinEntry']));
  const isBalanceMutating = Boolean(useIsMutating(['wallets/balance']));

  const isLoading =
    user &&
    (!isLocationAvailable || isBalanceMutating || isCreateOrJoinMutating || isCancelEntryMutating);

  const isDisabled = !!error || !isEntryEnabled || isLoading;

  return {
    error,
    errorMessage,
    isDisabled,
    isLoading,
  };
};

export default useEnterContestValidation;

export { EntryValidationErrorType };
export type { UseContestEntryValidationProps };
