import Link from 'next/link';
import { createStyles } from '@mantine/core';

import { Routes } from '~/domains/common/constants/routes';

import { LogoSplashText } from './SitesMenu/logos/LogoSplashText';

const useStyles = createStyles((theme) => ({
  logo: {
    display: 'flex',

    '@media (hover: hover)': {
      '& svg': {
        transition: 'fill 150ms ease-in-out',
      },
      '&:hover svg': {
        fill: theme.colors.blue[4],
      },
    },

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}));

function Logo() {
  const { classes } = useStyles();

  return (
    <Link href={Routes.main()} className={classes.logo}>
      <LogoSplashText color="white" />
    </Link>
  );
}

export default Logo;
