import type { ButtonProps } from '@mantine/core';
import { Button, createPolymorphicComponent, createStyles } from '@mantine/core';
import Link from 'next/link';
import { forwardRef } from 'react';

const useStyles = createStyles((theme) => ({
  primaryButton: {
    color: theme.black,
    '&:disabled': {
      color: theme.colors.gray[7],
      backgroundColor: theme.colors.gray[3],
    },
  },
  linkButton: {
    color: theme.colorScheme === 'dark' ? theme.colors.splash[5] : theme.colors.splash[7],
    background: 'transparent',
    textDecoration: 'none',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      background: 'none',
    },
  },
}));

type PrimaryButtonProps = Omit<ButtonProps, 'color'> & {
  onClick?: (event?: React.MouseEvent) => void;
};

const PrimaryButtonFactory = forwardRef<HTMLButtonElement, PrimaryButtonProps>(
  ({ children, className, ...props }, ref) => {
    const { classes, cx } = useStyles();

    return (
      <Button
        ref={ref}
        variant="filled"
        className={cx(classes.primaryButton, className)}
        {...props}
      >
        {children}
      </Button>
    );
  }
);
PrimaryButtonFactory.displayName = 'PrimaryButton';

/** @deprecated - use domains/design-system/Button instead */
const PrimaryButton = createPolymorphicComponent<'button', ButtonProps>(PrimaryButtonFactory);

type PrimaryButtonLinkProps = PrimaryButtonProps & {
  href: string;
};

/** @deprecated - use domains/design-system/Button instead */
function PrimaryButtonLink(props: PrimaryButtonLinkProps) {
  return <PrimaryButton component={Link} {...props} />;
}

type ButtonLinkProps = ButtonProps & { href: string; onClick?: (event?: React.MouseEvent) => void };

/** @deprecated - use domains/design-system/Button instead */
function ButtonLink(props: ButtonLinkProps) {
  return <Button component={Link} {...props} />;
}

/** @deprecated - use domains/design-system/Button instead */
function LinkButton(props: PrimaryButtonProps) {
  const { classes, cx } = useStyles();
  const { className } = props;
  return <Button {...props} className={cx(classes.linkButton, className)} variant="default" />;
}

const useUnstyledButtonStyles = createStyles({
  button: {
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
  },
});

/** @deprecated - use domains/design-system/Button instead */
function UnstyledButton({
  className,
  ...restProps
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const { classes, cx } = useUnstyledButtonStyles();
  return <button {...restProps} className={cx(classes.button, className)} />;
}

export { PrimaryButton, LinkButton, PrimaryButtonLink, ButtonLink, UnstyledButton };
