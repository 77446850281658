/* eslint-disable no-underscore-dangle */
import type { AxiosInstance } from 'axios';

import { IS_STORYBOOK } from '~/config/const';
import { fetchRefreshToken } from '~/services/token/refresh-token';
import { isRoutePublic } from '~/utils/isRoutePublic';

export const handleRefreshInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error?.config;
      const isClient = typeof window !== 'undefined';

      if (!isClient) {
        /* We are bypassing the refresh token mechanism on the server side
           because server does not have an access to the refresh token
           and client can resolve this on its own */
        return Promise.reject(error);
      }

      const isPublic = isRoutePublic(window.location.pathname);

      if (!(error?.response?.status === 401 && !originalRequest?._retry) || IS_STORYBOOK) {
        return Promise.reject(error);
      }

      try {
        originalRequest._retry = true;
        const { accessToken } = await fetchRefreshToken(!isPublic);
        instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        return await instance(originalRequest);
      } catch (refreshTokenError) {
        console.error('Refresh token error', refreshTokenError);
        return Promise.reject(error);
      }
    }
  );
};
