import { Drawer } from '@mantine/core';
import type { ComponentProps } from 'react';

import { defaultHeaderHeight } from '../Header/Header';

type HeaderDrawerProps = {
  isOpen: ComponentProps<typeof Drawer>['opened'];
  onClose: ComponentProps<typeof Drawer>['onClose'];
  children: React.ReactNode;
};

export function HeaderDrawer({ isOpen, onClose, children }: HeaderDrawerProps) {
  return (
    <Drawer
      opened={isOpen}
      onClose={onClose}
      position="top"
      size="full"
      withOverlay={false}
      styles={(theme) => ({
        root: {
          zIndex: theme.other.zIndex.headerDrawer,
        },
        header: {
          display: 'none',
        },
        drawer: {
          top: defaultHeaderHeight,
          backgroundColor: theme.white,
        },
        body: {
          maxHeight: 'calc(100vh - 60px)',
        },
      })}
    >
      {children}
    </Drawer>
  );
}
