import { getAnonymousId, getFirstTouchUTM, getLastTouchUTM } from '../utils';

import { branchioInstanceV1, branchAuth } from './branch-io.instance';
import { DeepLinkType } from './types';
import type { DeepLinkPayload, AxiosBranchLinkResponse, APIBranchLinkResponse } from './types';

type DeepLinkDataPayload = {
  campaign?: string;
  channel?: string;
  feature?: string;
  stage?: string;
  data: {
    $canonical_url: string;
    $desktop_url?: string;
    $og_image_url?: string;
  };
};

/** Parse <contest-id> form URL
 *
 * url schema: <environment>.splashsports.com/contest/<contest-id>/*
 */
function getContestIdFromUrl(url: string): string | undefined {
  // Regex that returns url path after "contest/", delimited by '/'
  // eslint-disable-next-line no-useless-escape
  const regex = /contest\/([^\/]*)/;
  const urlPath = url.match(regex)?.[1];

  return urlPath;
}

const constructDeepLinkData = (
  deepLink: string,
  deepLinkType: DeepLinkType
): DeepLinkDataPayload => {
  const host =
    process.env.NEXT_PUBLIC_ENV === 'localhost'
      ? 'http://dev.splashsports.com'
      : window.location.origin;
  const fullUrl = `${host}${deepLink}`;
  const referralRegexp = /[&?]referralCode=([^&]*)/;
  const referralCode =
    deepLink.match(referralRegexp)?.[1] || window.location.search.match(referralRegexp)?.[1];
  const contestId = getContestIdFromUrl(deepLink);

  switch (deepLinkType) {
    // Branch UTM params (channel, campaign & source) set as defaults
    case DeepLinkType.CONTEST_DETAIL:
      return {
        channel: 'web',
        campaign: 'contest-detail',
        feature: 'Contest detail app promotion',
        stage: 'existing user',
        data: {
          $canonical_url: fullUrl,
          $og_image_url: contestId && `${host}/og?contestUuid=${contestId}&isDeadlineEnabled=false`,
        },
      };
    case DeepLinkType.REFER_A_FRIEND:
      return {
        channel: 'web',
        campaign: 'refer-a-friend',
        feature: 'Refer a friend deeplink',
        stage: 'new user',
        data: {
          $canonical_url: fullUrl,
          // RAF links should always have desktop web redirects
          $desktop_url: fullUrl,
          $og_image_url: referralCode && `${host}/og/type/referral?code=${referralCode}`,
        },
      };
    case DeepLinkType.SIGN_UP:
      return {
        channel: 'web',
        campaign: 'sign-up',
        feature: 'Sign up app promotion',
        stage: 'new user',
        data: {
          $canonical_url: fullUrl,
          $og_image_url: referralCode && `${host}/og/type/referral?code=${referralCode}`,
        },
      };
    case DeepLinkType.UNSUPPORTED_FEATURE:
      return {
        channel: 'web',
        campaign: 'unsupported-feature',
        feature: 'Feature not supported on web',
        stage: 'existing user',
        data: {
          $canonical_url: fullUrl,
          $og_image_url: contestId && `${host}/og?contestUuid=${contestId}&isDeadlineEnabled=false`,
        },
      };
    default:
      // if the feature is not supported, return a default payload
      return {
        data: {
          $canonical_url: fullUrl,
        },
      };
  }
};

const constructCommonDeepLinkPayload = (
  deepLink: string,
  deepLinkType: DeepLinkType
): DeepLinkPayload => {
  const payload = constructDeepLinkData(deepLink, deepLinkType);
  const firstTouchUtm = getFirstTouchUTM();
  const utmParams =
    firstTouchUtm && typeof firstTouchUtm === 'object' && Object.keys(firstTouchUtm).length > 0
      ? firstTouchUtm
      : getLastTouchUTM();

  // Hijack the campaign ID to forward the anonymous ID for better cross-platform attribution stitching
  payload.data['~campaign_id'] = getAnonymousId();

  // Override default branch.io UTM params when campaign is detected
  if (utmParams) {
    for (const [key, value] of Object.entries(utmParams)) {
      switch (key) {
        case 'utm_medium':
          payload.feature = value;
          break;
        case 'utm_source':
          payload.channel = value;
          break;
        case 'utm_campaign':
          payload.campaign = value;
          break;
        default:
          break;
      }
    }
  }

  return {
    ...payload,
    ...branchAuth,
  };
};

export const getBranchLink = async (
  deepLinkURL: string,
  deepLinkType: DeepLinkType
): Promise<APIBranchLinkResponse> => {
  const response = await branchioInstanceV1.post<AxiosBranchLinkResponse>(
    '/url',
    constructCommonDeepLinkPayload(deepLinkURL, deepLinkType)
  );

  // @ts-expect-error FIXME: No idea why type inference doesn't work here
  return response.data;
};
