import axios from 'axios';

import RequestHeadersBase from '~/common/constants/RequestHeadersBase';
import { handleRefreshInterceptor } from '~/services/interceptors/handle-refresh.interceptor';
import { setAuthTokenInterceptor } from '~/services/interceptors/set-auth-token.interceptor';

export const identityInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL_IDENTITY_SERVICE,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthTokenInterceptor(identityInstance);
handleRefreshInterceptor(identityInstance);
