export default {
  connectedChannelHeader: {
    channelBanner: 'channel.connectedChannelHeader.channelBanner',
    channelAvatar: 'channel.connectedChannelHeader.channelAvatar',
    copyLinkButton: 'cahnnel.connectedChannelHeader.copyLinkButton',
    editButton: 'channel.connectedChannelHeader.editButton',
    channelEntriesCount: 'channel.connectedChannelHeader.channelEntriesCount',
    followersButton: 'channel.connectedChannelHeader.followersButton',
    channelFollowersCount: 'channel.connectedChannelHeader.channelFollowersCount',
    channelFollowingCount: 'channel.connectedChannelHeader.channelFollowingCount',
    followingButton: 'channel.connectedChannelHeader.followingButton',
    createdTab: 'channel.connectedChannelHeader.createdTab',
    enteredTab: 'channel.connectedChannelHeader.enteredTab',
    upcomingButton: 'channel.connectedChannelHeader.upcomingButton',
    contestStatusBar: 'channel.connectedChannelHeader.contestStatusBar',
    liveButton: 'channel.connectedChannelHeader.liveButton',
    recentButton: 'channel.connectedChannelHeader.recentButton',
    channelName: 'channel.connectedChannelHeader.channelName',
    channelHandle: 'channel.connectedChannelHeader.channelHandle',
    channelDescription: 'channel.connectedChannelHeader.channelDescription',
    followButton: 'channel.connectedChannelHeader.followButton',
  },
  channelContests: {
    channelContestsEmptyState: 'channel.channelContests.channelContestsEmptyState',
  },
  search: {
    tabs: 'channel.search.tabs',
    followingTab: 'channel.search.followingTab',
    followersTab: 'channel.search.followersTab',
    searchInputfield: 'channel.search.searchInputfield',
    emptyState: 'channel.search.emptyState',
    followersEmptyState: 'channel.search.tyState',
    searchResult: 'channel.search.searchResult',
  },
  channelDetailPage: {
    createaContestButton: 'channel.channelDetailPage.createaContestButton',
  },
  channelEditPage: {
    nameInput: 'channel.channelEditPage.nameInput',
    handleInput: 'channel.channelEditPage.handleInput',
    descriptionInput: 'channel.channelEditPage.description',
    saveChangesButton: 'channel.channelEditPage.saveChangesButton',
  },
  invitesManager: {
    textInputField: 'channel.invitesManager.textInputField',
    importButton: 'channel.invitesManager.importButton',
    reviewInviteButton: 'channel.invitesManager.reviewInviteButton',
    recipentsNumberCell: 'channel.invitesManager.recipentsNumberCell',
    trashIcon: 'channel.invitesManager.trashIcon',
    emptyState: 'channel.invitesManager.emptyState',
    clearButton: 'channel.invitesManager.clearButton',
    emailInvitesNumber: 'channel.invitesManager.emailInvitesNumber',
    sendInviteButton: 'channel.invitesManager.sendInviteButton',
  },
};
