import { useTheme } from '@emotion/react';
import { Button, Divider, Group, Loader, Modal, Stack, Text } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import dt from '~/testing';

import { useDeleteSavedCard } from '../../hooks/useDeleteSavedCard';

type DeleteCardProps = {
  opened: boolean;
  onClose: VoidFunction;
  id: string;
  lastDigits: string;
};

function DeleteCardModal({ opened, onClose, id, lastDigits }: DeleteCardProps) {
  const { t } = useTranslation('payments');
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);
  const { deleteCard, isDeleting } = useDeleteSavedCard({
    cardId: id,
    callback: () => {
      queryClient.invalidateQueries({ queryKey: ['payments/paymentMethods', user?.id] });
      onClose();
    },
  });
  return (
    <Modal
      data-test-id={dt.payments.deposits.components.deleteCardModal.container}
      withCloseButton={false}
      zIndex={theme.other.zIndex.deleteCardModal}
      onClose={onClose}
      opened={opened}
      centered
      size={280}
      padding={0}
      trapFocus={false}
    >
      {isDeleting ? (
        <Stack spacing={0} p="md" align="center">
          <Loader />
        </Stack>
      ) : (
        <Stack
          data-test-id={dt.payments.deposits.components.deleteCardModal.text}
          spacing={0}
          p="md"
        >
          <Text sx={{ cursor: 'default' }} size={18} fw={600} align="center">
            {t('deleteCardModal.removeCard')}
          </Text>
          <Text sx={{ cursor: 'default' }} size={14} align="center">
            {t('deleteCardModal.removeCardCopy', { lastDigits })}
          </Text>
        </Stack>
      )}

      <Divider />
      <Group noWrap spacing={0}>
        <Button
          data-test-id={dt.payments.deposits.components.deleteCardModal.cancelButton}
          variant="white"
          w="50%"
          onClick={() => onClose()}
        >
          <Text fw={500} align="center" color="blue.5">
            {t('deleteCardModal.cancel')}
          </Text>
        </Button>
        <Divider orientation="vertical" />
        <Button
          data-test-id={dt.payments.deposits.components.deleteCardModal.removeButton}
          variant="white"
          w="50%"
          onClick={() => deleteCard()}
        >
          <Text fw={500} align="center" color="red.5">
            {t('deleteCardModal.remove')}
          </Text>
        </Button>
      </Group>
    </Modal>
  );
}

export default DeleteCardModal;
