import Link from 'next/link';
import { Anchor, useMantineTheme } from '@mantine/core';

import { getPrimaryThemeColor } from '~/config/themeUtils';

import { PrimaryWhaleWithText } from './PrimaryWhaleWithText';

export const enum LogoTypes {
  PRIMARY_WITH_TEXT = 'PRIMARY_WITH_TEXT',
  PRIMARY_NO_TEXT = 'PRIMARY_NO_TEXT',
  WHITE_NO_TEXT = 'WHITE_NO_TEXT',
}

type LogoProps = {
  size?: number;
  color?: string;
};

type SplashLogoProps = {
  size?: LogoProps['size'];
  type?: LogoTypes;
  color?: LogoProps['color'];
  withoutLink?: boolean;
};

export function PrimaryWhale({ size, color, className }: LogoProps & { className?: string }) {
  const height = size;
  const width = size * 2.11; // ratio of height to width;

  const theme = useMantineTheme();
  const primaryColor = color ?? getPrimaryThemeColor(theme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 244.49 115.36"
    >
      <g>
        <path
          d="m77.41,9.68c0,21.38-17.33,38.71-38.71,38.71S0,31.05,0,9.68h17.37c5.65,0,10.99,2.57,14.52,6.98l6.81,8.52,6.81-8.52c3.53-4.41,8.87-6.98,14.52-6.98h17.37M186.81,0c31.86,0,57.68,25.82,57.68,57.68s-25.83,57.68-57.68,57.68h-98.37c-31.86,0-57.68-25.82-57.68-57.68h15.9c0,15.61,17.17,28.27,38.36,28.27,10.02,0,19.15-2.84,25.98-7.48,17.67-10.87,17.7-35.26,26.62-50.93C149.39,6.88,166,0,186.81,0m21.79,36.45c2.32,2.32,6.07,2.32,8.39,0,2.32-2.32,2.32-6.07,0-8.39-2.32-2.32-6.07-2.32-8.39,0-2.32,2.32-2.32,6.07,0,8.39h0"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}

export function SplashLogo({
  size = 30,
  type = LogoTypes.PRIMARY_WITH_TEXT,
  color,
  withoutLink = false,
}: SplashLogoProps) {
  const theme = useMantineTheme();

  const getLogo = () => {
    switch (type) {
      case LogoTypes.WHITE_NO_TEXT:
        return <PrimaryWhale size={size} color={theme.white} />;
      case LogoTypes.PRIMARY_NO_TEXT:
        return <PrimaryWhale size={size} />;
      case LogoTypes.PRIMARY_WITH_TEXT:
      default:
        return (
          <PrimaryWhaleWithText
            size={size}
            color={color}
            primaryColor={getPrimaryThemeColor(theme)}
            secondaryColor={color || theme.white}
          />
        );
    }
  };

  if (withoutLink) return getLogo();

  return (
    <Anchor
      component={Link}
      href="/"
      sx={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        '&:focus-visible': {
          outlineOffset: '4px',
          outline: `2px solid ${theme.primaryColor}`,
          borderRadius: '4px',
        },
      }}
    >
      {getLogo()}
    </Anchor>
  );
}

export function SplashyWave() {
  return (
    <svg viewBox="0 0 174.47064 46.34594" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="wave-gradient" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop style={{ stopColor: '#0fefe2' }} offset="0" id="deep-ocean" />
        </linearGradient>
      </defs>
      <path
        style={{ fill: 'url(#wave-gradient)', fillRule: 'evenodd' }}
        d="m 0,11.38448 c 0,0 21.133851,11.39531 43.617661,11.38441 C 66.101471,22.75799 107.96856,0.03262 130.508,7e-5 c 22.53944,-0.0325 43.96264,11.38441 43.96264,11.38441 V 46.34594 H 0 Z"
      />
    </svg>
  );
}
