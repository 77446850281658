import { useContext } from 'react';

import { LocationContext } from '~/components/providers/LocationProvider';

function useIsCanada() {
  const { locationCountryName } = useContext(LocationContext);
  return locationCountryName === 'canada';
}

export { useIsCanada };
