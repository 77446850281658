type LogoProps = {
  size?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  color?: string;
};
type PrimaryWhaleWithTextProps = {
  primaryColor: string;
  secondaryColor: string;
};

export function PrimaryWhaleWithText({
  size,
  primaryColor,
  secondaryColor,
}: LogoProps & PrimaryWhaleWithTextProps) {
  const height = size;
  const width = size * (1793.5 / 194.7); // ratio of height to width;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1793.5 194.7"
    >
      <g>
        <path
          fill={primaryColor}
          d="M123.6,32.1c0.8-4.1,1.3-8.3,1.3-12.6h-28c-9.1,0-17.7,4.1-23.4,11.3l-11,13.7l-11-13.7
          c-5-6.2-12.2-10.2-20-11.1c-0.6-0.1-1.1-0.1-1.7-0.1s-1.1,0-1.7,0H0C0,25,0.7,30.2,2,35.2c0.5,2,1.1,4,1.8,5.9
          c0.4,1,0.7,1.9,1.1,2.8C14.4,66.2,36.6,82,62.5,82C92.6,82,117.8,60.6,123.6,32.1z"
        />
        <path
          fill={primaryColor}
          d="M390.3,69.3L390.3,69.3c-1.3-4.4-3-8.6-5-12.7c-0.3-0.7-0.7-1.3-1-2c-0.7-1.3-1.4-2.7-2.1-4
          c-1.1-2-2.3-3.8-3.6-5.7c-16.7-24.8-45-41-77.2-41c-28.3,0-51.8,7.9-69.9,30.4c-3.3,4.2-6.5,8.8-9.5,14c-0.5,0.8-0.9,1.6-1.3,2.4
          c-0.8,1.6-1.6,3.3-2.4,5c-0.4,0.9-0.8,1.7-1.1,2.6c-10,24.7-13.2,56.8-38.1,72.1c-9.7,6.5-22.1,10.9-35.9,11.8c-1,0.1-2,0.1-3,0.2
          c-1,0-2,0-3,0c-17.1,0-32.6-5.1-43.8-13.4c-3.5-2.6-6.6-5.5-9.2-8.6c-2.1-2.5-3.8-5.2-5.2-8c-0.4-0.7-0.7-1.4-1-2.1
          c-1.8-4.3-2.8-8.8-2.8-13.6H49.6c0,8,1,15.8,2.9,23.3c0.4,1.5,0.8,3,1.2,4.4c1.4,4.4,3,8.6,5,12.7c0.3,0.7,0.7,1.4,1,2
          c0.7,1.3,1.4,2.7,2.1,4c0.4,0.6,0.8,1.3,1.1,1.9c0.8,1.3,1.6,2.5,2.4,3.8c2.5,3.7,5.3,7.2,8.3,10.5c1,1.1,2,2.2,3.1,3.2
          c2.1,2.1,4.3,4.1,6.6,6c8,6.6,17.2,11.9,27.2,15.6c1.4,0.5,2.9,1,4.3,1.5c6.6,2,13.4,3.4,20.5,3.9c2.4,0.2,4.8,0.3,7.2,0.3h158.7
          c32.1,0,60.5-16.3,77.2-41c1.2-1.9,2.4-3.8,3.6-5.7c0.8-1.3,1.5-2.6,2.2-4c0.3-0.7,0.7-1.3,1-2c2-4.1,3.6-8.3,5-12.7
          c0.5-1.5,0.9-2.9,1.2-4.4c1.9-7.4,2.9-15.2,2.9-23.3c0-8-1-15.8-2.9-23.3C391.2,72.2,390.7,70.7,390.3,69.3z M352.9,56.9
          c-0.1,0.6-0.2,1.2-0.3,1.8l0,0c-0.4,1.5-1.2,2.9-2.4,4c-0.4,0.4-0.8,0.7-1.2,1c-0.2,0.1-0.4,0.3-0.6,0.4c-0.3,0.2-0.6,0.3-0.9,0.5
          s-0.7,0.3-1,0.4c-0.4,0.1-0.8,0.2-1.2,0.3c-0.6,0.1-1.2,0.2-1.8,0.2l0,0c-0.5,0-1.1-0.1-1.6-0.1c-0.6-0.1-1.1-0.2-1.6-0.5
          c-0.1,0-0.2-0.1-0.3-0.1c-1.1-0.5-2.2-1.2-3.1-2.1c-3.7-3.7-3.7-9.8,0-13.5c0.9-0.9,2-1.6,3.1-2.1c0.1,0,0.2-0.1,0.3-0.1
          c0.5-0.2,1.1-0.3,1.6-0.4c0.5-0.1,1.1-0.2,1.6-0.2l0,0c0.6,0,1.2,0.1,1.8,0.2c0.4,0.1,0.8,0.2,1.2,0.3c0.4,0.1,0.7,0.2,1,0.4
          c0.3,0.1,0.6,0.3,0.9,0.5c0.2,0.1,0.4,0.3,0.6,0.4c0.4,0.3,0.8,0.6,1.2,1c1.2,1.2,2,2.6,2.4,4c0.2,0.6,0.3,1.2,0.3,1.8
          C352.9,55.7,352.9,56.3,352.9,56.9z"
        />
        <path
          fill={secondaryColor}
          d="M491.4,86.5c-18.5-2.4-27.3-3.5-27.3-10.8c0-5.1,5.1-9.5,18.9-9.5c14.7,0,21.1,4.8,22,11.9h30.6
          c-1.1-23.5-20-38.7-50.4-38.7c-31.9,0-51.9,12.8-51.9,38.1c0,21.3,14.1,31.9,42.9,35.6c24,3.3,31,5.5,31,12.5c0,7-7,9.7-20.5,9.7
          c-16.9,0-23.5-5.1-24.4-12.1h-30.6c0.9,23.5,20,38.9,52.8,38.9S538,148.5,538,124C538,101.4,522.4,90.4,491.4,86.5z"
        />
        <path
          fill={secondaryColor}
          d="M608.4,39.4c-34.8,0-57.9,22.9-59.8,59.2c-0.4,6.6-0.7,12.3-0.7,21.1v75h31.7v-48c7,9.2,18.9,14.5,33.9,14.5
          c32.1,0,56.8-25.3,56.8-58.7C670.2,66.2,647.3,39.4,608.4,39.4z M609,131.8c-17.8,0-29.3-12.1-29.3-31.2s11.9-31.5,29.3-31.5
          s29.5,13.2,29.5,31.5C638.5,118.8,626.6,131.8,609,131.8z"
        />
        <rect x="682.3" fill={secondaryColor} width="31.9" height="159.5" />
        <path
          fill={secondaryColor}
          d="M848.6,97c-2.2-34.8-26.2-57.6-60.1-57.6c-37.6,0-62.3,25.1-62.3,63.4c0,35.6,23.1,59.4,57.6,59.4
          c17.6,0,31-5.9,38.3-17.6l0.4,15h29l-1.5-32.8C849.5,111.5,849.1,105.4,848.6,97z M787.9,133.1L787.9,133.1
          c-18.3,0-29.7-12.3-29.7-31.9s12.1-32.1,29.7-32.1s29,13,29,32.1C817,121.9,805.3,133.1,787.9,133.1z"
        />
        <path
          fill={secondaryColor}
          d="M919,86.5c-18.5-2.4-27.3-3.5-27.3-10.8c0-5.1,5.1-9.5,18.9-9.5c14.7,0,21.1,4.8,22,11.9h30.6
          c-1.1-23.5-20-38.7-50.4-38.7c-31.8,0-51.8,12.7-51.8,38c0,21.3,14.1,31.9,42.9,35.6c24,3.3,31,5.5,31,12.5s-7,9.7-20.5,9.7
          c-16.9,0-23.5-5.1-24.4-12.1h-30.6c0.9,23.5,20,38.9,52.8,38.9s53.5-13.6,53.5-38.1C965.7,101.4,950.1,90.4,919,86.5z"
        />
        <path
          fill={secondaryColor}
          d="M1042.2,39.4L1042.2,39.4c-15.2,0-26,4.4-33.2,12.3l0.4-51.7h-31.9v159.5h31.9V97.2c0-18.3,8.1-29,26-29
          c17.8,0,23.8,9.2,23.8,26.8v64.5h31.9v-70C1091.1,59.8,1071.1,39.4,1042.2,39.4z"
        />
        <path
          fill={secondaryColor}
          d="M1211.2,86.5c-18.5-2.4-27.3-3.5-27.3-10.8c0-5.1,5.1-9.5,18.9-9.5c14.7,0,21.1,4.8,22,11.9h30.6
          c-1.1-23.5-20-38.7-50.4-38.7c-31.9,0-51.9,12.8-51.9,38.1c0,21.3,14.1,31.9,42.9,35.6c24,3.3,31,5.5,31,12.5c0,7-7,9.7-20.5,9.7
          c-16.9,0-23.5-5.1-24.4-12.1h-30.6c0.9,23.5,20,38.9,52.8,38.9s53.5-13.6,53.5-38.1C1257.8,101.4,1242.2,90.4,1211.2,86.5z"
        />
        <path
          fill={secondaryColor}
          d="M1328.2,39.4L1328.2,39.4c-34.8,0-57.9,22.9-59.8,59.2c-0.4,6.6-0.7,12.3-0.7,21.1v75h31.7v-48
          c7,9.2,18.9,14.5,33.9,14.5c32.1,0,56.8-25.3,56.8-58.7C1390.1,66.2,1367.2,39.4,1328.2,39.4z M1328.9,131.8
          c-17.8,0-29.3-12.1-29.3-31.2s11.9-31.5,29.3-31.5s29.5,13.2,29.5,31.5C1358.4,118.8,1346.5,131.8,1328.9,131.8z"
        />
        <path
          fill={secondaryColor}
          d="M1461.6,39.4c-36.1,0-61.6,25.3-61.6,61.4s25.5,61.4,61.6,61.4s61.6-25.3,61.6-61.4S1497.6,39.4,1461.6,39.4z
          M1461.3,132.4c-17.4,0-29.5-13-29.5-31.7s12.3-31.9,29.7-31.9s29.7,13.2,29.7,31.9S1478.9,132.4,1461.3,132.4z"
        />
        <path
          fill={secondaryColor}
          d="M1534.2,97.7v61.8h31.9V97.7c0-18.5,5.9-25.1,24.6-25.1h7.3V42.9h-9.9C1552.4,42.9,1534.2,61.4,1534.2,97.7z"
        />
        <path
          fill={secondaryColor}
          d="M1638.6,103.4L1638.6,103.4V71.5h39.2V42.9h-39.2V13.6h-31.9v85.6c0,42.9,19.6,60.3,51.5,60.3h16.9l3.5-29.7
          c-1.1,0-3.1,0.2-13.9,0.2C1648.1,130,1638.6,125.6,1638.6,103.4z"
        />
        <path
          fill={secondaryColor}
          d="M1746.7,86.5c-18.5-2.4-27.3-3.5-27.3-10.8c0-5.1,5.1-9.5,18.9-9.5c14.7,0,21.1,4.8,22,11.9h30.6
          c-1.1-23.5-20-38.7-50.4-38.7c-31.9,0-51.9,12.8-51.9,38.1c0,21.3,14.1,31.9,42.9,35.6c24,3.3,31,5.5,31,12.5c0,7-7,9.7-20.5,9.7
          c-16.9,0-23.5-5.1-24.4-12.1H1687c0.9,23.5,20,38.9,52.8,38.9s53.5-13.6,53.5-38.1C1793.3,101.4,1777.7,90.4,1746.7,86.5z"
        />
      </g>
    </svg>
  );
}
