import { ContestStatus } from '@betterpool/api-types/contests-service';

import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import type { MyUserContest } from '~/domains/contest/domains/myContests/types/userContest';

import UserContestMetaSurvivor from './UserContestMetaSurvivor';
import ContestMetaGeneric from './ContestMetaGeneric';
import UserContestMetaGeneric from './UserContestMetaGeneric';

type ContestCardFooterProps = {
  contest: Contest | MyUserContest;
  isMyContestsView: boolean;
  isSurvivor: boolean;
  winning: number;
  entryPrice: number;
  actionBanner?: { color: string; content: string } | string;
  isSettled: boolean;
  totalEntries: number;
  winningEntries: number;
  userAliveEntries: number;
  userSegmentedPayouts?: number;
};

function ContestCardFooter({
  contest,
  totalEntries,
  winningEntries,
  winning,
  entryPrice,
  actionBanner,
  isSettled,
  isMyContestsView,
  isSurvivor,
  userAliveEntries,
  userSegmentedPayouts,
}: ContestCardFooterProps) {
  if (contest.status !== ContestStatus.SCHEDULED && isMyContestsView && isSurvivor)
    return (
      <UserContestMetaSurvivor
        isSettled={isSettled}
        numberOfUserEntries={contest.entries.user}
        winning={winning}
        entryPrice={entryPrice}
        userAliveEntries={userAliveEntries}
        winningEntries={winningEntries}
        totalEntries={totalEntries}
        actionBanner={actionBanner}
      />
    );

  if (contest.status !== ContestStatus.SCHEDULED && isMyContestsView && !isSurvivor)
    return (
      <UserContestMetaGeneric
        isSettled={isSettled}
        numberOfUserEntries={contest.entries.user}
        winning={winning}
        entryPrice={entryPrice}
        actionBanner={actionBanner}
        userSegmentedPayouts={userSegmentedPayouts}
      />
    );

  return <ContestMetaGeneric actionBanner={actionBanner} contest={contest} />;
}

export default ContestCardFooter;
