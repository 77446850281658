import { useContext } from 'react';
import Cookies from 'js-cookie';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserContext } from '~/components/providers/UserProvider';
import { KYCContext } from '~/components/providers/KYCProvider';
import { clearAnalyticsUser, reportEvent } from '~/domains/analytics';
import { logOut } from '~/services/identity/authenticated/identity.service.api';
import XPointSingleton from '~/utils/xpoint';

const useLogout = () => {
  const { setUser } = useContext(UserContext);
  const { setKYCStatus } = useContext(KYCContext);
  const queryClient = useQueryClient();

  const { mutate: handleLogout, isLoading: isLoggingOut } = useMutation({
    mutationKey: ['logOut'],
    mutationFn: logOut,
    onSettled: () => {
      setUser(null);
      Cookies.remove('accessToken');
      Cookies.remove('isLoggedIn', { domain: '.splashsports.com' });
      Cookies.remove('refreshToken');
      window.localStorage.removeItem('locationToken');
      window.localStorage.removeItem('createContestData');
      window.sessionStorage.clear();
      setKYCStatus(null);
      window.sessionStorage.setItem('sign_out', 'yes');
      queryClient.clear();
      XPointSingleton.invalidateToken();

      reportEvent('User > Logout');
      clearAnalyticsUser();
    },
  });

  return { handleLogout, isLoggingOut };
};

export default useLogout;
