import { ApplicationType, LinkType } from '../const';

export default {
  common: {
    fieldIsInvalid: '{{field}} is invalid',
    fieldIsRequired: '{{field}} is required',
  },
  accordion: {
    title: 'External accounts',
    subtitle: 'Link your external accounts to your Splash account',
    badge: 'ExternalID',
  },
  [ApplicationType.RYP]: {
    name: 'RunYourPool',
    card: {
      title: 'RUNYOURPOOL',
      subtitle: 'Access your existing connections on RYP by <link></link>.',
      linkedSubtitle: 'Your Splash account is linked with your RYP account.',
      linkText: 'linking both accounts',
      badge: 'External ID',
    },
  },
  [ApplicationType.OFP]: {
    name: 'OfficeFootballPool',
    card: {
      title: 'OFFICEFOOTBALLPOOL',
      subtitle: 'Access your existing connections on OFP by <link></link>.',
      linkedSubtitle: 'Your Splash account is linked with your OFP account.',
      linkText: 'linking both accounts',
      badge: 'External ID',
    },
  },
  applications: {
    buttonLabel: {
      [LinkType.SIGN_UP]: 'Sign Up via <0></0>',
      [LinkType.SIGN_IN]: 'Log In via <0></0>',
      [LinkType.LINK]: 'Link <0></0> account',
    },
  },
  signUp: {
    title: 'Finalize Sign Up',
    noAccountDescription:
      "We couldn't find any Splash account associated with your {{applicationName}} account. If you wish to create a new Splash account using your {{applicationName}} credentials, please select a handle and provide an email address for the new account.",
    fields: {
      handle: 'Handle',
      email: 'Email',
      referralCode: 'Referral Code (optional)',
    },
    /** @deprecated - validations are handled in useUserSchema.ts */
    handle: {
      requirements: {
        notAllowedChars: 'Username contains not allowed characters',
        startsEndsSpecialChars: 'Username cannot start or end with special characters',
        hasConsecutiveSpecialChars: 'Username cannot contain consecutive special characters',
        tooShort: 'Username must not have less than 3 characters',
        tooLong: 'Username must not have more than 50 characters',
        containsWhiteSpaces: 'Username must not contain any whitespaces',
        isEmailAddress: 'Username must not be an email address',
      },
    },
    email: {
      requirements: {
        restricted: 'We are unable to create an account with this email address.',
      },
    },
    errorTitle: 'OAuth Sign Up Error',
    errorMessage: 'There was an error creating your account',
    buttonLabel: 'Play Now',
    footnote: {
      terms:
        "Clicking Play Now confirms you are age 18+ (19+ in AL & 21+ MA, IA, LA, AZ) and agree to our <termsLink>Terms</termsLink> & <privacyLink>Privacy Policy</privacyLink>. You'll also get offers and messages from the SplashSports companies.",
      gambling:
        'If you or someone you know has a gambling problem and wants help, call <phone>1-800-522-4700</phone> or chat <chatLink>here</chatLink>.',
    },
  },
  signIn: {
    errorTitle: 'OAuth Log In Error',
    errorMessage: 'There was an error signing with your external account',
  },
  linkAccount: {
    errorTitle: 'OAuth Link Account Error',
    errorMessage: 'There was an error linking your external account',
  },
};
