import { useTheme } from '@emotion/react';
import { Box, Button, Stack, Text, TextInput } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import z from 'zod';

import { MOBILE_SECTION_HEIGHT, SECTION_HEIGHT } from './consts';

type InviteViaEmailProps = {
  setEmailInvites: Dispatch<SetStateAction<string[]>>;
};

function InviteViaEmail({ setEmailInvites }: InviteViaEmailProps) {
  const { t } = useTranslation('common');
  const [emails, setEmails] = useState('');
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });

  const addEmail = () => {
    const emailList = emails.includes(',') ? emails.split(',') : emails.split(' ');
    const schema = z.coerce.string().email();

    const sanitizedEmailList = emailList
      .map((email) => email.trim())
      .filter((email) => schema.safeParse(email).success);
    setEmailInvites((prevState) => {
      const combinedList = [...prevState, ...sanitizedEmailList];
      const uniqueList = [...new Set(combinedList)];
      return uniqueList;
    });
  };

  const handleOnClick = () => {
    addEmail();
    setEmails('');
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      addEmail();
      setEmails('');
    }
  };

  return (
    <Box
      h={isTablet ? MOBILE_SECTION_HEIGHT : SECTION_HEIGHT}
      w={isTablet ? '100%' : '60%'}
      px="sm"
    >
      <Stack>
        <Stack spacing={0}>
          <Text size={18} fw={700}>
            {t('invites.modal.inviteViaEmail.title')}
          </Text>
          <Text size={14} color="dimmed">
            {t('invites.modal.inviteViaEmail.subTitle')}
          </Text>
        </Stack>
        <Stack spacing="xs">
          <TextInput
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            onKeyDown={handleOnKeyDown}
            onFocus={(e) => e.stopPropagation()}
            label={t('invites.modal.inviteViaEmail.label')}
            placeholder={t('invites.modal.inviteViaEmail.placeholder')}
            rightSection={
              <Button onClick={handleOnClick}>
                <Text color="black">{t('invites.modal.inviteViaEmail.add')}</Text>
              </Button>
            }
            rightSectionWidth={66}
          />
          <Text color="dimmed" size={14}>
            {t('invites.modal.inviteViaEmail.disclaimer')}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
}

export default InviteViaEmail;
