export default {
  grid: 'lobby.grid',
  header: {
    toggle: {
      grid: {
        view: 'lobby.header.toggle.grid.view',
      },
      table: {
        view: 'lobby.header.toggle.table.view',
      },
    },
  },
  table: 'lobby.table',
};
