const legalStates = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
};

const legalStatesOptions = Object.entries(legalStates)
  .sort(([, a], [, b]) => a.localeCompare(b))
  .map(([code, name]) => ({
    label: `${name} (${code})`,
    value: code,
  }));

const enum DepositOption {
  USD_25 = 'USD_25',
  USD_50 = 'USD_50',
  USD_100 = 'USD_100',
  USD_200 = 'USD_200',
  CUSTOM = 'CUSTOM',
}

const defaultDepositOption = DepositOption.USD_50;

const CUSTOM_AMOUNT_MAX_LIMIT = 10_000;

const depositOptions = [
  DepositOption.USD_25,
  DepositOption.USD_50,
  DepositOption.USD_100,
  DepositOption.USD_200,
  DepositOption.CUSTOM,
];

const depositOptionToValue = {
  [DepositOption.USD_25]: 25,
  [DepositOption.USD_50]: 50,
  [DepositOption.USD_100]: 100,
  [DepositOption.USD_200]: 200,
};

const NEW_CARD_OPTION = 'NEW_CARD';

const defaultCountryOption = 'US';

enum MessageKeys {
  REJECTED = 'REJECTED',
}

export {
  legalStatesOptions,
  DepositOption,
  defaultDepositOption,
  CUSTOM_AMOUNT_MAX_LIMIT,
  depositOptions,
  depositOptionToValue,
  NEW_CARD_OPTION,
  defaultCountryOption,
  MessageKeys,
};
