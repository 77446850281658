import type { ContestType, Sport } from '@betterpool/api-types/contests-service';

import { FEATURE } from '~/common/enums/feature.enum';
import { useTreatments } from '~/domains/common/hooks/useTreatments';

const featureFlag = FEATURE.ENABLE_NEW_GAMESHELL_V2_FOR_CONTEST_TYPE;

export const useIsGamecenterEnabled = (contestType?: ContestType, sport?: Sport) => {
  const { isReady, treatments } = useTreatments([featureFlag]);
  const { config } = treatments?.[featureFlag] ?? {};
  const isEnabled = sport && contestType ? config?.[contestType]?.includes(sport) : null;

  return {
    isReady: isReady && !!contestType && !!sport,
    isEnabled,
  };
};
