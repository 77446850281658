import type * as braze from '@braze/web-sdk';

export type BrazeInstance = typeof braze;

export const isControlMessage = (
  msg: braze.InAppMessage | braze.ControlMessage
): msg is braze.ControlMessage => msg.isControl;

export const isSlideUpMessage = (
  msg: braze.InAppMessage
  // @ts-expect-error Braze type is missing this property
): msg is braze.SlideUpMessage => msg.Ce === 'SLIDEUP';

export const isModalMessage = (
  msg: braze.InAppMessage
  // @ts-expect-error Braze type is missing this property
): msg is braze.ModalMessage => msg.Ce === 'MODAL';

export const isMessageWithUrlRedirect = (msg: braze.SlideUpMessage): msg is braze.SlideUpMessage =>
  msg.clickAction === 'URI' && !!msg.uri;
