export function RAFDrawerIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={81} height={60} fill="none">
      <g fillRule="evenodd" clipPath="url(#raf-icon-a)" clipRule="evenodd">
        <path
          fill="url(#raf-icon-b)"
          d="M38.165 17.541c-8.473 1.134-13.325 10.453-9.416 18.087 4.638 9.06 17.831 8.97 22.27-.154 3.568-7.331-.507-15.867-8.472-17.749-.709-.167-3.599-.288-4.382-.184Zm3.455 2.694c7.735 1.373 10.755 10.917 5.246 16.58-4.67 4.802-12.694 3.584-15.77-2.395-3.724-7.239 2.488-15.612 10.524-14.186Zm-2.44 2.074c-.425.217-.678.62-.678 1.08 0 .282-.029.316-.438.506-1.917.893-2.573 3.776-1.204 5.291.461.511 1.364 1.037 2.696 1.57 1.63.654 1.93 1 1.605 1.853-.418 1.094-1.716 1.35-3.14.62-.734-.377-1.197-.405-1.608-.097-1.317.982-.357 2.538 1.858 3.012.203.043.231.088.231.372 0 .635.604 1.19 1.295 1.19.697 0 1.317-.636 1.317-1.353 0-.272.035-.307.563-.58 2.538-1.313 2.965-4.923.772-6.537-.305-.225-.934-.534-1.69-.831-1.62-.637-2.004-.882-2.004-1.277 0-.958 1.046-1.313 2.148-.727.871.461 1.644.304 2.002-.408.426-.85-.046-1.656-1.26-2.15-.47-.191-.486-.207-.58-.627-.21-.935-1.047-1.337-1.886-.907Z"
        />
        <path
          fill="url(#raf-icon-c)"
          d="M38.19 15.05c1.076.286 1.337.242 6.19-1.035 1.496-.394 2.723-.695 2.726-.669.004.027.015.282.026.569.067 1.82.778 2.709 3.359 4.2l1.74 1.007.194.61c1.287 4.064 4.736 6.794 9.143 7.238l1.17.118 1.274.702c1.183.651 1.27.712 1.236.858-.246 1.037-.198 1.086 2.781 2.806 2.703 1.56 2.708 1.562 3.312 1.378l.295-.09 3.484-5.99 3.484-5.99-.022-.346c-.045-.686-.1-.732-2.812-2.297-2.857-1.65-3.13-1.732-3.737-1.125l-.178.178-1.374-.767-1.374-.767-3.037-3.412-3.037-3.412-4.85-2.774-4.85-2.773-.652-.047c-.689-.05-13.699 3.298-15.08 3.88-1.337.564-2.422 2.06-2.537 3.502-.163 2.034 1.166 3.925 3.127 4.447Zm-.225-3.155c-.477-.73-.292-1.804.385-2.227.12-.076 3.365-.976 7.212-2.002L52.555 5.8l.428.186c.236.102 2.196 1.22 4.356 2.483l3.927 2.297 3.005 3.39 3.005 3.388 1.611.96 1.61.959-1.956 3.39-1.957 3.388-1.222-.705c-1.462-.843-2.092-1.057-3.47-1.18-3.53-.312-6.007-2.33-7.054-5.746-.376-1.226-.207-1.063-2.606-2.504l-2.107-1.265-.188-.405c-.285-.614-.242-1.124.165-1.912l.337-.654 3.409 1.939c3.327 1.893 3.416 1.938 3.703 1.905 1.017-.116 1.578-1.082 1.118-1.923-.25-.46-8.206-5.039-8.727-5.023-.483.014-.889.326-1.265.973l-.303.522-4.465 1.152c-5.196 1.34-5.373 1.355-5.944.48Zm32.84 12.26 2.549-4.415 1.094.632 1.095.631-2.549 4.415-2.549 4.415-1.094-.632-1.094-.632 2.548-4.415Z"
        />
        <path
          fill="url(#raf-icon-d)"
          d="M41.56 44.951c-1.075-.286-1.336-.243-6.189 1.035-1.496.394-2.723.695-2.727.668a20.824 20.824 0 0 1-.025-.568c-.067-1.82-.778-2.71-3.359-4.201l-1.74-1.006-.194-.611c-1.287-4.063-4.736-6.794-9.143-7.238l-1.17-.118-1.274-.701c-1.183-.652-1.27-.713-1.236-.858.246-1.037.198-1.086-2.781-2.806-2.703-1.56-2.708-1.562-3.312-1.378l-3.78 6.08-3.484 5.99.023.346c.045.686.1.731 2.812 2.297 2.857 1.65 3.13 1.732 3.737 1.124l.178-.177 1.374.767 1.374.766 3.037 3.413 3.037 3.412 4.85 2.773 4.85 2.773.652.047c.689.05 13.699-3.297 15.08-3.88 1.337-.563 2.422-2.06 2.537-3.501.163-2.035-1.167-3.925-3.127-4.448Zm.226 3.154c.477.73.292 1.804-.385 2.228-.12.075-3.365.976-7.212 2.002L27.196 54.2l-.428-.185c-.236-.102-2.196-1.22-4.356-2.483l-3.927-2.298-3.005-3.389-3.005-3.389-1.611-.959-1.61-.96 1.956-3.389 1.957-3.389 1.222.705c1.462.843 2.092 1.058 3.47 1.18 3.529.312 6.007 2.33 7.054 5.746.376 1.226.207 1.063 2.606 2.504l2.107 1.265.188.405c.285.614.242 1.124-.165 1.913l-.337.654-3.409-1.94c-3.327-1.892-3.416-1.938-3.703-1.905-1.017.117-1.578 1.082-1.118 1.924.25.46 8.206 5.038 8.726 5.023.484-.015.89-.327 1.266-.973l.303-.522 4.465-1.152c5.196-1.341 5.373-1.355 5.944-.48ZM8.946 35.846l-2.549 4.415-1.095-.632-1.094-.632 2.549-4.414 2.548-4.415 1.095.632 1.095.632-2.55 4.414Z"
        />
      </g>
      <defs>
        <linearGradient
          id="raf-icon-b"
          x1={39.849}
          x2={39.849}
          y1={17.502}
          y2={42.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4BEBE2" />
          <stop offset={1} stopColor="#DA47F7" />
        </linearGradient>
        <linearGradient
          id="raf-icon-c"
          x1={52.219}
          x2={61.201}
          y1={23.313}
          y2={7.756}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4BEBE2" />
          <stop offset={1} stopColor="#DA47F7" />
        </linearGradient>
        <linearGradient
          id="raf-icon-d"
          x1={27.532}
          x2={18.55}
          y1={36.688}
          y2={52.245}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4BEBE2" />
          <stop offset={1} stopColor="#DA47F7" />
        </linearGradient>
        <clipPath id="raf-icon-a">
          <path fill="#fff" d="M.5 0h80v60H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
