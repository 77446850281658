import { getCdnUrl } from '~/domains/assets/utils/getCdnUrl';

const DEFAULT_SPLASH_THUMBNAIL = getCdnUrl('/images/commissioner/DefaultCommissionerAvatar.png');

export function getCommissionerAvatar({
  avatarUrl,
  createdByAdmin,
}: {
  avatarUrl: string;
  createdByAdmin: boolean;
}) {
  if (createdByAdmin) return DEFAULT_SPLASH_THUMBNAIL;

  return avatarUrl;
}
