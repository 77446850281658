import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import { reportEvent } from '~/domains/analytics';
import { Routes } from '~/domains/common/constants/routes';
import { FEATURE } from '~/common/enums/feature.enum';
import { logError } from '~/common/utils/log';
import { useTreatments } from '~/domains/common/hooks/useTreatments';

const APP_PROMPT_KEY = 'appPromptSeen';

const enum PromptLocation {
  SIGNUP = 'signup',
  LOGIN = 'login',
  KYC = 'kyc',
  DEPOSIT = 'deposit',
  CONTEST_DETAIL = 'contestDetail',
}

const useAppDownloadPrompt = (invokerLocation: PromptLocation, isRedirect = true) => {
  const router = useRouter();

  const { isReady, treatments } = useTreatments([FEATURE.ENABLE_APP_PROMPT]);
  const appPromptConfig = treatments[FEATURE.ENABLE_APP_PROMPT]?.config;
  const isEnableAppPromptEnabled =
    !!appPromptConfig && treatments[FEATURE.ENABLE_APP_PROMPT]?.treatment !== 'off';

  const handleShowAppDownloadPrompt = useCallback(
    (redirect?: { redirectTo: string }): Promise<boolean> | boolean | undefined => {
      if (!isReady) return;

      // <FF> Don't show the app prompt at all
      if (!isEnableAppPromptEnabled) {
        return;
      }

      // <FF> Don't show the download prompt to desktop users
      if (!appPromptConfig.showOnDesktop && !(isAndroid || isIOS)) {
        return;
      }

      // Parse stored invoked locations (if available)
      const parsedInvokedLocations =
        localStorage.getItem(APP_PROMPT_KEY) &&
        JSON.parse(localStorage.getItem(APP_PROMPT_KEY)).locations;
      const invokedLocations = Array.isArray(parsedInvokedLocations) ? parsedInvokedLocations : [];

      // <FF> Show app prompt only once for a given invoker location
      if (appPromptConfig.showOnlyOnce && localStorage.getItem(APP_PROMPT_KEY)) {
        const { location } = JSON.parse(localStorage.getItem(APP_PROMPT_KEY));

        // Old key format (backward support)
        if (location === invokerLocation) return;

        // New key format check supporting multiple locations
        if (invokedLocations.includes(invokerLocation)) return;
      }

      // <FF> Show app prompt based on invoker location
      switch (invokerLocation) {
        case PromptLocation.SIGNUP:
          if (!appPromptConfig.showAfterSignup) {
            return;
          }
          break;
        case PromptLocation.LOGIN:
          if (!appPromptConfig.showAfterLogin) {
            return;
          }
          break;
        case PromptLocation.KYC:
          if (!appPromptConfig.showAfterKYC) {
            return;
          }
          break;
        case PromptLocation.DEPOSIT:
          if (!appPromptConfig.showAfterDeposit) {
            return;
          }
          break;
        case PromptLocation.CONTEST_DETAIL:
          if (!appPromptConfig.showAtContestDetail) {
            return;
          }
          break;
        default:
          logError('Invalid invoker location for app prompt');
      }

      localStorage.setItem(
        APP_PROMPT_KEY,
        JSON.stringify({
          locations: [...invokedLocations, invokerLocation ?? 'unknown'],
          timestamp: Date.now(),
        })
      );

      reportEvent('Promo > App Download Promo shown', {
        location: invokerLocation,
      });

      return isRedirect ? router.push(Routes.appDownloadPrompt(redirect)) : true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [appPromptConfig, isEnableAppPromptEnabled, isReady, router.push]
  );

  return handleShowAppDownloadPrompt;
};

export { useAppDownloadPrompt, PromptLocation };
