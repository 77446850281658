import { Box } from '@mantine/core';
import type { Dispatch, SetStateAction } from 'react';

import InviteViaEmail from './InviteViaEmail';
import { MOBILE_SECTION_HEIGHT } from './consts';

type PanelEmailProps = {
  setEmailInvites: Dispatch<SetStateAction<string[]>>;
};

function EmailPanel({ setEmailInvites }: PanelEmailProps) {
  return (
    <Box mt="lg" h={MOBILE_SECTION_HEIGHT}>
      <InviteViaEmail setEmailInvites={setEmailInvites} />
    </Box>
  );
}

export default EmailPanel;
