enum ApplicationType {
  RYP = 'runyourpool',
  OFP = 'officefootballpool',
}

enum LinkType {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  LINK = 'LINK',
}

type OAuthOptions = {
  redirectPath: string;
  scope: string[];
};

const oauthOptions: { [key: string]: OAuthOptions } = {
  [LinkType.SIGN_UP]: {
    redirectPath: '/oauth/sign-up',
    scope: ['username', 'email'],
  },
  [LinkType.SIGN_IN]: {
    redirectPath: '/oauth/sign-in',
    scope: [],
  },
  [LinkType.LINK]: {
    redirectPath: '/oauth/link',
    scope: [],
  },
};

export { ApplicationType, LinkType, oauthOptions };
