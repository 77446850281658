/* eslint-disable import/no-cycle */
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { Routes } from '~/domains/common/constants/routes';
import { logError } from '~/common/utils/log';
import { getStoredUser, setAnalyticsUser, updateAnalyticsUser } from '~/domains/analytics';
import Braze from '~/domains/analytics/braze/braze.class';
import { fetchUserWithRefreshToken } from '~/services/identity/authenticated/identity.service.api';
import { getUserSsnStatus, meDetailed } from '~/services/users/users.service.api';
import type { APIAuthUserData, APIUserResponse, UserSsnStatus } from '~/services/users/types';
import XPointSingleton from '~/utils/xpoint';

type UserContextType = {
  isUserSignedIn?: boolean;
  user: APIAuthUserData;
  userDetails: APIUserResponse['data'];
  userSsnStatus: UserSsnStatus;
  refreshUserDetails: VoidFunction;
  refreshUserSsnStatus: VoidFunction;
  setUser: Dispatch<SetStateAction<APIAuthUserData>>;
  initializing: any;
  setRedirect: (redirect: string) => void;
  getRedirect: () => string | null;
  clearRedirect: () => void;
  isBetterpoolEmail: boolean;
};

const UserContext = createContext<UserContextType>({
  user: null,
  userDetails: null,
  userSsnStatus: null,
  refreshUserDetails: () => {},
  refreshUserSsnStatus: () => {},
  setUser: null,
  initializing: null,
  setRedirect: () => {},
  getRedirect: () => null,
  clearRedirect: () => {},
  isBetterpoolEmail: false,
});

interface UserContextProviderProps {
  children: React.ReactNode;
}

function UserContextProvider({ children }: UserContextProviderProps) {
  const [user, setUser] = useState<APIAuthUserData>(null);
  const [initializing, setInitializing] = useState(true);
  const [userResolved, setUserResolved] = useState(false);

  const refreshToken = Cookies.get('refreshToken');
  const storedUser = getStoredUser();
  const router = useRouter();

  // --------------- REDIRECTS ---------------
  const redirectKey = 'sign_in_redirect';
  const getRedirect = useCallback(
    (): string | null => window.sessionStorage.getItem(redirectKey),
    [redirectKey]
  );

  const setRedirect = useCallback(
    (redirect: string) => window.sessionStorage.setItem(redirectKey, redirect),
    [redirectKey]
  );

  const clearRedirect = useCallback(
    () => window.sessionStorage.removeItem(redirectKey),
    [redirectKey]
  );

  // --------------- USER FUNCTIONS ---------------
  const { data: userDetails, refetch: refreshUserDetails } = useQuery({
    queryKey: ['userDetails', user?.id],
    enabled: user !== null,
    queryFn: meDetailed,
    onError: (error: AxiosError) => {
      if ([404, 401].includes(error?.response?.status)) {
        return;
      }

      logError(error);
    },
  });

  const { data: userSsnStatus, refetch: refreshUserSsnStatus } = useQuery({
    queryKey: ['userSsnStatus', user?.id],
    enabled: user !== null,
    queryFn: getUserSsnStatus,
    onError: (error: AxiosError) => {
      if ([404, 401].includes(error?.response?.status)) {
        return;
      }

      logError(error);
    },
  });

  const refreshUser = useCallback(async () => {
    try {
      const refreshedUser = await fetchUserWithRefreshToken();
      setUser(refreshedUser.data);
    } catch (error) {
      console.warn('failed to refresh');
    } finally {
      setUserResolved(true);
    }
    setInitializing(false);
  }, []);

  useEffect(() => {
    if (!storedUser && refreshToken) {
      refreshUser();
      setInitializing(false);
      return;
    }

    if (!storedUser && !refreshToken) {
      setUserResolved(true);
      setInitializing(false);
      return;
    }

    if (storedUser && !user) {
      setUser(storedUser);
      setUserResolved(true);
      setInitializing(false);
    }
  }, [refreshUser, JSON.stringify(storedUser), refreshToken, user]);

  // Validate that user has active refresh token cookie every 10s (logout across tabs)
  const timerIdRef = useRef(null);
  useEffect(() => {
    const stopPolling = () => clearInterval(timerIdRef.current);
    const pollingCallback = () => {
      const hasRefreshToken = Cookies.get('refreshToken');

      if (!hasRefreshToken) {
        stopPolling();
        router.replace(Routes.logout());
      }
    };
    const startPolling = () => {
      timerIdRef.current = setInterval(pollingCallback, 10000);
    };

    if (user) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [user]);

  // --------------- ANALYTICS ---------------
  // Set new user
  useEffect(() => {
    if (user) {
      setAnalyticsUser(user);
      Braze.setUserId(user.id);
      XPointSingleton.setUserId(user.id);
    }
  }, [user]);

  // Update user details
  useEffect(() => {
    if (userDetails) {
      updateAnalyticsUser(userDetails.data);
    }
  }, [userDetails]);

  const value = useMemo(
    () => ({
      isUserSignedIn: !!refreshToken,
      user,
      userDetails: userDetails?.data,
      userSsnStatus: userSsnStatus?.data?.userSsnStatus,
      refreshUserDetails,
      refreshUserSsnStatus,
      initializing,
      setUser,
      setRedirect,
      getRedirect,
      clearRedirect,
      isBetterpoolEmail: user?.email?.endsWith('@betterpool.com'),
    }),
    [
      clearRedirect,
      getRedirect,
      initializing,
      refreshToken,
      refreshUserDetails,
      refreshUserSsnStatus,
      setRedirect,
      user,
      userDetails?.data,
      userSsnStatus?.data?.userSsnStatus,
    ]
  );

  if (!userResolved) {
    return null;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserContextProvider;

export { UserContext, type UserContextType };
