/* eslint-disable react/static-property-placement */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/state-in-constructor */
import React from 'react';
import Bugsnag from '@bugsnag/js';

interface ErrorBoundaryProps {
  FallbackComponent: React.ComponentType<{ clearError?: () => void }> | null;
  fallbackText: string | null;
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

// @todo EDGE-3991 - review Custom Error boundary
class ErrorBoundaryCustom extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps: Partial<ErrorBoundaryProps> = {
    fallbackText: null,
    FallbackComponent: null,
  };

  state: ErrorBoundaryState = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.warn('Error Boundary Custom Details', error, info);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  clearError = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { children, FallbackComponent, fallbackText } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return FallbackComponent ? <FallbackComponent clearError={this.clearError} /> : fallbackText;
    }

    return children;
  }
}

const ErrorBoundary =
  process.env.NEXT_PUBLIC_BUGSNAG_ENABLED === 'true' && !!Bugsnag.getPlugin('react')
    ? Bugsnag.getPlugin('react').createErrorBoundary(React)
    : ErrorBoundaryCustom;

export default ErrorBoundary;
