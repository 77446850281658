import { getCommissionerAvatar } from './getCommissionerAvatar';

type GetContestThumbnailProps = {
  contestThumbnailUrl: string;
  createdByAdmin: boolean;
  avatarUrl: string;
};

export const getContestThumbnail = ({
  contestThumbnailUrl,
  createdByAdmin,
  avatarUrl,
}: GetContestThumbnailProps) => {
  if (contestThumbnailUrl) return contestThumbnailUrl;

  return getCommissionerAvatar({ avatarUrl, createdByAdmin });
};
