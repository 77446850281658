import type { AxiosInstance } from 'axios';

import { getAnonymousId } from '~/domains/analytics';

export const setAnonymousIdInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async (config) => {
      const anonymousId = getAnonymousId();
      config.headers.set('X-Anonymous-Id', anonymousId);

      return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
  );
};
