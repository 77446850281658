import axios from 'axios';

const branchCommonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const branchAuth = {
  branch_key: `${process.env.NEXT_PUBLIC_BRANCH_KEY}`,
};

export const branchioInstanceV1 = axios.create({
  baseURL: 'https://api2.branch.io/v1',
  headers: branchCommonHeaders,
});

export const branchioInstanceV2 = axios.create({
  baseURL: 'https://api2.branch.io/v2',
  headers: branchCommonHeaders,
});
