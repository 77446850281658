import axios from 'axios';

import RequestHeadersBase from '~/common/constants/RequestHeadersBase';
import RequestHeadersPlatform from '~/common/constants/RequestHeadersPlatform';
import { handleRefreshInterceptor } from '~/services/interceptors/handle-refresh.interceptor';
import { setAuthTokenInterceptor } from '~/services/interceptors/set-auth-token.interceptor';
import { Method, setLocationInterceptor } from '~/services/interceptors/set-location.interceptor';

export const contestsServiceInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_SPLASH}contests-service/api`,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthTokenInterceptor(contestsServiceInstance);
handleRefreshInterceptor(contestsServiceInstance);
setLocationInterceptor(contestsServiceInstance, [
  [Method.POST, '/entries'],
  [Method.POST, '/v2/entries'],
]);

export const contestImageUploadService = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_SPLASH}contests-service/api/assets`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    ...RequestHeadersPlatform,
  },
});

setAuthTokenInterceptor(contestImageUploadService);
handleRefreshInterceptor(contestImageUploadService);
