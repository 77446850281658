export const convertPaginationToSwagFormat = ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}): {
  pageSize: number;
  page: number;
} => ({
  pageSize: limit,
  page: offset / limit + 1,
});
