import type { Sport } from '@betterpool/api-types/contests-service';

import { getCdnUrl } from '~/domains/assets/utils/getCdnUrl';

const DEFAULT_BANNER = getCdnUrl('/images/commissioner/DefaultCommissionerBanner.png');

type GetContestBannerProps = {
  contestBannerlUrl: string;
  sport: Sport;
};

export const getContestBanner = ({ contestBannerlUrl, sport }: GetContestBannerProps) => {
  if (contestBannerlUrl) return contestBannerlUrl;
  if (!sport) return DEFAULT_BANNER;

  return getCdnUrl(`/images/contest/banner/${sport}.jpg`);
};
