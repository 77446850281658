const enum AsideContentType {
  GAME_INFO = 'gameInfo',
  PICKS = 'picks',
  STANDINGS = 'standings',
  MY_ENTRIES = 'myEntries',
  PLAYER_DETAIL = 'playerDetail',
  CONTEST_DETAIL = 'contestDetail',
  CHAT = 'chat',
}

const enum QueryOptAsideType {
  PLAYER_ID = 'playerId',
}

const enum PanelContentType {
  PICKS_BAR = 'picksBar',
}

const enum ContentURLType {
  ASIDE = 'aside',
  PANEL = 'panel',
}

export { AsideContentType, ContentURLType, PanelContentType, QueryOptAsideType };
