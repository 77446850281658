import { FEATURE } from '~/common/enums/feature.enum';
import { useTreatments } from '~/domains/common/hooks/useTreatments';

export const useHomePageEnabled = () => {
  const { isReady, treatments } = useTreatments([FEATURE.ENABLE_HOME_PAGE]);

  const isHomePageEnabled = treatments[FEATURE.ENABLE_HOME_PAGE]?.treatment === 'on';

  return {
    isReady,
    isHomePageEnabled,
  };
};
