/* eslint-disable react/destructuring-assignment */
import type {
  CSSObject,
  TextInputProps as MantineTextInputProps,
  PasswordInputProps,
} from '@mantine/core';
import {
  PasswordInput as MantinePasswordInput,
  TextInput as MantineTextInput,
  createStyles,
} from '@mantine/core';

import { textStyles } from './Text';

type InputBase = {
  label?: string | JSX.Element;
  value?: string;
  error?: string;
  description?: string;
  hasBorder?: boolean;
};

type TextInputProps = InputBase & MantineTextInputProps;

type StyleArgs = Pick<TextInputProps, 'label' | 'error' | 'value' | 'hasBorder'> & {
  isTextarea?: boolean;
};

export const useStyles = createStyles(
  (theme, { label, error, value, isTextarea, hasBorder }: StyleArgs) => {
    const input = {
      ...(isTextarea ? textStyles['body-large'] : textStyles['title-medium']),
      padding: '5px 16px',
      minHeight: '48px',

      ...(label && {
        ...(value?.length > 0 && { paddingTop: '23px' }),
        '&:focus, &:autofill, &:-webkit-autofill': {
          paddingTop: '23px',
        },
      }),

      '&, &.mantine-TextInput-invalid': {
        color: theme.colors.gray[9],
      },
    } as CSSObject;

    return {
      root: {
        position: 'relative',
        lineHeight: 1,

        '&:focus-within': {
          label: { ...textStyles['body-small'], top: 5 },
        },
      },
      wrapper: {
        marginBottom: 0,
        minHeight: '48px',
        background: theme.white,
        borderRadius: '8px',
        overflow: 'hidden',

        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: hasBorder ? theme.colors.gray[4] : 'transparent',
        '&:focus-within': {
          borderColor: theme.colors.gray[6],
        },
        ...(error?.length > 0 && {
          borderColor: theme.colors.orange[3],
        }),
      },
      input,
      // needed for password input
      innerInput: input,

      label: {
        position: 'absolute',
        top: 5,
        left: 16,
        zIndex: 1,
        color: theme.colors.gray[6],
        ...(value?.length > 0
          ? textStyles['body-small']
          : {
              ...textStyles['title-medium'],
              top: 14,
            }),
        transition: '0.2s font-size, 0.2s line-height, 0.2s font-weight, 0.2s top',
      },
      description: textStyles['body-medium'],
      error: {
        margin: '8px 0 0',
        ...textStyles['body-medium'],
        color: theme.colors.orange[3],
      },
    };
  }
);

export function TextInput(props: TextInputProps) {
  const { classes, cx } = useStyles(props);

  return (
    <MantineTextInput
      {...props}
      classNames={{
        root: cx(classes.root, props.classNames?.root),
        wrapper: cx(classes.wrapper, props.classNames?.wrapper),
        input: cx(classes.input, props.classNames?.input),
        label: cx(classes.label, props.classNames?.label),
        description: cx(classes.description, props.classNames?.description),
        error: cx(classes.error, props.classNames?.error),
      }}
      variant="unstyled"
    />
  );
}

export function PasswordInput(props: InputBase & PasswordInputProps) {
  const { classes } = useStyles(props);

  return <MantinePasswordInput classNames={classes} variant="unstyled" {...props} />;
}
