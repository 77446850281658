export default {
  domains: {
    kyc: {
      components: {
        introModal: {
          primaryButton: 'user.domains.kyc.components.introModal.primaryButton',
        },
      },
    },
  },
};
