import { createStyles } from '@mantine/core';
import { useRouter } from 'next/router';

import { Button, ButtonLink } from '~/domains/design-system/Button';

import { ErrorScreen } from '../ErrorScreen/ErrorScreen';

type UnknownErrorScreenProps = {
  isCompact?: boolean;
  isComponentLevelError?: boolean;
  isShowHomeButton?: boolean;
};

const useStyles = createStyles((theme) => ({
  outline: {
    color: theme.colors.gray[6],
    borderColor: theme.colors.gray[6],
  },
}));

function UnknownErrorScreen({
  isCompact,
  isComponentLevelError,
  isShowHomeButton,
}: UnknownErrorScreenProps) {
  const { classes } = useStyles();
  const router = useRouter();

  return (
    <ErrorScreen
      errorCode={500}
      title="Something went wrong"
      description="Our servers could not handle your request. Don't worry, our development team was already notified. Try refreshing the page."
      cta={[
        <Button
          key="reload"
          onClick={() => {
            router.reload();
          }}
          variant="primary-fill"
        >
          Reload the page
        </Button>,
        ...(isShowHomeButton
          ? [
              <ButtonLink
                key="home"
                variant="secondary-stroke"
                className={classes.outline}
                href="/"
              >
                Take me home
              </ButtonLink>,
            ]
          : []),
      ]}
      isCompact={isCompact}
      isComponentLevelError={isComponentLevelError}
    />
  );
}

export default UnknownErrorScreen;
