import { useSyncExternalStore } from 'react';

import XPointSingleton from '~/utils/xpoint';

function getLocationStatus() {
  return XPointSingleton.getLocationTokenData()?.status;
}

function useLocationStatus() {
  return useSyncExternalStore(XPointSingleton.subscribeToLocationToken, getLocationStatus);
}

export { useLocationStatus };
