import { objectToTuples } from '~/services/utils';

type DecodedQueryString = Record<string, string | string[] | boolean | number | undefined>;

export const createQueryString = (obj: DecodedQueryString): string => {
  const tuples = objectToTuples(obj);
  const queryString = new URLSearchParams(tuples).toString();

  return queryString.length ? `?${queryString}` : '';
};
