import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import {
  getNotifications,
  getNotificationsCount,
} from '~/services/notifications/notifications-service.service.api';

export function useNotifications({ enabled }: { enabled?: boolean }) {
  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: ({ pageParam = 1 }) => getNotifications({ page: pageParam }),
    getNextPageParam: ({ totalPages, page }) => {
      if (page < totalPages) {
        return page + 1;
      }
    },
    enabled,
  });

  const notifications = data?.pages.flatMap((page) => page.data) ?? [];

  return {
    notifications,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  };
}

export function useNotificationsCount({ enabled }: { enabled?: boolean }) {
  return useQuery({
    queryKey: ['notificationsCount'],
    queryFn: () => getNotificationsCount(),
    enabled,
    staleTime: 10 * 1000,
  });
}
