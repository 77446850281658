import type { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';

export const setAuthTokenInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async (config) => {
      if (Cookies.get('refreshToken')) {
        config.headers.setAuthorization(`Bearer ${Cookies.get('accessToken')}`);
      }
      return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
  );
};
