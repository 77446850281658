import Cookies from 'js-cookie';

import { jwtDecodeSafe } from '~/utils/jwt';

interface AccessTokenDataRaw {
  sub: string;
  'cognito:username': string;
  ryp_user_id?: string;
  ofp_user_id?: string;
}

interface AccessTokenData {
  id: string;
  username: string;
  rypUserId?: string;
  ofpUserId?: string;
}

function getAccessTokenData(
  accessToken: string | undefined = Cookies.get('accessToken')
): AccessTokenData {
  const rawData = jwtDecodeSafe<AccessTokenDataRaw>(accessToken);
  return {
    id: rawData?.sub,
    username: rawData?.['cognito:username'],
    rypUserId: rawData?.ryp_user_id,
    ofpUserId: rawData?.ofp_user_id,
  };
}

function getHasUserLinkedRYP(
  accessToken: string | undefined = Cookies.get('accessToken')
): boolean {
  const { rypUserId } = getAccessTokenData(accessToken);
  return !!rypUserId;
}

export { getAccessTokenData, getHasUserLinkedRYP };
