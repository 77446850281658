import { AppShell, MantineProvider } from '@mantine/core';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { Header } from '~/domains/common/components/Header/Header';
import UnknownErrorScreen from '~/domains/common/components/UnknownErrorScreen/UnknownErrorScreen';

import { SplashLogo } from '../Logos/SplashLogo';

type ErrorScreenProps = {
  clearError?: () => void;
};

function ErrorScreen({ clearError }: ErrorScreenProps) {
  const router = useRouter();

  useEffect(() => {
    if (!clearError) {
      return;
    }

    router.events.on('routeChangeComplete', clearError);

    return () => {
      router.events.off('routeChangeComplete', clearError);
    };
  }, [clearError, router.events]);

  return (
    <MantineProvider inherit theme={{ colorScheme: 'light' }}>
      <AppShell
        padding={0}
        header={
          <Header>
            <SplashLogo />
          </Header>
        }
      >
        <UnknownErrorScreen isShowHomeButton />
      </AppShell>
    </MantineProvider>
  );
}

export default ErrorScreen;
