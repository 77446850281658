import type { UserContest } from '@betterpool/api-types/contests-service';
import { ContestStatus } from '@betterpool/api-types/contests-service';
import { useMantineTheme, Progress } from '@mantine/core';
import React from 'react';

import { ReturnPercentage } from '~/components/utils/formatters';

// TODO move widget to domains/common - only bar with entries
import { LeaderboardWidget } from '~/domains/contest/domains/game/domains/common/components/LeaderboardWidget/LeaderboardWidget';
import type { MyUserContest } from '~/domains/contest/domains/myContests/types/userContest';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';

type ContestCardProgressBarProps = {
  contest: Contest | MyUserContest;
  isSurvivor?: boolean;
  leaderboard?: UserContest['leaderboard'];
};

function ContestCardProgressBar({ leaderboard, isSurvivor, contest }: ContestCardProgressBarProps) {
  const theme = useMantineTheme();

  if (leaderboard) {
    return (
      <LeaderboardWidget
        isSurvivor={isSurvivor}
        isOnlyProgress
        isVisibleOnTablet
        isCompact
        isWidthAuto
        leaderboard={{
          entryPrice: contest.entry_fee,
          winning: (leaderboard?.userPayout ?? 0) / 100,
          winningEntries: leaderboard?.payoutSpots,
          isSettled: [ContestStatus.FINISHED, ContestStatus.FINALIZED].includes(contest.status),
          userEntries: leaderboard?.positions.map((position) => ({
            id: position.entryId,
            rank: position.rank,
          })),
          totalEntries: leaderboard?.totalSpots,
        }}
        progressBarHeight={8}
      />
    );
  }

  return (
    <Progress
      mt={8}
      mb={theme.spacing.sm}
      sections={[
        {
          value: ReturnPercentage(contest.entries.filled, contest.entries.max),
          color: 'primary',
        },
        {
          value: 100,
          color: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[2],
        },
      ]}
    />
  );
}

export default ContestCardProgressBar;
