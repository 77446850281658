import { useMutation } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { showNotification } from '@mantine/notifications';

import { deleteSavedCard } from '~/services/wallet/wallet.service.api';

function useDeleteSavedCard({ cardId, callback }: { cardId: string; callback?: VoidFunction }) {
  const { t } = useTranslation('payments');
  const handleDeleteSavedCard = useCallback(async () => {
    await deleteSavedCard(cardId);
  }, []);

  const { mutate: deleteCard, isLoading: isDeleting } = useMutation({
    mutationKey: ['deleteCard', cardId],
    mutationFn: handleDeleteSavedCard,
    onSuccess: () => {
      showNotification({
        message: t('deleteCardModal.success.message'),
        color: 'green',
      });
    },
    onError: () => {
      showNotification({
        title: t('deleteCardModal.error.title'),
        message: t('deleteCardModal.error.message'),
        color: 'red',
      });
    },
    onSettled: callback,
  });

  return {
    deleteCard,
    isDeleting,
  };
}

export { useDeleteSavedCard };
