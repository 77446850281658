import type { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';

import { getStoredUser } from '~/domains/analytics';
import { me } from '~/services/users/users.service.api';
import { jwtDecodeSafe } from '~/utils/jwt';

// This interceptor compares the accessToken username with the
// session user.username, if they don't match it calls the me endpoint
// to update the session username
// ** Do not use this Interceptor on the user instance / me endpoint **
// ** This could cause looping and unintended side effects
export const verifyUserSessionInterceptor = (instance: AxiosInstance) => {
  interface UserToken {
    username: string;
  }

  instance.interceptors.request.use(
    async (config) => {
      const accessToken = Cookies.get('accessToken');
      if (accessToken) {
        const tokenUser = jwtDecodeSafe<UserToken>(accessToken);
        const sessionUser = getStoredUser();

        if (tokenUser?.username !== sessionUser?.username) {
          await me();
        }
      }
      return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
  );
};
