import { useQuery } from '@tanstack/react-query';

import { getLatestAppVersion } from '../nextApi.service';

const useLatestAppVersion = () =>
  useQuery({
    queryKey: ['appVersion'],
    queryFn: () => getLatestAppVersion(),
    refetchInterval: 60_000, // 1 minute
  });

export default useLatestAppVersion;
