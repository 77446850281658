export default {
  billingAddress: {
    title: 'Billing Address',
    isSameAddress: 'My billing and account address is the same',
    address: {
      label: 'Address',
      placeholder: 'Address',
      error: 'Address cannot be empty',
    },
    address2: {
      label: 'Address 2',
      placeholder: 'Address 2',
    },
    city: {
      label: 'City',
      placeholder: 'City',
      error: 'City cannot be empty',
    },
    state: {
      label: {
        US: 'State',
        CA: 'Province',
      },
      placeholder: {
        US: 'State',
        CA: 'Province',
      },
      error: 'Please choose a state',
    },
    zip: {
      label: 'Zip Code',
      placeholder: 'Zip Code',
      error: {
        US: 'Please enter a valid zip code',
        CA: 'Please enter a valid postal code',
      },
    },
    country: {
      label: 'Country',
      placeholder: 'Country',
      error: 'Please choose a country',
    },
  },
};
