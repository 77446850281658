export default {
  detail: {
    general: {
      main: 'quickPick.detail.general.main',
    },
    entries: {
      main: 'quickPick.detail.entries.main',
    },
    prizePayout: {
      main: 'quickPick.detail.prizePayout.main',
    },
    description: {
      main: 'quickPick.detail.description.main',
    },
    rules: {
      main: 'quickPick.detail.rules.main',
    },
    entrants: {
      main: 'quickPick.detail.entrants.main',
    },
  },
};
