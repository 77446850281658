import { kycServiceInstance } from './kyc.service.instance';
import { type IGetKYCVerificationResponse } from './kyc.interface';

export const postKycVerify = async (payload: any) => {
  const response = await kycServiceInstance.post('/verification', payload);
  return response.data;
};

export const getKycVerify = async () => {
  const response = await kycServiceInstance.get<IGetKYCVerificationResponse>('/verification');
  return response.data;
};
