import createReactComponent from '../createReactComponent';
export default createReactComponent('info-triangle', 'IconInfoTriangle', [
  [
    'path',
    {
      d: 'M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 9h.01', key: 'svg-1' }],
  ['path', { d: 'M11 12h1v4h1', key: 'svg-2' }],
]);
