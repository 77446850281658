export default {
  table: {
    filter: {
      soon: 'Coming soon',
      sport: {
        golf: {
          label: 'PGA',
          tooltipLabel: 'Golf',
        },
        football: {
          label: 'NFL',
          tooltipLabel: 'Football',
        },
        soccer: {
          label: 'EPL',
          tooltipLabel: 'Soccer',
        },
        basketball: {
          label: 'NBA',
          tooltipLabel: 'Basketball',
        },
        baseball: {
          label: 'MLB',
          tooltipLabel: 'Baseball',
        },
        hockey: {
          label: 'NHL',
          tooltipLabel: 'Hockey',
        },
      },
    },
  },
  carousel: {
    commissionerPrefix: 'by',
    entries: 'Entries ({{user}}/{{max}})',
    entry: 'Entry',
    prizes: 'Prizes',
    play: 'Play for {{entryFee}}',
    visitDetail: 'Visit contest detail',
  },
  commissionerSearchInput: {
    placeholder: 'Find commissioners',
    list: {
      empty: 'No commissioners found. Please try another search term.',
      error: {
        title: 'Something went wrong',
        text: `
          Our servers could not handle your request. Don't worry, our development team was already
          notified. Please try refreshing the page.
        `,
      },
      minChars: {
        zero: 'Please type at least {{minChars}} characters',
        more: 'Please type {{charsRemaining}} more characters',
      },
    },
  },
};
