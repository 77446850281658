import Cookies from 'js-cookie';

import { REFRESH_TOKEN_EXPIRATION_TIME } from '~/domains/auth/consts';

import { identityInstance } from './identity-instance';
import type { APIAuthRefreshTokenResponse } from './types';

export const fetchRefreshToken = async (
  shouldRedirect = true
): Promise<APIAuthRefreshTokenResponse['data']> => {
  try {
    const refreshToken = Cookies.get('refreshToken');
    if (!refreshToken) {
      throw Error('No refresh token');
    }

    // Get User new token
    const response: APIAuthRefreshTokenResponse = await identityInstance.post('/auth/refresh', {
      refreshToken,
    });

    const tokenData = response.data;
    const { accessToken, expiresIn } = tokenData;
    const expiresInXDays = expiresIn / (24 * 60 * 60); // expiresIn is in seconds

    Cookies.set('accessToken', accessToken, {
      expires: expiresInXDays,
    });
    Cookies.set('isLoggedIn', 'true', {
      expires: REFRESH_TOKEN_EXPIRATION_TIME,
      domain: '.splashsports.com',
      secure: true,
      sameSite: 'strict',
    });

    return tokenData;
  } catch (error) {
    Cookies.remove('accessToken');
    Cookies.remove('isLoggedIn', { domain: '.splashsports.com' });
    Cookies.remove('refreshToken');
    sessionStorage.clear();
    localStorage.clear();
    if (shouldRedirect) {
      window.location.pathname = '';
    }
    throw error;
  }
};
