import { Checkbox } from '@mantine/core';
import { type UseFormReturnType } from '@mantine/form';
import useTranslation from 'next-translate/useTranslation';
import { useContext, type ChangeEventHandler, useCallback } from 'react';

import type { UserContextType } from '~/components/providers/UserProvider';
import { UserContext } from '~/components/providers/UserProvider';
import { defaultCountryOption } from '~/domains/payments/deposits/components/consts';
import dt from '~/testing';

import { type BillingAddressFields, type BillingAddressFormValues } from './types';

type SameAddressCheckboxProps<T extends BillingAddressFormValues> = {
  form: UseFormReturnType<T>;
};

function getIsAddressSameAsAccount(
  billingAddress: BillingAddressFields,
  userDetails?: UserContextType['userDetails']
) {
  if (!userDetails) {
    return false;
  }

  return (
    billingAddress.address === userDetails.address &&
    billingAddress.address2 === '' &&
    billingAddress.city === userDetails.city &&
    billingAddress.country === userDetails.country &&
    billingAddress.state === userDetails.state &&
    billingAddress.zip === userDetails.zip
  );
}

export default function SameAddressCheckbox<T extends BillingAddressFormValues>({
  form,
}: SameAddressCheckboxProps<T>) {
  const { t } = useTranslation('forms');
  const { userDetails } = useContext(UserContext);

  const { setValues } = form;

  const onIsAddressSameAsAccountChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const isChecked = event.currentTarget.checked;
      setValues((state) => ({
        ...state,
        billingAddress: isChecked
          ? {
              address: userDetails.address,
              address2: '',
              city: userDetails.city,
              country: userDetails.country,
              state: userDetails.state,
              zip: userDetails.zip,
            }
          : {
              address: '',
              address2: '',
              city: '',
              country: defaultCountryOption,
              state: '',
              zip: '',
            },
      }));
    },
    [setValues, userDetails]
  );

  return (
    <Checkbox
      checked={getIsAddressSameAsAccount(form.values.billingAddress, userDetails)}
      onChange={onIsAddressSameAsAccountChange}
      label={t('billingAddress.isSameAddress')}
      data-test-id={dt.payments.deposits.depositForm.sameBillingAddressCheckbox}
    />
  );
}
