import { Button, Skeleton, Stack, Text, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import AnimatedCheckmark from '~/components/payments/AnimatedCheckmark';
import { UnstyledNextLink } from '~/components/shared/UnstyledNextLink/UnstyledNextLink';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';
import { formatDollars } from '~/domains/common/utils/formatters';
import { getTextColor } from '~/domains/payments/utils/utils';
import dt from '~/testing';

interface DepositCompletedProps {
  onClose?: React.MouseEventHandler;
  depositAmount: number;
}

function DepositCompleted({ onClose, depositAmount }: DepositCompletedProps) {
  const { data: balance, isFetching } = useWalletBalance();
  const { t } = useTranslation('payments');
  const theme = useMantineTheme();

  const continueButton = (
    <Button
      onClick={onClose}
      data-test-id={dt.payments.deposits.depositCompletedModal.continueButton}
      mt={24}
      fullWidth
      size="lg"
    >
      <Text color="black">{t('continue')}</Text>
    </Button>
  );

  return (
    <Stack spacing="xl" align="center" justify="center" w="100%" h="100%">
      <AnimatedCheckmark />

      <Text
        color={getTextColor(theme)}
        size={32}
        fw={700}
        align="center"
        data-test-id={dt.payments.deposits.depositCompletedModal.successMessage}
      >
        {t('deposits.completed.fundsAdded', {
          amount: formatDollars(depositAmount),
        })}
      </Text>

      <Skeleton visible={isFetching}>
        <Text
          color={getTextColor(theme)}
          size={21}
          fw={700}
          align="center"
          data-test-id={dt.payments.deposits.depositCompletedModal.newBalance}
        >
          {t('deposits.completed.newBalance', {
            amount: formatDollars(balance?.availableBalance),
          })}
        </Text>
      </Skeleton>

      {onClose ? (
        continueButton
      ) : (
        <UnstyledNextLink href="/contest-lobby" $fullWidth>
          {continueButton}
        </UnstyledNextLink>
      )}

      <Text
        data-test-id={dt.payments.deposits.depositCompletedModal.footerNote}
        color="gray.6"
        mt={64}
        align="center"
      >
        {t('deposits.completed.footerNote')}
      </Text>
    </Stack>
  );
}

export default DepositCompleted;
