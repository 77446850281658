import type { PropsWithChildren } from 'react';
import { createContext, useMemo, useState } from 'react';

type MenuId = 'sites' | 'user' | undefined;

type HeaderMenusContextValue = {
  menuId: MenuId;
  onMenuOpen: (menuIdOrFn?: MenuId | ((prevValue: MenuId) => MenuId)) => void;
  onMenuToggle: (menuId: MenuId) => void;
};

export const HeaderMenusContext = createContext<HeaderMenusContextValue>({
  menuId: undefined,
  onMenuOpen: () => {},
  onMenuToggle: () => {},
});

export function HeaderMenusContextProvider({ children }: PropsWithChildren) {
  const [menuId, setMenuId] = useState<HeaderMenusContextValue['menuId']>(undefined);

  const value = useMemo(
    () => ({
      menuId,
      onMenuOpen: setMenuId,
      onMenuToggle: (newMenuId) =>
        setMenuId((prevMenuId) => (prevMenuId === newMenuId ? undefined : newMenuId)),
    }),
    [menuId, setMenuId]
  );

  return <HeaderMenusContext.Provider value={value}>{children}</HeaderMenusContext.Provider>;
}
