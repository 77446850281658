import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  modalHeader: {
    position: 'absolute',
    top: theme.spacing.lg,
    right: theme.spacing.lg,
  },
  modal: {
    maxWidth: 810,
    width: '100%',

    [theme.fn.smallerThan('md')]: {
      paddingBottom: `${theme.spacing.lg}px !important`,
    },
  },
  loaderContainer: {
    height: '100%',
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    transition: 'opacity 300ms ease-in-out',
  },

  contentEnter: {
    opacity: 0,
  },
  contentEnterActive: {
    opacity: 1,
    transition: 'opacity 300ms ease-in-out',
  },
  contentExitActive: {
    opacity: 0,
    transition: 'opacity 300ms ease-in-out',
  },
  contentExit: {
    opacity: 1,
  },
}));

export default useStyles;
