import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import orderBy from 'lodash.orderby';

import { safeJSONParse } from '~/common/utils/safeJSONParse.util';
import { useInfiniteScroll } from '~/hooks/useInfiniteScroll';
import { getPoolMembers } from '~/services/ryp/ryp.service.api';

type UseFetchRYPUsersByContestProps = {
  selectedItem: string;
};

const useFetchRYPUsersByContest = ({ selectedItem }: UseFetchRYPUsersByContestProps) => {
  const PAGE_SIZE = 50;

  const selectedItemInfo = safeJSONParse(selectedItem);
  const {
    data: rypUserData,
    isInitialLoading: isRYPUsersInitialLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['invites/rypContestEntrants', selectedItem],
    enabled: !!selectedItemInfo && selectedItemInfo?.id?.startsWith('ryp_'),
    queryFn: ({ pageParam = 0 }) =>
      getPoolMembers({
        offset: pageParam,
        limit: PAGE_SIZE,
        poolId: selectedItemInfo.id.slice(4),
      }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.offset + PAGE_SIZE;
      if (nextPage >= lastPage.total) {
        return undefined;
      }
      return nextPage;
    },
  });

  const { loaderRef } = useInfiniteScroll({
    fetchMore: fetchNextPage,
    hasMore: hasNextPage,
  });

  const contestUsers = useMemo(() => {
    const allUsers = rypUserData?.pages
      .map((page) =>
        page.data.map((user) => ({
          id: user.userId,
          username: user.displayName,
        }))
      )
      .flat();
    const uniqueUsers = [...new Map(allUsers?.map((user) => [user.id, user])).values()].filter(
      Boolean
    );
    const users = orderBy(uniqueUsers, 'username', 'asc');
    if (users?.length) {
      return users;
    }
    return [];
  }, [rypUserData]);

  return {
    contestUsers,
    loaderRef,
    hasNextPage,
    isLoading: isRYPUsersInitialLoading || isFetching,
  };
};
export default useFetchRYPUsersByContest;
