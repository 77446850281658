const enum TransactionType {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

// eslint-disable-next-line no-restricted-syntax
enum PaymentMethod {
  CARD = 'CARD',
  PAYPAL = 'PAYPAL',
  ONLINE_BANKING = 'ONLINE_BANKING',
  PAPER_CHECK = 'PAPER_CHECK',
  VENMO = 'VENMO',
  SKRILL = 'SKRILL',
}

// eslint-disable-next-line no-restricted-syntax
enum PaymentType {
  CARD = 'CARD',
  PAYPAL = 'PAYPAL',
  VIPPREFERRED = 'VIPPREFERRED',
  PAPER_CHECK = 'PAPER_CHECK',
  VENMO = 'VENMO',
  SKRILL = 'SKRILL',
}

const PAYMENT_TYPE_TO_METHOD = {
  [PaymentType.CARD]: PaymentMethod.CARD,
  [PaymentType.PAYPAL]: PaymentMethod.PAYPAL,
  [PaymentType.VIPPREFERRED]: PaymentMethod.ONLINE_BANKING,
  [PaymentType.VENMO]: PaymentMethod.VENMO,
  [PaymentType.PAPER_CHECK]: PaymentMethod.PAPER_CHECK,
  [PaymentType.SKRILL]: PaymentMethod.SKRILL,
} as const;

const enum FeeType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export { FeeType, PAYMENT_TYPE_TO_METHOD, PaymentMethod, PaymentType, TransactionType };
