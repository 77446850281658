import type { TMantineColors } from './types';

export const SPLASH_COLORS = [
  '#97e7f5',
  '#b2fef9',
  '#77f6ef',
  '#4bebe2',
  '#0fefe2',
  '#03cdc2',
  '#00b0a6',
  '#00938a',
  '#007972',
  '#00635d',
] as TMantineColors;

// Note: First three colors are duplicated
// to reflect mantine ten colors type
export const GRAY_COLORS = [
  '#F1F3F5',
  '#F1F3F5',
  '#F1F3F5',
  '#D1D1D1',
  '#C1C2C5',
  '#C1C2C5',
  '#909296',
  '#495057',
  '#25262B',
  '#1A1C1E',
] as TMantineColors;

export const IS_STORYBOOK = process.env.STORYBOOK_ENV === 'true';
