import React from 'react';

import useLatestAppVersion from '~/domains/services/hooks/useLatestAppVersion';

import NewAppVersionBanner from './NewAppVersionBanner';

import packageJSON from 'package.json';

function ConnectedNewAppVersionBanner() {
  const { data } = useLatestAppVersion();

  if (!data?.version || data.version === packageJSON.version) {
    return null;
  }

  return <NewAppVersionBanner />;
}

export default ConnectedNewAppVersionBanner;
