import { createStyles } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import { Text } from '~/domains/design-system/Text';

const useStyles = createStyles((theme, { backgroundColor }: { backgroundColor?: string }) => ({
  container: {
    borderRadius: '16px',
    background: backgroundColor ?? theme.black,
  },
  label: {
    display: 'block',
    padding: backgroundColor ? '1px 4px' : '0 2px',
    color: backgroundColor ?? theme.black,
    background: theme.colors.orange[4],
    borderRadius: '14px',
  },
  showEmpty: {
    width: theme.other.spacing._8,
    height: theme.other.spacing._8,
  },
}));

function NotificationBadge({
  children,
  backgroundColor,
  showEmpty = false,
}: PropsWithChildren<{ backgroundColor?: string; showEmpty?: boolean }>) {
  const { classes, cx } = useStyles({ backgroundColor });

  return (
    <div className={classes.container}>
      <Text
        variant="headline-x-small"
        className={cx(classes.label, showEmpty && classes.showEmpty)}
      >
        {children}
      </Text>
    </div>
  );
}

export default NotificationBadge;
