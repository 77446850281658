export default {
  tutorial: {
    modal: {
      action: {
        close: 'Close',
      },
    },
    next: 'Next',
    letsGo: "Let's go!",
  },
};
