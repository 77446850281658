import { useSyncExternalStore } from 'react';

import XPointSingleton from '~/utils/xpoint';

import countryCodeToName from '../constants/countryCodeToName';

function getLocationCountry() {
  const { country } = XPointSingleton.getLocationTokenData() || {};
  return countryCodeToName[country] || country;
}

function useLocationCountry() {
  return useSyncExternalStore(XPointSingleton.subscribeToLocationToken, getLocationCountry);
}

export { useLocationCountry };
