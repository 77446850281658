import { Flex, Group } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { useHomePageEnabled } from '~/common/hooks/useHomePageEnabled';
import { UserContext } from '~/components/providers/UserProvider';
import { Routes } from '~/domains/common/constants/routes';
import { useCurrentUserCommissionerChannel } from '~/domains/common/hooks/useCurrentUserCommissionerChannel';
import { useMyContestsPageEnabled } from '~/domains/common/hooks/useMyContestsPageEnabled';
import { useTreatments } from '~/domains/common/hooks/useTreatments';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

import { SubHeaderCommissionerCTA } from './SubHeaderCommissionerCTA';
import { SubHeaderLink } from './SubHeaderLink';
import { SubHeaderReferAFriendCTA } from './SubHeaderReferAFriendCTA';

export function AppShellSubheader() {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { treatments } = useTreatments([FEATURE.SHOW_PROMOTION_LINKS_IN_SUBNAV]);

  const { user } = useContext(UserContext);
  const { isHomePageEnabled } = useHomePageEnabled();
  const { isMyContestsPageEnabled } = useMyContestsPageEnabled();
  const isChannelsV1Enabled = useIsFeatureEnabled(FEATURE.ENABLE_CHANNELS_V1);

  const { data: channelData } = useCurrentUserCommissionerChannel();
  const promoLinks = useMemo(() => {
    const config = treatments[FEATURE.SHOW_PROMOTION_LINKS_IN_SUBNAV]?.config;
    return config ?? [];
  }, [treatments]);

  const menuItems = useMemo(
    () => [
      ...(isHomePageEnabled ? [{ href: Routes.home(), label: t('headerNavigation.home') }] : []),
      { href: Routes.contestLobby(), label: t('headerNavigation.contestLobby') },
      ...(user
        ? [
            {
              href: isMyContestsPageEnabled ? Routes.myContests() : Routes.myEntries(),
              label: isMyContestsPageEnabled
                ? t('headerNavigation.myContests')
                : t('headerNavigation.myEntries'),
            },
          ]
        : []),
      ...(isChannelsV1Enabled
        ? [
            {
              href: Routes.channel(channelData?.data?.handle),
              label: t('headerNavigation.channel'),
              isActiveOnSubRoutes: true,
            },
          ]
        : [
            {
              href: Routes.commissionerHub(channelData?.data?.slug),
              label: t('headerNavigation.myCommissionerChannel'),
            },
          ]),
      ...promoLinks.map(({ label, href }) => ({
        href,
        label,
        isExternal: true,
      })),
    ],
    [
      isHomePageEnabled,
      t,
      user,
      isMyContestsPageEnabled,
      isChannelsV1Enabled,
      channelData?.data?.slug,
      channelData?.data?.handle,
      promoLinks,
    ]
  );

  return (
    <Flex
      bg="dark.6"
      h={62}
      align="center"
      justify="center"
      pos="sticky"
      top={0}
      sx={(theme) => ({ zIndex: theme.other.zIndex.header })}
    >
      <Group w="100%" maw={1440} position="apart" px="lg" noWrap>
        <Group spacing={32} noWrap>
          {menuItems.map(({ label, href, isExternal, isActiveOnSubRoutes }) => (
            <SubHeaderLink
              key={href}
              href={href}
              isActive={
                href === router.pathname ||
                href === router.asPath ||
                (isActiveOnSubRoutes && router.asPath.includes(href))
              }
              isExternal={isExternal}
            >
              {label}
            </SubHeaderLink>
          ))}
        </Group>
        <Group spacing="sm" noWrap>
          <SubHeaderReferAFriendCTA />
          <SubHeaderCommissionerCTA />
        </Group>
      </Group>
    </Flex>
  );
}
