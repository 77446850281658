import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Routes } from '~/domains/common/constants/routes';

import { DeepLinkType } from './types';
import { getBranchLink } from './branch-io.service.api';
import { getQRcodeBase64 } from './nextApi.service';

export const useContestDeepLinkWebToApp = ({
  contestId,
  deepLinkType,
}: {
  contestId: string;
  deepLinkType?: DeepLinkType;
}): [string, string | undefined] | null => {
  const [appLink, setAppLink] = useState<string>(null);
  const [appLinkQR, setAppLinkQR] = useState<string>(null);

  const deepLinkTypeOverride = deepLinkType || DeepLinkType.CONTEST_DETAIL;

  // Branch.io deeplink generation
  const { data: deepLinkURL } = useQuery({
    queryKey: [`deepLink/${deepLinkTypeOverride}/url`, contestId],
    queryFn: () => getBranchLink(Routes.contestDetail(contestId), deepLinkTypeOverride),
    onSuccess: (data) => data,
  });

  const { data: deepLinkQRBase64 } = useQuery({
    queryKey: [`deepLink/${deepLinkTypeOverride}/qr-code`, contestId],
    enabled: !!deepLinkURL?.url,
    queryFn: () => getQRcodeBase64(deepLinkURL?.url),
  });

  useEffect(() => {
    if (deepLinkURL?.url) setAppLink(deepLinkURL?.url);
    if (deepLinkQRBase64) setAppLinkQR(deepLinkQRBase64);
  }, [contestId, deepLinkURL, deepLinkQRBase64]);

  return [appLink, appLinkQR];
};

export const useSignupDeepLinkWebToApp = (referralCode?: string, deepLinkType?: DeepLinkType) => {
  const [appLink, setAppLink] = useState<string>(null);

  const deepLinkTypeOverride = deepLinkType || DeepLinkType.SIGN_UP;

  // Branch.io deeplink generation
  const { data: deepLinkURL } = useQuery({
    queryKey: [`deepLink/${deepLinkTypeOverride}/url`, referralCode],
    queryFn: () => getBranchLink(Routes.signUp({ referralCode }), deepLinkTypeOverride),
    onSuccess: (data) => data,
  });

  useEffect(() => {
    if (deepLinkURL?.url) setAppLink(deepLinkURL?.url);
  }, [deepLinkURL]);

  return [appLink];
};
