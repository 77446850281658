import { useRef, useEffect } from 'react';
import lottie from 'lottie-web';

interface AnimatedCheckmarkProps {
  width?: number;
  height?: number;
}

function AnimatedCheckmark({ width = 200, height = 200 }: AnimatedCheckmarkProps) {
  const containerRef = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: {
        v: '4.8.0',
        meta: { g: 'LottieFiles AE ', a: '', k: '', d: '', tc: '' },
        fr: 60,
        ip: 0,
        op: 120,
        w: 512,
        h: 512,
        nm: 'Checkmark Lottie 2',
        ddd: 0,
        assets: [],
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Checkmark',
            parent: 2,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [-7, -16.5, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [4, -2.5],
                          [-1, 2.5],
                          [-4, -0.5],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [0.913725495338, 0.913725495338, 0.913725495338, 1],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 2, ix: 5 },
                    lc: 2,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [1497, 1497], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.167], y: [0.167] },
                      t: 9,
                      s: [100],
                    },
                    { t: 34, s: [0] },
                  ],
                  ix: 1,
                },
                e: { a: 0, k: 100, ix: 2 },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 0,
            op: 1200,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Circle',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [-7, -16, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 9,
                    s: [100, 100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 17,
                    s: [93.16, 93.16, 100],
                  },
                  {
                    i: { x: [0.486, 0.486, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 25,
                    s: [102.64, 102.64, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    t: 31.666,
                    s: [96, 96, 100],
                  },
                  { t: 37, s: [100, 100, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [269, 269], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0, 0, 0, 1], // Edit Color Here
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7, -16], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 1200,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Outer Circle',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0.167] },
                    t: 9,
                    s: [100],
                  },
                  { t: 84, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [-4, -20, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                    o: {
                      x: [0.167, 0.167, 0.167],
                      y: [0.167, 0.167, 16.667],
                    },
                    t: 9,
                    s: [0, 0, 100],
                  },
                  { t: 84, s: [100, 100, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [495, 495], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0, 0, 0, 1], // Edit Color Here
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-4, -20], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 1200,
            st: 0,
            bm: 0,
          },
        ],
        markers: [],
      },
    });
  }, []);

  return <div ref={containerRef} style={{ width, height }} />;
}

export default AnimatedCheckmark;
