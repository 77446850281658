import { useCallback, useContext } from 'react';
import type { EntriesDetails, ContestStatus } from '@betterpool/api-types/contests-service';
import { useRouter } from 'next/router';

import { UserContext } from '~/components/providers/UserProvider';
import { useSignUpModalDispatchContext } from '~/domains/common/context/SignUpModalContext';
import { getCurrentLocationForRef } from '~/domains/common/utils/getCurrentLocationForRef';
import { useIsAnonymousPicksAllowedForContest } from '~/domains/contest/hooks/useIsAnonymousPicksAllowedForContest';
import { RootModalsContext } from '~/domains/common/context/RootModalsContext/RootModalsContext';

import useGamecenterPicksPath from '../../gamecenter/domains/common/hooks/useGamecenterPicksPath';

import useEnterContestValidation from './useEnterContestValidation';

type UseContestEntryButtonProps = {
  contestId: string;
  isAfterDeadline: boolean;
  entries?: EntriesDetails;
  isAllowedInLocation?: boolean;
  status: ContestStatus;
};

const useEnterContestButton = ({
  contestId,
  isAfterDeadline,
  entries,
  isAllowedInLocation,
  status,
}: UseContestEntryButtonProps) => {
  const router = useRouter();
  const dispatch = useSignUpModalDispatchContext();
  const { openModal } = useContext(RootModalsContext);

  const { error, errorMessage, isDisabled, isLoading } = useEnterContestValidation({
    isAfterDeadline,
    entries,
    isAllowedInLocation,
    status,
  });

  const { user } = useContext(UserContext);
  const { fullContest, isAnonymousPicksEnabled, isAnonymousPicksAllowedForContest } =
    useIsAnonymousPicksAllowedForContest(contestId);

  const picksPath = useGamecenterPicksPath({
    id: contestId,
    contestType: fullContest?.data?.contest_type,
    sport: fullContest?.data?.league.sport,
    ref: getCurrentLocationForRef(),
  });

  const handleClick = useCallback(
    async (onSuccess?: (entryIDs?: string[]) => Promise<void> | void) => {
      if (!user) {
        if (isAnonymousPicksEnabled && isAnonymousPicksAllowedForContest) {
          await router.push(picksPath);
          return;
        }

        dispatch({ type: 'openSignUpModal', payload: { contestId } });

        return;
      }

      if (!isDisabled) {
        openModal({
          type: 'EnterContestModal',
          contestId,
          onSuccess,
        });
      }
    },
    [
      user,
      isDisabled,
      isAnonymousPicksEnabled,
      isAnonymousPicksAllowedForContest,
      dispatch,
      contestId,
      router,
      openModal,
      picksPath,
    ]
  );

  return {
    error,
    errorMessage,
    isDisabled,
    isLoading,
    onClick: handleClick,
  };
};

export default useEnterContestButton;

export type { UseContestEntryButtonProps };
