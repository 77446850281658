import { useContext } from 'react';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import Dropdown from '~/domains/common/components/Dropdown/Dropdown';

import HeaderDrawer from '../HeaderDrawer';
import { HeaderMenusContext } from '../../HeaderMenusContext';

import useStyles from './styles';
import SitesMenuContent from './SitesMenuContent';
import SitesMenuButton from './SitesMenuButton';

function SitesMenu() {
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const { menuId, onMenuOpen, onMenuToggle } = useContext(HeaderMenusContext);
  const isOpen = menuId === 'sites';

  if (isMobile) {
    return (
      <>
        <SitesMenuButton
          onClick={() => {
            onMenuToggle('sites');
          }}
          isOpen={isOpen}
        />
        <HeaderDrawer
          isOpen={isOpen}
          onOpen={() => {
            onMenuOpen('sites');
          }}
          transition="slide-right"
        >
          <SitesMenuContent
            onClick={() => {
              onMenuOpen();
            }}
          />
        </HeaderDrawer>
      </>
    );
  }

  return (
    <Dropdown
      dropdownPosition="bottom-start"
      dropdownClassName={classes.dropdown}
      dropdownOffset={{ x: 16 }}
      trigger={({ ref, onClick }) => <SitesMenuButton ref={ref} onClick={onClick} />}
      onChange={(opened) => {
        onMenuOpen(opened ? 'sites' : undefined);
      }}
      isOpen={isOpen}
    >
      <SitesMenuContent
        onClick={() => {
          onMenuOpen();
        }}
      />
    </Dropdown>
  );
}

export default SitesMenu;
