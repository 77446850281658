import {
  Button,
  Center,
  Input,
  NumberInput,
  Select,
  Skeleton,
  Space,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo } from 'react';
import Trans from 'next-translate/Trans';

import type { PaymentMethod } from '~/common/enums/payments.enum';
import { PAYMENT_TYPE_TO_METHOD, PaymentType, TransactionType } from '~/common/enums/payments.enum';
import { formatDollars } from '~/domains/common/utils/formatters';
import BillingAddressFormSection from '~/domains/forms/components/BillingAddressFormSection/BillingAddressFormSection';
import { RadioButton, RadioButtonGroup } from '~/domains/payments/components/RadioButton';
import { usePaymentMethods } from '~/domains/payments/hooks/usePaymentMethods';
import CardTypeIcon from '~/domains/payments/icons/CardTypeIcon';
import { PaymentType as WalletPaymentType } from '~/services/wallet/interfaces/wallets.interface';
import dt from '~/testing';
import { FEATURE } from '~/common/enums/feature.enum';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { paymentTypeOptions } from '~/domains/payments/constants';
import { ErrorAlert } from '~/domains/common/components/Alerts/Alerts';
import { Link } from '~/domains/common/components/Link/Link';

import { getIconColor, isNewCardOption } from '../../utils/utils';
import { useAvailablePaymentMethods } from '../../hooks/useAvailablePaymentMethods';

import { CardDetailsFormSection } from './CardDetailsFormSection';
import { CardSelect } from './CardSelect';
import { DepositMethods } from './DepositMethods';
import {
  DepositOption,
  NEW_CARD_OPTION,
  depositOptionToValue,
  depositOptions,
  legalStatesOptions,
} from './consts';
import useDepositForm from './useDepositForm';
import { useCountryOptions } from './useCountryOptions';

export function DepositForm({
  contestIdForPostDeposit,
  setCompletedDepositAmount,
  fallbackUrl,
}: {
  contestIdForPostDeposit?: string;
  setCompletedDepositAmount: (amount: number) => void;
  fallbackUrl?: string;
}) {
  const { t } = useTranslation('payments');
  const theme = useMantineTheme();
  const countryOptions = useCountryOptions();

  const { form, handleSubmit, cardDetailsRef, isDepositing, depositAmountWithFees } =
    useDepositForm({
      contestIdForPostDeposit,
      setCompletedDepositAmount,
      fallbackUrl,
    });
  const { values, setFieldValue } = form;
  const { cardOption, depositOption, paymentType } = values;

  const { data: paymentMethods, isLoading: isLoadingSavedCards } = usePaymentMethods({
    transactionType: TransactionType.DEPOSIT,
    paymentType: WalletPaymentType.CARD,
  });

  const cardOptions = useMemo(() => {
    const defaultOption = [
      {
        value: NEW_CARD_OPTION,
        label: t('addNewCard'),
      },
    ];

    return paymentMethods
      ? [
          ...paymentMethods.map((item) => ({
            value: item.id,
            lastDigits: item.card.lastDigits,
            expMonth: item.card.cardExpiry.month,
            expYear: item.card.cardExpiry.year,
            cardType: item.card.cardType,
            label: t('cardSelectLabel', {
              lastDigits: item.card.lastDigits,
              expMonth: item.card.cardExpiry.month,
              expYear: item.card.cardExpiry.year,
            }),
          })),
          ...defaultOption,
        ]
      : defaultOption;
  }, [paymentMethods, t]);

  // Cannot use Mantine's useDidUpdate, because this has to run onMount as well
  useEffect(() => {
    if (
      // No card selected yet
      (!cardOption ||
        // Switching payment types (de-select credit/debit card and pick a new default)
        !cardOptions.some(({ value }) => value === cardOption)) &&
      !isLoadingSavedCards
    ) {
      setFieldValue('cardOption', cardOptions[0]?.value);
    }
  }, [cardOptions, cardOption, setFieldValue, isLoadingSavedCards]);

  const { availablePaymentMethods, isLoadingAvailablePaymentMethods } = useAvailablePaymentMethods({
    transactionType: TransactionType.DEPOSIT,
  });

  const isCurrentPaymentTypeDisabled = availablePaymentMethods?.some(
    (item) =>
      item.paymentMethod === PAYMENT_TYPE_TO_METHOD[paymentType] && item.status === 'disabled'
  );

  const formattedAmountWithFees = Number.isNaN(depositAmountWithFees)
    ? ''
    : formatDollars(depositAmountWithFees);

  const currentCardType = useMemo(
    () => paymentMethods?.find((item) => item.id === cardOption)?.card.cardType,
    [cardOption, paymentMethods]
  );

  const isSelectCardVisible = paymentType === PaymentType.CARD;
  const isNewCardFormVisible = isSelectCardVisible && isNewCardOption(cardOption);
  const isSavedCardFormVisible =
    isSelectCardVisible && !!cardOption && !isNewCardOption(cardOption);
  const isSubmitButtonDisabled =
    isCurrentPaymentTypeDisabled || isLoadingAvailablePaymentMethods || !depositAmountWithFees;

  const showVIPP = useIsFeatureEnabled(FEATURE.SHOW_VIPP_AND_PAPER_CHECK);
  const showVenmo = useIsFeatureEnabled(FEATURE.ENABLE_VENMO);

  const filteredPaymentTypes = useMemo(
    () =>
      paymentTypeOptions
        .filter((option) => {
          const availablePaymentMethod = availablePaymentMethods?.find(
            (method) => method.paymentMethod === (option as unknown as PaymentMethod) // FIXME: availablePaymentMethods should be typed correctly, but that requires a larger refactor
          );

          if (!availablePaymentMethod) {
            return false;
          }

          return availablePaymentMethod.status !== 'hidden';
        })
        .filter((option) =>
          showVIPP
            ? option !== PaymentType.PAPER_CHECK
            : ![PaymentType.PAPER_CHECK, PaymentType.VIPPREFERRED].includes(option)
        )
        .filter((option) => showVenmo || option !== PaymentType.VENMO),
    [availablePaymentMethods, showVIPP, showVenmo]
  );

  // instead of setting default paymentType in initialValue (which can be disabled or hidden), we select the first available payment type
  useEffect(() => {
    if (!paymentType && filteredPaymentTypes.length > 0) {
      form.setFieldValue('paymentType', filteredPaymentTypes[0]);
    }
  }, [filteredPaymentTypes, form, paymentType]);

  if (!isLoadingAvailablePaymentMethods && filteredPaymentTypes.length === 0) {
    return (
      <ErrorAlert px="lg" mt="xs">
        <Trans
          i18nKey="payments:deposits.errors.noAvailableMethods"
          components={{
            customerSupportLink: (
              <Link
                href="mailto:support@splashsports.com?subject=Splash Support - Deposit Issues&body=Hello, Splash Support"
                target="_blank"
                style={{ color: 'inherit', textDecoration: 'underline' }}
              />
            ),
          }}
        />
      </ErrorAlert>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <DepositMethods
          availablePaymentMethods={availablePaymentMethods}
          filteredPaymentTypes={filteredPaymentTypes}
          isLoading={isLoadingAvailablePaymentMethods}
          paymentType={paymentType}
          onChange={(type) => form.setFieldValue('paymentType', type)}
        />
        <Input.Wrapper label={t('deposits.form.amount.label')}>
          <RadioButtonGroup>
            {depositOptions.map((option) => (
              <RadioButton
                key={option}
                $active={option === depositOption}
                onClick={() => form.setFieldValue('depositOption', option)}
                type="button"
                data-test-id={dt.payments.deposits.depositForm.depositOption}
              >
                <Text>
                  {option === DepositOption.CUSTOM
                    ? t('deposits.form.amount.custom')
                    : formatDollars(depositOptionToValue[option])}
                </Text>
              </RadioButton>
            ))}
          </RadioButtonGroup>
        </Input.Wrapper>

        {depositOption === DepositOption.CUSTOM && (
          <NumberInput
            {...form.getInputProps('customAmount')}
            precision={2}
            placeholder={t('deposits.form.amount.placeholder')}
            autoFocus
            hideControls
            size="lg"
            data-test-id={dt.payments.deposits.depositForm.customAmountInput}
            inputMode="decimal"
          />
        )}

        {isSelectCardVisible && (
          <Input.Wrapper label={t('deposits.form.card.label')}>
            <Skeleton visible={isLoadingSavedCards}>
              <Select
                styles={{
                  itemsWrapper: {
                    gap: '8px',
                    backgroundColor: theme.colors.gray[2],
                    padding: theme.spacing.md,
                  },
                }}
                value={cardOption}
                data={cardOptions}
                size="lg"
                data-test-id={dt.payments.deposits.depositForm.cardSelect.select}
                icon={<CardTypeIcon type={currentCardType} color={getIconColor(theme)} />}
                onChange={(value) => form.setFieldValue('cardOption', value ?? undefined)}
                withinPortal
                itemComponent={CardSelect}
              />
            </Skeleton>
          </Input.Wrapper>
        )}

        {isNewCardFormVisible && (
          <>
            <Space h="sm" />
            <CardDetailsFormSection form={form} ref={cardDetailsRef} />
            <Space h="sm" />
            <BillingAddressFormSection
              form={form}
              stateOptions={legalStatesOptions}
              countryOptions={countryOptions}
              withSameAddressCheckbox
            />
          </>
        )}

        {isSavedCardFormVisible && (
          <TextInput
            {...form.getInputProps('confirmCVV')}
            onChange={(event) => {
              form.setFieldValue('confirmCVV', event.target.value.replace(/\D+/g, '').slice(0, 4));
            }}
            placeholder={t('deposits.form.confirmCVV.placeholder')}
            data-test-id={dt.payments.deposits.depositForm.cvvInput}
            label={t('deposits.form.confirmCVV.label')}
            labelProps={{ size: 'sm' }}
            description={t('deposits.form.confirmCVV.description')}
            descriptionProps={{ size: 'md' }}
            withAsterisk
            size="lg"
          />
        )}

        <Space h="xl" />

        <Button
          size="lg"
          disabled={isSubmitButtonDisabled}
          type="submit"
          loading={isDepositing}
          data-test-id={dt.payments.deposits.depositForm.submitButton}
        >
          <Text color={isSubmitButtonDisabled ? 'gray.5' : theme.black}>
            {t('deposits.form.deposit', { amount: formattedAmountWithFees })}
          </Text>
        </Button>

        {!isSubmitButtonDisabled && (
          <Center>
            <Text
              size={14}
              color={theme.colorScheme === 'light' ? theme.black : theme.colors.gray[4]}
              data-test-id={dt.payments.deposits.depositForm.youWillBeChargedBySplash}
            >
              {paymentType === PaymentType.PAYPAL
                ? t('deposits.form.youWillBeRedirectedToPaypal')
                : formattedAmountWithFees &&
                  t('deposits.form.youWillBeChargedBySplash', {
                    amount: formattedAmountWithFees,
                  })}
            </Text>
          </Center>
        )}
      </Stack>
    </form>
  );
}
