import { Modal, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from 'react-responsive';

import { DepositView } from './DepositView';

type DepositModalProps = {
  isOpen?: boolean;
  onClose: VoidFunction;
  contestIdForPostDeposit?: string;
  fallbackUrl?: string;
};

function DepositModal({
  isOpen = true,
  onClose,
  contestIdForPostDeposit,
  fallbackUrl,
}: DepositModalProps) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  return (
    <Modal
      onClose={onClose}
      opened={isOpen}
      size="xl"
      fullScreen={isMobile}
      zIndex={theme.other.zIndex.dialogModal}
    >
      <DepositView
        contestIdForPostDeposit={contestIdForPostDeposit}
        onContinue={onClose}
        onLeave={onClose}
        fallbackUrl={fallbackUrl}
      />
    </Modal>
  );
}

export default DepositModal;
