import type { MantineTheme } from '@mantine/core';

import {
  DepositOption,
  NEW_CARD_OPTION,
  depositOptionToValue,
} from '../deposits/components/consts';

function getAmount(depositOption: DepositOption, customAmount: number | undefined) {
  switch (depositOption) {
    case DepositOption.USD_25:
    case DepositOption.USD_50:
    case DepositOption.USD_100:
    case DepositOption.USD_200:
      return depositOptionToValue[depositOption];
    case DepositOption.CUSTOM:
    default:
      return customAmount;
  }
}

const getIconColor = (theme: MantineTheme) =>
  theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[6];

const getTextColor = (theme: MantineTheme) =>
  theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.black;

const isNewCardOption = (cardOption: string) => cardOption === NEW_CARD_OPTION;

export { getAmount, getIconColor, getTextColor, isNewCardOption };
