import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { UserContext } from '~/components/providers/UserProvider';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { getCommissionerChannelDataByUser } from '~/services/commissioner/commissioner-service.service.api';

import { isLoadingFixedForDisabledQuery } from '../utils/isLoadingFixedForEnabled';

export function useCurrentUserCommissionerChannel() {
  const { user } = useContext(UserContext);
  const showCommissionerCTA = useIsFeatureEnabled(FEATURE.SHOW_COMMISSIONER_ONBOARDING_CTA);
  const { data, error, isLoading, fetchStatus } = useQuery({
    enabled: showCommissionerCTA && Boolean(user),
    queryKey: ['commissioner/channel'],
    queryFn: () => getCommissionerChannelDataByUser(),
  });

  return {
    data,
    error,
    isLoading: isLoadingFixedForDisabledQuery(isLoading, fetchStatus),
    showCommissionerCTA,
  };
}
