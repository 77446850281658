import { Button, Center, Stack, Text, useMantineTheme } from '@mantine/core';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { LocationContext } from '~/components/providers/LocationProvider';
import { UnstyledNextLink } from '~/components/shared/UnstyledNextLink/UnstyledNextLink';
import { ErrorAlert, WarningAlert } from '~/domains/common/components/Alerts/Alerts';
import {
  isGeoLocationDenied,
  isGeoLocationPrompted,
  isLocationDenied,
} from '~/domains/common/components/VerificationBanner/utils';
import { Routes } from '~/domains/common/constants/routes';
import { BalanceBox } from '~/domains/payments/components/BalanceBox';
import { useKYCStatus } from '~/domains/payments/hooks/useKYCStatus';
import { getTextColor } from '~/domains/payments/utils/utils';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { useAppDownloadPrompt, PromptLocation } from '~/hooks/useAppDownloadPrompt';
import { KYCVerificationStatus } from '~/services/kyc/kyc.interface';
import dt from '~/testing';

import DepositCompleted from './DepositCompleted';
import { DepositForm } from './DepositForm';

type DepositViewProps = {
  contestIdForPostDeposit?: string;
  onContinue?: VoidFunction;
  onLeave?: VoidFunction;
  fallbackUrl?: string;
};

function DepositView({
  contestIdForPostDeposit,
  onContinue,
  onLeave,
  fallbackUrl,
}: DepositViewProps) {
  const isDepositEnabled = useIsFeatureEnabled(
    FEATURE.ENABLE_CONTEST_DETAILS_JOIN_AND_DEPOSIT_BUTTONS
  );
  const { locationStatus, geoPermissionStatus } = useContext(LocationContext);
  const { data: kycStatus, isFetched: isKycStatusFetched } = useKYCStatus();
  const router = useRouter();
  const redirectTo = router.query.redirectTo as string;

  const handleShowAppDownloadPrompt = useAppDownloadPrompt(PromptLocation.DEPOSIT);

  const theme = useMantineTheme();
  const { t } = useTranslation('payments');

  const [completedDepositAmount, setCompletedDepositAmount] = useState<number>(null);

  const handleSetCompletedDepositAmount = (amount: number) => {
    window.scrollTo(0, 0);
    setCompletedDepositAmount(amount);
  };

  const handleOnContinue = () => {
    if (!handleShowAppDownloadPrompt({ redirectTo })) {
      if (redirectTo) {
        router.push(redirectTo);
        return;
      }
      onContinue?.();
    }
  };

  if (completedDepositAmount !== null) {
    return <DepositCompleted depositAmount={completedDepositAmount} onClose={handleOnContinue} />;
  }

  return (
    <Stack data-test-id={dt.payments.deposits.depositView}>
      <Center>
        <Text
          fw={700}
          size={32}
          color={getTextColor(theme)}
          data-test-id={dt.payments.deposits.depositViewTitle}
        >
          {t('deposits.form.title')}
        </Text>
      </Center>

      <BalanceBox variant="availableBalance" />

      {(() => {
        if (!isDepositEnabled) {
          return <ErrorAlert>{t('deposits.errors.blockedByFeatureFlag')}</ErrorAlert>;
        }

        if (isGeoLocationPrompted(geoPermissionStatus)) {
          return null;
        }

        if (isGeoLocationDenied(geoPermissionStatus)) {
          return (
            <WarningAlert>
              <Trans
                i18nKey="payments:deposits.errors.locationServicesDisabled"
                components={{
                  actionRequired: (
                    <Text fw={600} span>
                      {t('deposits.errors.actionRequired')}
                    </Text>
                  ),
                  locationServices: (
                    <Link
                      style={{
                        color: theme.black,
                        display: 'inline-block',
                      }}
                      href="https://knowledge.splashsports.com/blog/location-verification"
                      target="_blank"
                    >
                      <Text>{t('deposits.errors.links.locationServices')}</Text>
                    </Link>
                  ),
                  customerSupportLink: (
                    <Link
                      style={{
                        color: theme.black,
                        display: 'inline-block',
                      }}
                      href="mailto:support@splashsports.com?subject=Splash Support - Deposit Issues&body=Hello, Splash Support"
                      target="_blank"
                    >
                      <Text>{t('deposits.errors.links.customerSupport')}</Text>
                    </Link>
                  ),
                  eligibleLocation: (
                    <Link
                      style={{
                        color: theme.black,
                        display: 'inline-block',
                      }}
                      href="https://legal.splashsports.com/map/splash-fantasy-contest-availability"
                      target="_blank"
                    >
                      <Text>{t('deposits.errors.links.eligibleLocation')}</Text>
                    </Link>
                  ),
                }}
              />
            </WarningAlert>
          );
        }

        if (isLocationDenied(locationStatus)) {
          return (
            <ErrorAlert>
              <Trans
                i18nKey="payments:deposits.errors.blockedByLocation"
                components={{
                  pleaseNote: (
                    <Text fw={600} span>
                      {t('deposits.errors.pleaseNote')}
                    </Text>
                  ),
                  eligibleLocation: (
                    <Link
                      style={{
                        color: theme.colors.gray[6],
                        display: 'inline-block',
                      }}
                      href="https://legal.splashsports.com/map/splash-fantasy-contest-availability"
                      target="_blank"
                    >
                      <Text color="gray.6">{t('deposits.errors.links.eligibleLocation')}</Text>
                    </Link>
                  ),
                  customerSupportLink: (
                    <Link
                      style={{
                        color: theme.colors.gray[6],
                        display: 'inline-block',
                      }}
                      href="mailto:support@splashsports.com?subject=Splash Support - Deposit Issues&body=Hello, Splash Support"
                      target="_blank"
                    >
                      <Text color="gray.6">{t('deposits.errors.links.customerSupport')}</Text>
                    </Link>
                  ),
                }}
              />
            </ErrorAlert>
          );
        }

        if (isKycStatusFetched && kycStatus !== KYCVerificationStatus.ACCEPT) {
          return (
            <Stack>
              <WarningAlert>{t('deposits.warnings.missingKYC')}</WarningAlert>
              <Center>
                <UnstyledNextLink href={Routes.userVerification({ redirectTo })} onClick={onLeave}>
                  <Button>{t('continue')}</Button>
                </UnstyledNextLink>
              </Center>
            </Stack>
          );
        }

        return (
          <DepositForm
            contestIdForPostDeposit={contestIdForPostDeposit}
            setCompletedDepositAmount={handleSetCompletedDepositAmount}
            fallbackUrl={fallbackUrl}
          />
        );
      })()}
    </Stack>
  );
}

export { DepositView };
