import { forwardRef } from 'react';
import type { MantineNumberSize, TextProps as MantineTextProps } from '@mantine/core';
import { Text as MantineText, createPolymorphicComponent, createStyles } from '@mantine/core';

import { titleStyles } from '../Title/Title';

/** @deprecated use `domains/design-system/Text` instead */
export const textStyles = {
  ...titleStyles,
  'web-body-xl': {
    fontSize: '24px',
    lineHeight: '1.3333333333333333',
    fontWeight: 400,
  },
  'web-body-large': {
    fontSize: '16px',
    lineHeight: '1.25',
    fontWeight: 400,
  },
  'web-body-medium': {
    fontSize: '14px',
    lineHeight: '1.5714285714285714',
    fontWeight: 400,
  },
  'web-body-small': {
    fontSize: '12px',
    lineHeight: '1.6666666666666667',
    fontWeight: 400,
  },
  'web-label-large': {
    fontSize: '16px',
    lineHeight: '1.5',
    fontWeight: 900,
  },
  'web-label-medium': {
    fontSize: '14px',
    lineHeight: '1.5714285714285714',
    fontWeight: 900,
  },
  'web-label-small': {
    fontSize: '12px',
    lineHeight: '1.6666666666666667',
    fontWeight: 700,
  },
  'web-label-x-small': {
    fontSize: '8px',
    fontWeight: 700,
    lineHeight: '10px',
  },
} as const;

export type TextProps = {
  tag?: 'div' | 'span' | 'p' | 'small' | 'strong' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  variant?:
    | keyof typeof textStyles
    | {
        base?: keyof typeof textStyles;
        sm?: keyof typeof textStyles;
        md?: keyof typeof textStyles;
        lg?: keyof typeof textStyles;
      };
} & Omit<MantineTextProps, 'tag' | 'variant'>;

const useTextStyles = createStyles((theme, { variant }: TextProps) => ({
  text: {
    ...(() => {
      if (typeof variant === 'string') {
        return textStyles[variant];
      }

      return Object.entries(variant).reduce((acc, [key, value]) => {
        if (key === 'base') {
          return { ...acc, ...textStyles[value] };
        }

        return {
          ...acc,
          [theme.fn.largerThan(key as MantineNumberSize)]: {
            ...textStyles[value],
          },
        };
      }, {});
    })(),
  },
}));

const TextFactory = forwardRef<HTMLDivElement, TextProps>(
  ({ tag = 'span', variant = 'web-body-medium', className, ...props }, ref) => {
    const { cx, classes } = useTextStyles({ variant });

    return (
      <MantineText component={tag} ref={ref} className={cx(classes.text, className)} {...props} />
    );
  }
);
TextFactory.displayName = 'Text';

/** @deprecated use `domains/design-system/Text` instead */
export const Text = createPolymorphicComponent<'span', TextProps>(TextFactory);
