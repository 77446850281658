import { SlateLabelEnum, type Contest as APIContest } from '@betterpool/api-types/contests-service';

import pickPropertiesFromObject from '~/domains/common/utils/pickPropertiesFromObject';

import type { Contest } from '../interfaces/contest.interface';

function parseContest(response: APIContest): Contest {
  return {
    ...pickPropertiesFromObject(response, [
      'id',
      'contest_template_id',
      'name',
      'commissioner',
      'entries',
      'tags',
      'prize_pool',
      'status',
      'unlisted',
      'beginnersOnly',
      'paid_out_at',
      'is_after_first_late_swap_deadline',
      'created_by_admin',
      'resizing_mode',
      'league',
      'banner_url',
      'thumbnail_url',
      'is_allowed_in_location',
      'entry_fee',
    ]),
    start_date: new Date(response.start_date),
    end_date: new Date(response.end_date),
    settings: {
      ...response.settings,
      slateLabel: response.settings?.slateLabel ?? SlateLabelEnum.SLATE,
    },
  };
}

export default parseContest;
