import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { STALE_TIME } from '~/domains/contest/constants/query';
import { getPools } from '~/services/ryp/ryp.service.api';

type UseFetchRYPPoolsProps = {
  hasUserLinkedRYP: boolean;
};

const PAGE_SIZE = 50;
const useFetchRYPPools = ({ hasUserLinkedRYP }: UseFetchRYPPoolsProps) => {
  const {
    data: rypUserData,
    isInitialLoading: isRYPPoolsInitialLoading,
    isFetching,
  } = useQuery({
    queryKey: ['commissioner/rypContests'],
    enabled: hasUserLinkedRYP,
    queryFn: () =>
      getPools({
        offset: 0,
        limit: PAGE_SIZE,
      }),
    staleTime: STALE_TIME,
  });

  const pools = useMemo(
    () =>
      rypUserData?.data
        .filter((pool) => pool.memberCount > 1)
        .sort((a, b) => new Date(b.startAt).getTime() - new Date(a.startAt).getTime())
        .map((pool) => ({
          created_by_admin: false,
          commissioner: {
            id: null,
            username: null,
          },
          entries: {
            filled: pool.memberCount,
            max: 0,
            user: 0,
            max_per_user: 0,
          },
          id: `ryp_${pool.poolId}`,
          name: pool.name,
          thumbnail_url: 'https://www.runyourpool.com/new/static/media/ryp-logo.e720c018.png',
        })),
    [rypUserData]
  );

  return {
    pools,
    isLoading: isRYPPoolsInitialLoading || isFetching,
  };
};
export default useFetchRYPPools;
