import NextLink from 'next/link';
import styled from '@emotion/styled';

type UnstyledNextLinkProps = {
  $fullWidth?: boolean;
  $colorInherit?: boolean;
  $isHover?: boolean;
};

export const UnstyledNextLink = styled(NextLink)<UnstyledNextLinkProps>`
  text-decoration: none;
  cursor: pointer;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  ${({ $colorInherit }) => ($colorInherit ? 'color: inherit;' : '')};
  ${({ $isHover }) => ($isHover ? ` &:hover { text-decoration: underline;}` : '')};
`;
