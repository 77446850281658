import useTranslation from 'next-translate/useTranslation';
import { useMantineTheme } from '@mantine/core';

import { CurrencyConvertor } from '~/components/utils/formatters';

import type { ContestCardActionBanner } from '../types';

import ContestCardFooterMeta from './ContestCardFooterMeta';

type UserContestMetaSurvivorProps = {
  isSettled: boolean;
  winning: number;
  entryPrice: number;
  totalEntries: number;
  winningEntries: number;
  actionBanner: ContestCardActionBanner;
  numberOfUserEntries: number;
  userAliveEntries: number;
};

function UserContestMetaSurvivor({
  entryPrice,
  isSettled,
  totalEntries,
  winning,
  winningEntries,
  actionBanner,
  userAliveEntries,
  numberOfUserEntries,
}: UserContestMetaSurvivorProps) {
  const theme = useMantineTheme();
  const { t } = useTranslation('contest');

  const winningColor = winning > 0 ? theme.colors.green[5] : 'inherit';

  const meta = [
    {
      value: `${userAliveEntries} / ${numberOfUserEntries}`,
      label: t('common.contestCard.footer.mySurvivors'),
      span: 3,
    },
    {
      value: CurrencyConvertor(entryPrice / 100),
      label: t('common.contestCard.footer.entry'),
      span: 3,
    },
    {
      value: isSettled ? CurrencyConvertor(winning / 100) : `${winningEntries} / ${totalEntries}`,
      label: isSettled
        ? t('common.contestCard.footer.won')
        : t('common.contestCard.footer.survivors'),
      valueColor: isSettled ? winningColor : 'inherit',
      span: 6,
      textAlign: 'right',
    },
  ];

  return <ContestCardFooterMeta actionBanner={actionBanner} meta={meta} />;
}

export default UserContestMetaSurvivor;
