import { Drawer, createStyles } from '@mantine/core';
import type { PropsWithChildren } from 'react';

const useStyles = createStyles((theme) => ({
  drawerRoot: {
    zIndex: theme.other.zIndex.headerDrawer,
  },
  drawerHeader: {
    display: 'none',
  },
  drawerDrawer: {
    top: 56,
    backgroundColor: theme.white,
    overflow: 'auto',
    maxHeight: 'auto',
    height: 'auto',
    bottom: 0,
  },
}));

type HeaderDrawerProps = PropsWithChildren<{
  isOpen: boolean;
  onOpen: (v: boolean) => void;
  transition?: 'slide-left' | 'slide-right';
}>;

function HeaderDrawer({ children, isOpen, onOpen, transition = 'slide-left' }: HeaderDrawerProps) {
  const { classes } = useStyles();

  return (
    <Drawer
      opened={isOpen}
      position="top"
      size="full"
      onClose={() => {
        onOpen(false);
      }}
      withOverlay={false}
      transition={transition}
      classNames={{
        root: classes.drawerRoot,
        header: classes.drawerHeader,
        drawer: classes.drawerDrawer,
      }}
    >
      {children}
    </Drawer>
  );
}

export default HeaderDrawer;
