import { createStyles } from '@mantine/core';

const displayAsText = (errorCode) => ['number', 'string'].includes(typeof errorCode);
const useStyles = createStyles(
  (
    theme,
    {
      isCompact,
      isComponentLevelError,
      errorCode,
    }: {
      isCompact?: boolean;
      isComponentLevelError?: boolean;
      errorCode?: string | number | JSX.Element;
    } = {}
  ) => ({
    root: {
      flexGrow: 1,
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      ...(() => {
        if (isCompact) {
          return {
            paddingTop: '0px',
            paddingBottom: '80px',
          };
        }

        if (isComponentLevelError) {
          return {};
        }

        return {
          paddingTop: '80px',
          paddingBottom: '120px',
        };
      })(),
    },

    label: {
      textAlign: 'center',
      fontWeight: 900,
      ...(displayAsText(errorCode) ? { fontSize: '220px' } : { fontSize: '40px' }),
      lineHeight: 1,
      marginBottom: theme.spacing.xl,
      color: theme.colors.gray[3],
      marginTop: 0,
      ...(isComponentLevelError
        ? {
            margin: 0,
            fontSize: '120px',
          }
        : {}),

      [theme.fn.smallerThan('sm')]: {
        fontSize: '120px',
        ...(isCompact
          ? {
              marginTop: theme.spacing.xl,
            }
          : {}),
      },
    },

    title: {
      color: theme.colors.gray[6],
      textAlign: 'center',
      fontSize: theme.fontSizes.md * 2,
      fontWeight: 900,
      ...(isComponentLevelError
        ? {
            margin: 0,
            fontSize: theme.fontSizes.xl,
          }
        : {}),

      [theme.fn.smallerThan('sm')]: {
        fontSize: '32px',
      },
    },

    description: {
      color: theme.colors.gray[6],
      maxWidth: '740px',
      margin: 'auto',
      marginTop: theme.spacing.xs,
      marginBottom: theme.spacing.xl * 2,
      ...(isComponentLevelError
        ? {
            marginBottom: theme.spacing.xl,
          }
        : {}),
    },
  })
);

export default useStyles;
