import type {
  PostResponsibleGamingLimitsPayload,
  PostResponsibleGamingTimeoutPayload,
} from './interfaces/responsible-gaming.interface';
import type {
  IGetSettingsAvailablePaymentMethodsResponse,
  IGetSettingsDisabledPaymentMethodsResponse,
  IGetSettingsFeesResponse,
  IGetSettingsTransactionLimitsResponse,
} from './interfaces/settings.interface';
import type {
  IGetWalletsBalance,
  IGetWalletsPaymentHandles,
  IPostWalletsDepositsPayload,
  IPostWalletsDepositsResponse,
  IPostWalletsPaymentsHandlesPaypalPayload,
  IPostWalletsPaymentsHandlesPaypalResponse,
} from './interfaces/wallets.interface';
import { walletServiceInstance } from './wallet.service.instance';

export const getWalletBalance = async () => {
  const response = await walletServiceInstance.get<IGetWalletsBalance>('/wallets/balance');
  return response.data;
};

export const withdrawFromWallet = async (payload: any) => {
  const response = await walletServiceInstance.post('/wallets/withdrawals', payload);
  return response.data;
};

export const depositToWallet = async (payload: IPostWalletsDepositsPayload) => {
  const response = await walletServiceInstance.post<IPostWalletsDepositsResponse>(
    '/wallets/deposits',
    payload
  );
  return response.data;
};

export const createPayPalHandle = async (payload: IPostWalletsPaymentsHandlesPaypalPayload) => {
  const response = await walletServiceInstance.post<IPostWalletsPaymentsHandlesPaypalResponse>(
    '/wallets/payment-handles/paypal',
    payload
  );
  return response.data;
};

export const createSkrillHandle = async (payload: IPostWalletsPaymentsHandlesPaypalPayload) => {
  const response = await walletServiceInstance.post<IPostWalletsPaymentsHandlesPaypalResponse>(
    '/wallets/payment-handles/skrill',
    payload
  );
  return response.data;
};

export const createVenmoHandle = async (payload: IPostWalletsPaymentsHandlesPaypalPayload) => {
  const response = await walletServiceInstance.post<IPostWalletsPaymentsHandlesPaypalResponse>(
    '/wallets/payment-handles/venmo',
    payload
  );
  return response.data;
};

export const getPaymentsMethods = async () => {
  const response =
    await walletServiceInstance.get<IGetWalletsPaymentHandles>('wallets/payment-handles');
  return response.data;
};

export const getTransactionHistory = async (
  page: number,
  from?: Date,
  to?: Date,
  pageSize = 10
) => {
  let queryParams = `page=${page}&pageSize=${pageSize}`;
  if (from) {
    queryParams += `&from=${from.toISOString()}`;
  }
  if (to) {
    queryParams += `&to=${to.toISOString()}`;
  }
  const response = await walletServiceInstance.get(`ledger/transactions?${queryParams}`);
  return response.data;
};

export const getResponsibleGamingLimits = async () => {
  const response = await walletServiceInstance.get(`limits`);
  return response.data;
};

export const postResponsibleGamingLimits = async (payload: PostResponsibleGamingLimitsPayload) => {
  const response = await walletServiceInstance.post(`limits`, payload);
  return response.data;
};

export const postResponsibleGamingTimeout = async (
  payload: PostResponsibleGamingTimeoutPayload
) => {
  const response = await walletServiceInstance.post(`timeouts`, payload);
  return response.data.data;
};

export const getTransactionLimits = async () => {
  const response = await walletServiceInstance.get<IGetSettingsTransactionLimitsResponse>(
    'settings/transaction-limits'
  );
  return response.data.data;
};

export const getProcessingFees = async () => {
  const response = await walletServiceInstance.get<IGetSettingsFeesResponse>('settings/fees');
  return response.data.data;
};

/** @deprecated - use getAvailablePaymentMethods instead */
export const getDisabledPaymentMethods = async () => {
  const response = await walletServiceInstance.get<IGetSettingsDisabledPaymentMethodsResponse>(
    'settings/disabled-payment-methods'
  );
  return response.data.data;
};

export const getAvailablePaymentMethodsDeposit = async () => {
  const response = await walletServiceInstance.get<IGetSettingsAvailablePaymentMethodsResponse>(
    'settings/available-payment-methods/deposit'
  );
  return response.data.data;
};

export const getAvailablePaymentMethodsWithdrawal = async () => {
  const response = await walletServiceInstance.get<IGetSettingsAvailablePaymentMethodsResponse>(
    'settings/available-payment-methods/withdrawal'
  );
  return response.data.data;
};

export const deleteSavedCard = async (cardId: string) => {
  const response = await walletServiceInstance.delete(`/wallets/cards/${cardId}`);
  return response.data;
};
