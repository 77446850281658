import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { v4 as uuidv4 } from 'uuid';

import { TransactionType } from '~/common/enums/payments.enum';
import { logError } from '~/common/utils/log';
import { SplitFullNameToFirstLast } from '~/components/utils/formatters';
import { reportEvent } from '~/domains/analytics';
import { usePaymentMethods } from '~/domains/payments/hooks/usePaymentMethods';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';
import type { PaysafeInstance } from '~/domains/payments/types/paysafe';
import { PaymentType } from '~/services/wallet/interfaces/wallets.interface';
import { depositToWallet } from '~/services/wallet/wallet.service.api';

import { isCustomDeposit } from './consts';

const enum PaysafeErrorCodes {
  'E5281' = '5281', // https://developer.paysafe.com/en/api-docs/cards/test-and-go-live/card-errors/#common-errors
  'E3007' = '3007', // https://developer.paysafe.com/en/api-docs/cards/test-and-go-live/card-errors/#authorization-errors
  'E9125' = '9125', // https://developer.paysafe.com/en/api-docs/client-side-sdks/paysafejs-powered-by-cards-api/tokenize/#list-of-tokenize-callback-errors
}

type PaysafeError = {
  code: PaysafeErrorCodes;
  detailedMessage: string;
  displayMessage: string;
};

const isPaysafeError = (error: unknown): error is PaysafeError => {
  const paysafeError = error as PaysafeError;
  return (
    'code' in paysafeError && 'detailedMessage' in paysafeError && 'displayMessage' in paysafeError
  );
};

function useDepositWithNewCard({
  setCompletedDepositAmount,
}: {
  setCompletedDepositAmount?: (amount: number) => void;
}) {
  const { t } = useTranslation('payments');
  const { refetch: refetchWalletBalance } = useWalletBalance();
  const { refetch: refetchPaymentMethods } = usePaymentMethods({
    transactionType: TransactionType.DEPOSIT,
    paymentType: PaymentType.CARD,
  });

  return useMutation({
    mutationFn: async ({
      paysafeInstance,
      depositAmountWithFees,
      merchantRefNum = uuidv4(),
      cardholderName,
      billingDetails,
      email,
    }: {
      paysafeInstance: PaysafeInstance;
      depositAmount: number;
      depositAmountWithFees: number;
      merchantRefNum?: string;
      cardholderName: string;
      billingDetails: {
        address: string;
        city: string;
        state: string;
        country: string;
        zip: string;
      };
      email: string;
    }) => {
      const { firstName, lastName } = SplitFullNameToFirstLast(cardholderName);
      const { token: paymentHandleToken } = await paysafeInstance.tokenize({
        amount: depositAmountWithFees * 100, // Paysafe requires amount in cents
        merchantRefNum,
        transactionType: 'PAYMENT',
        paymentType: PaymentType.CARD,
        openAs: 'NEW_TAB',
        customerDetails: {
          holderName: cardholderName,
          billingDetails,
          profile: {
            firstName,
            lastName,
            email,
          },
        },
      });
      return depositToWallet({
        amount: depositAmountWithFees,
        merchantRefNum,
        paymentHandleToken,
        currency: 'USD',
        savePaymentMethod: true,
      });
    },
    onSuccess: (response, payload) => {
      if (response.data.status === 'COMPLETED') {
        reportEvent('Transactions > Deposit', {
          deposit_id: response.id,
          deposit_method: 'Card (new)',
          deposit_amount: payload.depositAmount,
          is_custom_deposit: isCustomDeposit(payload.depositAmount),
          currency: 'USD',
        });
        refetchWalletBalance();
        refetchPaymentMethods();
        setCompletedDepositAmount?.(payload.depositAmount);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error Missing types for error response in Swagger
        logError(response.error);
        showNotification({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error Missing types for error response in Swagger
          title: response.error,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error Missing types for error response in Swagger
          message: response?.data?.message || response.message,
          color: 'red',
          autoClose: 5000,
        });
      }
    },
    onError: (error) => {
      logError(error);

      // Paysafe error
      if (isPaysafeError(error)) {
        if ([PaysafeErrorCodes.E5281, PaysafeErrorCodes.E9125].includes(error.code)) {
          showNotification({
            title: t('deposits.errors.paysafe.cardTypeNotSupportedTitle'),
            message: t('deposits.errors.paysafe.cardTypeNotSupportedMessage'),
            color: 'red',
            autoClose: 5000,
          });
          return;
        }

        if (error.code === PaysafeErrorCodes.E3007) {
          showNotification({
            title: t('deposits.errors.paysafe.incorrectAddressTitle'),
            message: t('deposits.errors.paysafe.incorrectAddressMessage'),
            color: 'red',
            autoClose: 5000,
          });
          return;
        }
        showNotification({
          title: t('deposits.errors.paysafe.generalTitle'),
          message: t('deposits.errors.paysafe.generalMessage'),
          color: 'red',
          autoClose: 5000,
        });
        return;
      }

      // Axios error
      if (error instanceof AxiosError) {
        const errorMessage = {
          /** @ts-expect-error @todo EDGE-5999 - error is not typed */
          title: t(`errors.${error.code}.title`),
          /** @ts-expect-error @todo EDGE-5999 - error is not typed */
          message: t(`errors.${error.code}.message`),
        };

        if (error.code === 'ERR_BAD_REQUEST') {
          errorMessage.title = t('deposits.errors.newCard');
          errorMessage.message = error.response?.data?.message ?? '';
        }

        showNotification({
          title: errorMessage.title,
          message: errorMessage.message,
          color: 'red',
          autoClose: 5000,
        });
      }
    },
  });
}

export { useDepositWithNewCard };
