// A wrapper for Bugsnag notify
// - Checks if Bugsnag is enabled for logging
// - Initializes Bugsnag
// - Populates user from session if found
import Bugsnag from '@bugsnag/js';

import { getStoredUser } from '~/domains/analytics';

interface Props {
  errorMessage?: string;
  context?: string;
  severity?: 'info' | 'warning' | 'error';
  details?: object;
  errorDetails?: object;
}

const bugSnagLog = ({
  errorMessage,
  context = 'unknown',
  severity = 'info',
  details,
  errorDetails,
}: Props) => {
  const user = getStoredUser();

  if (process.env.NEXT_PUBLIC_BUGSNAG_ENABLED === 'true') {
    Bugsnag.notify(new Error(`${errorMessage}`), (event) => {
      event.severity = severity;
      event.context = context;
      event.setUser(user?.id, user?.email, user?.username);
      if (details) {
        event.addMetadata('details', details);
      }
      if (errorDetails) {
        event.addMetadata('error', errorDetails);
      }
    });
  }
};

export { bugSnagLog };
