import type { CSSProperties } from 'react';

import type { PaymentType } from '~/services/wallet/interfaces/wallets.interface';

type PaysafeError = {
  code: string;
  dispayMessage: string;
  detailedMessage: string;
  correlationId: string;
};

const enum PaysafeFieldName {
  CARD_NUMBER = 'cardNumber',
  EXPIRY_DATE = 'expiryDate',
  CVV = 'cvv',
}

const enum PaysafeFieldId {
  CARD_NUMBER = 'paysafe_cardNumber',
  EXPIRY_DATE = 'paysafe_expiryDate',
  CVV = 'paysafe_cvv',
}

const PAYSAFE_FIELD_ID_TO_NAME = {
  [PaysafeFieldId.CARD_NUMBER]: PaysafeFieldName.CARD_NUMBER,
  [PaysafeFieldId.EXPIRY_DATE]: PaysafeFieldName.EXPIRY_DATE,
  [PaysafeFieldId.CVV]: PaysafeFieldName.CVV,
} as const;

type PaysafeFieldOptions = {
  selector: string;
  placeholder: string;
  iframeTitle?: string;
  accessibilityLabel?: string;
  accessibilityErrorMessage?: string;
  optional?: boolean;
  masked?: boolean;
  separator?: string; // only expiryDate
};

type PaysafeEvent = {
  type: string;
  target: {
    fieldName: PaysafeFieldName;
    containerElement: HTMLElement;
  };
  data: {
    isEmpty: boolean;
    cardBrand: string;
    declineRate: number;
    salvageFlow: boolean;
  };
};

type PaysafeEventListener = (instance: PaysafeInstance, event: PaysafeEvent) => void;

type PaysafeInstance = {
  show: () => Promise<{
    card?: { error?: PaysafeError };
  }>;
  fields: {
    (fieldName: PaysafeFieldName): {
      on: (events: string, listener: PaysafeEventListener) => void;
    };
  } & Record<
    PaysafeFieldName,
    {
      isValid: () => boolean;
    }
  >;
  tokenize: (options: PaysafeTokenizeOptions) => Promise<{
    token?: string;
  }>;
};

type PaysafeTokenizeOptions = {
  amount: number; // Paysafe requires amount in cents
  merchantRefNum: string;
  transactionType: 'PAYMENT';
  paymentType: PaymentType;
  openAs: 'NEW_TAB' | 'IFRAME';
  customerDetails: {
    holderName: string;
    billingDetails: {
      address: string;
      city: string;
      state: string;
      country: string;
      zip: string;
    };
    profile: {
      firstName: string;
      lastName: string;
      email: string;
    };
  };
};

type CamelToKebab<T extends string, A extends string = ''> = T extends `${infer F}${infer R}`
  ? CamelToKebab<R, `${A}${F extends Lowercase<F> ? '' : '-'}${Lowercase<F>}`>
  : A;

type CamelToKebabKeys<T> = {
  [K in keyof T as K extends string ? CamelToKebab<K> : K]: T[K];
};

type PaysafeWindowObject = {
  fields: {
    setup: (
      apiKey: string,
      options: {
        accounts: {
          default: number;
        };
        currencyCode: string; // 'USD'
        environment: string; // 'TEST' | 'LIVE'
        fields: Record<PaysafeFieldName, PaysafeFieldOptions>;
        style: Record<'input' | 'input::placeholder', CamelToKebabKeys<CSSProperties>>;
      }
    ) => Promise<PaysafeInstance>;
  };
};

export { type PaysafeWindowObject, type PaysafeInstance, PaysafeFieldId, PAYSAFE_FIELD_ID_TO_NAME };
