export default {
  faqs: 'FAQs',
  allowedJurisdictions: {
    label: 'Where can I play Splash?',
    panelContent: {
      primary: 'Splash is live in the following states:',
      secondary: `If you are outside of these jurisdictions, you will be able to log in,
      but won't be able to deposit or join contests.`,
    },
  },
  editAccountDetails: {
    label: 'How can I edit my account details?',
    panelContent: {
      primary: `Account details like name and email address cannot be updated after
      signup. If you need assistance with your account details please reach
      out to our Customer Support team.`,
    },
  },
  personalInformation: {
    label: 'Why do you need my personal information like address and birthday?',
    panelContent: {
      primary: `Before you enter a contest, you will need to go through the Know Your
      Customer (KYC) process where we will collect information from you like
      your address and birthdate. This process helps us to protect your
      identity and confirm that you are able to participate in our contests
      legally.`,
    },
  },
  requestIdentification: {
    label: 'Why are you requesting that I upload identification?',
    panelContent: {
      primary: `In some cases, we require upload of your government issued
        identification in order to verify your identity.`,
    },
  },
  optOutPersonalInformation: {
    label: 'How can I opt out of the sale of my personal Information?',
    panelContent: {
      primary: `Splash Sports provides Users with the ability to opt out of having their
        personal information shared with third parties for advertising,
        analytics, and certain other non-required purposes. Splash Sports users
        can email`,
      secondary: 'to opt out of such sharing practices.',
    },
  },
  privacyPolicy: {
    label: 'Splash Sports Privacy Policy',
    panelContent: {
      primary: 'A link to our privacy policy can be found',
      secondary: 'here',
    },
  },
  depositOptions: {
    label: 'What deposit options are there?',
    panelContent: {
      primary: `We support deposits from Visa Debit and Credit Cards, Mastercard Debit
        and Credit Cards, and Discover Debit and Credit Cards`,
    },
  },
  howToDeposit: {
    label: 'How do I deposit?',
    panelContent: {
      primary: `To deposit money into your account, click on your display name in the
        top right corner, then select Deposit. Choose the amount you would like
        to withdraw, then enter you credit or debit card information and
        submit.`,
    },
  },
  depositHistory: {
    label: 'Can I see a history of my deposits?',
    panelContent: {
      primary: `To see your entire transaction history, including deposits, withdrawals,
        entries, and payouts, you click on your display name in the top right
        corner then select My Splash Account. Once on the account page, open the
        transaction history menu.`,
    },
  },
  depositLimit: {
    label: 'What is a deposit limit?',
    panelContent: {
      primary: `A deposit limit is a limit you can put on how much you are able to
        deposit and/or play on Splash on a weekly or monthly basis. You can set
        a limit on the amount you deposit, the number of contests you enter, or
        the entry fee on contests you enter.`,
    },
  },
  setDepositLimit: {
    label: 'How do I set a deposit limit?',
    panelContent: {
      primary: `To set a deposit limit, you click on your display name in the top right
      corner then select My Splash Account. Open the Responsible Gaming menu
      then select which type of limit you would like to set and enter the
      timeframe and amount to set the limit.`,
    },
  },
  changeDepositLimit: {
    label: `Can I change my deposit limit after I've set it?`,
    panelContent: {
      primary: `You are not able to change your deposit limits. 90 days after you have
      set your deposit limit, it will be automatically removed from your
      account.`,
    },
  },
  howToWithdraw: {
    label: 'How do I withdraw?',
    panelContent: {
      primary: `You can withdraw via the Withdraw button on the Account Page. We
      currently support withdrawals to Debit Cards. In order to withdraw to a
      debit card, you must first make a successful deposit with that debit
      card.`,
    },
  },
  withdrawalStates: {
    label: 'Can I withdraw in any state?',
    panelContent: {
      primary: `Yes, you may withdraw funds in any location, including jurisdictions we
        do not operate or offer contests in.`,
    },
  },
  withdrawalTimeframe: {
    label: 'How long does a withdrawal take?',
    panelContent: {
      primary: `You can expect funds to be in your account within 3-7 business days of
        withdrawing your money.`,
    },
  },
  withdrawalOptions: {
    label: 'What withdrawal options are there?',
    panelContent: {
      primary: `You are able to withdraw via a debit card. You can
        only withdraw to a payment method you have used to deposit with. We are
        unable to process withdrawals to credit cards.`,
    },
  },
  withdrawalHistory: {
    label: 'Can I see a history of my withdrawals?',
    panelContent: {
      primary: `To see your entire transaction history, including deposits, withdrawals,
        entries, and payouts, you click on your display name in the top right
        corner then select My Splash Account. Once on the account page, open the
        transaction history menu.`,
    },
  },
  joinContest: {
    label: 'How do I join a contest?',
    panelContent: {
      primary: `To join a contest, go to the Contest Lobby to see a list of contests
      accepting entries. The entry fee is the Join Now button.`,
    },
  },
  findContestDetails: {
    label: 'Where can I find contest details?',
    panelContent: {
      primary: `Contest details for contests you are participating in can be found by
      going to the contest, then clicking the information icon in the bottom
      right corner. This will bring up contest rules and payout details.`,
    },
  },
  seeAllContests: {
    label: 'How do I see all of the contests I have entered?',
    panelContent: {
      primary: `To see all of the contests you have entered, navigate to the My Entries
      tab. From there you can look at In Progress, Upcoming, and Settled
      Contests.`,
    },
  },
  contestsHistory: {
    label: 'Can I see a history of all of the contests I have entered?',
    panelContent: {
      primary: `To see all of the contests you have entered, navigate to the My Entries
      tab. From there you can look at In Progress, Upcoming, and Settled
      Contests. You can also view entry history in the transaction section of
      My Splash Account.`,
    },
  },
  contestResizing: {
    label: 'How does contest resizing work?',
    panelContent: {
      resizing: `Resizing allows your private Splash Sports contest to run for a prize
      even if it isn't 100% filled. Opting in to this feature is available
      at contest creation.`,
      resizingCriteria: `CONTEST RESIZING CRITERIA`,
      resizingConditions: 'A contest can be automatically resized if:',
      resizingCondition1: '1) It has between 2 and 1000 entries;',
      resizingCondition2: `2) the number of entries is greater than the number of prize spots;
      and`,
      resizingCondition3: `3) the commissioner selects the resizing option at contest creation.`,
      contestNotFilled: `Should any of these conditions not be met and the contest does not
      fill (to 100%), it will run for free and all entrants will be refunded
      their entry fee.`,
      contestNote: `Note: Public contests will NOT be resized.`,
      resizedContestStructure: `RESIZED CONTEST PRIZE STRUCTURE`,
      contestNotFilledExplanation: `If your private contest has not filled by the entry deadline and is
      automatically resized, it will maintain the initial prize structure if
      possible. Otherwise, the next closest prize payout structure will be
      chosen from the table below:`,
      tableHeader1: 'Original Prize Structure',
      tableHeader2: 'Number of Entries When Contest Locks',
      tableHeader3: 'Resized Contest Payout',
      originalPrizeStructure1: 'Winner takes all',
      originalPrizeStructure2: 'Top X win',
      numOfEntriesLock1: '2 or more',
      numOfEntriesLock2: 'x or fewer',
      numOfEntriesLock3: 'x+1 or more',
      resizedContestPayout1: 'Winner takes all',
      resizedContestPayout2: 'Contest runs for free',
      resizedContestPayout3: 'Top X win',
    },
  },
  contestPayoutAmounts: {
    label: 'How can I see payout amounts for contests I am in?',
    panelContent: {
      primary: `Contest details for contests you are participating in can be found by
      going to the contest, then clicking the information icon in the bottom
      right corner. This will bring up contest rules and payout details.`,
    },
  },
  contestPayoutProcessing: {
    label: 'How quickly will contest payouts be processed?',
    panelContent: {
      primary: `When you win money in a contest, that money will be in your Splash
      wallet within 48 hours of the contest ending.`,
    },
  },
  closeSplashAccount: {
    label: 'How do I close my Splash account?',
    panelContent: {
      primary: `To request to close your Splash account, please reach out to
      support@splashsports.com.`,
    },
  },
  deleteSplashAccount: {
    label: 'How do I delete my Splash account and data?',
    panelContent: {
      primary: `To request to delete your Splash account, please reach out to
      support@splashsports.com.`,
    },
  },
};
