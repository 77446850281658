import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';

import type { APIUserEntry, UserEntry } from '../interfaces/user-entry.interface';

function parseEntriesForContest(response: PaginatedData<APIUserEntry>): PaginatedData<UserEntry> {
  const { data: entries, offset, total } = response;

  const mappedEntries =
    !entries || !Array.isArray(entries)
      ? []
      : entries.reduce<UserEntry[]>(
          (acc, entry, index) => [
            ...acc,
            {
              ...entry,
              entryNumber: total - index - offset,
            },
          ],
          []
        );

  return Object.assign(response, { data: mappedEntries });
}

export default parseEntriesForContest;
