/* eslint-disable */
// @ts-nocheck
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { me } from '~/services/users/users.service.api';
import { HmacSHA256 } from 'crypto-js';

function IntercomLoggedIn() {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    me().then((response) => setUserData(response.data));
  }, []);

  if (!userData || !userData?.email || !process.env.NEXT_PUBLIC_INTERCOM_KEY) {
    return null;
  }

  const user_hash = HmacSHA256(userData?.email, process.env.NEXT_PUBLIC_INTERCOM_KEY).toString();

  return (
    <>
      <Script id="intercomSettings">
        {
          (window.intercomSettings = {
            api_base: 'https://api-iam.intercom.io',
            app_id: 'r6ozvojt',
            email: userData.email,
            user_hash,
          })
        }
      </Script>

      <Script id="initIntercom">
        {(function () {
          const w = window;
          const ic = w.Intercom;
          if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
          } else {
            const d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            const l = function () {
              const s = d.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://widget.intercom.io/widget/r6ozvojt';
              const x = d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
              l();
            } else if (w.attachEvent) {
              w.attachEvent('onload', l);
            } else {
              w.addEventListener('load', l, false);
            }
          }
        })()}
      </Script>
    </>
  );
}

export default IntercomLoggedIn;
