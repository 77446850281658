import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  ul: {
    all: 'unset',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.xl,
    gap: theme.spacing.sm,
  },
  li: {
    all: 'unset',
    backgroundColor: 'transparent !important',
  },
  link: {
    display: 'block',
    color: theme.colors.dark[8],
    borderRadius: theme.radius.sm,
    padding: '0 4px',
    margin: '0 -4px',
  },
  divider: {
    borderColor: theme.colors.gray[2],
  },
}));
