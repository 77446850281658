/* eslint-disable react-hooks/rules-of-hooks */
import { useContext, useMemo } from 'react';
import { useTreatments as useBaseTreatments, SplitContext } from '@splitsoftware/splitio-react';

import type { FEATURE } from '~/common/enums/feature.enum';
import { safeJSONParse } from '~/common/utils/safeJSONParse.util';

type TreatmentWithConfig<C = any> = {
  treatment: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: C;
};

export const useTreatments = <F extends FEATURE, FC extends Record<F, any>>(
  featureFlagNames: F[],
  attributes?: SplitIO.Attributes,
  key?: SplitIO.SplitKey
): {
  isReady: boolean;
  treatments: {
    [key in F]: TreatmentWithConfig<FC[key]> | null;
  };
} => {
  if (process.env.STORYBOOK) {
    return {
      isReady: true,
      treatments: featureFlagNames.reduce(
        (acc, featureFlagName) => {
          acc[featureFlagName] = {
            treatment: 'on',
            config: null,
          };

          return acc;
        },
        {} as Record<F, TreatmentWithConfig>
      ),
    };
  }

  const splitContext = useContext(SplitContext);
  const treatments = useBaseTreatments(featureFlagNames, attributes, key);
  const treatmentsWithParsedConfigs = useMemo(
    () =>
      Object.entries(treatments || {}).reduce(
        (acc, [featureFlagName, value]) => {
          if (!value) {
            return acc;
          }

          const { config, treatment } = value;
          acc[featureFlagName] = {
            treatment,
            ...(config && { config: safeJSONParse(config) }),
          };

          return acc;
        },
        {} as Record<F, TreatmentWithConfig>
      ),
    [treatments]
  );

  return {
    isReady: splitContext.isReady,
    treatments: treatmentsWithParsedConfigs,
  };
};
