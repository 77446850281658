import { useContext } from 'react';
import { MantineProvider } from '@mantine/core';

import { UserContext } from '~/components/providers/UserProvider';
import { KYCContext } from '~/components/providers/KYCProvider';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';

import { useCurrentUserCommissionerChannel } from '../../hooks/useCurrentUserCommissionerChannel';

import AppShellHeader from './AppShellHeader';

function ConnectedAppShellHeader() {
  const { user } = useContext(UserContext);
  const { data: channelData } = useCurrentUserCommissionerChannel();
  const { KYCStatus } = useContext(KYCContext);
  const { data } = useWalletBalance();

  return (
    <MantineProvider inherit theme={{ colorScheme: 'light', globalStyles: undefined }}>
      <AppShellHeader
        user={user}
        channelHandle={channelData?.data?.handle}
        channelSlug={channelData?.data?.slug}
        avatarUrl={channelData?.data?.avatarUrl}
        isUserVerified={KYCStatus === 'accept'}
        balance={data?.availableBalance}
      />
    </MantineProvider>
  );
}

export default ConnectedAppShellHeader;
