export const over18UnderLegalAgeStates = {
  MA: {
    age: 21,
    state: 'Massachusetts',
  },
  IA: {
    age: 21,
    state: 'Iowa',
  },
  AZ: {
    age: 21,
    state: 'Arizona',
  },
  LA: {
    age: 21,
    state: 'Louisiana',
  },
  AL: {
    age: 19,
    state: 'Alabama',
  },
};

export const specialStates = Object.keys(over18UnderLegalAgeStates);

export const hideBannerRoutes = [
  '/sign-in',
  '/sign-up',
  '/user-verification',
  '/user-verification/accept',
  '/user-verification/reject',
  '/user-verification/refer',
  '/user-verification/resubmit',
  '/user-verification/review',
  '/timeout',
  '/beta_success',
  '/account/confirm-password',
  '/account/forgot-password',
];
