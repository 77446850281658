import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { logError } from '~/common/utils/log';
import { UserContext } from '~/components/providers/UserProvider';
import { reportEvent } from '~/domains/analytics';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';
import { objectToTuples } from '~/services/utils';
import type { VIPPNativeQueryParams } from '~/services/wallet/interfaces/wallets.interface';
import { PaymentType } from '~/services/wallet/interfaces/wallets.interface';
import { depositToWallet } from '~/services/wallet/wallet.service.api';

import { isCustomDeposit } from './consts';

const apiKey = process.env.NEXT_PUBLIC_PAYSAFE_API_KEY;
const environment = process.env.NEXT_PUBLIC_PAYSAFE_ENVIRONMENT;
const accountId = parseInt(process.env.NEXT_PUBLIC_PAYSAFE_VIPP_ACCOUNT, 10);

const enum PaysafeErrorCodes {
  'E5281' = '5281',
}

type PaysafeError = {
  code: PaysafeErrorCodes;
  detailedMessage: string;
  displayMessage: string;
};

const isPaysafeError = (error: unknown): error is PaysafeError => {
  if (!error) {
    return false;
  }

  const paysafeError = error as PaysafeError;
  return (
    'code' in paysafeError && 'detailedMessage' in paysafeError && 'displayMessage' in paysafeError
  );
};

export function useDepositWithVIPP() {
  const { t } = useTranslation('payments');
  const { refetch: refetchWalletBalance } = useWalletBalance();
  const { userDetails, user } = useContext(UserContext);
  const merchantRefNum = uuidv4();

  return useMutation({
    mutationFn: async ({
      depositAmountWithFees,
      setCompletedDepositAmount,
      query,
    }: {
      depositAmountWithFees?: number;
      setCompletedDepositAmount?: (amount: number) => void;
      query?: VIPPNativeQueryParams;
    }) => {
      const isUsingQuery = query && Object.entries(query).length > 0;
      const dateOfBirth = new Date(userDetails?.dateOfBirth);
      if (!window.paysafe) {
        throw new Error('Paysafe not loaded yet');
      }

      const params = isUsingQuery
        ? {
            amountInCents: Number(query?.amountInCents),
            firstName: query?.firstName,
            lastName: query?.lastName,
            email: query?.email,
            phone: query?.phone,
            dobDay: Number(query?.dobDay),
            dobMonth: Number(query?.dobMonth),
            dobYear: Number(query?.dobYear),
            address: query?.address,
            city: query?.city,
            zip: query?.zip,
            state: query?.state.toUpperCase(),
            country: query?.country,
          }
        : {
            amountInCents: depositAmountWithFees * 100,
            firstName: userDetails?.firstName,
            lastName: userDetails?.lastName,
            email: user?.email,
            phone: userDetails?.mobileNumber,
            dobDay: dateOfBirth?.getUTCDate(),
            dobMonth: dateOfBirth.getUTCMonth() + 1,
            dobYear: dateOfBirth?.getUTCFullYear(),
            address: userDetails?.address,
            city: userDetails?.city,
            zip: userDetails?.zip,
            state: userDetails?.state.toUpperCase(),
            country: userDetails?.country,
          };

      const {
        amountInCents,
        firstName,
        lastName,
        email,
        phone,
        dobDay,
        dobMonth,
        dobYear,
        address,
        city,
        zip,
        state,
        country,
      } = params;

      const values = {
        paymentType: PaymentType.VIPPREFERRED,
        currency: 'USD',
        amount: amountInCents,
        payout: false,
        payoutConfig: {
          maximumAmount: 100000000,
        },
        locale: 'en_US',
        simulator: 'EXTERNAL',
        environment,
        customer: {
          firstName,
          lastName,
          email,
          phone,
          dateOfBirth: {
            day: dobDay,
            month: dobMonth,
            year: dobYear,
          },
        },
        billingAddress: {
          street: address,
          city,
          zip,
          country,
          state,
        },
        merchantRefNum,
        canEditAmount: false,
        paymentMethodDetails: {
          vippreferred: {
            accountId,
            registrationId: userDetails?.vippRegistrationId,
          },
        },
        merchantDescriptor: {
          dynamicDescriptor: 'Splashsports',
          phone: '1234567890',
        },
        displayPaymentMethods: ['vippreferred'],
        threeDs: {
          merchantUrl: window.location.href,
          messageCategory: 'PAYMENT',
          authenticationPurpose: 'PAYMENT_TRANSACTION',
          deviceChannel: 'BROWSER',
        },
      };

      return new Promise<void>((resolve, reject) => {
        (window as any).paysafe.checkout.setup(
          apiKey,
          values,
          (instance, _, result) => {
            const amount = (result?.amount ?? 0) / 100;
            instance.showSuccessScreen();
            if (isUsingQuery) {
              const nativeAppQuery = new URLSearchParams(
                objectToTuples({
                  paymentHandleToken: result.paymentHandleToken,
                  merchantRefNum,
                })
              ).toString();

              window.location.href = `${process.env.NEXT_PUBLIC_NATIVE_APP_VIPP}://onlinebanking?${nativeAppQuery}`;
              return;
            }

            depositToWallet({
              amount,
              merchantRefNum,
              paymentHandleToken: result.paymentHandleToken,
              currency: 'USD',
              paymentType: PaymentType.VIPPREFERRED,
            })
              .then(() => {
                refetchWalletBalance();
                setCompletedDepositAmount?.(amount);
                instance.close();
                reportEvent('Transactions > Deposit', {
                  deposit_id: result.id,
                  deposit_method: 'VIPP',
                  deposit_amount: amount,
                  is_custom_deposit: isCustomDeposit(amount),
                  currency: 'USD',
                });
              })
              .then(() => resolve())
              .catch((error) => {
                instance.close();
                logError(error);
                // Paysafe error
                if (isPaysafeError(error)) {
                  if (error.code === PaysafeErrorCodes.E5281) {
                    if (isUsingQuery) {
                      const nativeAppQuery = new URLSearchParams(
                        objectToTuples({
                          error: t('deposits.errors.paysafe.cardTypeNotSupportedMessage'),
                        })
                      ).toString();

                      window.location.href = `${process.env.NEXT_PUBLIC_NATIVE_APP_VIPP}://onlinebanking?${nativeAppQuery}`;
                      return;
                    }
                    showNotification({
                      title: t('deposits.errors.paysafe.cardTypeNotSupportedTitle'),
                      message: t('deposits.errors.paysafe.cardTypeNotSupportedMessage'),
                      color: 'red',
                      autoClose: 5000,
                    });
                  } else {
                    if (isUsingQuery) {
                      const nativeAppQuery = new URLSearchParams(
                        objectToTuples({
                          error: t('deposits.errors.paysafe.generalMessage'),
                        })
                      ).toString();

                      window.location.href = `${process.env.NEXT_PUBLIC_NATIVE_APP_VIPP}://onlinebanking?${nativeAppQuery}`;
                      return;
                    }
                    showNotification({
                      title: t('deposits.errors.paysafe.generalTitle'),
                      message: t('deposits.errors.paysafe.generalMessage'),
                      color: 'red',
                      autoClose: 5000,
                    });
                  }
                }
                // Axios error
                else if (error instanceof AxiosError) {
                  const errorMessage = {
                    /** @ts-expect-error @todo EDGE-5999 - error is not typed */
                    title: t(`errors.${error.code}.title`),
                    /** @ts-expect-error @todo EDGE-5999 - error is not typed */
                    message: t(`errors.${error.code}.message`),
                  };

                  if (error.code === 'ERR_BAD_REQUEST') {
                    errorMessage.title = t('deposits.errors.vipp');
                    errorMessage.message = error.response?.data?.message ?? '';
                  }

                  if (isUsingQuery) {
                    const nativeAppQuery = new URLSearchParams(
                      objectToTuples({ error: errorMessage.message })
                    ).toString();

                    window.location.href = `${process.env.NEXT_PUBLIC_NATIVE_APP_VIPP}://onlinebanking?${nativeAppQuery}`;
                    return;
                  }
                  showNotification({
                    title: errorMessage.title,
                    message: errorMessage.message,
                    color: 'red',
                    autoClose: 5000,
                  });
                }

                reject(error);
              });
          },
          () => {
            if (isUsingQuery) {
              const nativeAppQuery = new URLSearchParams(
                objectToTuples({ error: 'User closed VIPP Modal' })
              ).toString();
              window.location.href = `${process.env.NEXT_PUBLIC_NATIVE_APP_VIPP}://onlinebanking?${nativeAppQuery}`;
              return;
            }
            reject();
          }
        );
      });
    },
    retry: 1,
    retryDelay: 500,
  });
}
