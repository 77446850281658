// eslint-disable-next-line @typescript-eslint/no-var-requires
const { pathToRegexp } = require('path-to-regexp');

export const isRoutePublic = (pathname: string) => {
  try {
    for (const pattern of PUBLIC_ROUTES) {
      if (pathToRegexp(pattern).exec(pathname)) {
        return true;
      }
    }
  } catch {
    return false;
  }

  return false;
};
