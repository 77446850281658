import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { createStyles } from '@mantine/core';

import type { ButtonProps } from '~/domains/design-system/Button';
import { Button } from '~/domains/design-system/Button';
import dt from '~/testing';

import { useContest } from '../../../hooks/useContest';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.other.spacing._4,
  },
}));

type InviteCTAProps = Omit<ButtonProps, 'variant' | 'size' | 'children'> & {
  contestId?: string;
  onClick?: VoidFunction;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  isContestLoaded?: boolean;
  style?: React.CSSProperties;
  leftIcon?: React.ReactNode;
};

export function InviteCTA({
  contestId = '',
  onClick,
  variant = 'secondary-stroke',
  size = 'medium',
  isContestLoaded,
  style,
  leftIcon,
  className,
  ...buttonProps
}: InviteCTAProps) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation('common');
  const { data: fetchedContest, isLoading } = useContest(contestId, !isContestLoaded);

  return (
    <Button
      data-test-id={dt.contestDetail.page.header.actions.invite}
      disabled={!fetchedContest && !isContestLoaded && isLoading}
      onClick={onClick}
      variant={variant}
      size={size}
      style={style}
      className={cx(classes.container, className)}
      {...buttonProps}
    >
      {leftIcon}
      {t('contest.invite.cta')}
    </Button>
  );
}
