import { Alert, Text, useMantineTheme, type AlertProps } from '@mantine/core';

// TODO([EDGE-12763]): move to design-system
export function ErrorAlert({ children, ...props }: AlertProps) {
  const theme = useMantineTheme();
  return (
    <Alert
      {...props}
      bg={theme.colorScheme === 'light' ? theme.colors.red[7] : theme.colors.red[4]}
    >
      <Text color={theme.colorScheme === 'light' ? theme.colors.gray[0] : theme.colors.dark[7]}>
        {children}
      </Text>
    </Alert>
  );
}

// TODO([EDGE-12763]): move to design-system
export function WarningAlert({ children, ...props }: AlertProps) {
  const theme = useMantineTheme();
  return (
    <Alert
      {...props}
      bg={theme.colorScheme === 'dark' ? theme.colors.yellow[8] : theme.colors.yellow[3]}
    >
      <Text color={theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[7]}>
        {children}
      </Text>
    </Alert>
  );
}
