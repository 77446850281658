import { Anchor } from '@mantine/core';
import Link from 'next/link';

import dt from '~/testing';

type SubHeaderLinkProps = {
  href: string;
  isActive?: boolean;
  isExternal?: boolean;
  children: React.ReactNode;
};

export function SubHeaderLink({ href, isActive, isExternal, children }: SubHeaderLinkProps) {
  return (
    <Anchor
      component={isExternal ? 'a' : Link}
      href={href}
      target={isExternal ? '_blank' : undefined}
      data-test-id={dt.common.components.appShellSubheader.subHeaderLink}
      sx={(theme) => ({
        borderRadius: 4,
        padding: '0 4px',
        margin: '0 -4px',
        color: isActive ? theme.fn.primaryColor() : theme.colors.dark[0],
        '&:hover': {
          color: theme.white,
          textDecoration: 'none',
        },
      })}
    >
      {children}
    </Anchor>
  );
}
