import useLeaderboardWidgetStyles from './styles';

function LeaderboardWidgetProgressBar({
  value,
  progressBarHeight = 4,
  isSurvivor,
  isSettled,
}: {
  isSurvivor: boolean;
  isSettled: boolean;
  progressBarHeight: number;
  value: number;
}) {
  const { classes } = useLeaderboardWidgetStyles({ isSurvivor, progressBarHeight });

  return (
    <div className={classes.progressBar}>
      {!(isSettled && isSurvivor) && value > 0 ? (
        <div className={classes.progressBarValue} style={{ width: `${value}%` }} />
      ) : null}
    </div>
  );
}

export default LeaderboardWidgetProgressBar;
