/* eslint-disable no-console */
import { useRouter } from 'next/router';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';

import { Routes } from '~/domains/common/constants/routes';
import { PrevLocationContext } from '~/domains/common/context/PrevLocationContext';

type QuickPickSlipDetailPrevPathContextValue = {
  prevLocationBeforeGamecenter: string;
};

const QuickPickSlipDetailPrevPathContext = createContext<QuickPickSlipDetailPrevPathContextValue>({
  prevLocationBeforeGamecenter: Routes.myContests(),
});

export function QuickPickSlipDetailPrevPathContextProvider({ children }: PropsWithChildren) {
  const { slipId } = useRouter().query;
  const { prevAsPath } = useContext(PrevLocationContext);
  const [prevLocationBeforeGamecenter, setPrevLocationBeforeGamecenter] = useState<string | null>(
    null
  );
  const prevRoute = prevLocationBeforeGamecenter ?? Routes.quickPicksActive();

  useEffect(() => {
    if (!prevAsPath || prevAsPath?.startsWith(`/quick-picks/slip/${slipId}/`)) {
      return;
    }
    if (
      [Routes.signIn(), Routes.signUp(), Routes.userVerification()].some((path) =>
        prevAsPath.startsWith(path)
      )
    ) {
      return;
    }

    setPrevLocationBeforeGamecenter(prevAsPath);
  }, [prevAsPath, slipId]);

  const value = useMemo(
    () =>
      ({
        prevLocationBeforeGamecenter: prevRoute,
      }) satisfies QuickPickSlipDetailPrevPathContextValue,
    [prevRoute]
  );

  return (
    <QuickPickSlipDetailPrevPathContext.Provider value={value}>
      {children}
    </QuickPickSlipDetailPrevPathContext.Provider>
  );
}

export default QuickPickSlipDetailPrevPathContext;
