import { useQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';

import { TransactionType } from '~/common/enums/payments.enum';
import { UserContext } from '~/components/providers/UserProvider';
import type { PaymentType } from '~/services/wallet/interfaces/wallets.interface';
import { CardCategory } from '~/services/wallet/interfaces/wallets.interface';
import { getPaymentsMethods } from '~/services/wallet/wallet.service.api';

import { PAYMENTS_QUERY_STALE_TIME } from './consts';

type UsePaymentMethodsArgs = {
  transactionType: TransactionType;
  paymentType: PaymentType;
};

export function usePaymentMethods({ transactionType, paymentType }: UsePaymentMethodsArgs) {
  const { user } = useContext(UserContext);

  const { data, ...query } = useQuery({
    queryKey: ['payments/paymentMethods', user?.id],
    queryFn: getPaymentsMethods,
    staleTime: PAYMENTS_QUERY_STALE_TIME,
  });

  const paymentMethods = useMemo(() => {
    if (!data) return null;

    const { paymentHandles } = data.data;

    const filtered = paymentHandles.filter((paymentHandle) => {
      if (paymentHandle.paymentType !== paymentType) {
        return false;
      }

      // Withdrawals to credit cards are not enabled
      if (
        transactionType === TransactionType.WITHDRAWAL &&
        paymentHandle.card.cardCategory === CardCategory.CREDIT
      ) {
        return false;
      }

      return true;
    });

    return filtered;
  }, [data, paymentType, transactionType]);

  return {
    ...query,
    data: paymentMethods,
  };
}
