type PartialCommissioner = {
  handle?: string;
  username?: string;
};

/**
 * Get the user's handle, if it exists, otherwise use the username. This is needed because the user's handle is not always available. Especially in the commissioner object.
 */
export function getHandle(commissioner: PartialCommissioner): string {
  return commissioner?.handle || commissioner?.username;
}
