import { Box, ScrollArea, Skeleton, Stack, Text } from '@mantine/core';
import type { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import useTranslation from 'next-translate/useTranslation';
import { IconSearch } from '@tabler/icons-react';

import repeat from '~/common/utils/repeat';
import { safeJSONParse } from '~/common/utils/safeJSONParse.util';

import type { EntrantUser } from '../../services/interfaces/entrant.interface';

import InviteViaEmail from './InviteViaEmail';
import { SECTION_HEIGHT, MOBILE_SECTION_HEIGHT } from './consts';
import UserList from './shared/UserList';

type InviteModalMiddleProps = {
  selectedItem: string;
  setEmailInvites?: Dispatch<SetStateAction<string[]>>;
  splashInvites: string[];
  setSplashInvites: Dispatch<SetStateAction<string[]>>;
  rypInvites: string[];
  setRYPInvites: Dispatch<SetStateAction<string[]>>;
  rypPools: string[];
  setRYPPools: Dispatch<SetStateAction<string[]>>;
  contests: string[];
  setContests: Dispatch<SetStateAction<string[]>>;
  users?: EntrantUser[];
  isLoading?: boolean;
  loaderRef: MutableRefObject<undefined>;
};

function InviteModalMiddle({
  selectedItem,
  setEmailInvites,
  splashInvites,
  setSplashInvites,
  rypInvites,
  setRYPInvites,
  rypPools,
  setRYPPools,
  contests,
  setContests,
  users,
  isLoading,
  loaderRef,
}: InviteModalMiddleProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });

  if (selectedItem === 'email') {
    return <InviteViaEmail setEmailInvites={setEmailInvites} />;
  }

  const showEmptyState = users === undefined || users?.length === 0;

  const selectedItemInfo = safeJSONParse(selectedItem);

  const isRYPSelected = selectedItemInfo?.id?.startsWith('ryp_');

  return (
    <Box
      h={isTablet ? MOBILE_SECTION_HEIGHT : SECTION_HEIGHT}
      w={isTablet ? '100%' : '60%'}
      sx={{ minWidth: '275px' }}
    >
      {users?.length > 0 ? (
        <ScrollArea
          h="100%"
          styles={showEmptyState ? { root: { '& div': { height: '100%' } } } : {}}
        >
          <UserList
            options={users as EntrantUser[]}
            optionsSelected={isRYPSelected ? rypInvites : splashInvites}
            setOptionsSelected={isRYPSelected ? setRYPInvites : setSplashInvites}
            contestsSelected={isRYPSelected ? rypPools : contests}
            setContestsSelected={isRYPSelected ? setRYPPools : setContests}
            selectedItem={selectedItem}
          />
          <Box ref={loaderRef} sx={{ height: isLoading ? '20px' : '1px' }} />
        </ScrollArea>
      ) : (
        <Stack h="100%" align="center" justify="center">
          {isLoading ? (
            <Stack h="100%" w="100%">
              {repeat(5, (i) => (
                <Skeleton key={i} height={30} width="100%" />
              ))}
            </Stack>
          ) : (
            <>
              <IconSearch />
              <Text w="70%" size={14} align="center">
                {t('invites.modal.usersEmptyState')}
              </Text>
            </>
          )}
        </Stack>
      )}
    </Box>
  );
}

export default InviteModalMiddle;
