import type { PropsWithChildren } from 'react';
import { MantineProvider } from '@mantine/core';

import { RootModalsContextProvider as BaseRootModalsContextProvider } from './RootModalsContext';
import RootModals from './RootModals';

function RootModalsContextProvider({ children }: PropsWithChildren) {
  return (
    <BaseRootModalsContextProvider>
      {children}
      <MantineProvider inherit theme={{ colorScheme: 'light', globalStyles: undefined }}>
        <RootModals />
      </MantineProvider>
    </BaseRootModalsContextProvider>
  );
}

export default RootModalsContextProvider;
