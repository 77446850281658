import axios from 'axios';

import RequestHeadersBase from '~/common/constants/RequestHeadersBase';

import { handleRefreshInterceptor } from '../interceptors/handle-refresh.interceptor';
import { setAuthTokenInterceptor } from '../interceptors/set-auth-token.interceptor';

export const notificationsService = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_SPLASH}notification-service/api`,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthTokenInterceptor(notificationsService);
handleRefreshInterceptor(notificationsService);
