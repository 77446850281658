import { Tooltip as MantineTooltip, createStyles } from '@mantine/core';
import type { ReactNode } from 'react';
import { Text } from '~/domains/design-system/Text';

type TooltipProps = {
  isRendered?: boolean;
  labels?: {
    label: string | ReactNode;
    condition?: boolean;
  }[];
  fontSize?: number;
} & Partial<React.ComponentProps<typeof MantineTooltip>>;

const useStyles = createStyles((theme, { fontSize }: { fontSize: number }) => ({
  tooltip: {
    border: `1px solid ${theme.colors.gray2[1]}`,
    color: theme.black,
    backgroundColor: theme.white,
    fontSize: fontSize || 'inherit',
  },
  arrow: {
    border: `1px solid ${theme.colors.gray2[0]}`,
  },
}));

export function Tooltip({ isRendered = true, labels, fontSize, events, ...rest }: TooltipProps) {
  const { children } = rest;
  const { classes } = useStyles({ fontSize });

  if (!isRendered) return <div className={rest.className}>{children}</div>;

  const pickLabel = labels?.find(({ condition, label }) => {
    if (typeof condition === 'undefined') return label;
    return condition;
  })?.label;

  const defaultEvents = {
    hover: true,
    focus: true,
    touch: true,
  };

  return (
    <MantineTooltip
      events={events || defaultEvents}
      label={pickLabel}
      classNames={classes}
      {...rest}
    >
      {children}
    </MantineTooltip>
  );
}
