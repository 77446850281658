export default {
  contestLeaders: {
    title: {
      scheduled: 'Entrants',
      other: 'Leaderboard',
    },
  },
  common: {
    makePicks: 'Make picks',
    slateStatusBadge: {
      scheduled: 'Upcoming',
      live: 'Live',
      settled: 'Settled',
      finished: 'Final',
    },
    entries: {
      title: 'My entries {{status}}',
      active: 'Active ({{ count }})',
      eliminated: 'Eliminated ({{ count }})',
      showMyEntries: 'Show my entries',
      entry: {
        title: 'Entry {{count}}',
        option: {
          edit: 'Edit picks',
          make: 'Make picks',
        },
      },
    },
    footer: {
      actions: {
        calendar: 'Calendar',
        picks: 'Picks',
        gameInfo: 'Game Info',
        contestRules: 'Contest rules',
        standings: 'Standings',
        chat: 'Chat',
      },
    },
    gameCard: {
      winProbablity: '{{value}}% Win Prob',
    },
    leaderboard: {
      notAvailable: 'Leaderboard for this week will be available {{date}}',
      notAvailableWithoutDate: 'Leadeboard is not available yet. Please come back later.',
    },
    leadeboardWidget: {
      myEntries: 'My Entries:',
      mySurvivors: 'My Survivors:',
      entry: 'Entry:',
      won: 'Won:',
      winning: 'Winning:',
      suvivors: 'Survivors:',
      tooltip__hasPlural: {
        one: 'Entry {{index}}: {{count}} pt',
        other: 'Entry {{index}}: {{count}} pts',
      },
    },
    statusHeader: {
      entries: 'Entry {{index}} of {{count}}',
      entriesBtn: 'My Entries',
      newEntryBtn: 'New Entry',
    },
    makePickModal: {
      title: {
        made__hasPlural: {
          one: "You've made your pick!",
          other: "You've made your picks!",
        },
        saved__hasPlural: {
          one: "You've saved a pick!",
          other: "You've saved picks!",
        },
      },
      description: 'You chose the {{winningTeamName}} to beat the {{loosingTeamName}}',
      actions: {
        login: 'Log in to save',
        verify: 'Verify account to enter',
        enter: 'Enter for {{fee}}',
        cancel: 'Cancel',
        skip: 'Skip for now',
        addNewEntry: 'Add new entry',
        addNewEntryDeposit: 'Deposit to add new entry',
        findAnotherContest: 'Find another contest',
        inviteFriend: 'Invite a friend',
        continue: 'Continue making picks',
        savedPicks: 'Continue with saved picks',
      },
      featuredContests: {
        title: 'Want to win more?',
        description:
          'Show your skills and increase your chances of winning by entering more contests',
        error: 'Oops! Something went wrong. Please try again later.',
        recommendedForYou: 'SUGGESTED FOR YOU',
        browseSplash: 'BROWSE ALL CONTESTS',
      },
    },
    games__hasPlural: {
      one: '{{count}} game',
      other: '{{count}} games',
    },
    pointsAbbr__hasPlural: {
      one: '{{count}}pt',
      other: '{{count}}pts',
    },
    error: {
      picksheetLocked:
        'The picksheet for the current slate has been locked already. Please refresh the page to see new updates.',
    },
    soon: {
      title: 'To make your picks for this contest, download the app',
      description:
        'This contest is not yet available on our website, but it is coming soon. In the meantime, use the link below to play on the mobile app.',
      qrcode: 'SCAN THE QR CODE TO DOWNLOAD THE APP',
    },
    contestPicksheetNotSupported: {
      title: 'Contest not yet supported',
      description: 'This contest is not yet supported on the web',
      goToDetail: 'Go to contest detail',
      goToHome: 'Take me home',
    },
    action: {
      preview: 'Preview',
      sort: {
        time: 'Sort by start time',
        seed: 'Sort by seed',
      },
    },
  },
  dfs: {
    infoBar: {
      selection: '{{count}} of {{max}} players drafted',
    },
    actions: {
      clear: 'Clear Selection',
      submit: 'Submit Lineup',
      login: 'Login to submit',
      verify: 'Verify to enter',
      deposit: 'Enter for {{fee}}',
      create: 'Create new entry',
    },
    standings: {
      standingsTitle: 'Standings',
      tabs: {
        leaderboard: 'Leaderboard',
        entrants: 'Entrants',
        myEntries: 'My Entries',
        detail: 'Detail',
      },
      pagination: {
        loadMore: 'Load next page',
        loading: 'Loading...',
        nothingMore: 'No more results',
      },
    },
    playerDetail: {
      title: 'Player Detail',
      seasonTitle: 'Season totals',
      logTitle: 'Game log',
      actions: {
        draft: 'Draft',
        removeDraft: 'Remove Draft',
      },
    },
    contestDetail: {
      title: 'Contest Detail',
    },
    picks: {
      // TODO We don't have a way to drill the username yet ... :/
      // otherEntryTitle: "<countWrapper>{{entrantName}}</countWrapper>'s Picks ",
      otherEntryTitle: 'Their Picks',
      tierTitle: 'My Picks <countWrapper>({{pickCount}} / {{maxPicks}})</countWrapper>',
      currentTierTitle: 'Pick Tier <countWrapper>{{pickTierId}}</countWrapper>',
      tierSubtitleOtherEntry__hasPlural: {
        one: 'Picked Single Player From Each Tier',
        other: 'Picked {{count}} Players From Each Tier',
      },
      tierSubtitle__hasPlural: {
        one: 'Pick a Single Player From Each Tier',
        other: 'Pick {{count}} Players From Each Tier',
      },
      dropWorstCount__hasPlural: {
        one: 'Worst player score is being dropped',
        other: '{{count}} worst player scores are being dropped',
      },
    },
    playerPoolError: {
      noPicks: {
        title: 'No lineup submitted',
        subtitleOwnEntry:
          'You have not submitted a lineup. Draft your team now before it is too late!',
      },
      playerPoolNotReady: {
        title: 'Hang Tight!',
        subtitle: 'The player pool for this slate will be generated in {{time}}',
        subtitleNoTime: 'The player pool for this slate will be generated soon.',
      },
    },
    submit: {
      success: 'Your Lineup has been submitted.',
      error: 'Your Lineup has not been submitted. Please try again.',
    },
    playerCard: {
      upcomingPlaceholder: 'shown on game start',
      emptyPick: 'Select',
      dropWorstNth__hasPlural: {
        one: 'Worst',
        other: '{{ordinal}} worst',
      },
    },
    chat: {
      title: 'Chat',
    },
  },
  teamSurvivor: {
    nav: {
      picks: 'Your Picks',
      leaderboard: 'Leaderboard',
      stats: 'Stats',
    },
    subHeader: {
      slateLabel: 'WK {{week}} (1 of {{entriesCount}})',
      stats: {
        winningLabel: 'Winning',
        out__hasPlural: {
          one: 'Out: {{count}} pt',
          other: 'Out: {{count}} pts',
        },
        survivors: 'Survivors: {{survivors}} of {{totalEntries}}',
      },
    },
    picksheet: {
      title__hasPlural: {
        one: 'Select {{count}} team for {{name}}. If you win, you survive.',
        other: 'Select {{count}} teams for {{name}}. If all games win, you survive.',
      },
      notifications: {
        remove: 'Your pick has been removed.',
      },
      error: 'An error occured. Please try refresh the page.',
      noData: 'There are no games for this contest yet',
      team: {
        noData: 'The team is not ready yet.',
        tooltip: {
          expectedPicksCount: 'Remove current selection(s) if you’d like to make a new one.',
          pickLimitSet: 'Pick limit is set.',
          isGamePickChangeable: 'Pick is not changeable.',
        },
      },
    },
    legend: {
      default: {
        upcoming: 'Upcoming',
      },
      upcoming: {
        correct: 'Correct',
        eliminated: 'Eliminated',
        current: 'Current Pick',
      },
      live: {
        correct: 'Survived or Winning',
        eliminated: 'Eliminated or Currently Losing',
      },
      final: {
        correct: 'Survived',
        eliminated: 'Eliminated',
      },
    },
    leaderboard: {
      winning: 'Winning',
    },
  },
  teamPickEm: {
    nav: {
      picks: 'Your Picks',
      leaderboard: 'Standings',
      stats: 'Stats',
    },
    picksheet: {
      title__hasPlural: {
        // TODO: this was updated as hotfix to fix the issue with the UI
        one: 'Predict the winner from the following games',
        other: 'Predict the winner from the following games',
      },
      action: {
        success: 'Picks were saved successfully',
      },
    },
    userBar: {
      tooltip__hasPlural: {
        one: 'Entry {{index}}: {{count}} pt',
        other: 'Entry {{index}}: {{count}} pts',
      },
      winningLabel: 'Winning',
    },
    leaderboard: {
      nav: {
        overall: 'Overall',
        byWeek: 'By Week',
      },
      common: {
        rank: 'Rank',
        entrant: 'Entrant',
        winsRatio: 'Wins/Total',
        points: 'Pts',
      },
      overall: {
        position: '{{label}} Place / <valueWrapper>{{value}}</valueWrapper>',
      },
    },
    picksPanel: {
      clear: 'Clear',
      picksMade__hasPlural: {
        one: 'pick made',
        other: 'picks made',
      },
      missedClicks__hasPlural: {
        one: '1 missed pick',
        other: '{{ count }} missed picks',
      },
      savePicks: 'Save Picks',
      login: 'Login to save',
      verify: 'Verify to enter',
      deposit: 'Enter for {{fee}}',
      create: 'Create new entry',
      empty: 'Empty',
      pristine:
        'Current selection of picks has already been saved. To proceed with saving any changes, please make an update.',
      missingTeamIds: "Can't save best picks with missing teams in the selected game.",
    },
    discardChangesModal: {
      title: 'Unsaved Picks',
      description: 'You have unsaved picks. Are you sure you want to discard them?',
      cancel: 'Cancel',
      submit: 'Discard',
    },
    picksheetNotAvailableYet: {
      title: 'Slate not available',
      subtitle:
        'You will be able to make your selection(s) for this slate once the participants are determined.',
    },
    bestPick: {
      title: 'Best pick',
      alreadySelected: 'Already selected',
      teamNotSelected: 'You must pick a team in this game first',
    },
  },
  gameStats: {
    leagueNotSupported: 'Matchup screen not available for this league',
    odds: {
      title: 'Odds',
      attributes: {
        spread: 'Spread',
        moneyline: 'Moneyline',
        win_probability: 'Implied win probablity',
      },
    },
    overview: {
      title: {
        nfl: 'Overview',
        ncaab: 'Regular season stats',
      },
      attributes: {
        ppg: 'Points per game',
        pa: 'Points against',
        fieldGoalPercentage: 'Field Goal %',
        reboundsPerGame: 'Rebounds per game',
        assistsPerGame: 'Assists per game',
        blocksPerGame: 'Blocks per game',
        stealsPerGame: 'Steals per game',
      },
    },
    injuries: {
      title: 'Injuries',
      status: {
        Questionable: 'Quest',
        Doubtful: 'Doub',
        Out: 'Out',
      },
    },
    stats: {
      lastGames_title: 'Last {{ count }} games',
      lastMeetings_title: 'Last {{ count }} meetings',
      win: 'W',
      lose: 'L',
    },
    title: {
      stats: 'Stats',
      owners: 'Owners',
    },
    owners: {
      entries__hasPlural: {
        one: '1 entry',
        other: '{{ count }} entries',
      },
    },
  },
  contestStats: {
    nav: {
      team_pick_em: {
        byWeek: 'By Week',
        overall: 'Overall',
      },
      team_survivor: {
        byWeek: 'Daily Summary',
        overall: 'Team Availability',
      },
      default: {
        byWeek: 'By Week',
        overall: 'Overall',
      },
    },
    team: 'Team',
    summary: {
      byWeekTitle: '{{label}} Summary',
      overallTitle: 'Overall Summary',
      teamAvailabilityTitle: 'Availability of Teams',
      survivors: 'Survivors',
      eliminated: 'Eliminated',
      entered: 'Entered',
      noPick: 'No Pick',
      complete: 'Complete picks',
      incomplete: 'Missed pick',
      available: 'Available',
      unavailable: 'Unavailable',
      picksMade: 'Picks Made',
      footNote: '*Incl. missing picks',
    },
    list: {
      team: 'Team',
      pickedCount: '#Picked',
      pickedPercentage: '%Picked',
      availableCount: 'Available this slate',
    },
    statsNotAvailable: 'Contest statistics are not available yet. Please come back later.',
  },
};
