import { forwardRef } from 'react';
import type { MantineNumberSize } from '@mantine/core';
import { Text as MantineText, createPolymorphicComponent, createStyles } from '@mantine/core';

import type { TextProps } from '../Text/Text';

/** @deprecated use `domains/design-system/Text` instead */
export const titleStyles = {
  'web-headline-xxl': {
    fontSize: '48px',
    lineHeight: '1.1666666666666667',
    fontWeight: 900,
  },
  'web-headline-xl': {
    fontSize: '40px',
    lineHeight: '1.2',
    fontWeight: 900,
  },
  'web-headline-large': {
    fontSize: '32px',
    lineHeight: '1.25',
    fontWeight: 900,
  },
  'web-headline-medium': {
    fontSize: '24px',
    lineHeight: '1.3333333333333333',
    fontWeight: 900,
  },
  'web-headline-small': {
    fontSize: '16px',
    lineHeight: '1.5',
    fontWeight: 900,
  },
  'web-headline-x-small': {
    fontSize: '14px',
    lineHeight: '1.5714285714285714',
    fontWeight: 900,
  },
  'web-headline-extra-small': {
    fontSize: '12px',
    lineHeight: '1.5714285714285714',
    fontWeight: 900,
  },
  'web-subhead-large': {
    fontSize: '24px',
    lineHeight: '1.1666666666666667',
    fontWeight: 600,
  },
  'web-subhead-medium': {
    fontSize: '16px',
    lineHeight: '1.25',
    fontWeight: 600,
  },
  'web-subhead-small': {
    fontSize: '14px',
    lineHeight: '1.2857142857142858',
    fontWeight: 600,
  },
  'web-subhead-x-small': {
    fontSize: '12px',
    lineHeight: '1.3333333333333333',
    fontWeight: 600,
  },
  'web-subhead-xx-small': {
    fontSize: '10px',
    lineHeight: '1.4',
    fontWeight: 600,
  },
  'web-title-large': {
    fontSize: '24px',
    lineHeight: '1.1666666666666667',
    fontWeight: 500,
  },
  'web-title-medium': {
    fontSize: '16px',
    lineHeight: '1.25',
    fontWeight: 500,
  },
  'web-title-small': {
    fontSize: '12px',
    lineHeight: '1.3333333333333333',
    fontWeight: 500,
  },
} as const;

type TitleProps = {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  variant?:
    | keyof typeof titleStyles
    | {
        base?: keyof typeof titleStyles;
        sm?: keyof typeof titleStyles;
        md?: keyof typeof titleStyles;
        lg?: keyof typeof titleStyles;
      };
} & Omit<TextProps, 'tag' | 'variant'>;

const useTitleStyles = createStyles((theme, { variant }: TitleProps) => ({
  title: {
    margin: 0,
    ...(() => {
      if (!variant) {
        return {};
      }

      if (typeof variant === 'string') {
        return titleStyles[variant];
      }

      return Object.entries(variant).reduce((acc, [key, value]) => {
        if (key === 'base') {
          return { ...acc, ...titleStyles[value] };
        }

        return {
          ...acc,
          [theme.fn.largerThan(key as MantineNumberSize)]: {
            ...titleStyles[value],
          },
        };
      }, {});
    })(),
  },
}));

const TitleFactory = forwardRef<HTMLHeadingElement, TitleProps>(
  ({ tag, variant, className, ...props }, ref) => {
    const { cx, classes } = useTitleStyles({ variant });

    return (
      <MantineText component={tag} ref={ref} className={cx(classes.title, className)} {...props} />
    );
  }
);
TitleFactory.displayName = 'Title';

/** @deprecated use `domains/design-system/Title` instead */
export const Title = createPolymorphicComponent<'h1', TitleProps>(TitleFactory);
