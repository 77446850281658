import { IconGridDots, IconX } from '@tabler/icons-react';
import { forwardRef } from 'react';
import { PrimaryWhale } from '~/components/shared/Logos/SplashLogo';
import { UnstyledButton } from '~/domains/design-system/Button';
import useStyles from './styles';

const SitesMenuButton = forwardRef(
  ({ onClick, isOpen }: { onClick?: () => void; isOpen?: boolean }, ref) => {
    const { classes } = useStyles();

    return (
      // @ts-expect-error Mantine types are incorrect
      <UnstyledButton className={classes.sitesButton} onClick={onClick} ref={ref}>
        <PrimaryWhale size={20} />
        {isOpen ? (
          <IconX size={23} color="white" style={{ marginLeft: -1.5, marginRight: -1.5 }} />
        ) : (
          <IconGridDots size={20} color="white" />
        )}
      </UnstyledButton>
    );
  }
);

SitesMenuButton.displayName = 'SitesMenuButton';

export default SitesMenuButton;
