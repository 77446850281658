// eslint-disable-next-line no-restricted-syntax
export enum CommishTabValues {
  HOME = 'home',
  CONTESTS = 'contests',
  MEMBERS = 'members',
}

// eslint-disable-next-line no-restricted-syntax
export enum CommishContestTabValues {
  OVERVIEW = 'overview',
  ENTRIES = 'entries',
  POSTS = 'posts',
  SETTINGS = 'settings',
}

// eslint-disable-next-line no-restricted-syntax
export enum OverviewTimeframe {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}
