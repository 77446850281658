/**
 * Format channel details number to a more readable format:
 * @example
 *    0 > 999: Show whole number (ie. 753)
 *    1000 > 9,999: Round to hundreds (ie. 5.3K)
 *    10,000 > 999,999: Round to thousands (ie. 322K).
 *    1,000,000 > 9,999,999: Round to hundred-thousands (ie. 4.1M).
 *    10,000,000+: Round to millions (ie. 22M).
 */
export function formatChannelDetails(number: number): string {
  if (number < 1000) {
    return number.toString();
  }
  if (number < 10000) {
    // Round to the nearest hundred, then divide by 10 for a single decimal place, removing trailing zeros
    return `${Math.round(number / 100) / 10}K`;
  }
  if (number < 1000000) {
    return `${Math.round(number / 1000)}K`;
  }
  if (number < 10000000) {
    // Round to the nearest hundred thousand, then divide by 10 for a single decimal place, removing trailing zeros
    return `${Math.round(number / 100000) / 10}M`;
  }
  return `${Math.round(number / 1000000)}M`;
}
