import { Accordion, Group, Skeleton, Stack, Text } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import type { Dispatch, SetStateAction } from 'react';

import { getHasUserLinkedRYP } from '~/domains/oauth/utils/getAccessTokenData';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import repeat from '~/common/utils/repeat';

import useFetchRYPPools from '../../hooks/useFetchRYPPools';
import useFetchSplashContests from '../../hooks/useFetchSplashContests';

import SingleContest from './SingleContest';

type ContestAccordionPanelProps = {
  value: 'Splash' | 'RYP' | 'OFP';
  selectedItem: string;
  setSelectedItem: Dispatch<SetStateAction<string>>;
  setSelectedMobileTab?: Dispatch<SetStateAction<string>>;
};

function ContestAccordionPanel({
  value,
  selectedItem,
  setSelectedItem,
  setSelectedMobileTab,
}: ContestAccordionPanelProps) {
  const { t } = useTranslation('common');
  const hasUserLinkedRYP = getHasUserLinkedRYP();
  const { pools: rypPools } = useFetchRYPPools({ hasUserLinkedRYP });
  const {
    contests: splashContests,
    loaderRef: splashLoaderRef,
    isFetchingNextPage: splashIsFetchingNextPage,
    hasNextPage: splashHasNextPage,
  } = useFetchSplashContests();

  const getPanelConfig = () => {
    switch (value) {
      case 'RYP':
        return {
          contests: rypPools as Pick<
            Contest,
            'name' | 'created_by_admin' | 'commissioner' | 'entries' | 'id' | 'thumbnail_url'
          >[],
          hasNextPage: false,
        };
      case 'Splash':
      default:
        return {
          contests: splashContests,
          loaderRef: splashLoaderRef,
          hasNextPage: splashHasNextPage,
          isFetchingNextPage: splashIsFetchingNextPage,
        };
    }
  };

  const { contests, loaderRef, isFetchingNextPage, hasNextPage } = getPanelConfig();

  return (
    <Accordion.Item value={value}>
      <Accordion.Control>{t('invites.modal.xContests', { value })}</Accordion.Control>
      <Accordion.Panel>
        {contests?.length > 0 ? (
          <>
            {contests.map((contest) => (
              <SingleContest
                key={contest.id}
                contest={contest}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                setSelectedMobileTab={setSelectedMobileTab}
              />
            ))}
          </>
        ) : (
          <Text>{t('invites.modal.noContestsFound')}</Text>
        )}
        {hasNextPage && (
          <div ref={loaderRef}>
            {isFetchingNextPage && (
              <Stack>
                {repeat(5, () => (
                  <Group noWrap mt={10} spacing={8} w="100%">
                    <Skeleton h={40} radius="xl" w="15%" />
                    <Stack w="80%" spacing={4}>
                      <Skeleton h={15} w="80%" />
                      <Skeleton h={15} w="20%" />
                    </Stack>
                  </Group>
                ))}
              </Stack>
            )}
          </div>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
}

export default ContestAccordionPanel;
