import * as FullStory from '@fullstory/browser';

import log from '~/common/utils/log';

const initFullStory = () => {
  if (!process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
    return;
  }

  const isDebugMode = process.env.NEXT_PUBLIC_DEBUG === 'true';

  FullStory.init(
    {
      orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID,
      namespace: 'FS',
      devMode: isDebugMode,
    },
    ({ sessionUrl }) => {
      log('FullStory Initialised: ', sessionUrl);
    }
  );
};

const isFullStoryInitialised = () => FullStory.isInitialized();

const stopFullStory = () => {
  if (isFullStoryInitialised()) {
    FullStory.shutdown();
  }
};

export { initFullStory, isFullStoryInitialised, stopFullStory };
