import type { APIAuthUserResponse } from '~/services/users/types';
import { me } from '~/services/users/users.service.api';

import { fetchRefreshToken } from '../../token/refresh-token';

import { identityInstance } from './identity-instance';

const fetchUserWithRefreshToken = async (): Promise<APIAuthUserResponse> => {
  // Get the refresh token
  await fetchRefreshToken();
  // Get User
  const user = await me();

  return user;
};

const logOut = async () => {
  const response = await identityInstance.post('/auth/logout');

  return response.data;
};

const deactivateAccount = async () => {
  const response = await identityInstance.delete('/users/disable');

  return response.data;
};

interface OAuthLinkAccountInput {
  applicationType: string;
  authorizationCode: string;
}

const oauthLinkAccount = async (input: OAuthLinkAccountInput) => {
  const response = await identityInstance.post('/oauth/link', input);

  return response.data;
};

const getNotificationPreferences = async () => {
  const response = await identityInstance.get('/users/me/notifications/preferences?type=email');

  return response.data;
};

const updateNotificationPreferences = async (payload: { id: string; isEnabled: boolean }) => {
  const response = await identityInstance.patch('/users/me/notifications/preferences', payload);

  return response.data;
};

export const generateAndStoreSingleUseToken = async (authToken?: string) => {
  const response = await identityInstance.get('/auth/single-use-tokens', {
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : undefined,
    },
  });

  return response.data;
};

export {
  fetchUserWithRefreshToken,
  logOut,
  oauthLinkAccount,
  deactivateAccount,
  getNotificationPreferences,
  updateNotificationPreferences,
};
