import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';

import IntercomLoggedIn from '~/components/Intercom/IntercomLoggedIn';

import { UserContext } from './UserProvider';

interface AuthGuardProps {
  children: React.ReactNode;
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, initializing } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    if (!initializing) {
      if (!user) {
        // If they are signing out we use this so we don't show login modal
        if (window.sessionStorage.getItem('sign_out')) {
          window.sessionStorage.removeItem('sign_out');
          router.push(`/sign-in`);
        } else {
          router.push({
            pathname: '/sign-in',
            // BUG: router.asPath returns incorrect url for paths with query params
            // query: { redirectTo: router.asPath },
            query: {
              redirectTo: `${window.location.pathname}${window.location.search}`,
            },
          });
        }
      }
    }
  }, [initializing, router, user]);

  if (initializing) {
    return null;
    // add initalizing loader?
  }

  // if auth initialized with a valid user show protected page
  if (user) {
    return (
      <>
        <IntercomLoggedIn />
        {children}
      </>
    );
  }

  /* otherwise don't return anything, will do a redirect from useEffect */
  return null;
}
