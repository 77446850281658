export default {
  header: {
    title: 'My Contests',
    tabs: {
      scheduled: 'Upcoming',
      inProgress: 'Live',
      finalized: 'Final',
    },
  },
  pills: {
    all: 'All',
    survivor: 'Survivor',
    pickEm: "Pick'Em",
    tiers: 'Tiers',
    oneAndDone: 'O&D',
  },
  cards: {
    footer: {
      mySurvivors: 'My survivors',
      survivors: 'Survivors',
      myEntries: 'My entries',
      entries: 'Entries',
      entry: 'Entry',
      winning: 'Winning',
      won: 'Won',
      prizes: 'Prizes',
      segmented: 'Won (to date)',
    },
    banner: {
      makePicks: 'Make your pick(s)',
      draftLineup__hasPlural: {
        one: '{{count}} undrafted lineup',
        other: '{{count}} undrafted lineups',
      },
      lineupWithInjuries__hasPlural: {
        one: '{{count}} lineup with injuries',
        other: '{{count}} lineups with injuries',
      },
      picksheetNotAvailable: {
        teamBased: 'Pick sheet not yet available',
        playerBased: 'Player pool not yet available',
      },
    },
  },
  states: {
    empty: {
      scheduled: {
        title: 'Upcoming contests',
        subtitle: 'This page is for contests you have entered that have not started yet.',
        text: 'Click on the Live tab above to view your contests that are already in progress. To find a new contest to play in, navigate to the Home page or Contest Lobby above.',
      },
      inProgress: {
        title: 'Live contests',
        subtitle: 'This page is for contests you have entered that have already begun.',
        text: 'Click on the Upcoming tab above to view contests you have entered that have not begun yet. To find a new contest to play in, navigate to the Home page or Contest Lobby above.',
      },
      finalized: {
        title: 'Final contests',
        subtitle: 'This page is for contests you have entered that have ended.',
        text: 'Click on the Upcoming or Live tabs above to view contests you have entered. To find a new contest to play in, navigate to the Home page or Contest Lobby above.',
      },
    },
    error: {
      title: 'Something went wrong',
      subtitle1: 'Our servers could not handle your request.',
      subtitle2: "Don't worry, our development team was already notified. Try refreshing the page.",
      action: 'Reload the page',
    },
  },
  itemsCount: 'Showing {{count}} out of {{total}} contests',
};
