import { Box, Divider, ScrollArea, Stack, Text, createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';

import { FEATURE } from '~/common/enums/feature.enum';
import { ApplicationType } from '~/domains/oauth/const';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

import { getHasUserLinkedRYP } from '../../../../oauth/utils/getAccessTokenData';

import { MOBILE_SECTION_HEIGHT, RYP_CTA_HEIGHT, SEARCH_HEIGHT, SECTION_HEIGHT } from './consts';
import LinkEOCTA from './shared/LinkEOCTA';
import { ContestList } from './shared/ContestList';

const useStyles = createStyles((theme) => ({
  item: {
    padding: theme.spacing.sm,
    width: '100%',
    cursor: 'pointer',
  },
}));

type InviteModalLeftProps = {
  selectedItem: string;
  setSelectedItem: Dispatch<SetStateAction<string>>;
  emailOnly?: boolean;
  isRYPOnly: boolean;
};

function InviteModalLeft({
  selectedItem,
  setSelectedItem,
  emailOnly,
  isRYPOnly,
}: InviteModalLeftProps) {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const { classes } = useStyles();
  const enableOauth = useIsFeatureEnabled(FEATURE.SHOW_OAUTH_OPTIONS);
  const [showRYP, setShowRYP] = useState(true);
  const hasUserLinkedRYP = getHasUserLinkedRYP();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });

  const showRYPCTA = enableOauth && showRYP && !hasUserLinkedRYP;
  const isEmailSelected = selectedItem === 'email';
  const sectionHeight = isTablet ? MOBILE_SECTION_HEIGHT : SECTION_HEIGHT;
  const rypButtonHeight = showRYPCTA ? RYP_CTA_HEIGHT : 0;
  const searchOffset = emailOnly ? SEARCH_HEIGHT + theme.spacing.md : 0;

  return (
    <Box
      w="45%"
      h={`calc(${sectionHeight}px - ${rypButtonHeight}px + ${searchOffset}px)`}
      miw={320}
      sx={{
        borderRight:
          theme.colorScheme === 'dark'
            ? `1px solid ${theme.colors.gray[8]}`
            : `1px solid ${theme.colors.gray[4]}`,
      }}
    >
      <ScrollArea h="100%">
        <Stack w="100%" spacing={2} px="sm">
          {!isRYPOnly && (
            <>
              <Text transform="uppercase" color="dimmed" size={12}>
                {t('invites.modal.people')}
              </Text>
              <Box
                bg={isEmailSelected ? theme.colors.splash[1] : 'transparent'}
                className={classes.item}
                onClick={() => setSelectedItem('email')}
                sx={{ borderRadius: theme.radius.sm }}
              >
                <Text
                  size={14}
                  fw={isEmailSelected ? 700 : 400}
                  color={
                    isEmailSelected
                      ? theme.colors.dark[7]
                      : theme.colors.gray[theme.colorScheme === 'dark' ? 3 : 5]
                  }
                >
                  {t('invites.modal.addViaEmail')}
                </Text>
              </Box>
            </>
          )}
          {!emailOnly && (
            <>
              <Divider mb="xs" />
              <ContestList
                isRYPOnly={isRYPOnly}
                showRYPAccordion={hasUserLinkedRYP}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            </>
          )}
        </Stack>
      </ScrollArea>
      {showRYPCTA && <LinkEOCTA eo={ApplicationType.RYP} onClose={() => setShowRYP(false)} />}
    </Box>
  );
}

export { InviteModalLeft };
