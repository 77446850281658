const enum PaymentHandleStatus {
  INITIATED = 'INITIATED',
  COMPLETED = 'COMPLETED',
  PAYABLE = 'PAYABLE',
  PROCESSING = 'PROCESSING',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
}

const enum PaymentType {
  CARD = 'CARD',
  PAYPAL = 'PAYPAL',
  VIPPREFERRED = 'VIPPREFERRED',
  VENMO = 'VENMO',
}

const enum CardType {
  VI = 'VI',
  MC = 'MC',
  AM = 'AM',
  DI = 'DI',
}

const enum CardCategory {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

interface CardExpiryDto {
  month: number;
  year: number;
}

interface ICardResponseDto {
  lastDigits: string;
  cardExpiry: CardExpiryDto;
  cardBin: string;
  cardType: CardType;
  cardCategory: CardCategory;
  holderName: string;
}

interface IPaymentHandleDto {
  id: string;
  merchantRefNum: string;
  status: PaymentHandleStatus;
  paymentType: PaymentType;
  accountId: string;
  card: ICardResponseDto;
  billingDetailsId: string;
}

interface IPaymentHandlesResponseDto {
  id: string;
  status: string;
  merchantCustomerId: string;
  paymentToken: string;
  paymentHandles: IPaymentHandleDto[];
}

interface IGetWalletsPaymentHandles {
  id: string;
  data: IPaymentHandlesResponseDto;
}

interface IGetWalletsBalanceResponseDto {
  availableBalance: number; // This is in dollars (not cents)
  credits: number;
  withdrawableBalance: number;
}

interface IGetWalletsBalance {
  id: string;
  data: IGetWalletsBalanceResponseDto;
}

interface IPostWalletsPaymentsHandlesPaypalPayload {
  merchantRefNum: string;
  email?: string;
  amount: number;
  transactionType: 'PAYMENT';
  currency: 'USD';
  savePaymentMethod?: boolean;
  simulator: 'INTERNAL' | 'EXTERNAL';
  returnLinks?: {
    rel: 'default' | 'on_failed' | 'on_completed';
    href: string;
    method: 'GET' | 'POST';
  }[];
}

interface IPostWalletsPaymentsHandlesPaypalResponse {
  id: string;
  data: {
    id: string;
    status: 'INITIATED' | 'COMPLETED' | 'PAYABLE' | 'PROCESSING' | 'EXPIRED' | 'FAILED';
    paymentHandleToken: string;
    links: {
      rel: 'redirect_payment';
      href: string;
    }[];
  };
}

interface IPostWalletsDepositsPayload {
  paymentHandleId?: string;
  cvv?: string;
  paymentHandleToken?: string;
  amount: number;
  currency: 'USD';
  merchantRefNum: string;
  savePaymentMethod?: boolean;
  paymentType?: PaymentType;
  returnLinks?: {
    rel: 'default' | 'on_failed' | 'on_completed';
    href: string;
    method: 'GET' | 'POST';
  }[];
}

interface IPostWalletsDepositsResponse {
  id: string;
  data: {
    userId: string;
    status: 'INITIATED' | 'COMPLETED' | 'PAYABLE' | 'PROCESSING' | 'EXPIRED' | 'FAILED';
  };
}

export type VIPPNativeQueryParams = {
  amountInCents: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dobDay: string;
  dobMonth: string;
  dobYear: string;
  address: string;
  city: string;
  zip: string;
  state: string;
  country: string;
};

interface IPostWalletsPaymentsHandlesVIPPPayload {
  merchantRefNum: string;
  amount?: number;
  returnLinks?: {
    rel: 'default';
    href: string;
    method: 'POST';
  }[];
  query?: VIPPNativeQueryParams;
}

export {
  CardCategory,
  CardType,
  PaymentType,
  type IGetWalletsBalance,
  type IGetWalletsPaymentHandles,
  type IPostWalletsDepositsPayload,
  type IPostWalletsDepositsResponse,
  type IPostWalletsPaymentsHandlesPaypalPayload,
  type IPostWalletsPaymentsHandlesPaypalResponse,
  type IPostWalletsPaymentsHandlesVIPPPayload,
};
