import type { PropsWithChildren } from 'react';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { UnstyledButton } from '@mantine/core';
import { IconChevronsUp } from '@tabler/icons-react';
import Trans from 'next-translate/Trans';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';

import { Banner, BannerType } from '../Banner/Banner';

function ReloadPageButton({ children, isBold }: PropsWithChildren & { isBold?: boolean }) {
  return (
    <UnstyledButton
      onClick={() => {
        window.location.reload();
      }}
      sx={(theme) => ({
        color: theme.black,
        ...(isBold && { fontWeight: 700 }),

        '&:hover': {
          textDecoration: 'underline',
        },
      })}
    >
      {children}
    </UnstyledButton>
  );
}

function NewAppVersionBanner() {
  const isMobile = useIsMobile();
  const { t } = useTranslation('common');

  return (
    <Banner
      title={<ReloadPageButton>{t('banner.newAppVersion.titleMobile')}</ReloadPageButton>}
      message={
        <Trans
          i18nKey="common:banner.newAppVersion.title"
          components={{
            link: <ReloadPageButton isBold />,
          }}
        />
      }
      Icon={IconChevronsUp}
      type={BannerType.INFO}
      minimal={isMobile}
      allowClose
    />
  );
}

export default NewAppVersionBanner;
