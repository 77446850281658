export function LogoSplashText({
  size = 30,
  color = '#1A1C1E',
}: {
  size?: number;
  color?: string;
}) {
  return (
    <svg
      width={size * (156 / 30)}
      height={size}
      viewBox="0 0 156 30"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28.9465 6.1001V23.5001H32.4265V6.1001H28.9465Z" />
      <path d="M62.5998 23.5001V6.1001H66.0798L66.0318 11.7401C66.8238 10.8761 67.9998 10.3961 69.6558 10.3961C72.7998 10.3961 74.9838 12.6281 74.9838 15.8681V23.5001H71.5038V16.4681C71.5038 14.5481 70.8558 13.5401 68.9118 13.5401C66.9678 13.5401 66.0798 14.7161 66.0798 16.7081V23.5001H62.5998Z" />
      <path d="M134.12 16.9239C134.12 21.6039 136.256 23.4999 139.736 23.4999H141.584L141.968 20.2599C141.939 20.2599 141.904 20.2613 141.852 20.2634C141.687 20.2701 141.347 20.2839 140.456 20.2839C138.632 20.2839 137.6 19.8039 137.6 17.3799V13.8999H141.872V10.7799H137.6V7.58789H134.12V16.9239Z" />
      <path d="M143.377 19.54C143.473 22.108 145.561 23.788 149.137 23.788C152.689 23.788 154.969 22.3 154.969 19.636C154.969 17.164 153.265 15.964 149.881 15.532L149.838 15.5264C147.851 15.2662 146.905 15.1423 146.905 14.356C146.905 13.804 147.457 13.324 148.969 13.324C150.577 13.324 151.273 13.852 151.369 14.62H154.705C154.585 12.052 152.521 10.396 149.209 10.396C145.729 10.396 143.545 11.788 143.545 14.548C143.545 16.876 145.081 18.028 148.225 18.436C150.841 18.796 151.609 19.036 151.609 19.804C151.609 20.548 150.841 20.86 149.377 20.86C147.529 20.86 146.809 20.308 146.713 19.54H143.377Z" />
      <path d="M125.71 16.7558C125.71 12.7958 127.702 10.7798 131.59 10.7798H132.67V14.0198H131.878C129.838 14.0198 129.19 14.7398 129.19 16.7558V23.4998H125.71V16.7558Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.591 17.092C110.591 21.028 113.375 23.788 117.311 23.788C121.247 23.788 124.031 21.028 124.031 17.092C124.031 13.156 121.247 10.396 117.311 10.396C113.375 10.396 110.591 13.156 110.591 17.092ZM120.551 17.092C120.551 19.132 119.207 20.548 117.287 20.548C115.391 20.548 114.071 19.132 114.071 17.092C114.071 15.052 115.415 13.612 117.311 13.612C119.207 13.612 120.551 15.052 120.551 17.092Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.6879 19.156C95.6879 18.196 95.7119 17.572 95.7599 16.852C95.9759 12.892 98.4959 10.396 102.288 10.396C106.536 10.396 109.032 13.324 109.032 17.284C109.032 20.932 106.344 23.692 102.84 23.692C101.208 23.692 99.9119 23.116 99.1439 22.108V27.34H95.6879V19.156ZM102.36 20.476C104.28 20.476 105.576 19.108 105.576 17.068C105.576 15.076 104.28 13.636 102.36 13.636C100.464 13.636 99.1679 15.076 99.1679 17.068C99.1679 19.156 100.416 20.476 102.36 20.476Z"
      />
      <path d="M82.5378 19.54C82.6338 22.108 84.7218 23.788 88.2978 23.788C91.8498 23.788 94.1298 22.3 94.1298 19.636C94.1298 17.164 92.4258 15.964 89.0418 15.532L88.9988 15.5264C87.0121 15.2662 86.0658 15.1423 86.0658 14.356C86.0658 13.804 86.6178 13.324 88.1298 13.324C89.7378 13.324 90.4338 13.852 90.5298 14.62H93.8658C93.7458 12.052 91.6818 10.396 88.3698 10.396C84.8898 10.396 82.7058 11.788 82.7058 14.548C82.7058 16.876 84.2418 18.028 87.3858 18.436C90.0018 18.796 90.7698 19.036 90.7698 19.804C90.7698 20.548 90.0018 20.86 88.5378 20.86C86.6898 20.86 85.9698 20.308 85.8738 19.54H82.5378Z" />
      <path d="M49.2358 19.54C49.3318 22.108 51.4198 23.788 54.9958 23.788C58.5478 23.788 60.8278 22.3 60.8278 19.636C60.8278 17.164 59.1238 15.964 55.7398 15.532L55.6968 15.5264C53.7101 15.2662 52.7638 15.1423 52.7638 14.356C52.7638 13.804 53.3158 13.324 54.8278 13.324C56.4358 13.324 57.1318 13.852 57.2278 14.62H60.5638C60.4438 12.052 58.3798 10.396 55.0678 10.396C51.5878 10.396 49.4038 11.788 49.4038 14.548C49.4038 16.876 50.9398 18.028 54.0838 18.436C56.6998 18.796 57.4678 19.036 57.4678 19.804C57.4678 20.548 56.6998 20.86 55.2358 20.86C53.3878 20.86 52.6678 20.308 52.5718 19.54H49.2358Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5248 23.788C36.7568 23.788 34.2368 21.196 34.2368 17.308C34.2368 13.132 36.9248 10.396 41.0288 10.396C44.7248 10.396 47.3408 12.892 47.5808 16.684C47.5951 16.9559 47.6094 17.2065 47.6243 17.4682L47.6244 17.4692L47.6245 17.4705C47.6596 18.0859 47.6983 18.7632 47.7488 19.924L47.9168 23.5H44.7488L44.7008 21.868C43.9088 23.14 42.4448 23.788 40.5248 23.788ZM40.9568 20.62C42.8528 20.62 44.1248 19.396 44.1248 17.14C44.1248 15.052 42.8528 13.636 40.9568 13.636C39.0368 13.636 37.7168 15.052 37.7168 17.14C37.7168 19.276 38.9648 20.62 40.9568 20.62Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8039 19.156C13.8039 18.196 13.8279 17.572 13.8759 16.852C14.0919 12.892 16.6119 10.396 20.4039 10.396C24.6519 10.396 27.1479 13.324 27.1479 17.284C27.1479 20.932 24.4599 23.692 20.9559 23.692C19.3239 23.692 18.0279 23.116 17.2599 22.108V27.34H13.8039V19.156ZM20.4759 20.476C22.3959 20.476 23.6919 19.108 23.6919 17.068C23.6919 15.076 22.3959 13.636 20.4759 13.636C18.5799 13.636 17.2839 15.076 17.2839 17.068C17.2839 19.156 18.5319 20.476 20.4759 20.476Z"
      />
      <path d="M0.653748 19.54C0.749748 22.108 2.83775 23.788 6.41375 23.788C9.96575 23.788 12.2457 22.3 12.2457 19.636C12.2457 17.164 10.5417 15.964 7.15775 15.532L7.11473 15.5264C5.12809 15.2662 4.18175 15.1423 4.18175 14.356C4.18175 13.804 4.73375 13.324 6.24575 13.324C7.85375 13.324 8.54975 13.852 8.64575 14.62H11.9817C11.8617 12.052 9.79775 10.396 6.48575 10.396C3.00575 10.396 0.821748 11.788 0.821748 14.548C0.821748 16.876 2.35775 18.028 5.50175 18.436C8.11775 18.796 8.88575 19.036 8.88575 19.804C8.88575 20.548 8.11775 20.86 6.65375 20.86C4.80575 20.86 4.08575 20.308 3.98975 19.54H0.653748Z" />
    </svg>
  );
}
