import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { UserContext } from '~/components/providers/UserProvider';

import { getContest } from '../domains/services/contest.service.api';
import { ContestQueryKeys, STALE_TIME } from '../constants/query';

export const useContest = (contestId?: string, enabled = true, staleTime = STALE_TIME) => {
  const { query } = useRouter();
  const { user } = useContext(UserContext);
  const { contestUuid } = query;
  const contestIdToFetch = contestId || contestUuid;

  const fullContest = useQuery({
    queryKey: ContestQueryKeys.CONTEST_DETAIL({ contestId: contestIdToFetch, userId: user?.id }),
    enabled: !!contestIdToFetch && enabled,
    staleTime,
    queryFn: () => getContest(contestIdToFetch?.toString()),
  });

  return fullContest;
};
