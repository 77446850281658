import { ActionIcon, List, Stack, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { IconCopy } from '@tabler/icons-react';
import { useContext } from 'react';

import { Text, textStyles } from '~/domains/design-system/Text';
import { Title } from '~/domains/design-system/Title';
import { Button } from '~/domains/design-system/Button';
import { TextInput } from '~/domains/design-system/TextInput';
import { UserContext } from '~/components/providers/UserProvider';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import dt from '~/testing';

import { useCopyReferralCode } from '../hooks/useCopyReferralCode';

import { RAFDrawerIcon } from './RAFDrawerIcon';

function ListMarker({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      h={40}
      w={40}
      align="center"
      justify="center"
      sx={(theme) => ({
        border: `1px solid ${theme.colors.gray2[1]}`,
        borderRadius: '4px',
      })}
    >
      <Text variant="headline-large" c="gray2.7">
        {children}
      </Text>
    </Stack>
  );
}

type RAFDrawerProps = {
  amount: string;
  onClose: () => void;
};

export function RAFDrawer({ amount, onClose }: RAFDrawerProps) {
  const theme = useMantineTheme();
  const { t } = useTranslation('user');
  const { user } = useContext(UserContext);
  const referralCode = user?.referralCode;
  const isMobile = useIsMobile();

  const { handleCopy, handleCopyLink } = useCopyReferralCode({
    referralCode,
    onClose,
  });

  return (
    <Stack spacing={24} maw={390}>
      <Stack spacing={16} align="center">
        <RAFDrawerIcon />
        <Text
          sx={{
            textAlign: 'center',
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: 900,
            fontFamily: theme.other.fontFamily.hubotSans,
            lineHeight: '36px',
            textTransform: 'uppercase',
            background: 'linear-gradient(90deg, #4BEBE2 0%, #E143FF 100%)',
            backgroundClip: 'text',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
          }}
        >
          {t('referAFriend.banner', { amount })}
        </Text>
      </Stack>
      <Stack align="center">
        <Title variant="headline-xl">{t('referAFriend.drawer.title')}</Title>
        <List
          type="ordered"
          spacing={24}
          pb={16}
          styles={{
            item: textStyles['body-large'],
          }}
        >
          <List.Item icon={<ListMarker>1</ListMarker>}>
            {t('referAFriend.drawer.bullet1')}
          </List.Item>
          <List.Item icon={<ListMarker>2</ListMarker>}>
            {t('referAFriend.drawer.bullet2')}
          </List.Item>
          <List.Item icon={<ListMarker>3</ListMarker>}>
            {t('referAFriend.drawer.bullet3')}
          </List.Item>
        </List>
      </Stack>

      <TextInput
        value={referralCode}
        readOnly
        styles={{
          wrapper: {
            background: `${theme.colors.gray2[1]} !important`,
            borderRadius: '4px !important',
          },
          input: {
            textAlign: 'center',
            color: theme.colors.gray2[7],
            ...textStyles['label-large'],
            // @ts-expect-error mantine is stupid and we need to override fontWeight via !important
            fontWeight: '900 !important',
          },
          rightSection: {
            paddingRight: '12px',
          },
        }}
        rightSection={
          <ActionIcon
            data-test-id={dt.common.components.RAFdrawer.copyCodeIcon}
            onClick={() => handleCopy()}
          >
            <IconCopy color={theme.colors.gray2[7]} />
          </ActionIcon>
        }
      />
      <Button
        variant="primary-fill"
        onClick={() => handleCopyLink()}
        data-test-id={dt.common.components.RAFdrawer.copyReferralLinkButton}
      >
        {t(isMobile ? 'referAFriend.drawer.ctaMobile' : 'referAFriend.drawer.cta')}
      </Button>
    </Stack>
  );
}
