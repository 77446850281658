export default {
  notifications: {
    today: 'Today',
    content: {
      channelFollowed: '<handle></handle> followed you!',
      contestInvited: '<handle></handle> invited you to a <contestName></contestName>!',
      contestEdited: '<handle></handle> updated <contestName></contestName> contest!',
      quickPicksContestWon: 'You won <amountWon></amountWon> in your QuickPicks contest!',
    },
  },
  noResults: {
    title: 'No notifications',
    text: 'New followers and contest invites appear here.',
  },
  readAll: 'Mark all as read',
};
