import type { ReactElement } from 'react';
import React from 'react';
import Head from 'next/head';

type HeadTitleProps = {
  title?: string;
  children?: ReactElement;
};

function HeadTitle({ title, children }: HeadTitleProps) {
  const titleString = title ? `${title} | Splash Sports` : 'Splash Sports';

  return (
    <Head>
      <title>{titleString}</title>
      <meta property="og:title" content={titleString} />
      {children}
    </Head>
  );
}

export default HeadTitle;
