export default {
  action: {
    cta: 'contest-create-action-cta',
    next: 'contest-create-action-next',
    back: 'contest-create-action-back',
    submit: 'contest-create-action-submit',
    login: 'contest-create-action-login',
    invite: 'contest-create-action-invite',
    view: 'contest-create-action-view',
    editSettings: 'contest-create-action-edit-settings',
    showAll: 'contest-create-action-view-all',
    showLess: 'contest-create-action-view-less',
  },
  container: 'contest-create-container',
  field: (sectionKey: string, path: string[]) =>
    `contest-create-field-${sectionKey}-${path.join('-')}`,
  section: (sectionKey: string, name: 'label' | 'description') =>
    `contest-create-section-${sectionKey}-${name}`,
  switchOption: 'contest-create-switch-option',
  selectOption: 'contest-create-field-select-option',
  checkOption: 'contest-create-field-check-option',
  fieldMeta: {
    title: 'contest-create-field-meta-title',
    description: 'contest-create-field-meta-description',
    error: 'contest-create-field-meta-error',
  },
  image: {
    container: 'contest-create-image-container',
    success: 'contest-create-image-success',
    reject: 'contest-create-image-error',
    idle: 'contest-create-image-idle',
    drag: 'contest-create-image-drag',
  },
  numberField: 'contest-create-number-field',
  summary: {
    label: (label: string) => `contest-create-summary-label-${label}`,
    subLabel: (label: string) => `contest-create-summary-sublabel-${label}`,
    field: (field: string) => `contest-create-summary-field-${field}`,
    action: {
      change: 'contest-create-summary-action-change',
    },
  },
  table: {
    container: 'contest-create-table-container',
    head: {
      label: 'contest-create-table-header-label',
    },
    body: {
      row: 'contest-create-table-body-row',
      cell: 'contest-create-table-body-cell',
      switch: 'contest-create-table-body-cell-switch',
      select: 'contest-create-table-body-cell-select',
    },
  },
  segments: {
    container: 'contest-create-segments-container',
    action: {
      save: 'contest-create-segments-action-save',
      edit: 'contest-create-segments-action-edit',
    },
    edit: {
      slate: 'contest-create-segments-edit-slate',
      segment: 'contest-create-segments-edit-segment',
    },
    view: {
      segment: 'contest-create-segments-view-segment',
    },
  },
  screen: {
    inprogress: 'contest-create-screen-inprogress',
    submitted: 'contest-create-screen-submitted',
  },
  drawer: {
    container: 'contest-create-drawer-container',
    close: 'contest-create-drawer-close',
  },
  previous: {
    container: 'contest-create-previous-container',
    contest: 'contest-create-previous-contest',
  },
};
