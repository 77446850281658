'use client';

import { AppShell, Box } from '@mantine/core';

import { Footer } from '~/components/shared/Footer/Footer';
import { AppShellHeaderRoot } from '~/domains/common/components/AppShellHeaderRoot/AppShellHeaderRoot';
import AppBanners from '~/domains/common/components/AppBanners/AppBanners';
import Debug from '~/domains/debug';

type PageLayoutProps = {
  children: React.ReactNode;
  hasOwnAppshell?: boolean;
  ignoreVerificationBanners?: boolean;
};

export function PageLayout({
  children,
  hasOwnAppshell,
  ignoreVerificationBanners,
}: PageLayoutProps) {
  return hasOwnAppshell ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <>
      <AppShell
        padding={0}
        header={<AppShellHeaderRoot />}
        styles={{
          main: {
            display: 'flex',
            flexFlow: 'column nowrap',
            padding: 0,
            minHeight: 'calc(100vh - 122px)',
            width: '100%',
          },
        }}
      >
        <AppBanners ignoreVerificationBanners={ignoreVerificationBanners} />
        <Box h="100%">{children}</Box>
      </AppShell>
      <Footer />
      <Debug />
    </>
  );
}
