import type { AxiosInstance } from 'axios';

export const setUserTimezoneInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async (config) => {
      config.headers.set('X-User-Timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

      return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
  );
};
