import { useInfiniteQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import { STALE_TIME } from '~/domains/contest/constants/query';
import { useInfiniteScroll } from '~/hooks/useInfiniteScroll';
import { getContestsByCommissionerId } from '~/services/contests/contests.service.api';
import { SortOptions } from '~/domains/common/enums/sort-options.enum';
import { SortDirection } from '~/domains/common/enums/sort-direction.enum';

import { ContestStatus } from '../../services/enums/contests-status.enum';

const useFetchSplashContests = () => {
  const { user } = useContext(UserContext);
  const PAGE_SIZE = 10;
  const {
    data: contestData,
    isInitialLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['invites/contests'],
    enabled: !!user,
    queryFn: ({ pageParam = 0 }) =>
      getContestsByCommissionerId({
        commissionerId: user.id,
        contestStatuses: [
          ContestStatus.SCHEDULED,
          ContestStatus.IN_PROGRESS,
          ContestStatus.FINALIZED,
          ContestStatus.FINISHED,
        ],
        sort: SortOptions.START_DATE,
        dir: SortDirection.DESC,
        minEntries: 2,
        limit: PAGE_SIZE,
        offset: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.offset + PAGE_SIZE;
      if (nextPage > lastPage.total) {
        return undefined;
      }
      return nextPage;
    },
    staleTime: STALE_TIME,
  });

  const { loaderRef } = useInfiniteScroll({
    fetchMore: fetchNextPage,
    hasMore: hasNextPage,
  });

  const contests = useMemo(() => {
    const allContests = contestData?.pages?.map((page) => page.data).flat();

    if (allContests?.length) {
      return allContests;
    }
    return [];
  }, [contestData]);

  return {
    contests,
    loaderRef,
    hasNextPage,
    isInitialLoading,
    isFetchingNextPage,
  };
};
export default useFetchSplashContests;
