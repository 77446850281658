import { forwardRef } from 'react';
import type { MantineNumberSize, TextProps as MantineTextProps } from '@mantine/core';
import {
  // eslint-disable-next-line no-restricted-imports
  Text as MantineText,
  createPolymorphicComponent,
  createStyles,
} from '@mantine/core';

import { mainTheme } from '~/config/theme';

import { titleStyles } from './Title';

export const textStyles = {
  ...titleStyles,
  'body-x-large': {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.375,
  },
  'body-large': {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.4285714285714286,
  },
  'body-medium': {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  'body-small': {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '14px',
  },
  'label-large': {
    fontSize: 16,
    fontWeight: 900,
    lineHeight: 1.5,
  },
  'label-medium': {
    fontSize: 14,
    fontWeight: 900,
    lineHeight: 1.5714285714285714,
  },
  'label-small': {
    fontSize: 12,
    fontWeight: 900,
    lineHeight: 1.6666666666666667,
  },
  'display-body-small': {
    fontFamily: mainTheme.other.fontFamily.hubotSans,
    fontSize: 10,
    lineHeight: '14px',
    fontStretch: '75%',
  },
  'display-body-medium': {
    fontFamily: mainTheme.other.fontFamily.hubotSans,
    fontSize: 12,
    lineHeight: '18px',
    fontStretch: '75%',
  },
  'display-body-large': {
    fontFamily: mainTheme.other.fontFamily.hubotSans,
    fontSize: 14,
    lineHeight: '20px',
    fontStretch: '75%',
  },
  'display-body-x-large': {
    fontFamily: mainTheme.other.fontFamily.hubotSans,
    fontSize: 16,
    lineHeight: '22px',
    fontStretch: '75%',
  },
} as const;

export type TextProps = {
  tag?: 'div' | 'span' | 'p' | 'small' | 'strong' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  variant?:
    | keyof typeof textStyles
    | {
        base?: keyof typeof textStyles;
        sm?: keyof typeof textStyles;
        md?: keyof typeof textStyles;
        lg?: keyof typeof textStyles;
      };
} & Omit<MantineTextProps, 'tag' | 'variant'>;

const useTextStyles = createStyles((theme, { variant }: TextProps) => ({
  text: {
    ...(() => {
      if (typeof variant === 'string') {
        return textStyles[variant];
      }

      return Object.entries(variant).reduce((acc, [key, value]) => {
        if (key === 'base') {
          return { ...acc, ...textStyles[value] };
        }

        return {
          ...acc,
          [theme.fn.largerThan(key as MantineNumberSize)]: {
            ...textStyles[value],
          },
        };
      }, {});
    })(),
  },
}));

const TextFactory = forwardRef<HTMLDivElement, TextProps>(
  ({ tag = 'span', variant = 'body-medium', className, ...props }, ref) => {
    const { cx, classes } = useTextStyles({ variant });

    return (
      <MantineText component={tag} ref={ref} className={cx(classes.text, className)} {...props} />
    );
  }
);
TextFactory.displayName = 'Text';

export const Text = createPolymorphicComponent<'span', TextProps>(TextFactory);
