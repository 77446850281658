import { ActionIcon } from '@mantine/core';
import Link from 'next/link';

import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { FEATURE } from '~/common/enums/feature.enum';

import { Routes } from '../../constants/routes';

import { IconSearch } from './IconSearch';

export function AppShellHeaderSearchButton() {
  const isChannelsV1Enabled = useIsFeatureEnabled(FEATURE.ENABLE_CHANNELS_V1);

  if (!isChannelsV1Enabled) {
    return null;
  }

  return (
    <ActionIcon
      component={Link}
      href={Routes.search()}
      title="Search"
      size={28}
      sx={(theme) => ({
        '&:hover': {
          background: theme.colors.gray[7],
        },
      })}
    >
      <IconSearch />
    </ActionIcon>
  );
}
