import { createStyles } from '@mantine/core';
import { CSSTransition } from 'react-transition-group';

import type { DropdownOffset, DropdownPosition } from './types';
import useDropdown from './useDropdown';

const useStyles = createStyles(
  (
    theme,
    {
      transitionTimeout,
      dropdownPosition,
    }: { transitionTimeout: number; dropdownPosition: DropdownPosition }
  ) => ({
    dropdownBase: {
      position: 'absolute',
      background: theme.white,

      '&-enter': {
        opacity: 0,
      },
      '&-enter-active': {
        opacity: 1,
        transition: `opacity ${transitionTimeout}ms ease-in-out`,
      },
      '&-exit': {
        opacity: 1,
      },
      '&-exit-active': {
        opacity: 0,
        transition: `opacity ${transitionTimeout}ms ease-in-out`,
      },

      ...(() => {
        switch (dropdownPosition) {
          case 'bottom-center':
          case 'top-center':
            return { transform: 'translateX(-50%)' };
          default:
            return {};
        }
      })(),
    },
  })
);

type DropdownProps = {
  children: React.ReactNode;
  dropdownClassName?: string;
  dropdownOffset?: DropdownOffset;
  dropdownPosition?: DropdownPosition;
  isOpen?: boolean;
  onChange?: (isOpen: boolean) => void;
  transitionTimeout?: number;
  trigger: (props: {
    ref: React.RefObject<any>;
    onClick: () => void;
    isOpen: boolean;
  }) => React.ReactElement;
};

function Dropdown({
  children,
  dropdownClassName,
  dropdownOffset,
  dropdownPosition,
  isOpen: controlledIsOpen,
  onChange: controlledOnChange,
  transitionTimeout = 150,
  trigger,
}: DropdownProps) {
  const { classes, cx } = useStyles({ transitionTimeout, dropdownPosition });
  const { triggerRef, dropdownRef, isOpen, toggle, position } = useDropdown<
    ReturnType<typeof trigger> extends React.ComponentType<infer P> ? P : never,
    HTMLDivElement
  >({
    dropdownPosition,
    dropdownOffset,
    controlledIsOpen,
    controlledOnChange,
  });

  return (
    <>
      {trigger({ ref: triggerRef, onClick: toggle, isOpen })}
      <CSSTransition
        in={isOpen}
        timeout={transitionTimeout}
        classNames={classes.dropdownBase}
        unmountOnExit
      >
        <div
          className={cx(classes.dropdownBase, dropdownClassName)}
          style={{ ...position }}
          ref={dropdownRef}
        >
          {children}
        </div>
      </CSSTransition>
    </>
  );
}

export default Dropdown;
