import type { AxiosResponse } from 'axios';

export const enum DeepLinkType {
  UNSUPPORTED_FEATURE = 'UNSUPPORTED_FEATURE',
  SIGN_UP = 'SIGN_UP',
  CONTEST_DETAIL = 'CONTEST_DETAIL',
  REFER_A_FRIEND = 'REFER_A_FRIEND',
}

export type DeepLinkPayload = {
  data: {
    $canonical_url: string;
    $desktop_url?: string;
    $after_click_url?: string;
  };

  // Analytics
  channel?: string;
  campaign?: string;
  feature?: string;
  stage?: string;

  // Auth
  branch_key: string;
};

export type APIBranchLinkResponse = { url: string };
export type AxiosBranchLinkResponse = AxiosResponse<APIBranchLinkResponse, unknown>;
